import React, { FC, useEffect, useState } from 'react'

import ITabulador from '../../../Interfaces/Catalogos/ITabulador'
import ITabuladorConceptos from '../../../Interfaces/Catalogos/ITabuladorConceptos'
import DTOConceptos from '../../../DTO/Utils/DTOConceptos'

import CTabDataService from '../../../Services/Corresponsalias/Corresponsales.Tabuladores.Services'
import CTabDetDataService from '../../../Services/Corresponsalias/Corresponsales.Tabuladores.Detalle.Sevices'
import ClientesDataService from '../../../Services/Catalogos/Clientes.Services'

import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { ControlledInput } from '../../Utils/ControlledInput/ControlledInput'
import DataTable from 'react-data-table-component'
import { Alert, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import 'react-data-table-component-extensions/dist/index.css'
import { BsPlusSquareFill } from 'react-icons/bs'
import IClientes from '../../../Interfaces/Catalogos/IClientes'
import { FcTimeline } from 'react-icons/fc'
import { FaEraser, FaPencilAlt, FaSave, FaTimesCircle } from 'react-icons/fa'
import CorresponsalesServices from '../../../Services/Catalogos/Corresponsales.Services'
import ICatCorresponsales from '../../../Interfaces/Catalogos/ICatCorresponsales'

interface IProps {}

interface IselectedRows {
  allSelected: boolean
  selectedCount: number
  selectedRows: any
}

export default function CatTabuladores (props: IProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Tabulador, setTabulador] = useState(0)
  const [Concepto, setConcepto] = useState(0)
  const [Cliente, setCliente] = useState(0)
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [NombreTabulador, setNombreTabulador] = useState('')
  const [DetalleConceptos, setDetalleConceptos] = useState<ITabuladorConceptos[]>([])
  const [CatConceptos, setCatConceptos] = useState<DTOConceptos[]>([])
  const [Tabuladores, setTabuladores] = useState<ITabulador[]>([])
  const [AllTabuladores, setAllTabuladores] = useState<ITabulador[]>([])
  const [Costo, setCosto] = useState('')
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [DialogTabs, setDialogTabs] = useState(false)
  const [ConceptoDialogTabs, setConceptoDialogTabs] = useState(false)
  const [Corresponsal, setCorresponsal] = useState(0)
  const [Corresponsales, setCorresponsales] = useState<ICatCorresponsales[]>([])
  const [Tipo, setTipo] = useState(0)
  const [Moneda, setMoneda] = useState(0)
  const columnsConcepts = [
    {
      name: 'Concepto',
      width: '55%',
      selector: (row: ITabuladorConceptos) => row.concepto,
      sortable: true,
    },
    {
      name: 'Costo Cliente',
      width: '15%',
      cell: (row: ITabuladorConceptos) => (
        <ControlledInput
          id={row.id}
          value={row.costo}
          postCost={(id, value) => {
            postCost(id, value, row.costoCorresponsal, row.idConcepto)
          }}
        />
      ),
    },
    {
      name: 'Costo Corresponsal',
      width: '15%',
      cell: (row: ITabuladorConceptos) => (
        <ControlledInput
          id={row.id}
          value={row.costoCorresponsal}
          postCost={(id, value) => {
            postCost(id, row.costo, value, row.idConcepto)
          }}
        />
      ),
    },
    {
      name: 'Elimina',
      width: '15%',
      cell: (row: ITabuladorConceptos) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setConcepto(row.id)
            setConceptoDialogTabs(true)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <FaTimesCircle />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const columnsTabs = [
    {
      name: 'Nombre',
      width: '70%',
      cell: (row: ITabulador) => (
        <div>
          {row.nombre}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Editar',
      width: '15%',
      cell: (row: ITabulador) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setTabulador(row.id)
            setNombreTabulador(row.nombre)
            setCorresponsal(row.idCorresponsal!)
            setTipo(row.tipo)
            setMoneda(row.moneda)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <FaPencilAlt />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Elimina',
      width: '15%',
      cell: (row: ITabulador) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setTabulador(row.id)
            setNombreTabulador(row.nombre)
            setDialogTabs(true)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <FaTimesCircle />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const postCost = (id: number, costoCliente: number, costoCorresponsal: number, idConcepto: number) => {
    const data: ITabuladorConceptos = {
      id: id,
      idTabulador: Tabulador,
      idConcepto: idConcepto,
      concepto: '',
      costo: costoCliente,
      activo: 1,
      costoCorresponsal: costoCorresponsal
    }
    CTabDetDataService.Append(data)
      .then((response) => {
        setDetalleConceptos(response.data)
        setHeader('Confirmacion')
        setMsg('La informacion se guardo corrctamente')
        setShowMsg(true)
        setConcepto(0)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const deleteTabulador = () => {
    CTabDataService.Delete(Tabulador)
      .then((response) => {
        if (response.status === 200) {
          setHeader('Informativo')
          setMsg(response.data.respuesta)
          setShowMsg(true)
          setDialogTabs(false)
          setTabuladores(Tabuladores.filter((data) => data.id != Tabulador))
          setAllTabuladores(Tabuladores)
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const deleteConcepto = () => {
    CTabDetDataService.Delete(Concepto)
    .then((resp) => {
      if(resp.status === 200){
        setHeader('Informativo')
        setMsg(resp.data.respuesta)
        setShowMsg(true)
        setConceptoDialogTabs(false)
        setDetalleConceptos(DetalleConceptos.filter(concepto => concepto.id != Concepto))
      }
    })
  }

  useEffect(() => {
    CTabDetDataService.getAllConcepts()
      .then((response) => {
        setCatConceptos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    ClientesDataService.getAllClientes(UserId)
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CorresponsalesServices.getAll()
      .then((response) => {
        setCorresponsales(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [])

  useEffect(() => {//Consulta los conceptos del tabulador
    CTabDetDataService.GetDetailByIdTab(Tabulador)
      .then((response) => {
        setDetalleConceptos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    console.log(Tabulador)
  }, [Tabulador])

  useEffect(() => {//Consulta los tabuladores de un cliente
    if (Cliente > 0) {
      setNombreTabulador('')
      setTabuladores([])
      setDetalleConceptos([])
      CTabDataService.GetByCustomer(Cliente, null)
        .then((response) => {
          setTabuladores(response.data)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  }, [Cliente])

  const filtraTabulador = (e: any) => {
    if (e.target.value !== '') {
      setTabuladores(
        AllTabuladores.filter(function (el) {
          return el.nombre.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
        })
      )
    } else {
      setTabuladores(AllTabuladores)
    }
  }

  const saveForm = () => {
    if(NombreTabulador){
      const data: ITabulador = {
        id: Tabulador,
        idCliente: Cliente,
        nombre: NombreTabulador,
        idCorresponsal:Corresponsal,
        tipo: Tipo,
        moneda: Moneda
      }
      CTabDataService.Append(data)
      .then((response) => {
        if (response.status === 200) {
          setHeader('Informativo')
          setMsg('La informacion se guardo correctamente')
          setShowMsg(true)
          if (Tabulador > 0) {
            let tmp = Tabuladores
            let idx = tmp.findIndex((obj) => obj.id == Tabulador)
            tmp[idx].nombre = NombreTabulador
            setTabuladores(tmp)
            setAllTabuladores(tmp)
          } else {
            setTabulador(response.data.id)
            const tmp = [...Tabuladores, response.data]
            setTabuladores(tmp)
            setAllTabuladores(tmp)
          }
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    }else{
      setHeader('Error')
      setMsg('Favor de capturar el nombre del tabulador')
      setShowMsg(true)
      setCorresponsal(0)
      return
    }
  }

  const addConcept = (idConcepto: number) => {
    if (Tabulador === 0) {
      setHeader('Error')
      setMsg('Por favor, primero seleccione el tabulador')
      setShowMsg(true)
      return
    }
    if (idConcepto === 0) {
      setHeader('Error')
      setMsg('Por favor, primero seleccione el concepto')
      setShowMsg(true)
      return
    }
    postCost(0, 0, 0, idConcepto)
  }

  const clearForm = () => {
    setTabulador(0)
    setNombreTabulador('')
    setCorresponsal(0)
    setConcepto(0)
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card style={{ height: '100%' }}>
            <Card.Body>
              <Container fluid>
                <Alert variant='primary'>
                  <Row>
                    <Col xs={1} style={{ paddingTop: '5px' }}>
                      <h5>Cliente: </h5>
                    </Col>
                    <Col xs={5}>
                      <h4>
                        <Form.Control
                          as='select'
                          onChange={(e) => {
                            setCliente(parseInt(e.target.value))
                          }}
                          className='form-select form-select-sm'
                          style={{ fontSize: '18px' }}
                        >
                          <option value='0'>-SELECCIONE-</option>
                          {Clientes
                            ? Clientes.map((c) => {
                                return (
                                  <option value={c.sClave} key={c.sClave}>
                                    {c.sRazonSocial}
                                  </option>
                                )
                              })
                            : null}
                        </Form.Control>
                      </h4>
                    </Col>
                  </Row>
                </Alert>
                <Row>
                  <Col xs={5}>
                    <Card style={{}}>
                      <Card.Header><h5>Tabuladores</h5></Card.Header>
                      <Card.Body>  
                        <Card.Subtitle className='mb-2 text-muted'>
                          <Row>
                          <Col
                              xs={1}
                              style={{ textAlign: 'right', paddingTop: '5px', cursor: 'pointer' }}
                              onClick={() => {clearForm()}}
                            >
                              <IconContext.Provider value={{ color: 'orange', size: '25px' }}>
                                <FaEraser />
                              </IconContext.Provider>
                            </Col>
                            <Col xs={10}>
                              <h4>
                                <Form.Control
                                  type='text'
                                  id='NombreTabulador'
                                  size='sm'
                                  value={NombreTabulador}
                                  onChange={(e) => {
                                    setNombreTabulador(e.target.value)
                                  }}
                                  placeholder='Ingrese el nombre del tabulador...'
                                  style={{ fontSize: '18px' }}
                                />
                              </h4>
                            </Col>
                            <Col xs={1} style={{ paddingTop: '5px' }}>
                                <IconContext.Provider value={{ color: 'green', size: '30px' }}>
                                  <FaSave  onClick={() => saveForm()} style={{ cursor: 'pointer' }}/>
                                </IconContext.Provider>
                            </Col>
                          </Row>
                        </Card.Subtitle>
                        <DataTable
                          noHeader
                          defaultSortFieldId={'id'}
                          defaultSortAsc={true}
                          pagination
                          highlightOnHover
                          columns={columnsTabs}
                          data={Tabuladores}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={7}>
                    <Card style={{}}>
                      <Card.Header><h5>Datos del Tabulador: {NombreTabulador}</h5></Card.Header>
                      <Card.Body>
                        <Card.Subtitle className='mb-2 text-muted'>
                          <Row>
                            <Col xs={3} style={{ paddingTop: '5px' }}>Corresponsal</Col>
                            <Col xs={8}>
                                <Form.Control
                                  disabled={Tabulador===0}
                                  as='select'
                                  onChange={(e) => {
                                    setCorresponsal(parseInt(e.target.value))
                                  }}
                                  className='form-select form-select-sm'
                                  style={{ fontSize: '18px' }}
                                  value={Corresponsal}
                                >
                                  <option value='0'>-SELECCIONE-</option>
                                  {Corresponsales
                                    ? Corresponsales.map((c) => {
                                        return (
                                          <option value={c.id} key={c.id}>
                                            {c.nombre} | {c.aduana} | {c.patente}
                                          </option>
                                        )
                                      })
                                    : null}
                                </Form.Control>
                            </Col>
                            <Col style={{ paddingTop: '5px' }}>
                                <IconContext.Provider value={{ color: 'green', size: '30px' }}>
                                  <FaSave  onClick={() => saveForm()} style={{ cursor: 'pointer' }}/>
                                </IconContext.Provider>
                            </Col>
                          </Row>
                          <Row style={{ paddingTop: '5px' }}>
                            <Col xs={6}>
                              <Row>
                                <Col xs={3}>Tipo: </Col>
                                <Col xs={9}>
                                  <Form>
                                    <Form.Check
                                      type='radio'
                                      id='radio-Impo'
                                      value={1}
                                      label='Impo'
                                      inline
                                      name='grupo'
                                      onChange={e => setTipo(parseInt(e.target.value))}
                                      checked={Tipo === 1}
                                      disabled={Tabulador===0}
                                    />
                                    <Form.Check 
                                      type='radio' 
                                      id='radio-Expo' 
                                      value={2} 
                                      label='Expo' 
                                      inline 
                                      name='grupo' 
                                      onChange={e => setTipo(parseInt(e.target.value))} 
                                      checked={Tipo === 2}
                                      disabled={Tabulador===0}
                                    />
                                  </Form>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={6}>
                              <Row>
                                <Col xs={4}>Moneda:</Col>
                                <Col xs={8}>
                                  <Form>
                                    <Form.Check 
                                      type='radio' 
                                      id='radio-USD' 
                                      value={1} 
                                      label='USD' 
                                      inline 
                                      name='grupo' 
                                      onChange={e => setMoneda(parseInt(e.target.value))} 
                                      checked={Moneda === 1}
                                      disabled={Tabulador===0}
                                    />
                                    <Form.Check 
                                      type='radio' 
                                      id='radio-MXN' 
                                      value={2} 
                                      label='MXN' 
                                      inline 
                                      name='grupo' 
                                      onChange={e => setMoneda(parseInt(e.target.value))} 
                                      checked={Moneda === 2}
                                      disabled={Tabulador===0}
                                    />
                                  </Form>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col xs={2} style={{ paddingTop: '8px' }}>
                              Conceptos
                            </Col>
                            <Col xs={10}>
                              <Form.Control
                                disabled={Tabulador===0}
                                as='select'
                                onChange={(e) => addConcept(parseInt(e.target.value))}
                                className='form-select form-select-sm'
                                style={{ fontSize: '17px' }}
                                value={Concepto}
                              >
                                <option value='0'>- Seleccione -</option>
                                {CatConceptos
                                  ? CatConceptos.map((c) => {
                                      return (
                                        <option value={c.id} key={c.id}>
                                          {c.concepto}
                                        </option>
                                      )
                                    })
                                  : null}
                              </Form.Control>
                            </Col>
                            {/* <Col style={{ paddingTop: '5px' }}>
                              <Form.Label
                                onClick={() => {
                                  addConcept()
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <IconContext.Provider value={{ color: 'green', size: '30px' }}>
                                  <BsPlusSquareFill />
                                </IconContext.Provider>
                              </Form.Label>
                            </Col> */}
                          </Row>
                        </Card.Subtitle>
                        <DataTable
                          noHeader
                          defaultSortFieldId={'id'}
                          defaultSortAsc={true}
                          pagination
                          highlightOnHover
                          columns={columnsConcepts}
                          data={DetalleConceptos}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
      <Modal
        show={DialogTabs}
        onHide={() => {
          setDialogTabs(false)
        }}
        size='sm'
      >
        <Modal.Body>
          ¿Esta seguro de eliminar:
          <br /> {NombreTabulador}?
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant='danger'
                onClick={deleteTabulador}
                size='sm'
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Si
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='secondary'
                onClick={() => {
                  setDialogTabs(false)
                }}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal
        show={ConceptoDialogTabs}
        onHide={() => {
          setConceptoDialogTabs(false)
        }}
        size='sm'
      >
        <Modal.Body>
          ¿Esta seguro de eliminar este concepto?
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant='danger'
                onClick={deleteConcepto}
                size='sm'
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Si
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='secondary'
                onClick={() => {
                  setConceptoDialogTabs(false)
                }}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
