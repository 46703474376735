import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICorresponsalAnticipos from '../../../Interfaces/Corresponsales/ICorresponsalAnticipos';
const CorresponsalesAnticipos: ICorresponsalAnticipos[] = 
 [{ id: 0,
    idTrafico: 0,
    anticipo: 0,
    moneda: 0,
    concepto: '',
    solicita: 0,
    fhSolicita: '',
    autoriza: 0,
    fhAutoriza: '',
    financiado:0,
    activo: false}]
const initialState = { CorresponsalesAnticipos }

export const CorresponsalesAnticiposSlice = createSlice({
  name: 'CorresponsalesAnticipos',
  initialState: initialState,
  reducers: {
    populateCorresponsalesAnticipos : (state, action: PayloadAction<ICorresponsalAnticipos[]>) => {
      state.CorresponsalesAnticipos = [{ id: 0, idTrafico: 0, anticipo: 0, moneda: 0, concepto: '-Seleccione-', solicita: 0, fhSolicita: '', autoriza: 0, fhAutoriza: '', financiado: 0, activo: false}]
      state.CorresponsalesAnticipos.push(... action.payload)
    },
    addCorresponsalesAnticipos : (state, action: PayloadAction<ICorresponsalAnticipos>) => {
      var Existe = state.CorresponsalesAnticipos.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.CorresponsalesAnticipos.push(action.payload)
    },
    updateCorresponsalesAnticipos : (state, action: PayloadAction<ICorresponsalAnticipos>) => {
      const i = state.CorresponsalesAnticipos.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.CorresponsalesAnticipos[i] = action.payload;
      else state.CorresponsalesAnticipos.push(action.payload);
    },
    deleteCorresponsalesAnticipos : (state, action: PayloadAction<number>) => {
      const newArr = state.CorresponsalesAnticipos.filter(data => data.id != action.payload);
      state.CorresponsalesAnticipos=newArr
    },
    InitCorresponsalesAnticipos : (state, action: PayloadAction<number>) => {
      state.CorresponsalesAnticipos=[{ 
        id: 0, 
        idTrafico: 0, 
        anticipo: 0, 
        moneda: 0, 
        concepto: '-Seleccione-', 
        solicita: 0, 
        fhSolicita: '', 
        autoriza: 0, 
        fhAutoriza: '', 
        financiado: 0, 
        activo: false}]
    },
  },
})

export const { addCorresponsalesAnticipos, 
  populateCorresponsalesAnticipos, 
  updateCorresponsalesAnticipos, 
  deleteCorresponsalesAnticipos,
  InitCorresponsalesAnticipos } = CorresponsalesAnticiposSlice.actions;
export default CorresponsalesAnticiposSlice.reducer;
