import http from "../common/http-common";
import ICorresponsalCatDestinos from "../../Interfaces/Corresponsales/ICorresponsalCatDestinos";

class CorresponsalesDataService {

  getAll(idCliente: number) {
    return http.get<ICorresponsalCatDestinos[]>(`/Corresponsalias/CatDestinos/GetAll?idCliente=${idCliente}`);
  }

}
export default new CorresponsalesDataService();