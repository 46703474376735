import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import CContDataService from '../../../Services/Corresponsalias/Corresponsales.Contenedores.Services'
import ICorresponsalContenedores from '../../../Interfaces/Corresponsales/ICorresponsalContenedores'
import { IconContext } from 'react-icons'
import { BsFillCalendarCheckFill, BsFillCalendarDayFill, BsFillRecordFill, BsFillXCircleFill, BsLink45Deg, BsPencil } from 'react-icons/bs'
import {
  deleteCorresponsalesContenedores,
  updateCorresponsalesContenedores,
} from '../../../store/features/Corresponsales/CorresponsalesContenedoresSlice'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
//Images
import caret from '../../../images/caret.png';
import clean from '../../../images/Clean.png';
import { FaFile, FaPencilAlt } from 'react-icons/fa'
import { FacturasContenedor } from './FacturasContenedor'

interface IProps {
  IDTrafico: number
  Editable: boolean
  setFechaDesaduanamiento: any
  FechaDesaduanamiento: Date
  setSemaforoFiscal: any
  SemaforoFiscal: number
}

export const SelectContainer: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const [toggleSelect, setToggleSelect] = useState(false)
  const mCContenedores = useSelector((state: RootState) => state.CCData.CorresponsalesContenedores)
  const mCTiposContenedores = useSelector((state: RootState) => state.CTiposContenedores.CatTiposContenedores)
  const [Contenedor, setContenedor] = useState('')
  const [IDContenedor, setIDContenedor] = useState(0)
  const [Placeholder, setPlaceholder] = useState('')
  const [DialogTabs, setDialogTabs] = useState(false)
  const [DialogAddDate, setDialogAddDate] = useState(false)
  const [FSemaforo, setFSemaforo] = useState<Date | null>()
  const [Semaforo, setSemaforo] = useState(0)
  const [ShowFacturasDialog, setShowFacturasDialog] = useState(false)
  const [IdTipoContenedor, setIdTipoContenedor] = useState(0)

  useEffect(() => {
    setPlaceholder('(' + mCContenedores.filter((item) => item.id > 0).length + ') Contenedores')
    if(mCContenedores.length > 1){
      setTimeout(() => {
        setFechaDesaduanamiento();
        setSemaforoFiscal();  
      }, 1000);
    }
  }, [mCContenedores])

  const loadInfo = (data: ICorresponsalContenedores) => {
    setIDContenedor(data.id)
    setContenedor(data.contenedor)
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const data: ICorresponsalContenedores = {
        id: IDContenedor,
        contenedor: Contenedor,
        idTrafico: props.IDTrafico,
        fSemaforo: FSemaforo ? adjustTimeZone(FSemaforo.toISOString()) : '',
        semaforo: 2,
        idTipoContenedor: 0
      }
      CContDataService.Append(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch(updateCorresponsalesContenedores(response.data))
            setContenedor('')
            setToggleSelect(true)
          }
        })
        .catch((e: Error) => {
          return
        })
    }
  }

  const adjustTimeZone = (str: string): string => {
    if (str) {
      const original: any = new Date(str)
      const real = new Date(original - original.getTimezoneOffset() * 60 * 1000)
      return real.toISOString()
    } else return ''
  }

  const updateItem = () => {
    const data: ICorresponsalContenedores = {
      id: IDContenedor,
      contenedor: Contenedor,
      idTrafico: props.IDTrafico,
      fSemaforo: FSemaforo ? adjustTimeZone(FSemaforo.toISOString()) : '',
      semaforo: Semaforo,
      idTipoContenedor: IdTipoContenedor
    }
    // console.log(JSON.stringify(data))
    CContDataService.Append(data)
      .then((response) => {
        if (response.status === 200) {
          setIDContenedor(0)
          setContenedor('')
          setDialogTabs(false)
          setFSemaforo(null)
          setSemaforo(0)
          dispatch(updateCorresponsalesContenedores(response.data))
          setDialogAddDate(false)
          return
        }
      })
      .catch((e: Error) => {
        return
      })
  }

  const deleteItem = () => {
    CContDataService.Delete(IDContenedor).then( (response) => {
      if(response.status === 200){
        dispatch(deleteCorresponsalesContenedores(IDContenedor))
        setIDContenedor(0)
        setContenedor('')
        setDialogTabs(false)
        setFSemaforo(null)
          setSemaforo(0)
        return;
      }
    }).catch((e: Error) => {
      return;
    });
  }

  const StrtoDate = (str: string): Date | null => {
    if (str) {
      const original: any = new Date(str)
      return original
    } else return null
  }

  const setFechaDesaduanamiento = () => {
    //Se valida si hay contenedores a los que no se les ha asignado una fecha
    let contenedoresSinFecha = mCContenedores.filter((item) => item.id > 0 && item.fSemaforo === null)
    if(contenedoresSinFecha.length > 0 && props.FechaDesaduanamiento === null){ //Si los hay, se borra la fecha de desaduanamiento
      props.setFechaDesaduanamiento(null);
    }else{ //Si no los hay, se busca el contenedor con la fecha más reciente
      let contenedor =  mCContenedores.reduce((a,b) => a.fSemaforo > b.fSemaforo ? a : b);
      //Se valida si la fecha del contenedor es mayor a la fecha de desaduanamiento actual para cambiarla. 
      if(props.FechaDesaduanamiento < new Date(contenedor.fSemaforo)) props.setFechaDesaduanamiento(new Date(contenedor.fSemaforo))
    }
  }

  const setSemaforoFiscal = () => {
    //Se valida si hay contenedores cuyo semaforo se encuentre en rojo (1) o sin seleccionar (0)
    let contenedoresEnRojo = mCContenedores.filter((item) => item.id > 0 && (item.semaforo === 1 || item.semaforo === 0))
    if(contenedoresEnRojo.length > 0){
      //Si los hay, se asigna el semaforo en rojo (0) en el componente padre
      props.setSemaforoFiscal(0)
    }else{ //Si no los hay, se establece el semaforo fiscal en verde (1) en el componente padre
      props.setSemaforoFiscal(1)
    }
  }

  return (
    <div>
      <div className='form-group'>
        <div className='col-sm-12 parent'>
          <div className='input-group'>
            <input type='hidden' className='form-control' name='IDContenedor' id='IDContenedor' value={IDContenedor} />
            <span className='input-group-addon sorroundImage'>
              <img
                src={clean}
                width='28'
                height='28'
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => {
                  setIDContenedor(0)
                  setContenedor('')
                }}
              />
            </span>
            <input
              type='text'
              className='form-control genericSelect'
              name='Contenedor'
              id='Contenedor'
              style={{ height: '30px' }}
              value={Contenedor}
              placeholder={Placeholder}
              disabled={!props.Editable}
              onChange={(e) => {
                setContenedor(e.target.value)
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              autoComplete='none'
            />
            <span className='input-group-addon sorroundImage'>
              <img
                src={caret}
                width='28'
                height='28'
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => {
                  setToggleSelect(!toggleSelect)
                }}
              />
            </span>
            <div className='child' style={toggleSelect ? { visibility: 'visible' } : { visibility: 'hidden' }}>
              <ul className='cleanLi'>
                {mCContenedores
                  ? mCContenedores
                      .map((item, index) => {
                        return item.id > 0 ? (
                          <li key={item.id} style={{marginBottom:'3px'}} onClick={() => loadInfo(item)}>
                            <span style={{display: props.Editable && toggleSelect ? 'inline-block' : 'none',}}>
                              <IconContext.Provider value={{ color: 'red', size: '15px' }}>
                                <BsFillXCircleFill className='pointer' onClick={() => {setDialogTabs(true)}}/>
                              </IconContext.Provider>
                            </span>
                            <span style={{display: props.Editable && toggleSelect ? 'inline-block' : 'none', paddingLeft:'3px'}}>
                                <IconContext.Provider value={{ color: 'green', size: '15px' }}>
                                  <BsLink45Deg className='pointer' onClick={() => setShowFacturasDialog(true)}/>
                                </IconContext.Provider>
                            </span>
                            {/* <span style={{paddingLeft:'3px'}}>
                                <IconContext.Provider value={{ color: 'blue', size: '15px' }}>
                                  <BsPencil className='pointer' onClick={() => loadInfo(item)} />
                                </IconContext.Provider>
                            </span> */}
                            <span style={{ paddingLeft: '10px' }}>{item.contenedor}</span>
                            <span>
                              &nbsp;| {item.fSemaforo} |{
                                item.semaforo === 1 ? (
                                  <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                    <BsFillRecordFill />
                                  </IconContext.Provider>
                                ) : item.semaforo === 2 ?(
                                  <IconContext.Provider value={{ color: '#4BFF59', size: '20px' }}>
                                    <BsFillRecordFill />
                                  </IconContext.Provider>
                                ) : ('')
                              }
                              <span>
                                <IconContext.Provider value={{ color: 'green', size: '20px' }}>
                                  <BsFillCalendarCheckFill
                                    className='pointer'
                                    style={{
                                      paddingLeft: '5px',
                                      visibility: props.Editable && toggleSelect ? 'visible' : 'hidden',
                                    }}
                                    onClick={() => {
                                      setFSemaforo(StrtoDate(item.fSemaforo))
                                      setSemaforo(item.semaforo)
                                      setIdTipoContenedor(item.idTipoContenedor)
                                      setDialogAddDate(true)
                                      setToggleSelect(!toggleSelect)
                                    }}
                                  />
                                </IconContext.Provider>
                              </span>
                            </span>
                          </li>
                        ) : (
                          ''
                        )
                      })
                      .reverse()
                  : ''}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={DialogTabs}
        onHide={() => {
          setDialogTabs(false)
        }}
        size='sm'
      >
        <Modal.Body>
          <Alert variant='primary'>
            <b>¿Esta seguro de eliminar?</b>
            <br />
            <h5> {Contenedor} </h5>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant='danger'
                onClick={deleteItem}
                size='sm'
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Si
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='secondary'
                onClick={() => {
                  setDialogTabs(false)
                }}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={DialogAddDate}
        onHide={() => {
          setDialogTabs(false)
        }}
        /*         size='sm' */
      >
        <Modal.Body>
          <Alert variant='primary'>
            <Row>
              <Col xs={12}>
                <b>¿Esta seguro de agregar fecha al contenedor: {Contenedor}?</b>
              </Col>
            </Row>
            <Row className='justify-content-end'>
              <Col xs={5}>
                Tipo de Contenedor
              </Col>
              <Col xs={7}>
                <Form.Control
                  as='select'
                  onChange={(e) => {setIdTipoContenedor(+e.target.value)}}
                  value={IdTipoContenedor}
                  className='form-select form-select-sm'
                >
                  <option value={0}>-SELECCIONE-</option>
                  {mCTiposContenedores ? mCTiposContenedores.map((t) => {
                    return <option value={t.id}>{t.descripcion}</option>
                  }) : null}
                </Form.Control>
              </Col>
            </Row>
            <Row style={{ paddingTop: '10px' }}>
              <Col xs={4}></Col>
              <Col xs={2}>Fecha</Col>
              <Col xs={6}>
                <DatePicker
                  selected={FSemaforo}
                  onChange={(date: any) => setFSemaforo(date)}
                  showTimeSelect
                  dateFormat='MM/dd/yyyy HH:mm:ss'
                  placeholderText='No definido'
                  isClearable
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: '10px' }}>
              <Col xs={5}></Col>
              <Col xs={2}>Semaforo</Col>
              <Col xs={5}>
                <Form.Control
                  as='select'
                  onChange={(e) => {
                    setSemaforo(parseInt(e.target.value))
                  }}
                  value={Semaforo}
                  className='form-select form-select-sm'
                >
                  <option value='0'>-SELECCIONE-</option>
                  <option value='1'>Rojo</option>
                  <option value='2'>Verde</option>
                </Form.Control>
              </Col>
            </Row>
          </Alert>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant='primary'
                onClick={updateItem}
                size='sm'
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Guardar
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='secondary'
                onClick={() => {
                  setIDContenedor(0)
                  setContenedor('')
                  setDialogAddDate(false)
                }}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={ShowFacturasDialog}
        onHide={() => setShowFacturasDialog(false)}
        centered
        dialogClassName='modal-20w'
      >
        <Modal.Body
          style={{
            height: 'calc(70vh)',
          }}
        >
          <div style={{height:'100%'}}>
            <FacturasContenedor IdContenedor={IDContenedor} Contenedor={Contenedor} IDTrafico={props.IDTrafico}/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Button
              variant='secondary'
              onClick={() => {
                setShowFacturasDialog(false)
              }}
              size='sm'
            >
              Cerrar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
