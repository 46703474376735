import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICorresponsalContenedores from '../../../Interfaces/Corresponsales/ICorresponsalContenedores';
const CorresponsalesContenedores: ICorresponsalContenedores[] = [{id:0, contenedor:'-Seleccione-', idTrafico:0, fSemaforo:'', semaforo:0, idTipoContenedor:0}]
const initialState = { CorresponsalesContenedores }

export const CorresponsalesContenedoresSlice = createSlice({
  name: 'CorresponsalesContenedores',
  initialState: initialState,
  reducers: {
    populateCorresponsalesContenedores : (state, action: PayloadAction<ICorresponsalContenedores>) => {
    },
    addCorresponsalesContenedores : (state, action: PayloadAction<ICorresponsalContenedores>) => {
      var Existe = state.CorresponsalesContenedores.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.CorresponsalesContenedores.push(action.payload)
    },
    updateCorresponsalesContenedores : (state, action: PayloadAction<ICorresponsalContenedores>) => {
      const i = state.CorresponsalesContenedores.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.CorresponsalesContenedores[i] = action.payload;
      else state.CorresponsalesContenedores.push(action.payload);
    },
    deleteCorresponsalesContenedores : (state, action: PayloadAction<number>) => {
      const newArr = state.CorresponsalesContenedores.filter(data => data.id != action.payload);
      state.CorresponsalesContenedores=newArr
    },
    InitCorresponsalesContenedores : (state, action: PayloadAction<number>) => {
      //state.CorresponsalesContenedores.splice(0,state.CorresponsalesContenedores.length-1)
      state.CorresponsalesContenedores=[{id:0, contenedor:'-Seleccione-', idTrafico:0, fSemaforo:'', semaforo:0, idTipoContenedor:0}]
    },
    addTxtContenedores: (state, action: PayloadAction<ICorresponsalContenedores[]>) => {
      const newArr = [...state.CorresponsalesContenedores, ...action.payload]
      state.CorresponsalesContenedores=newArr
    }
  },
})

export const { addCorresponsalesContenedores, 
  populateCorresponsalesContenedores, 
  updateCorresponsalesContenedores, 
  deleteCorresponsalesContenedores,
  InitCorresponsalesContenedores, addTxtContenedores } = CorresponsalesContenedoresSlice.actions;
export default CorresponsalesContenedoresSlice.reducer;
