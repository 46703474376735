export class TargetURL {
  get() {
   /*  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'https://gemcousa.solutions/GEMCOBackend/api'
      'https://localhost:5001/api'
      : "http://reportes.gemcousa.com:5000/api";
          'https://www.gemcousa.mx/GEMCOBackend/api'
        'https://gemcousa.solutions/GEMCOBackend/api' */
        return process.env.REACT_APP_API
  }
}
