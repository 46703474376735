import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICorresponsalesPartidas from '../../../Interfaces/Corresponsales/ICorresponsalPedimentoPartidas';
const CorresponsalesPartidas: ICorresponsalesPartidas[] = [{id:0, idTrafico:0, partida:0, idFactura:0, factura:'', proveedor:'', descripcionMaterial:'', fraccionArancelaria:'', valorAduana:0, dta:0, igi:0, ieps:0, activo:0}]
const initialState = { CorresponsalesPartidas }

export const CorresponsalesPartidasSlice = createSlice({
  name: 'CorresponsalesPartidas',
  initialState: initialState,
  reducers: {
    populateCorresponsalesPartidas : (state, action: PayloadAction<ICorresponsalesPartidas[]>) => {
      state.CorresponsalesPartidas=CorresponsalesPartidas
     // state.CorresponsalesPartidas.push(... action.payload)
     state.CorresponsalesPartidas=action.payload
    },
    updateCorresponsalesPartidas : (state, action: PayloadAction<ICorresponsalesPartidas>) => {
      console.log('action.payload='+action.payload);
      const i = state.CorresponsalesPartidas.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.CorresponsalesPartidas[i] = action.payload;
      else state.CorresponsalesPartidas.push(action.payload);
    },
    deleteCorresponsalesPartidas : (state, action: PayloadAction<number>) => {
      const newArr = state.CorresponsalesPartidas.filter(data => data.id !== action.payload);
      state.CorresponsalesPartidas=newArr
    },
    InitCorresponsalesPartidas : (state, action: PayloadAction<number>) => {
      state.CorresponsalesPartidas=CorresponsalesPartidas
    },
  },
})

export const { 
  populateCorresponsalesPartidas, 
  updateCorresponsalesPartidas, 
  deleteCorresponsalesPartidas,
  InitCorresponsalesPartidas } = CorresponsalesPartidasSlice.actions;
export default CorresponsalesPartidasSlice.reducer;
