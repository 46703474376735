import http from "../common/http-common";
import ICorresponsalesGuias from "../../Interfaces/Corresponsales/ICorresponsalesGuias";
import IRespuesta from "../../Interfaces/IRespuesta";

class CorresponsalesGuiasDataService {
  getAll(idTrafico: number) {
    return http.get<ICorresponsalesGuias[]>(`/Corresponsalias/Guias/getAll?idTrafico=${idTrafico}`);
  }
  Append(data: ICorresponsalesGuias) {
    return http.post<ICorresponsalesGuias>("/Corresponsalias/Guias/Append", data);
  }
  Delete(id: number) {
    return http.delete<IRespuesta>(`/Corresponsalias/Guias/Delete/${id}`);
  }
}
export default new CorresponsalesGuiasDataService();