import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DTOPedimentosConsolidados } from "../../../DTO/Corresponsales/DTOPedimentosConsolidados";

const PedimentosConsolidados: DTOPedimentosConsolidados[] = []

const initialState = {PedimentosConsolidados};

export const PedimentosConsolidadosSlice = createSlice({
    name: 'PedimentosConsolidados',
    initialState: initialState,
    reducers: {
        populatePedimentos : (state, action: PayloadAction<DTOPedimentosConsolidados[]>) => {
            state.PedimentosConsolidados = []
            state.PedimentosConsolidados.push(... action.payload)
          },
        addPedimento : (state, action: PayloadAction<DTOPedimentosConsolidados>) => {
            var Existe = state.PedimentosConsolidados.find(function(item) {
              return item.id === action.payload.id;
            });
           if (!Existe) state.PedimentosConsolidados.push(action.payload)
          },
          updatePedimento : (state, action: PayloadAction<DTOPedimentosConsolidados>) => {
            const i = state.PedimentosConsolidados.findIndex(_element => _element.id === action.payload.id);
            if (i > -1) state.PedimentosConsolidados[i] = action.payload;
            else state.PedimentosConsolidados.push(action.payload);
          },
          deletePedimento : (state, action: PayloadAction<number>) => {
            const newArr = state.PedimentosConsolidados.filter(data => data.id != action.payload);
            state.PedimentosConsolidados=newArr
          },
    }
})


export const { populatePedimentos, 
    addPedimento, 
    updatePedimento, 
    deletePedimento,
} = PedimentosConsolidadosSlice.actions;
export default PedimentosConsolidadosSlice.reducer;