import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import CGuiasDataService from '../../../Services/Corresponsalias/Corresponsales.Guias.Services'
import ICorresponsalesGuias from '../../../Interfaces/Corresponsales/ICorresponsalesGuias'
import { IconContext } from 'react-icons'
import { BsFillXCircleFill } from 'react-icons/bs'
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap'
import {
  deleteCorresponsalesGuias,
  updateCorresponsalesGuias,
} from '../../../store/features/Corresponsales/CorresponsalesGuiasSlice'
//Images
import caret from '../../../images/caret.png';
import clean from '../../../images/Clean.png';

interface IProps {
  IDTrafico: number
  Editable: boolean
}

export const SelectGuias: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const [toggleSelect, setToggleSelect] = useState(false)
  const mCGuias = useSelector((state: RootState) => state.CGuias.CorresponsalesGuias)
  const [Guia, setGuia] = useState('')
  const [IDGuia, setIDGuia] = useState(0)
  const [Placeholder, setPlaceholder] = useState('')
  const [DialogTabs, setDialogTabs] = useState(false)

  useEffect(() => {
    setPlaceholder('(' + mCGuias.filter((item) => item.id > 0).length + ') Guias')
  }, [mCGuias])

  const loadInfo = (data: ICorresponsalesGuias) => {
    setIDGuia(data.id)
    setGuia(data.guia)
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const data: ICorresponsalesGuias = {
        id: IDGuia,
        guia: Guia,
        idTrafico: props.IDTrafico,
      }
      CGuiasDataService.Append(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch(updateCorresponsalesGuias(response.data))
            setGuia('')
            setToggleSelect(true)
          }
        })
        .catch((e: Error) => {
          return
        })
    }
  }

  const deleteItem = () => {
    CGuiasDataService.Delete(IDGuia)
      .then((response) => {
        if (response.status === 200) {
          setIDGuia(0)
          setGuia('')
          setDialogTabs(false)
          dispatch(deleteCorresponsalesGuias(IDGuia))
          return
        }
      })
      .catch((e: Error) => {
        return
      })
  }
  return (
    <div>
      {' '}
      <div className='form-group'>
        <div className='col-sm-12 parent'>
          <div className='input-group'>
            <input type='hidden' className='form-control' name='IDGuia' id='IDGuia' value={IDGuia} />
            <span className='input-group-addon  sorroundImage'>
              <img
                src={clean}
                width='28'
                height='28'
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => {
                  setIDGuia(0)
                  setGuia('')
                }}
              />
            </span>
            <input
              type='text'
              className='form-control genericSelect'
              name='Guia'
              id='Guia'
              style={{ height: '30px' }}
              value={Guia}
              placeholder={Placeholder}
              disabled={!props.Editable}
              onChange={(e) => {
                setGuia(e.target.value)
              }}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <span className='input-group-addon sorroundImage'>
              <img
                src={caret}
                width='28'
                height='28'
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => {
                  setToggleSelect(!toggleSelect)
                }}
              />
            </span>
            <div className='child' style={toggleSelect ? { visibility: 'visible' } : { visibility: 'hidden' }}>
              <ul className='cleanLi'>
                {mCGuias
                  ? mCGuias
                      .map((item, index) => {
                        return item.id > 0 ? (
                          <li key={item.id} onClick={() => loadInfo(item)}>
                            <span
                              onClick={() => {
                                setDialogTabs(true)
                              }}
                            >
                              <IconContext.Provider value={{ color: 'red', size: '15px' }}>
                                <BsFillXCircleFill />
                              </IconContext.Provider>
                            </span>
                            <span style={{ paddingLeft: '10px' }}>{item.guia}</span>
                          </li>
                        ) : (
                          ''
                        )
                      })
                      .reverse()
                  : ''}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={DialogTabs}
        onHide={() => {
          setDialogTabs(false)
        }}
        size='sm'
      >
        <Modal.Body>
          <Alert variant='primary'>
            <b>¿Esta seguro de eliminar?</b>
            <br />
            <h5> {Guia} </h5>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant='danger'
                onClick={deleteItem}
                size='sm'
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Si
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='secondary'
                onClick={() => {
                  setDialogTabs(false)
                }}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
