import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICorresponsalFacturasTerceros from '../../../Interfaces/Corresponsales/ICorresponsalFacturasTerceros';
const CorresponsalesFacturasTerceros: ICorresponsalFacturasTerceros[] = [{id:0, factura:'-Seleccione-', idProveedor:0, idTrafico:0}]
const initialState = { CorresponsalesFacturasTerceros }

export const CorresponsalesFacturasTercerosSlice = createSlice({
  name: 'CorresponsalesFacturasTerceros', 
  initialState: initialState,
  reducers: {
    populateCorresponsalesFacturasTerceros : (state, action: PayloadAction<ICorresponsalFacturasTerceros[]>) => {
      state.CorresponsalesFacturasTerceros = [{id:0, factura:'-Seleccione-', idProveedor:0, idTrafico:0}]
      state.CorresponsalesFacturasTerceros.push(...action.payload)
    },
    addCorresponsalesFacturasTerceros : (state, action: PayloadAction<ICorresponsalFacturasTerceros>) => {
      var Existe = state.CorresponsalesFacturasTerceros.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.CorresponsalesFacturasTerceros.push(action.payload)
    },
    updateCorresponsalesFacturasTerceros : (state, action: PayloadAction<ICorresponsalFacturasTerceros>) => {
      const i = state.CorresponsalesFacturasTerceros.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.CorresponsalesFacturasTerceros[i] = action.payload;
      else state.CorresponsalesFacturasTerceros.push(action.payload);
    },
    deleteCorresponsalesFacturasTerceros : (state, action: PayloadAction<number>) => {
      const newArr = state.CorresponsalesFacturasTerceros.filter(data => data.id != action.payload);
      state.CorresponsalesFacturasTerceros=newArr
    },
    InitCorresponsalesFacturasTerceros : (state, action: PayloadAction<number>) => {
      state.CorresponsalesFacturasTerceros.splice(0,state.CorresponsalesFacturasTerceros.length-1)
    },
  },
})

export const { addCorresponsalesFacturasTerceros, 
  populateCorresponsalesFacturasTerceros, 
  updateCorresponsalesFacturasTerceros, 
  deleteCorresponsalesFacturasTerceros,
  InitCorresponsalesFacturasTerceros } = CorresponsalesFacturasTercerosSlice.actions;
export default CorresponsalesFacturasTercerosSlice.reducer;
