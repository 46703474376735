import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import { CiWarning } from "react-icons/ci";
import { MsgInformativo } from "../../Utils/Toast/msgInformativo";
import '../../../css/generic01.css'
import { ICorresponsalTraficoSolicitud } from "../../../Interfaces/Corresponsales/ICorresponsalTraficoSolicitud";
import { DTOTraficoSolicitud } from "../../../DTO/Corresponsales/DTOTraficoSolicitud";
import CTrafDataService from "../../../Services/Corresponsalias/Corresponsales.Trafico.Services"
import { useState } from "react";
import CorresponsalesTraficoSolicitudService from "../../../Services/Corresponsalias/Corresponsales.Trafico.Solicitud.Service";

interface IProps {
    solicitud: DTOTraficoSolicitud;
    userId: number;
    close: (estado: number) => void
    title: string;
    mensaje: string;
}
export const AutorizarSolicitud:React.FC<IProps> = (props) => {
    const [Motivo, setMotivo] = useState('')
    const [showMsg, setShowMsg] = useState(false)
    const [msg, setMsg] = useState('')
    const [header, setHeader] = useState('')
    const [SelectedOption, setSelectedOption] = useState(2)
    const [Depto, setDepto] = useState(() => {
        const stickyValue = window.localStorage.getItem('Departamento')
        return stickyValue !== null ? JSON.parse(stickyValue) : ''
    })
    const [Perfil, setPerfil] = useState(() => {
        const stickyValue = window.localStorage.getItem('Perfil')
        return stickyValue !== null ? JSON.parse(stickyValue) : ''
    })

    const AppendSolicitud = () =>{
        if(SelectedOption === 0 && !Motivo){
            setHeader('Error');
            setMsg('Favor de capturar una razon válida.')
            setShowMsg(true)
            return;
        }
        if(SelectedOption === 0 &&  Motivo.length < 30){
            setHeader('Error');
            setMsg('La razón del rechazo debe contener al menos 30 caracteres')
            setShowMsg(true)
            return;
        }
        var solicitud: ICorresponsalTraficoSolicitud = {
            id: props.solicitud.id,
            idTrafico: props.solicitud.idTrafico,
            idUsuarioSolicita: props.solicitud.idUsuarioSolicita,
            fechaSolicita: props.solicitud.fechaSolicita,
            motivoSolicita: props.solicitud.motivoSolicita,
            idUsuarioAutoriza: props.userId,
            fechaAutoriza: undefined,
            motivoRechazo: Motivo,
            estado: SelectedOption
        }
        if(Depto === 'Facturacion'){
            AppendSolicitudReapertura(solicitud)
        }
        if(Depto === 'Corresponsalias'){
            AppendSolicitudEliminarReferencia(solicitud)
        }
    }

    const AppendSolicitudReapertura = (solicitud: ICorresponsalTraficoSolicitud) => {
        CorresponsalesTraficoSolicitudService.AppendSolicitudReapertura(solicitud)
        .then((resp) => {
            if(resp.data.id > 0){
                props.close(SelectedOption)
            }
        })
        .catch(() => {
            setHeader('Error');
            setMsg('Ocurrió un error. Favor de intentar nuevamente')
            setShowMsg(true)
            return;
        })
    }

    const AppendSolicitudEliminarReferencia = (solicitud: ICorresponsalTraficoSolicitud) => {
        CorresponsalesTraficoSolicitudService.AppendSolicitudEliminarReferencia(solicitud)
        .then((resp) => {
            if(resp.data.id > 0){
                props.close(SelectedOption)
            }
        })
        .catch(() => {
            setHeader('Error');
            setMsg('Ocurrió un error. Favor de intentar nuevamente')
            setShowMsg(true)
            return;
        })
    }

    return(
        <>
            <h3>{props.title}</h3>
            <hr/>
            <Row>
                <CiWarning style={{fontSize:'150px', color:'#ff3100'}} />
            </Row>
            <Row>
                <Col xs={12}>
                    <p style={{textAlign:'center'}}>
                        {props.solicitud.usuarioSolicita} {props.mensaje}
                    </p>
                    <p style={{textAlign:'center'}}>{props.solicitud.motivoSolicita}</p>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col xs={4}>
                    <Form.Control 
                        as='select'
                        className="form-select form-select-sm"
                        value={SelectedOption}
                        style={{width:'100%', marginBottom:'10px'}}
                        onChange={(e) => setSelectedOption(+e.target.value)}
                    >
                        <option value={2}>Autorizar</option>
                        <option value={0}>Rechazar</option>
                    </Form.Control>
                </Col>
            </Row>
            <Row style={{visibility: SelectedOption === 0 ? 'visible' : 'hidden'}}>
                <Col xs={12}>
                    <FormLabel style={{width:'100%'}}>
                        <Form.Control
                            as="textarea"
                            rows={3} 
                            onChange={(e) => setMotivo(e.target?.value)}
                            placeholder='ingrese el motivo del rechazo de la solicitud'
                        />
                    </FormLabel>
                </Col>
            </Row>
            <Row className='justify-content-end'>
                <Col xs={4} style={{textAlign:'end'}}>
                    <Button variant='primary' onClick={() => {AppendSolicitud()}}>
                        Guardar
                    </Button>
                </Col>
            </Row>
            <MsgInformativo
                show={showMsg}
                msg={msg}
                header={header}
                msgColor={'primary'}
                closeToast={() => {
                    setShowMsg(false)
                }}
            />
        </>
    )
}