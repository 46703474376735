import React, { useState } from "react"
import { Button, Col, Form, FormLabel, Row } from "react-bootstrap"
import CTrafDataService from "../../../Services/Corresponsalias/Corresponsales.Trafico.Services"
import { MsgInformativo } from "../../Utils/Toast/msgInformativo"
import { ICorresponsalTraficoSolicitud } from "../../../Interfaces/Corresponsales/ICorresponsalTraficoSolicitud"
import CorresponsalesTraficoSolicitudService from "../../../Services/Corresponsalias/Corresponsales.Trafico.Solicitud.Service"

interface IProps{
    idTrafico: number,
    idUsuario: number,
    close: () => void,
    title: string,
}
export const CrearSolicitud:React.FC<IProps> = (props) => {
    const [Motivo, setMotivo] = useState('')
    const [showMsg, setShowMsg] = useState(false)
    const [msg, setMsg] = useState('')
    const [header, setHeader] = useState('')
    const [Depto, setDepto] = useState(() => {
        const stickyValue = window.localStorage.getItem('Departamento')
        return stickyValue !== null ? JSON.parse(stickyValue) : ''
    })
    const [Perfil, setPerfil] = useState(() => {
        const stickyValue = window.localStorage.getItem('Perfil')
        return stickyValue !== null ? JSON.parse(stickyValue) : ''
    }) 

    const AppendSolicitud = () => {
        if(!Motivo){
            setHeader('Error');
            setMsg('Favor de capturar una razon válida.')
            setShowMsg(true)
            return;
        }
        if(Motivo.length < 30){
            setHeader('Error');
            setMsg('La razón de su solicitud debe contener al menos 30 caracteres')
            setShowMsg(true)
            return;
        }
        var solicitud: ICorresponsalTraficoSolicitud = {
            id: 0,
            idTrafico: props.idTrafico,
            idUsuarioSolicita: props.idUsuario,
            fechaSolicita: undefined,
            motivoSolicita: Motivo,
            idUsuarioAutoriza: Perfil.includes('Sup') ? props.idUsuario : 0, //Si es un supervisor se asigna su id al usuario que autoriza ya que la solicitud no requiere ser autorizada por otro usuario
            fechaAutoriza: undefined,
            motivoRechazo: "",
            estado: Perfil.includes('Sup') ? 2 : 1 //Si es un supervisor se asigna el estado de la solicitud como autorizada desde que se crea
        }
        if(Depto === 'Facturacion'){
            AppendSolicitudReapertura(solicitud)
        }
        if(Depto === 'Corresponsalias'){
            AppendSolicitudEliminarReferencia(solicitud)
        }
    }

    const AppendSolicitudReapertura = (solicitud: ICorresponsalTraficoSolicitud) => {
        CorresponsalesTraficoSolicitudService.AppendSolicitudReapertura(solicitud)
        .then((resp) => {
            props.close()
        })
        .catch(() => {
            setHeader('Error');
            setMsg('Ocurrió un error. Favor de intentar nuevamente')
            setShowMsg(true)
            return;
        })
    }

    const AppendSolicitudEliminarReferencia = (solicitud: ICorresponsalTraficoSolicitud) => {
        CorresponsalesTraficoSolicitudService.AppendSolicitudEliminarReferencia(solicitud)
        .then((resp) => {
            props.close()
        })
        .catch(() => {
            setHeader('Error');
            setMsg('Ocurrió un error. Favor de intentar nuevamente')
            setShowMsg(true)
            return;
        })
    }
    return(
        <>
            <h3>{props.title}</h3>
            <hr/>
            <Row>
            <Col xs={12}>
            <FormLabel style={{width:'100%'}}>
                <Form.Control
                    as="textarea"
                    rows={5} 
                    onChange={(e) => setMotivo(e.target?.value)}
                    placeholder='ingrese el motivo de la solicitud'
                />
            </FormLabel>
            </Col>
            </Row>
            <Row className='justify-content-end'>
            <Col xs={2} style={{textAlign:'end'}}>
                <Button variant='primary' onClick={() => AppendSolicitud()}>
                Guardar
                </Button>
            </Col>
            </Row>
            <MsgInformativo
                show={showMsg}
                msg={msg}
                header={header}
                msgColor={'primary'}
                closeToast={() => {
                    setShowMsg(false)
                }}
            />
        </>
    )
}