import http from "../common/http-common";
import ICorresponsalCuentasComplementarias from "../../Interfaces/Corresponsales/ICorresponsalCuentasComplementarias";
import ICorresponsalCuentasComplementariasEstatus from "../../Interfaces/Corresponsales/ICorresponsalCuentaComplementariaEstatus";
import DTOCorresponsalCuentaComplementaria from "../../DTO/Corresponsales/DTOCuentaComplementaria";
import DTOCuentaComplementariaEstatus from "../../DTO/Corresponsales/DTOCuentaComplementariaEstatus";
import DTOLogCorresponsalComplementariaEstatus from "../../DTO/Corresponsales/DTOLogCorresponsalComplementariaEstatus";

class CorresponsalesDataService {
  url = '/Corresponsalias/CuentasComplementarias/'

  Append(data: DTOCorresponsalCuentaComplementaria) {
    return http.post<ICorresponsalCuentasComplementarias>(this.url+"Append", data);
  }
  Get(idTrafico: number) {
    return http.get<ICorresponsalCuentasComplementarias[]>(this.url+`Get?idTrafico=${idTrafico}`);
  }
  AppendEstatus(data: ICorresponsalCuentasComplementariasEstatus) {
    return http.post<ICorresponsalCuentasComplementariasEstatus>(this.url+"AppendEstatus", data);
  }
  GetAllEstatus() {
    return http.get<ICorresponsalCuentasComplementariasEstatus[]>(this.url+`GetEstatus`);
  }
  /* Delete(id: number) {
    return http.delete<ICorresponsalCuentasComplementarias[]>(`/Corresponsalias/Anticipos/Delete/${id}`);
  } */
  ChangeStatus(data: DTOCuentaComplementariaEstatus) {
    return http.post<ICorresponsalCuentasComplementarias>(this.url+"ChangeEstatus", data);
  }
  GetLogEstatus(id: number) {
    return http.get<DTOLogCorresponsalComplementariaEstatus[]>(this.url+`GetLogEstatus?id=${id}`);
  }
  ClearFile(idCuenta: number, witchFile: number) {
    return http.put<boolean>(this.url+`ClearFile/${idCuenta}/${witchFile}`);
  }
}
export default new CorresponsalesDataService();