import React, { FC, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import ICorresponsalPartidas from "../../../../Interfaces/Corresponsales/ICorresponsalPedimentoPartidas";
import { MsgInformativo } from "../../../Utils/Toast/msgInformativo";
import CPartidasService from "../../../../Services/Corresponsalias/Corresponsales.Trafico.Pedimento.Partidas.Services";
import { useDispatch, useSelector } from "react-redux";
import { updateCorresponsalesPartidas } from "../../../../store/features/Corresponsales/CorresponsalesPartidasSlice";
import { RootState } from "../../../../store/store";

interface IProps {
  IDTrafico: number;
  IDPartida: number;
}

export const DialogBox: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const mCFacturas = useSelector(
    (state: RootState) => state.CFData.CorresponsalesFacturas
  );
  const mCatProveedores = useSelector(
    (state: RootState) => state.CatProveedores.CatalogoProveedores
  );
  const mCPartidas = useSelector(
    (state: RootState) => state.CPartidas.CorresponsalesPartidas
  );
  const [Factura, setFactura] = useState("");
  const [IDFactura, setIDFactura] = useState(0);
  const [IDPartida, setIDPartida] = useState(0);
  const [Proveedor, setProveedor] = useState("");
  const [IDProveedor, setIDProveedor] = useState(0);
  const [DescripcionMaterial, setDescripcionMaterial] = useState("");
  const [FraccionArancelaria, setFraccionArancelaria] = useState("");
  const [ValorAduana, setValorAduana] = useState(0);
  const [DTA, setDTA] = useState(0);
  const [IGI, setIGI] = useState(0);
  const [IEPS, setIEPS] = useState(0);
  const [header, setHeader] = useState("");
  // const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const msgColor = "primary";

  const saveForm = () => {
    const data: ICorresponsalPartidas = {
      id: IDPartida,
      idTrafico: props.IDTrafico,
      partida: 0,
      idFactura: IDFactura,
      factura: Factura,
      proveedor: Proveedor,
      descripcionMaterial: DescripcionMaterial,
      fraccionArancelaria: FraccionArancelaria,
      valorAduana: ValorAduana,
      dta: DTA,
      igi: IGI,
      ieps: IEPS,
      activo: 1,
    };
    if (Factura.length < 4) {
      setHeader("Error");
      setMsg("Proporcione la factura");
      setShowMsg(true);
      return;
    }
    if (Proveedor.length < 4) {
      setHeader("Error");
      setMsg("Proporcione el proveedor");
      setShowMsg(true);
      return;
    }
    if (DescripcionMaterial.length < 4) {
      setHeader("Error");
      setMsg("Proporcione la descripcion del material");
      setShowMsg(true);
      return;
    }
    if (FraccionArancelaria.length < 4) {
      setHeader("Error");
      setMsg("Proporcione la fraccion arancelaria");
      setShowMsg(true);
      return;
    }
    if (ValorAduana === 0) {
      setHeader("Error");
      setMsg("Proporcione el valor aduana");
      setShowMsg(true);
      return;
    }
    console.log(JSON.stringify(data));
    CPartidasService.Append(data)
      .then((response) => {
        dispatch(updateCorresponsalesPartidas(response.data));
        setHeader("Informativo");
        setMsg("El registro se guardado exitosamente!");
        setShowMsg(true);
      })
      .catch((e: Error) => {
        setHeader("Error");
        setMsg("Ocurrio un error: " + e);
        setShowMsg(true);
        return;
      });
  };

  useEffect(() => {
    const dataFactura = mCFacturas.filter((item) => item.id === IDFactura);
    setFactura(dataFactura[0].factura);
    setIDProveedor(dataFactura[0].proveedor);
    const dataProveedor = mCatProveedores.filter(
      (item) => item.id === dataFactura[0].proveedor
    );
    setProveedor(dataProveedor[0].nombre);
  }, [IDFactura, mCFacturas, mCatProveedores]);

  useEffect(() => {
    const data = mCPartidas.filter((item) => item.id === props.IDPartida);
    if (data[0]) {
      setIDPartida(data[0].id);
      setIDFactura(data[0].idFactura);
      setFactura(data[0].factura);
      setDescripcionMaterial(data[0].descripcionMaterial);
      setProveedor(data[0].proveedor);
      setDTA(data[0].dta);
      setIGI(data[0].igi);
      setIEPS(data[0].ieps);
      setFraccionArancelaria(data[0].fraccionArancelaria);
      setValorAduana(data[0].valorAduana);
    }
  }, [props.IDPartida, mCPartidas]);

  return (
    <div>
      <Card
        style={{
          backgroundColor: "#F1F9FE",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <Container fluid="md">
          <Row style={{ paddingTop: "15px" }}>
            <Col xs={2}>Factura</Col>
            <Col xs={3}>
              {/*   <Form.Control
                type='text'
                id='Factura'
                size='sm'
                value={Factura}
                onChange={(e) => {
                  setFactura(e.target.value)
                }}
              /> */}
              <Form.Control
                id="CmbFacturas"
                as="select"
                onChange={(e) => {
                  setIDFactura(parseInt(e.target.value));
                }}
                value={IDFactura}
                className="form-select form-select-sm"
              >
                {mCFacturas
                  ? mCFacturas.map((item, index) => {
                      return item.idTrafico === props.IDTrafico ? (
                        <option value={item.id}>{item.factura}</option>
                      ) : (
                        <option value="0">-Seleccione-</option>
                      );
                    })
                  : ""}
              </Form.Control>
            </Col>
            <Col xs={5}></Col>
            <Col>
              <Button
                id="BtnSavePartida"
                variant="primary"
                onClick={() => {
                  saveForm();
                }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
          <Row style={{ paddingTop: "15px" }}>
            <Col xs={2}>Proveedor</Col>
            <Col xs={10}>
              <Form.Control
                type="text"
                id="Proveedor"
                size="sm"
                value={Proveedor}
                onChange={(e) => {
                  setProveedor(e.target.value);
                }}
                disabled={true}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: "15px" }}>
            <Col xs={2}>Descripcion del material</Col>
            <Col>
              <Form.Control
                rows={4}
                as="textarea"
                id="DescripcionMaterial"
                value={DescripcionMaterial}
                size="sm"
                onChange={(e) => {
                  setDescripcionMaterial(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: "15px" }}>
            <Col xs={3}>Fraccion arancelaria</Col>
            <Col xs={3}>
              <Form.Control
                type="text"
                id="FraccionArancelaria"
                size="sm"
                value={FraccionArancelaria}
                onChange={(e) => {
                  setFraccionArancelaria(e.target.value);
                }}
              />
            </Col>
            <Col xs={2}>Valor aduana</Col>
            <Col xs={3}>
              <CurrencyFormat
                id="ValorAduana"
                value={ValorAduana}
                thousandSeparator={true}
                placeholder="ValorAduana"
                onValueChange={(values: any) => {
                  const { value } = values;
                  setValorAduana(value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: "15px" }}>
            <Col xs={1} style={{ textAlign: "right" }}>
              DTA
            </Col>
            <Col xs={2}>
              <CurrencyFormat
                id="CDTA"
                placeholder="DTA"
                value={DTA}
                thousandSeparator={true}
                onValueChange={(values: any) => {
                  const { value } = values;
                  setDTA(value.length === 0 ? 0 : value);
                }}
              />
            </Col>
            <Col xs={1}>&nbsp;</Col>
            <Col xs={1} style={{ textAlign: "right" }}>
              IGI
            </Col>
            <Col xs={2}>
              <CurrencyFormat
                id="CIGI"
                value={IGI}
                thousandSeparator={true}
                placeholder="IGI"
                onValueChange={(values: any) => {
                  const { value } = values;
                  setIGI(value);
                }}
              />
            </Col>
            <Col xs={1}>&nbsp;</Col>
            <Col xs={1} style={{ textAlign: "right" }}>
              IEPS
            </Col>
            <Col xs={2}>
              <CurrencyFormat
                id="CIEPS"
                value={IEPS}
                thousandSeparator={true}
                placeholder="IEPS"
                onValueChange={(values: any) => {
                  const { value } = values;
                  setIEPS(value);
                }}
              />
            </Col>
          </Row>
        </Container>
      </Card>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false);
        }}
      />
    </div>
  );
};
