import http from "../common/http-common";
import IRespuesta from "../../Interfaces/IRespuesta";
import DTOConceptos from "../../DTO/Utils/DTOConceptos";
import ITabuladorConceptos from "../../Interfaces/Catalogos/ITabuladorConceptos";

class Corresponsales_Tabuladores_Detalles_DataService {
  GetDetailByIdTab(id: number) {
    return http.get<ITabuladorConceptos[]>(`/Catalogos/TabuladorDetalle/getDetailByIdTab?id=${id}`);
  }
  Append(data: ITabuladorConceptos) {
    return http.post<ITabuladorConceptos[]>("/Catalogos/TabuladorDetalle/Append", data);
  }
  Delete(id: number) {
    return http.delete<IRespuesta>(`/Catalogos/TabuladorDetalle/Delete/${id}`);
  }
  getAllConcepts() {
    return http.get<DTOConceptos[]>(`/Catalogos/TabuladorDetalle/Conceptos/getAllConcepts`);
  }
}
export default new Corresponsales_Tabuladores_Detalles_DataService();