import React, { FC, useEffect, useState } from 'react'
// Bootstrap
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { BsCloudDownload } from 'react-icons/bs'
// Interfaces
import IArchivoElectronico from '../../../Interfaces/IArchivoElectronico'
// DTOs
import DTOgetArchivosPorReferencia from '../../../DTO/DTOgetArchivosPorReferencia'
// Redux
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
// Servicios
import AEOServices from '../../../Services/Catalogos/ArchivoElectronico.Services'
import ArchivoElectronicoDataServices from '../../../Services/Catalogos/ArchivoElectronico.Services'
// Otros
import { AgGridReact } from 'ag-grid-react'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { TargetURL } from '../../../Constants/TargetURL'
import IFileManager from '../../../Interfaces/Utils/IFileManager'
import FileManagerServices from '../../../Services/Utils/FileManager.Services'
import { IconContext } from 'react-icons'
import { FaDownload } from 'react-icons/fa'

interface IProps {
  showDialog: boolean
  header: string
  closeDialog: (arg: boolean) => void
  archivos: IFileManager[]
}

interface ListaArchivos {
  archivo: string
}

const URL = new TargetURL()

export const DialogAE: FC<IProps> = (props) => {
  const [curURL, setCurURL] = useState(URL.get())
  const Referencia = useSelector((state: RootState) => state.ArchivoElectronico.Referencia)
  const Pedimento = useSelector((state: RootState) => state.ArchivoElectronico.Pedimento)
  const NoCliente = useSelector((state: RootState) => state.ArchivoElectronico.NoCliente)
  const [filteredData, setFilteredData] = useState<IFileManager[]>([])
  const [columnDefs] = useState([
    {
      field: 'nombreArchivo',
      cellRender: (params:any) => {return params.data.nombreArchivo},
      width: 430,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: false,
      sortable: true,
      filter: true,
    },{
      field: 'Descargar',
      width: 100,
      cellRenderer: (params:any) => {
        return (
          <div style={{textAlign:'center'}}>
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <FaDownload onClick={() => {getFile(params.data)}} style={{cursor:'pointer'}}/>
          </IconContext.Provider>
          </div>
        )
        //return <Button variant='warning' onClick={() => {getFile(params.data)}}></Button>
      },
    }
  ])
  const gridRef = React.useRef<any>(null)
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')

  const viewFileContent = (fileName: string) => {
    window.open(
      `${curURL}/ArchivoElectronico/getFileContent?Referencia=${Referencia}&PedimentoLargo=dummy&NoCliente=${NoCliente}&IdUsuario=0&Archivo=${fileName}`
    )
  }

  useEffect(() => {
    setFilteredData(props.archivos)
  }, [props.archivos])

  const getFile = (file: IFileManager) => {
    FileManagerServices.getFileContent(parseInt(file.tags), file.proceso).then((resp: any) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.nombreArchivo)
        document.body.appendChild(link)
        link.click()
    }).catch(() => {
      setHeader('Error')
      setMsg('Ocurrio un error durante la descarga, favor de intentar nuevamente')
      setShowMsg(true)
      return
    })
  }

  const getFilesByReference = () => {
    let selectedNodes = gridRef.current.api.getSelectedNodes()
    let selectedData = selectedNodes.map((node: any) => node.data)
    const Archivos = selectedData.map((item: ListaArchivos) => item.archivo)
    const data: DTOgetArchivosPorReferencia = {
      Referencia: Referencia,
      PedimentoLargo: Pedimento,
      NoCliente: NoCliente,
      IdUsuario: 0,
      Archivo: 'dummy',
      Archivos: Archivos,
    }
    /* AEOServices.getTheseFilesByReferences(data)
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', Referencia + '.zip')
        document.body.appendChild(link)
        link.click()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      }) */
    props.closeDialog(false)
  }

  return (
    <div>
      {' '}
      <Modal
        dialogClassName='modal-50w'
        show={props.showDialog}
        onHide={() => {
          props.closeDialog(false)
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Referencia: {Referencia}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filteredData ? (
            <div style={{ height: '350px', width: '100%' }} className='ag-theme-alpine'>
              <AgGridReact
                rowData={filteredData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={50}
                ref={gridRef}
                /* rowSelection={'multiple'}
                rowMultiSelectWithClick={true} */
              ></AgGridReact>
            </div>
          ) : (
            'Sin archivos que mostrar'
          )}
          <MsgInformativo show={show} msg={msg} header={header} msgColor={msgColor} closeToast={() => setShowMsg(false)} />
        </Modal.Body>
        {/* <Modal.Footer>
          <Container>
            <Row>
              <Col md={2}></Col>
              <Col md={4}>
                <Button
                  variant='warning'
                  size='sm'
                  onClick={() => {
                    getFilesByReference()
                  }}
                >
                  <BsCloudDownload /> Descargar
                </Button>
              </Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => {
                    props.closeDialog(false)
                  }}
                >
                  Cerrar
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer> */}
      </Modal>
    </div>
  )
}
