import http from "../common/http-common";
import ICorresponsalAnticipos from "../../Interfaces/Corresponsales/ICorresponsalAnticipos";
import DTOAnticiposAutoriza from "../../DTO/Corresponsales/DTOAnticiposAutoriza";

class CorresponsalesDataService {

  Append(data: ICorresponsalAnticipos) {
    return http.post<ICorresponsalAnticipos>("/Corresponsalias/Anticipos/Append", data);
  }
  getAll(idTrafico: number) {
    return http.get<ICorresponsalAnticipos[]>(`/Corresponsalias/Anticipos/get?idTrafico=${idTrafico}`);
  }
  Delete(id: number) {
    return http.delete<ICorresponsalAnticipos[]>(`/Corresponsalias/Anticipos/Delete/${id}`);
  }
  Autoriza(data: DTOAnticiposAutoriza) {
    return http.post<ICorresponsalAnticipos>("/Corresponsalias/Anticipos/Autoriza", data);
  }
  DownloadAnticipo(IdTrafico: number, idAnticipo: number){
    return http.get(`/Corresponsalias/Anticipos/Download?idTrafico=${IdTrafico}&idAnticipo=${idAnticipo}`, {responseType: 'blob'})
  }

}
export default new CorresponsalesDataService();