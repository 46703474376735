import React from "react";
import http from "../common/http-common";
import { DTOReportesEmbarquesPorImportar } from "../../DTO/Reportes/Embarques/DTOReportesEmabarquesPorImportar";
import { DTOReportesEmbarquesDespachados } from "../../DTO/Reportes/Embarques/DTOReportesEmabarquesDespachados";
import { DTOBuscarEmbarques } from "../../DTO/Reportes/Embarques/DTOBuscarEmbarques";

class ReportesEmbarquesService{

    getReporteEmbarquesPorImportar(){
        return http.get<DTOReportesEmbarquesPorImportar[]>(`ReportesEmbarques/PorImportar`);
    }

    getReporteEmbarquesDespachados(inicio: string, fin: string){
        return http.get<DTOReportesEmbarquesDespachados[]>(`ReportesEmbarques/Despachados?inicio=${inicio}&fin=${fin}`);
    }

    BuscarEmbarques(filtro: string){
        return http.get<DTOBuscarEmbarques[]>(`ReportesEmbarques/BuscarEmbarques?filtro=${filtro}`);
    }

    GetExcelPorImportar(){
        return http.get(`ReportesEmbarques/ExcelEmbarquesPorImportar`, {responseType: 'blob'})
    }

    GetExcelDespachados(inicio: string, fin: string){
        return http.get(`ReportesEmbarques/ExcelEmbarquesDespachados?inicio=${inicio}&fin=${fin}`, {responseType: 'blob'})
    }

    GetExcelBuscados(busqueda: string){
        return http.get(`ReportesEmbarques/ExcelBuscarEmbarques?filtro=${busqueda}`, {responseType: 'blob'})
    }
}

export default new ReportesEmbarquesService();