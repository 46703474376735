import { AgGridReact } from "ag-grid-react";
import React, { useState, useEffect } from "react";
import { DTOReportesEmbarquesDespachados } from "../../../DTO/Reportes/Embarques/DTOReportesEmabarquesDespachados";
import reportesEmbarquesService from "../../../Services/Reportes/reportesEmbarques.Service";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { CellIcon } from "../../Utils/CellIcon/CellIcon";
import { FaDownload } from "react-icons/fa";

export const EmbarquesDespachados: React.FC = () => {
    const gridRef = React.useRef<any>(null)
    const [Inicio, setInicio] = useState(currentDate(-7))
    const [Fin, setFin] = useState(currentDate(0))
    const [filteredData, setFilteredData] = useState<DTOReportesEmbarquesDespachados[]>([]);
    const [EmbarquesDespachados, setEmbarquesDespachados] = useState<DTOReportesEmbarquesDespachados[]>([]);
    const [columnDefs] = useState([
        { field: 'id', headerName: 'id', width: 70, sortable: true, filter: true },
        { field: 'aduana', headerName: 'ADUANA', },
        { field: 'tipoEmbarque', headerName: 'TIPO DE EMBARQUE',  },
        { field: 'destino', headerName: 'DESTINO',  },
        { field: 'diasEnAduana', headerName: 'DIAS EN ADUANA',  },
        { field: 'nombre', headerName: 'NOMBRE',  },
        { field: 'trafico', headerName: 'TRAFICO',  },
        { field: 'referencia', headerName: 'REFERENCIA',  },
        { field: 'noPedimento', headerName: 'NO DE PEDIMENTO',  },
        { field: 'clavePedimento', headerName: 'CLAVE DE PADIMENTO',  },
        { field: 'tipo', headerName: 'TIPO',  },
        { field: 'ordenCompra', headerName: 'ORDEN DE COMPRA',  },
        { field: 'factura', headerName: 'FACTURA',  },
        { field: 'proveedor', headerName: 'PROVEEDOR',  },
        { field: 'descripcionMercancia', headerName: 'DESCRIPCION',  },
        { field: 'lineaTransportistaInternacional', headerName: 'LINEA TRANSPORTISTA INTERNACIONAL',  },
        { field: 'lineaNaviera', headerName: 'LINEA NAVIERA',  },
        { field: 'mawb', headerName: 'MAWB',  },
        { field: 'hawb', headerName: 'HAWB',  },
        { field: 'fechaNotificacion', headerName: 'FECHA DE NOTIFICACION',  },
        { field: 'bultos', headerName: 'BULTOS',  },
        { field: 'cantidadContenedores', headerName: 'CANTIDAD DE CONTENEDORES',  },
        { field: 'numerosContenedores', headerName: 'NO. CONTENEDORES',  },
        { field: 'pesoBruto', headerName: 'PESO BRUTO',  },
        { field: 'pesoNeto', headerName: 'PESO NETO',  },
        { field: 'incoterm', headerName: 'INCOTERM',  },
        { field: 'fechaETA', headerName: 'FECHA ETA',  },
        { field: 'fechaEntrada', headerName: 'FECHA DE ENTRADA',  },
        { field: 'fechaRevalidacionGuia', headerName: 'FECHA DE REVALIDACION GUIA',  },
        { field: 'montoUSD', headerName: 'MONTO USD',  cellRenderer: (params: any) => {return `$ ${params.data.montoUSD}`}},
        { field: 'origen', headerName: 'ORIGEN',  },
        { field: 'fraccionArancelaria', headerName: 'FRACCION ARANCELARIA',  },
        { field: 'descripcion', headerName: 'DESCRIPCION',  },
        { field: 'preferenciaArancelaria', headerName: 'PREFERENCIA ARANCELARIA',  },
        { field: 'estatus', headerName: 'ESTATUS',  },
        { field: 'observaciones', headerName: 'OBSERVACIONES',  },
        { field: 'fechaInicioGastosAlmacenaje', headerName: 'FECHA DE INICIO DE GASTOS DE ALMACENAJE',  },
        { field: 'costoDiarioAlmacenaje', headerName: 'COSTO DIARIO ALMACENAJE',  cellRenderer: (params: any) => {return `$ ${params.data.costoDiarioAlmacenaje}`}},
        { field: 'costoDiarioConexion', headerName: 'COSTO DIARIO CONEXION',  cellRenderer: (params: any) => {return `$ ${params.data.costoDiarioConexion}`}},
        { field: 'totalPagar', headerName: 'TOTAL A PAGAR ALMACENAJE',  cellRenderer: (params: any) => {return `$ ${params.data.totalPagar}`}},
        { field: 'totalPagarConexion', headerName: 'TOTAL PAGAR CONEXION', cellRenderer: (params: any) => {return `$ ${params.data.totalPagarConexion}`}},
        { field: 'fechaPagoPedimento', headerName: 'FECHA DE PAGO PEDIMENTO',  },
        { field: 'fechaInstrucciones', headerName: 'FECHA DE INSTRUCCIONES',  },
        { field: 'horaInstrucciones', headerName: 'HORA DE INSTRUCCIONES',  },
        { field: 'fechaDespacho', headerName: 'FECHA DE DESPACHO',  },
        { field: 'diasCPPagado', headerName: 'DIAS C/PDTO PAGADO',  },
        { field: 'fechaSalidaContenedores', headerName: 'FECHA SALIDA DE CONTENEDORES',  },
        { field: 'nombrePaqueteria', headerName: 'NOMBRE DE PAQUETERIA O TRANSPORTISTA',  },
        { field: 'noGuiaEmbarqueDestino', headerName: 'NO. DE GUIA DE EMBARQUE A DESTINO',  },
        { field: 'fechaEntregaDestinoFinal', headerName: 'FECHA DE ENTREGA EN DESTINO FINAL',  },
        { field: 'horaEntregaDestinoFinal', headerName: 'HORA DE ENTREGA EN DESTINO FINAL',  },
        { field: 'facturaCorresponsal', headerName: 'FACTURA',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.facturaCorresponsal} idTrafico={params.data.id} proceso={16} ></CellIcon>
        }},
        { field: 'certificadoCalidad', headerName: 'CERTIFICADO DE CALIDAD',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.certificadoCalidad} idTrafico={params.data.id} proceso={15} ></CellIcon>
        }},
        { field: 'packingList', headerName: 'PACKING LIST',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.packingList} idTrafico={params.data.id} proceso={18} ></CellIcon>
        }},
        { field: 'hojaTecnica', headerName: 'HOJA TECNICA',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.hojaTecnica} idTrafico={params.data.id} proceso={17} ></CellIcon>
        }},
        { field: 'bl', headerName: 'BL',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.bl} idTrafico={params.data.id} proceso={14} ></CellIcon>
        }},
        { field: 'pedimentoInformativo', headerName: 'PEDIMENTO INFORMATIVO',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.pedimentoInformativo} idTrafico={params.data.id} proceso={12} ></CellIcon>
        }, width: 130},
        { field: 'pedimentoSimplificado', headerName: 'PEDIMENTO SIMPLIFICADO',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.pedimentoSimplificado} idTrafico={params.data.id} proceso={13} ></CellIcon>
        }, width: 130},
        { field: 'doda', headerName: 'DODA',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.doda} idTrafico={params.data.id} proceso={11} ></CellIcon>
        }, width: 100},
        { field: 'honorarios', headerName: 'HONORARIOS AA', cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.honorarios} idTrafico={params.data.id} proceso={37} ></CellIcon>
        }, width: 130},
        { field: 'totalHonorarios', headerName: 'TOTAL HONORARIOS AA', cellRenderer: (params: any) => {return `$ ${params.data.totalHonorarios}`}},
        { field: 'gastosTerceros', headerName: 'GASTOS A TERCEROS', cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.gastosTerceros} idTrafico={params.data.id} proceso={41} ></CellIcon>
        }, width: 130},
        { field: 'totalGastosTerceros', headerName: 'TOTAL GASTOS A TERCEROS', cellRenderer: (params: any) => {return `$ ${params.data.totalGastosTerceros}`}},
        { field: 'comprobantesGastosTerceros', headerName: 'COMPROBANTES GASTOS A TERCEROS', cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.comprobantesGastosTerceros} idTrafico={params.data.id} proceso={2} ></CellIcon>
        }, width: 130},
        { field: 'pruebaEntrega', headerName: 'PRUEBA DE ENTREGA', },
        { field: 'descargarTodos', headerName: 'DESCARGAR TODOS', },
    ]);

    function currentDate(days: number): string {
        var today = new Date()
        today.setDate(today.getDate() + days)
        var dd = String(today.getDate()).padStart(2, '0')
        var mm = String(today.getMonth() + 1).padStart(2, '0')
        var yyyy = today.getFullYear()
        return yyyy + '-' + mm + '-' + dd
    }

    const GetEmbarquesDespachados = () => {
        reportesEmbarquesService.getReporteEmbarquesDespachados(Inicio, Fin)
        .then(res => {
            setEmbarquesDespachados(res.data)
            setFilteredData(res.data)
        });
    }

    const filtraEmbarques = (e: any) => {
        gridRef.current.api.setQuickFilter(e.target.value)
    }

    const exportarEmbarques = () => {
        reportesEmbarquesService.GetExcelDespachados(Inicio, Fin)
        .then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ReporteEmbarquesDespachados.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    }

    return (
        <>
        <Card>
            <Card.Body>
                <Row>
                    <Col xs={3}>
                    <Form.Label style={{width:'100%'}}>
                        Fecha Inicial
                        <Form.Control
                            defaultValue={Inicio}
                            type='date'
                            name='Inicio'
                            placeholder='Inicio'
                            title='Inicio'
                            alt='Inicio'
                            data-date-format='YYYY-mm-dd'
                            onChange={(e) => setInicio(e.target.value)}
                            size='sm'
                        />
                    </Form.Label>
                    </Col>
                    <Col xs={3}>
                    <Form.Label style={{width:'100%'}}>
                        Fecha Final
                        <Form.Control
                            defaultValue={Fin}
                            type='date'
                            name='Fin'
                            placeholder='Fin'
                            title='Fin'
                            alt='Fin'
                            onChange={(e) => setFin(e.target.value)}
                            size='sm'
                        />
                    </Form.Label>
                    </Col>
                    <Col style={{margin:'auto 0px'}}>
                        <Button className="me-2" variant="primary" onClick={GetEmbarquesDespachados}>Generar</Button>
                        { EmbarquesDespachados.length > 0 ? <Button onClick={exportarEmbarques}><FaDownload/>&nbsp;Excel</Button> : "" }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        <Card className='mt-2' style={{visibility: EmbarquesDespachados.length > 0 ? "visible" : "hidden"}}>
            <Card.Body>  
                <Row className="mb-2">
                    <Col xs={5}>
                        <Form.Control
                            type='text'
                            size='sm'
                            placeholder='Search...'
                            onChange={(e) => {
                                filtraEmbarques(e)
                            }}
                        />
                    </Col>
                </Row>
                <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
                    <AgGridReact
                    rowData={filteredData}
                    columnDefs={columnDefs}
                    defaultColDef={{
                        resizable: true,
                        initialWidth: 200,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                    }}
                    pagination={true}
                    paginationAutoPageSize={true}
                    ref={gridRef}
                    rowSelection={'single'}
                    rowMultiSelectWithClick={true}
                    ></AgGridReact>
                </div>
            </Card.Body>
        </Card>
        </>
    );
}