import { useEffect, useState } from "react"
import { Alert, Card, Col, Row, Table } from "react-bootstrap"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { AgGridReact } from "ag-grid-react"
import ICorresponsalRectificacionHistorico from "../../../Interfaces/Corresponsales/ICorresponsalRectificacionHistorico"

interface IProps{
    //pedimento: number
    IdPedimentoConsolidado: string
}

export const HistorialRectificaciones: React.FC<IProps> = (props) => {
    const [columnDefs] = useState([
        { field: 'patente', width: 110, sortable: true, filter: true },
        { field: 'aduana', width: 110, sortable: true, filter: true },
        { field: 'pedimento', width: 120, sortable: true, filter: true },
        { field: 'clave', width: 100, sortable: true, filter: true },
        {
            field: 'fechaPago',
            sortable: true,
            filter: true,
            cellRenderer: (params: any) => {
                if (params.value) return params.value.substring(0, 10)
                else return ''
            },
        },
        {
            field: 'fhCreacion',
            sortable: true,
            filter: true,
            cellRenderer: (params: any) => {
                if (params.value) return params.value
                else return ''
            },
            flex:1
        },
    ])
    const mcRectificaciones = useSelector((state:RootState) => state.CRectificaciones.RectificacionesPedimentos)
    const [Rectificaciones, setRectificaciones] = useState<ICorresponsalRectificacionHistorico[]>([]);
    useEffect(()=>{
        if(props.IdPedimentoConsolidado !== '#')
            setRectificaciones(mcRectificaciones.filter(x => x.idPedimentoConsolidado === + props.IdPedimentoConsolidado))
        else
            setRectificaciones(mcRectificaciones)
    },[mcRectificaciones, props.IdPedimentoConsolidado])

    return(
        <div>
                <Row>
                    <Col xs={12}>
                        <b>Historial de Pedimentos Rectificados</b>
                    </Col>
                </Row>
                <br/>
                <div className="table-wrapper" style={{width:'100%', maxHeight:'100%'}}>
                    <Table
                        className="table table-earnings table-earnings__challenge"
                        hover
                        size="sm"
                    >
                        <thead>
                            <tr><th>#</th><th>Aduana</th><th>Patente</th><th>Pedimento</th><th>Clave</th><th>Fecha de Pago</th></tr>
                        </thead>
                        <tbody>
                            {Rectificaciones
                                ? Rectificaciones.map((item, index) => {
                                    return(
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.aduana}</td>
                                        <td>{item.patente}</td>
                                        <td>{item.pedimento}</td>
                                        <td>{item.clave}</td>
                                        <td>{item.fechaPago ? item.fechaPago.substring(0,10) : ''}</td>
                                        </tr>
                                    )
                                })
                            : ''}
                        </tbody>
                    </Table>
                </div>
        </div>
    )
}