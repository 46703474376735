import React, { FC, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { IconContext } from 'react-icons'

import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import {
  BsPlusLg,
  BsFileEarmarkExcel,
  BsSearch,
  BsFillXCircleFill,
  BsCheckCircleFill,
} from 'react-icons/bs'
import { RowClickedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { MsgInformativo } from '../Utils/Toast/msgInformativo'

import * as XLSX from 'xlsx'
import moment from 'moment'

import { ProgressBar } from './customCells/progressBar'
import TraficoCorresponsales from '../Corresponsales/TraficoCorresponsales'

import DTORptCorresponsalesTraficos from '../../DTO/Corresponsales/DTORptCorresponsalesTraficos'

import ICorresponsalTrafico from '../../Interfaces/Corresponsales/ICorresponsalTrafico'
import IClientes from '../../Interfaces/Catalogos/IClientes'
import ICatCorresponsales from '../../Interfaces/Catalogos/ICatCorresponsales'

import ClientesDataService from '../../Services/Catalogos/Clientes.Services'
import CorresponsalesDataService from '../../Services/Catalogos/Corresponsales.Services'
import reportesServices from '../../Services/Reportes/reportes.services'
import CContDataService from '../../Services/Corresponsalias/Corresponsales.Contenedores.Services'
import CTrafDataService from '../../Services/Corresponsalias/Corresponsales.Trafico.Services'
import ProvDataService from '../../Services/Catalogos/Proveedores.Services'
import CFacDataService from '../../Services/Corresponsalias/Corresponsales.Facturas.Services'

import { InitCorresponsalesFacturas } from '../../store/features/Corresponsales/CorresponsalesFacturasSlice'
import { InitCorresponsalesFacturasTerceros } from '../../store/features/Corresponsales/CorresponsalesFacturasTercerosSlice'
import { InitCorresponsalesContenedores, addCorresponsalesContenedores } from '../../store/features/Corresponsales/CorresponsalesContenedoresSlice'
import ICorresponsalContenedores from '../../Interfaces/Corresponsales/ICorresponsalContenedores'
import ICorresponsalFacturas from '../../Interfaces/Corresponsales/ICorresponsalFacturas'
import ICatProveedores from '../../Interfaces/Catalogos/ICatProveedores'
import { FaCrow, FaTrash } from 'react-icons/fa'
import { CrearSolicitud } from '../Corresponsales/SolicitudReapertura/CrearSolicitud'
import '../../css/generic01.css' 

interface IProps {}

export default function RptCorresponsalesTraficos(props: IProps) {
  const { proc, modo } = useParams()
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  }) 
  const queryParams = new URLSearchParams(window.location.search)
  const status = queryParams.get('status')
  const [StatusAnticipos, setStatusAnticipos] = useState(
    status ? parseInt(status) : 0
  )
  const dispatch = useDispatch()
  const [IDTrafico, setIDTrafico] = useState(0)
  const [Data, setData] = useState<ICorresponsalTrafico[]>([])
  const [filteredData, setFilteredData] = useState<ICorresponsalTrafico[]>([])
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [ShowModalSolicitud, setShowModalSolicitud] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-365))
  const [Fin, setFin] = useState(currentDate(0))
  const [TipoOperacion, setTipoOperacion] = useState(0)
  const [Cliente, setCliente] = useState(0)
  const [Corresponsal, setCorresponsal] = useState(() => {
    const stickyValue = window.localStorage.getItem('IdCorresponsal')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  //const [Corresponsal, setCorresponsal] = useState(0)
  const [Pedimento, setPedimento] = useState(0)
  const [Patente, setPatente] = useState(0);
  const [Aduana, setAduana] = useState('0');
  const [Referencia, setReferencia] = useState('')
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [filtro, setFiltro] = useState('')
  const gridRef = React.useRef<any>(null)
  const [DataCorresponsales, setDataCorresponsales] = useState<
    ICatCorresponsales[]
  >([])
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [Deleting, setDeleting] = useState(false);
  const [columnDefs] = useState([
    {
      
      field: 'acciones',
      headerName: '',
      width: 50,
      hide: !Perfil.includes('Corresponsalias'),
      cellRenderer: (params: any) => {
        return (
          <span style={{visibility: params.data.tieneSolicitud ? 'hidden' : 'visible'}}>
            <FaTrash color='red' cursor='pointer' onClick={(e) => {setDeleting(true)}}/>
          </span>
        )
      },
    },
    { field: 'id', headerName: 'id', width: 70, sortable: true, filter: true },
    {
      field: 'proceso',
      headerName: 'Status',
      width: 90,
      center: true,
      sortable: true,
      filter: true,
      cellRenderer: ProgressBar,
    },
    {
      field: 'folioGemco',
      headerName: 'Trafico',
      width: 140,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.data.folioGemco
      },
    },
    {
      field: 'fechaRegistro',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value.substring(0, 10)
      },
    },
    { field: 'sUsuario', headerName: 'Usuario', sortable: true, filter: true },
    {
      field: 'sCliente',
      headerName: 'Cliente',
      sortable: true,
      filter: true,
    },
    {
      field: 'sTipoOperacion',
      headerName: 'Tipo operacion',
      sortable: true,
      filter: true,
    },
    {
      field: 'sEstatus',
      headerName: 'Estado operacion',
      sortable: true,
      filter: true,
    },
    {
      field: 'sCorresponsal',
      headerName: 'Corresponsal',
      sortable: true,
      filter: true,
      hide: Perfil === 'Clientes',
    },
    { field: 'patente', sortable: true, filter: true },
    { field: 'aduana', sortable: true, filter: true },
    { field: 'pedimento', sortable: true, filter: true },
    {
      field: 'fechaPago',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value) return params.value.substring(0, 10)
        else return ''
      },
    },
    {
      field: 'fechaDesaduanamiento',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value) return params.value.substring(0, 10)
        else return ''
      },
    },
    {
      field: 'zipgemco',
      headerName: 'Zip GEMCO',
      sortable: true,
      filter: true,
      hide: Perfil === 'Corresponsales' || Perfil === 'Clientes',
      cellRenderer: (params: any) => {
        if (params.value === 'No')
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
        else
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
      },
    },
    {
      field: 'zipCorresponsal',
      headerName: 'Zip Corresponsal',
      sortable: true,
      filter: true,
      hide: Perfil === 'Corresponsales' || Perfil === 'Clientes',
      cellRenderer: (params: any) => {
        if (params.value === 'No')
          return (
            <div style={{ paddingLeft: '35px' }}>
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
        else
          return (
            <div style={{ paddingLeft: '35px' }}>
              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
      },
    },
    { field: 'ultimaActualizacion', sortable: true, filter: true },
  ])
  const [msgColor, setMsgColor] = useState('primary')

  function ConvertHexToString(str: string) {
    return unescape(str.replace(/\\/g, '%'))
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  useEffect(() => {
    if(Perfil !== "Corresponsales" && Perfil !== "Clientes"){
    ClientesDataService.getAllClientes(0)
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })

    CorresponsalesDataService.getAll()
      .then((response) => {
        setDataCorresponsales(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    }else{
      ClientesDataService.getAllClientes(UserId)
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    }
    generaReporte()
  }, [])

  function getParams(e: RowClickedEvent) {
    if(Deleting){
      if(e.data.pedimento > 0){
        setHeader('Error')
        setMsg('Favor de borrar el pedimento para poder eliminar la referencia.')
        setShowMsg(true)
        setDeleting(false)
        return;
      }
      setIDTrafico(e.data.id)
      setShowModalSolicitud(true)
    }else{
      AbreTrafico(e.data.id)
    }
  }

  function AbreTrafico(id: number) {
    dispatch(InitCorresponsalesFacturas(IDTrafico))
    dispatch(InitCorresponsalesFacturasTerceros(IDTrafico))
    dispatch(InitCorresponsalesContenedores(IDTrafico))
    setIDTrafico(id)
    setShowModal(true)
  }

  const generaReporte = () => {
    if (proc && modo) {
      const data: DTORptCorresponsalesTraficos = {
        Inicio: moment(Inicio).format('YYYY-MM-DD'),
        Fin: moment(Fin).format('YYYY-MM-DD'),
        TipoOperacion: TipoOperacion,
        NoCliente: Cliente,
        IdCorresponsal: Corresponsal,
        Pedimento: Pedimento,
        Aduana: Aduana,
        Patente: Patente,
        Proceso: Referencia !== '' || Pedimento !== 0 || Aduana !== '0' || Patente !== 0 ? 0 : parseInt(proc.replace('proc=', '')),
        Modo: modo ? parseInt(modo.replace('modo=', '')) : 0,
        Referencia: Referencia
      }
      reportesServices
        .getRptCorresponsalesTraficos(data)
        .then((response) => {
          setData(response.data)
          setFilteredData(response.data)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    gridRef.current.api.setQuickFilter(e.target.value)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Traficos de corresponsales')
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    /* let Heading = [
      [
        {
          title: 'Aduana Seccion Despacho',
          style: { font: { sz: '18', bold: true } },
        },
        'Patente',
        'Referencia',
        'Pedimento',
        'Fecha Pago',
        'Fecha Entrada Presentacion',
        'Clave Docto',
        'Es rectificacion',
        'Tipo Cambio',
        'Valor Dls',
        'Valor Aduana',
        'Numero Factura',
        'Fecha Factura',
        'Proveedor Factura',
        'Incrementables Fact',
        'Fraccion',
        'Subdiv NICO',
        'Descripcion',
        'Pais Origen',
        'Pais Vendedor',
        'Tasa DTA',
        'Tasa IGI',
        'Numero Parte',
        'Cantidad Comercial',
        'TL Pais',
        'Tipo Tasa',
        'Unidad Comercial',
        'Valor Factura Item',
        'Valor Comercial Ped',
        'Valor Factura Item MN',
      ],
    ] */
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    //XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A1', skipHeader: false })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    XLSX.writeFile(wb, fileName + '.xlsx')
    /* var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    } */
  }

  const readFile = (event: any) => {
    const fileUploaded = event.target.files[0];
    if(!fileUploaded) return;
    const fileReader = new FileReader();
    fileReader.readAsText(fileUploaded);

    fileReader.onload = async () => {
      const validDataArry = await cleanUpFileData(fileReader.result?.toString()!);
      let proveedores:ICatProveedores[] = [];
      await ProvDataService.getAll(0).then(async (response) => {proveedores = response.data});
      validDataArry.forEach(async element => {
        const model = filteredData.find(x => x.folioGemco === element[0]);
        if(model?.proceso === 1){
          model.pedimento = +element[6];
          model.clave = element[8];
          if(element[7] !== '') model.fechaPago = moment(new Date(element[7])).format("MM/DD/YYYY hh:mm:ss")
          model.tipoCambio = +element[15];
          model.noCuenta = element[9];
          model.semaforoFiscal = +element[23] === 1 ? 1 : 0;
          model.descripcionMercancia = element[14];
          if(element[22] !== '') model.fechaPago = moment(new Date(element[22])).format("MM/DD/YYYY hh:mm:ss")

          await CTrafDataService.Append(model)
            .then(async (response) => {
              if (response.data.estatusCode === 0) {
                
              }
            }).catch((error) => {
              setHeader('Error')
              setMsg('Ocurrio un error al actualizar la referencia: ' + model.folioGemco)
              setShowMsg(true)
              return
            })

          await CContDataService.getAll(model.id)
            .then(async (response) => {
              if(response.data.length > 0){
                let contenedor = response.data.filter(x => x.contenedor === element[32])[0]
                if(contenedor){
                  contenedor.fSemaforo = element[22]
                  contenedor.semaforo = +element[23] === 1 ? 2 : 1
                  await saveTxtContenedores(contenedor);
                }else{
                  await saveTxtContenedores({id: 0, contenedor: element[32], idTrafico: model.id , fSemaforo: element[22], semaforo: +element[23] === 1 ? 2 : 1,idTipoContenedor:0});
                }
              }else{
                await saveTxtContenedores({id: 0, contenedor: element[32], idTrafico: model.id , fSemaforo: element[22], semaforo: +element[23] === 1 ? 2 : 1, idTipoContenedor:0});
              }
            })
            .catch((e: Error) => {
              setHeader('Error')
              setMsg('Ocurrio un error al guardar el contenedor: ' + element[32])
              setShowMsg(true)
              return
            });

            
          let proveedor = proveedores.filter(x => x.nombre.includes(element[10]))[0]
          if(proveedor){
            await CFacDataService.getAll(model.id)
            .then(async (response) => {
              let factura = response.data.filter(f => f.factura === element[13])[0]
              if(factura){
                factura.valorFacturaDls = +element[16]
                factura.pedido = element[29]
                factura.proveedor = proveedor.id
                await saveFacturasTxt(factura).catch((e: Error) => {
                  setHeader('Error')
                  setMsg('Ocurrio un error al actualizar la factura: ' + factura.factura)
                  setShowMsg(true)
                  return
                })
              }else{
                await CFacDataService.Append({
                  id: 0, 
                  factura: element[13], 
                  idTrafico: model.id,
                  valorFacturaDls: +element[16],
                  proveedor: proveedor.id, 
                  corresponsal: model.idCorresponsal, 
                  pedido: element[29],
                  code: 0,
                  folioGEMCO: '',
                  uuid: '',
          fechaFactura: '',
          cove: '',
          entrega: '',
          transporte: '',
          idContenedor: 0,
                }).catch((e: Error) => {
                  setHeader('Error')
                  setMsg('Ocurrio un error al guardar la factura: ' + element[13])
                  setShowMsg(true)
                  return
                })
              }
            })
            .catch((e: Error) => {
              setHeader('Error')
              setMsg('Ocurrio un error: ' + e)
              setShowMsg(true)
              return
            })
          }else{
            setHeader('Error')
            setMsg('No se encontraron uno o más proveedores, favor de verificar los nombres');
            setShowMsg(true)
            return
          }
        }
      });
      if(hiddenFileInputRef.current !== null ) hiddenFileInputRef.current.value = "";
    };

    fileReader.onerror = () => {}
  };

  const cleanUpFileData = async (fileData: string) => {
    //Se separa el contenido del txt en renglones separandolo cada que encuentra un salto de linea
    let dataArray = fileData.split("\r\n")!;
    //Se filtran los renglones para dejar solamente los que tienen informacion de referencias
    let dataArrayWithoutJunkRows = dataArray.filter(x => x.startsWith("23-"));
    //La informacion de cada renglon se separa para poder manejar los datos invidualmente.
    let splitedDataArray: string[][] = [];
    dataArrayWithoutJunkRows.forEach(row => {
      splitedDataArray.push(row.split("\t"));
    });
    //Se vuelven a filtrar los renglones para quitar los que contengan pedimentos o referencas invalidas que hayan pasado los primeros filtros
    let validFormatDataArray = splitedDataArray.filter(x => +x[6] > 2000000 && !x[0].includes(",") && !x[0].includes("|"));
    let uniqueReferences: string[][] = [];
    let validDataArray = validFormatDataArray.filter( element => {
      let isDuplicate = uniqueReferences.filter(x => x[0] === element[0]);

      if(isDuplicate.length === 0){
        uniqueReferences.push(element);
        return true;
      }
      return false;
    });

    return validDataArray;
  }

  const saveTxtContenedores = async (contenedor: ICorresponsalContenedores) => {    
      await CContDataService.Append(contenedor)
      .catch((e: Error) => {
        return
      })
  }

  const saveFacturasTxt = async(factura: ICorresponsalFacturas) => {
      await CFacDataService.Append(factura)
      .catch((e: Error) => {
        return
      })
  }

  const handleClose = () => {
    setShowModalSolicitud(false)
    setDeleting(false)
  }
  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={8}>
              <Row>
                <Col xs={5}>
                  <Form.Control
                    type='text'
                    size='sm'
                    placeholder='Referencia'
                    onChange={(e) => {
                      setReferencia(e.target.value)
                      //filtraReporte(e)
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Control
                    type='text'
                    size='sm'
                    placeholder='Pedimento'
                    onChange={(e) => setPedimento(+e.target.value)}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Control
                    type='text'
                    size='sm'
                    placeholder='Aduana'
                    onChange={(e) => setAduana(e.target.value === '' ? '0' : e.target.value)}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Control
                    type='text'
                    size='sm'
                    placeholder='Patente'
                    onChange={(e) => setPatente(+e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row>
                <Col xs={6}>
                  <Form.Control
                    defaultValue={Inicio}
                    type='date'
                    name='Inicio'
                    placeholder='Inicio'
                    title='Inicio'
                    alt='Inicio'
                    data-date-format='YYYY-mm-dd'
                    onChange={(e) => setInicio(e.target.value)}
                    size='sm'
                  />
                </Col>
                <Col xs={6}>
                  <Form.Control
                    defaultValue={Fin}
                    type='date'
                    name='Fin'
                    placeholder='Fin'
                    title='Fin'
                    alt='Fin'
                    onChange={(e) => setFin(e.target.value)}
                    size='sm'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{paddingTop:'10px'}}>
            <Col lg={2}>
              <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                Tipo operacion
                <Form.Control
                  as='select'
                  onChange={(e) => setTipoOperacion(parseInt(e.target.value))}
                  className='form-select form-select-sm'
                >
                  <option value='0'>-SELECCIONE-</option>
                  <option value='1'>Importacion</option>
                  <option value='2'>Exportacion</option>
                </Form.Control>
              </Form.Label>
            </Col>
            <Col lg={4}>
              <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                Cliente
                <Form.Control
                  as='select'
                  onChange={(e) => {
                    setCliente(parseInt(e.target.value))
                  }}
                  className='form-select form-select-sm'
                >
                  <option value='0'>-SELECCIONE-</option>
                  {Clientes
                    ? Clientes.map((c) => {
                        return (
                          <option value={c.sClave} key={c.sClave}>
                            {c.sRazonSocial}
                          </option>
                        )
                      })
                    : null}
                </Form.Control>
              </Form.Label>
            </Col>
            {(Perfil !== "Corresponsales")? <>
              <Col lg={4}>
                <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                  Corresponsal
                  <Form.Control
                    as='select'
                    onChange={(e) => {
                      setCorresponsal(parseInt(e.target.value))
                    }}
                    className='form-select form-select-sm'
                  >
                    <option value='0'>-SELECCIONE-</option>
                    {DataCorresponsales
                      ? DataCorresponsales.map((c) => {
                          return (
                            <option value={c.id} key={c.id}>
                              {c.nombre}
                            </option>
                          )
                        })
                      : null}
                  </Form.Control>
                </Form.Label>
              </Col></> : ""
            }
          </Row>
          <Row style={{paddingTop:'10px'}}>
            <Col lg={12} style={{textAlign:'end'}}>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
                style={{marginRight:'5px'}}
              >
                <BsSearch />
                &nbsp; Buscar
              </Button>
              {(Depto === 'Corresponsalias' || Depto === 'Sistemas') ? (
                <Button
                  size='sm'
                  variant='primary'
                  onClick={() => {
                    AbreTrafico(0)
                  }}
                  style={{marginRight:'5px'}}
                >
                  <BsPlusLg />
                  &nbsp; Nuevo
                </Button>
              ) : (
                ''
              )}
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
                style={{marginRight:'5px'}}
              >
                <BsFileEarmarkExcel />
                &nbsp; Excel
              </Button>
              { (Depto === 'Corresponsalias' || Depto === 'Sistemas') ? <>
                <Form.Control type="file" style={{display:'none'}} onChange={readFile} ref={hiddenFileInputRef}/>
                <Button
                  id="BtnLoadTxt"
                  variant="success"
                  size="sm"
                  onClick={() => hiddenFileInputRef.current?.click()}
                >

                  <FaCrow />
                  &nbsp; Layout Casa Cuervo
                </Button>
              </> : ""}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          ref={gridRef}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={true}
          onRowClicked={(e) => getParams(e)}
        ></AgGridReact>
      </div>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
      <Modal
        show={ShowModal}
        size='sm'
        dialogClassName='modal-90w'
        disableEscapeKeyDown={true}
      >
        <Modal.Body
          style={{
            height: 'calc(90vh)',
            overflowY: 'auto',
          }}
        >
          <div style={{height:'100%'}}>
            <TraficoCorresponsales
              IDTrafico={IDTrafico}
              onClose={function (val: boolean): void {
                setShowModal(false)
                generaReporte()
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ShowModalSolicitud}
        dialogClassName='modal-50w'
        style={{backdropFilter:'brightness(0.5)'}}
        centered
        onHide={handleClose}
      >
        <Modal.Body>
          <div style={{ height: '250px', overflowX: 'hidden' }}>
            <CrearSolicitud 
              idTrafico={IDTrafico} 
              idUsuario={UserId} 
              title='Solicitud Para Eliminar Referencia'
              close={()=>{
                setShowModalSolicitud(false)
                setDeleting(false)
                generaReporte()
                if(Perfil === 'Sup. Corresponsalias'){
                  setHeader('Informativo');
                  setMsg('Referencia eliminada correctamente.')
                  setShowMsg(true)
                  return;
                }
                else{
                  setHeader('Informativo');
                  setMsg('Solicitud creada correctamente.')
                  setShowMsg(true)
                  return;
                }
              }
            }/>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
