import http from "../common/http-common";
import ICorresponsalCatMediosEmbarque from "../../Interfaces/Corresponsales/ICorresponsalCatMediosEmbarque";

class CorresponsalesDataService {

  getAll() {
    return http.get<ICorresponsalCatMediosEmbarque[]>(`/Corresponsalias/CatMediosEmbarques/GetAll`);
  }

}
export default new CorresponsalesDataService();