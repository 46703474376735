import { useDispatch, useSelector } from "react-redux"
import { TargetURL } from "../../../Constants/TargetURL"
import { useEffect, useRef, useState } from "react"
import { RootState } from "../../../store/store"
import IClientes from "../../../Interfaces/Catalogos/IClientes"
import ICorresponsalCatTipoDocumento from "../../../Interfaces/Corresponsales/ICorresponsalCatTipoDocumento"
import ITabulador from "../../../Interfaces/Catalogos/ITabulador"
import ICorresponsalCatDestinos from "../../../Interfaces/Corresponsales/ICorresponsalCatDestinos"
import ICorresponsalCatMediosEmbarque from "../../../Interfaces/Corresponsales/ICorresponsalCatMediosEmbarque"
import ICorresponsalCatTiposEmbarque from "../../../Interfaces/Corresponsales/ICorresponsalesCatTiposEmbarque"
import ICorresponsalCatAduanas from "../../../Interfaces/Corresponsales/ICorresponsalCatAduanas"
import ICorresponsalRazonRechazo from "../../../Interfaces/Corresponsales/ICorresponsalRazonesRechazo"
import ICorresponsalesCatTraficoEstatus from "../../../Interfaces/Corresponsales/ICorresponsalesCatTraficoEstatus"
import { ITiposMercancias } from "../../../Interfaces/Catalogos/ICatTiposMercancias"
import { ICatClavesPedimentos } from "../../../Interfaces/Catalogos/ICatClavesPedimentos"
import ICorresponsalContenedores from "../../../Interfaces/Corresponsales/ICorresponsalContenedores"
import ICorresponsalFacturas from "../../../Interfaces/Corresponsales/ICorresponsalFacturas"
import CCorresponsalesTrafico from "../../../Classes/Corresponsales/CCorresponsalesTrafico"
import ICorresponsalPedimento from "../../../Interfaces/Corresponsales/ICorresponsalPedimento"
// Services
import CAntDataService from '../../../Services/Corresponsalias/Corresponsales.Anticipos.Services'
import CContaDataService from '../../../Services/Contabilidad/Contabilidad.Corresponsalias.Services'
import CContDataService from '../../../Services/Corresponsalias/Corresponsales.Contenedores.Services'
import CorresponsalesDataService from '../../../Services/Catalogos/Corresponsales.Services'
import CFac3erDataService from '../../../Services/Corresponsalias/Corresponsales.FacturasTerceros.services'
import CFacDataService from '../../../Services/Corresponsalias/Corresponsales.Facturas.Services'
import CGuiasDataService from '../../../Services/Corresponsalias/Corresponsales.Guias.Services'
import CTrafDataService from '../../../Services/Corresponsalias/Corresponsales.Trafico.Services'
import ClientesDataService from '../../../Services/Catalogos/Clientes.Services'
import CTabDataService from '../../../Services/Corresponsalias/Corresponsales.Tabuladores.Services'
import ProvDataService from '../../../Services/Catalogos/Proveedores.Services'
import CCatTiposDocumentos from '../../../Services/Corresponsalias/Corresponsalias.CatTiposDocumentos.Services'
import CPartidasService from '../../../Services/Corresponsalias/Corresponsales.Trafico.Pedimento.Partidas.Services'
import CCatAduanasService from '../../../Services/Corresponsalias/Corresponsales.CatAduanas.Services'
import CCatDestinosService from '../../../Services/Corresponsalias/Corresponsales.CatDestinos.Services'
import CCatTiposEmbarqueService from '../../../Services/Corresponsalias/Corresponsales.CatTiposEmbarque.Services'
import CCatMediosEmbarqueService from '../../../Services/Corresponsalias/Corresponsales.CatMediosEmbarque.Services'
import CPedimentoService from '../../../Services/Corresponsalias/Corresponsales.Trafico.Pedimento.Services'
import DashboardService from '../../../Services/Dashboard/Dashboard.Corresponsales.Services'
import CCueCompService from '../../../Services/Corresponsalias/Corresponsales.CuentasComplementarias.Services'
import FileManService from '../../../Services/Utils/FileManager.Services'
import TiposMercanciaService from '../../../Services/Catalogos/TiposMercancia.Services'
import ClavesPedimentosService from "../../../Services/Catalogos/ClavesPedimentos.Service"
import { populateCatCorresponsales } from "../../../store/features/CatCorresponsales/CatCorresponsalesSlice"
import { InitCorresponsalesFacturas, addCorresponsalesFacturas, addTxtFacturas } from "../../../store/features/Corresponsales/CorresponsalesFacturasSlice"
import { InitCorresponsalesFacturasTerceros, addCorresponsalesFacturasTerceros } from "../../../store/features/Corresponsales/CorresponsalesFacturasTercerosSlice"
import { InitCorresponsalesContenedores, addCorresponsalesContenedores, addTxtContenedores } from "../../../store/features/Corresponsales/CorresponsalesContenedoresSlice"
import axios from "axios"
import moment from "moment"
import { Form, Card, Row, Col, Container, InputGroup, FormControl, Button, Tabs, Tab, Alert, Modal } from "react-bootstrap"
import CurrencyFormat from "react-currency-format"
import { IconContext } from "react-icons"
import { BsFillXCircleFill } from "react-icons/bs"
import { FaRegClone } from "react-icons/fa"
import { FcTimeline, FcBusinessman } from "react-icons/fc"
import { HiCurrencyDollar } from "react-icons/hi"
import MaskedInput from "react-text-mask"
import DTORectificacionHistorico from "../../../DTO/Corresponsales/DTORectificacionHistorico"
import DTOTraficoCompleto from "../../../DTO/Corresponsales/DTOTraficoCompleto"
import ICorresponsalesTraficoContabilidad from "../../../Interfaces/Corresponsales/ICorresponsalTraficoContabilidad"
import CentrosCostosService from "../../../Services/Catalogos/CentrosCostos.Service"
import FileManagerServices from "../../../Services/Utils/FileManager.Services"
import { populateCatProveedores } from "../../../store/features/CatProveedores/CatProveedoresSlice"
import { populateCorresponsalesAnticipos } from "../../../store/features/Corresponsales/CorresponsalesAnticiposSlice"
import { populateCorresponsalesCuentasComplementariasEstatus } from "../../../store/features/Corresponsales/CorresponsalesCuentasComplementariasEstatusSlice"
import { populateCorresponsalesGuias } from "../../../store/features/Corresponsales/CorresponsalesGuiasSlice"
import { populateCorresponsalesPartidas } from "../../../store/features/Corresponsales/CorresponsalesPartidasSlice"
import { CatCorresponsales } from "../../Catalogos/CatCorresponsales/CatCorresponsales"
import { Anticipos } from "../../Corresponsales/Anticipos/Anticipos"
import { Contenedores } from "../../Corresponsales/Contenedores/Contenedores"
import { SelectContainer } from "../../Corresponsales/Contenedores/SelectContainer"
import { CuentasComplementarias } from "../../Corresponsales/CuentasComplementarias/CuentasComplementarias"
import { SelectGuias } from "../../Corresponsales/Guias/SelectGuias"
import { PagosTerceros } from "../../Corresponsales/PagosTerceros/PagosTerceros"
import { Partidas } from "../../Corresponsales/Partidas/Partidas"
import { ProveedorFactura } from "../../Corresponsales/ProveedorFactura/ProveedorFactura"
import { Rectificaciones } from "../../Corresponsales/Rectificaciones/Rectificaciones"
import { Tabulador } from "../../Corresponsales/Tabulador/Tabulador"
import FileManager from "../../Utils/FileManager/FileManager"
import { MFileManager } from "../../Utils/MFileManager/MFileManager"
import { MsgInformativo } from "../../Utils/Toast/msgInformativo"
import DatePicker from 'react-datepicker'
import { populateRectificaciones } from "../../../store/features/Corresponsales/CorresponsalesPedimentosRectificacionesSlice"
import { NuevaRectificacion } from "../../Corresponsales/Rectificaciones/NuevaRectificacion"
import { PedimentosConsolidados } from "../../Corresponsales/PedimentosConsolidados/PedimentosConsolidados"
import { populatePedimentos } from "../../../store/features/Corresponsales/CorresponsalesPedimentosConsolidadosSlice"
import PedimentosConsolidadosService from '../../../Services/Corresponsalias/Corresponsales.Trafico.Pedimento.Consolidados.Service'
interface IProps {
    IDTrafico: number
    onClose: (val: boolean) => void
}

interface ItemList {
    id: number
    item: string
}

interface ItemList {
    item: string
}

interface IAduanas {
    id: number
    descripcion: string
}

export default function TraficoCliente(props:IProps){
    const URL = new TargetURL()
  const token = localStorage.getItem('token');
  const dispatch = useDispatch()
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Usuario, setUsuario] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  }) 
  const mCorresponsales = useSelector(
    (state: RootState) => state.CatCorresponsales.CatCorresponsales
  )
  const mCFacturas = useSelector(
    (state: RootState) => state.CFData.CorresponsalesFacturas
  )
  const mCFTerceros = useSelector(
    (state: RootState) => state.CF3rosData.CorresponsalesFacturasTerceros
  )
  const mCContenedores = useSelector(
    (state: RootState) => state.CCData.CorresponsalesContenedores
  )
  const mCAnticipos = useSelector(
    (state: RootState) => state.CAnticipos.CorresponsalesAnticipos
  )
  const mCCCEstatus = useSelector(
    (state: RootState) =>
      state.CCueCompEstatus.CorresponsalesCuentasComplementariasEstatus
  )
  const mCPedimentosConsolidados = useSelector(
    (state:RootState) => state.CPedimentosConsolidados.PedimentosConsolidados
  )
  const mCProveedores = useSelector((state:RootState) => state.CatProveedores.CatalogoProveedores)

  const [ModalSize, setModalSize] = useState('sm')
  const [showModal, setShowModal] = useState(false)
  const [LoadComponent, setLoadComponent] = useState(0)
  const [IDTrafico, setIDTrafico] = useState(props.IDTrafico)
  const [IDDetail, setIDDetail] = useState(0)
  const [FolioTrafico, setFolioTrafico] = useState('')
  const [Proceso, setProceso] = useState(0)
  const [IDCliente, setIDCliente] = useState(0)
  const [NombreCliente, setNombreCliente] = useState('')
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [CatTiposDocumento, setCatTiposDocumentos] =
    useState<ICorresponsalCatTipoDocumento[]>()
  const [
    CatTiposDocumentoInfoCorresponsal,
    setCatTiposDocumentosInfoCorresponsal
  ] = useState<ICorresponsalCatTipoDocumento[]>()
  const [TipoOperacion, setTipoOperacion] = useState(0)
  const [OpEntrada, setOpEntrada] = useState(0)
  const [OpSalida, setOpSalida] = useState(0)
  const [IDCorresponsal, setIDCorresponsal] = useState(0)
  const [Bultos, setBultos] = useState<number | undefined>(0)
  const [Kilos, setKilos] = useState<number | undefined>(0)
  const [Estatus, setEstatus] = useState<number>(0)
  const [Trafico, setTrafico] = useState<string | undefined>('')
  const [Aduana, setAduana] = useState<string | undefined>('0')
  const [Patente, setPatente] = useState<number | undefined>(0)
  const [Pedimento, setPedimento] = useState<number | undefined>(0)
  const [ClavePedimento, setClavePedimento] = useState<string>('')
  const [FechaPago, setFechaPago] = useState<string | undefined>('')
  const [TipoCambio, setTipoCambio] = useState<number | undefined>(0)
  const [ValorAduanaMN, setValorAduanaMN] = useState<number | undefined>(0)
  const [TotalPagado, setTotalPagado] = useState<number | undefined>(0)
  const [ValorFacturaMN, setValorFacturaMN] = useState<number | undefined>(0)
  const [CantidadFracciones, setCantidadFracciones] = useState<
    number | undefined
  >(0)
  const [Buque, setBuque] = useState<string | undefined>('')
  const [ValorFacturasDls, setValorFacturasDls] = useState<number | undefined>(
    0
  )
  const [DescripcionMercancia, setDescripcionMercancia] = useState<
    string | undefined
  >('')
  const [Observaciones, setObservaciones] = useState<string>('')
  const [FechaDesaduanamiento, setFechaDesaduanamiento] =
    useState<Date | null>()
  const [SemafoFiscal, setSemaforoFiscal] = useState<number | undefined>(0)
  const [NoCuenta, setNoCuenta] = useState<string | undefined>('')
  const [FechaCuenta, setFechaCuenta] = useState<string | undefined>('')
  const [Anticipo, setAnticipo] = useState<number | undefined>(0)
  const [IDTabulador, setIDTabulador] = useState(0)
  const [Tabuladores, setTabuladores] = useState<ITabulador[]>([])
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [DSCatAduanas, setDSCatAduanas] = useState<ICorresponsalCatAduanas[]>()
  const [DSCatDestinos, setDSCatDestinos] =
    useState<ICorresponsalCatDestinos[]>()
  const [DSCatMediosEmbarque, setDSCatMediosEmbarque] =
    useState<ICorresponsalCatMediosEmbarque[]>()
  const [DSCatTiposEmbarque, setDSCatTiposEmbarque] =
    useState<ICorresponsalCatTiposEmbarque[]>()
  const [LblFacturas, setLblFacturas] = useState('Facturas')
  const [LblFacturasTerceros, setLblFacturasTerceros] = useState(
    'Facturas pagos a terceros'
  )
  const [LblAnticipos, setLblAnticipos] = useState('Anticipos')
  const [LblPedimentos, setLblPedimentos] = useState('Pedimentos Consolidados')
  const [LblRectificaciones, setLblRectificaciones] = useState('Ped. Rectificados')
  const [StatusTrafico, setStatusTrafico] = useState(1)
  const [StatusColor, setStatusColor] = useState('#FFFFFF')
  const [PagosaTerceros, setPagosaTerceros] = useState(0)
  const [ComentariosRechazo, setComentariosRechazo] = useState<string>('')
  const [RechazosTrafico, setRechazosTrafico] = useState<
    ICorresponsalRazonRechazo[]
  >([])
  const [RechazosContabilidad, setRechazosContabilidad] = useState<
    ICorresponsalRazonRechazo[]
  >([])
  const [DataCatEstatus, setDataCatEstatus] = useState<
    ICorresponsalesCatTraficoEstatus[]
  >([])
  const [TiposMercancia, setTiposMercancia] = useState<ITiposMercancias[]>([])
  const [IDTipoMercancia, setIDTipoMercancia] = useState<number | null>(null)
  const [Aduanas, setAduanas] = useState<IAduanas[]>([
    { id: 440, descripcion: 'CD. ACUNA, COAH.' },
    { id: 810, descripcion: 'ALTAMIRA, TAMPS.' }
  ])
  const [IDAduana, setIDAduana] = useState(0)
  const [CostoDiario, setCostoDiario] = useState(0)
  const [CostoDiarioConexion, setCostoDiarioConexion] = useState(0)
  const [Descripcion, setDescripcion] = useState<string>('')
  const [IDDestino, setIDDestino] = useState(0)
  const [DiasPedPagado, setDiasPedPagado] = useState(0)
  const [Embalaje, setEmbalaje] = useState(0)
  const [IDEstatus, setIDEstatus] = useState(0)
  const [FAlmacenajeInicioGastos, setFAlmacenajeInicioGastos] =
    useState<string>('')
  const [FDespacho, setFDespacho] = useState<string>('')
  const [FechaETA, setFechaETA] = useState<string | undefined>('')
  const [FEntrada, setFEntrada] = useState<string>('')
  const [FHEntregaPlanta, setFHEntregaPlanta] = useState<Date | null>()
  const [FHInstrucciones, setFHInstrucciones] = useState<Date | null>()
  const [Fletes, setFletes] = useState(0)
  const [FRevalidacionGuia, setFRevalidacionGuia] = useState<string>('')
  const [HAWB, setHAWB] = useState('')
  const [Incoterm, setIncoterm] = useState('')
  const [LineaTransportistaInternacional, setLineaTransportistaInternacional] =
    useState('')
  const [LineaNaviera, setLineaNaviera] = useState('')
  const [FNotificacion, setFNotificacion] = useState<string>('')
  const [MAWB, setMAWB] = useState('')
  const [MontoUSD, setMontoUSD] = useState(0)
  const [NoGuia, setNoGuia] = useState('')
  const [Origen, setOrigen] = useState('')
  const [Otros, setOtros] = useState(0)
  const [Transportista, setTransportista] = useState('')
  const [PesoNeto, setPesoNeto] = useState(0)
  const [FraccionArancelaria, setFraccionArancelaria] = useState('')
  const [PreferenciaArancelaria, setPreferenciaArancelaria] = useState(0)
  const [Seguros, setSeguros] = useState(0)
  const [IDTipoEmbarque, setIDTipoEmbarque] = useState(0)
  const [TotalPagar, setTotalPagar] = useState(0)
  const [TotalPagarConexion, setTotalPagarConexion] = useState(0)
  const [ChangePermission, setChangePermission] = useState(false)
  const [Ready4NextProcess, setReady4NextProcess] = useState(false)
  const [TerminaProceso, setTerminaProceso] = useState(-100)
  const [TieneArchivoZIPGEMCO, setTieneArchivoZIPGEMCO] = useState(0)
  const [TieneArchivoZIPCorresponsal, setTieneArchivoZIPCorresponsal] =
    useState(0)
  const [IDRazonRechazo, setIDRazonRechazo] = useState(0)
  const [AduanaH, setAduanaH] = useState('')
  const [PatenteH, setPatenteH] = useState('')
  const [PedimentoH, setPedimentoH] = useState('')
  const [ClavePedimentoH, setClavePedimentoH] = useState('')
  const [FechaPagoH, setFechaPagoH] = useState('')
  const [TotalHonorariosAA, setTotalHonorariosAA] = useState(0)
  const [TotalGastosTerceros, setTotalGastosTerceros] = useState(0)

  const [showNextStepModal, setShowNexStepModal] = useState<boolean>(false);
  const [nextStepModalHeader, setNextStepModalHeader] = useState<string>("");
  const [nextStepModalBody, setNextStepModalBody] = useState<string>("");
  const [CargoPara, setCargoPara] = useState(0);
  const [CausaRectificacion, setCausaRectificacion] = useState('');
  const [EncabezadoPedimento1, setEncabezadoPedimento1] = useState("Pedimentos");
  const [EncabezadoPedimento2, setEncabezadoPedimento2] = useState(".");
  const [CentroCostos, setCentroCostos] = useState<string>('');
  const [ClaveClienteFacturacion, setClaveClienteFacturacion] = useState(0);
  const [NombreClienteFacturacion, setNombreClienteFacturacion] = useState('')
  const [RfcClienteFacturacion, setRfcClienteFacturacion] = useState('')
  const [ClavesPedimentos, setClavesPedimentos] = useState<ICatClavesPedimentos[]>([])
  const [FechaRegistro, setFechaRegistro] = useState(currentDate());
  const msgColor = 'primary'

  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  var txtContenedores: ICorresponsalContenedores[] = [];
  var txtFacturas: ICorresponsalFacturas[] = [];
  const itemsTrafico = [
    {
      key: 1,
      component: (
        <div>
          <img src="http://reportes.gemcousa.com/images/Ok.png" alt="" />
          Confirmado
        </div>
      )
    },
    {
      key: 0,
      component: (
        <div>
          <img src="http://reportes.gemcousa.com/images/Bad.png" alt="" />
          Rechazado
        </div>
      )
    }
  ]

  const handleClose = () => setShowModal(false)

  const adjustTimeZone = (str: string): string => {
    if (str) {
      const original: any = new Date(str)
      const real = new Date(original - original.getTimezoneOffset() * 60 * 1000)
      return real.toISOString()
    } else return ''
  }

  const saveForm = () => {
    if (IDCliente === 0) {
      setHeader('Error')
      setMsg('Para continuar seleccione el cliente')
      setShowMsg(true)
      return
    }
    if(TipoOperacion === 0){
      setHeader('Error')
      setMsg('Para continuar seleccione el tipo de operación')
      setShowMsg(true)
      return
    }
    if (OpEntrada === 0 || OpSalida === 0) {
      setHeader('Error')
      setMsg('Para continuar seleccion las operacion es de Entrada y Salida')
      setShowMsg(true)
      return
    }
    if (IDCorresponsal === 0) {
      setHeader('Error')
      setMsg('Para continuar seleccion el corresponsal')
      setShowMsg(true)
      return
    }    
    let data = new CCorresponsalesTrafico().getEmptyObject()
    data.id = IDTrafico
    data.idUsuario = UserId
    data.idCliente = IDCliente
    data.tipoOperacion = TipoOperacion
    data.opEntrada = OpEntrada
    data.opSalida = OpSalida
    data.idCorresponsal = IDCorresponsal
    data.bultos = Bultos
    data.kilos = Kilos
    data.estatus = Estatus
    data.trafico = Trafico
    data.aduana = Aduana
    data.patente = Patente
    data.pedimento = Pedimento
    data.clave = ClavePedimento
    data.fechaPago = FechaPago
    data.tipoCambio = TipoCambio
    data.valorAduanaMN = ValorAduanaMN
    data.totalPagado = TotalPagado
    data.valorFacturaMN = ValorFacturaMN
    data.cantidadFracciones = CantidadFracciones
    data.buque = Buque
    data.valorFacturaDls = ValorFacturasDls
    data.descripcionMercancia = DescripcionMercancia
    data.observaciones = Observaciones
    data.fechaDesaduanamiento = FechaDesaduanamiento
      ? adjustTimeZone(FechaDesaduanamiento.toISOString())
      : ''
    data.semaforoFiscal = SemafoFiscal
    data.noCuenta = NoCuenta
    data.fechaCuenta = FechaCuenta
    data.idTabulador = IDTabulador
    data.idTipoMercancia = IDTipoMercancia
    data.cargoA = CargoPara
    data.causaRectificacion = CausaRectificacion
    data.totalHonorariosAA = TotalHonorariosAA
    data.totalGastosTerceros = TotalGastosTerceros
    CTrafDataService.Append(data)
      .then((response) => {
        if (response.data.estatusCode === 0) {
          if (mCFacturas.length >= 2) {
            const Detail: ICorresponsalPedimento = {
              id: IDDetail ? IDDetail : 0,
              idTrafico: IDTrafico,
              aduana: IDAduana,
              costoDiario: CostoDiario,
              descripcion: Descripcion,
              destino: IDDestino,
              diasCPPagado: DiasPedPagado,
              embalaje: Embalaje,
              estatus: IDEstatus,
              fAlmacenajeInicioGastos: FAlmacenajeInicioGastos
                ? FAlmacenajeInicioGastos
                : '',
              fDespacho: FDespacho ? FDespacho : '',
              fechaETA: FechaETA,
              fEntrada: FEntrada ? FEntrada : '',
              fhEntregaPlanta: FHEntregaPlanta
                ? adjustTimeZone(FHEntregaPlanta.toISOString())
                : '',
              fhInstrucciones: FHInstrucciones
                ? adjustTimeZone(FHInstrucciones.toISOString())
                : '',
              fletes: Fletes,
              fRevalidacionGuia: FRevalidacionGuia ? FRevalidacionGuia : '',
              hawb: HAWB,
              incoterm: Incoterm,
              lineaTransportistaInternacional: LineaTransportistaInternacional,
              mawb: MAWB,
              montoUSA: MontoUSD,
              noGuia: NoGuia,
              observaciones: Observaciones,
              origen: Origen,
              otros: Otros,
              paqueteriaTransportista: Transportista,
              pesoNeto: PesoNeto,
              preferenciaArancelaria: PreferenciaArancelaria,
              seguros: Seguros,
              tipoEmbarque: IDTipoEmbarque,
              totalPagar: TotalPagar,
              activo: 1,
              lineaNaviera: LineaNaviera,
              fNotificacion: FNotificacion,
              fraccionArancelaria: FraccionArancelaria,
              costoDiarioConexion: CostoDiarioConexion,
              totalPagarConexion: TotalPagarConexion
            }
            CPedimentoService.Append(Detail)
              /*.then((responseDetail) => {
                setHeader('Informativo')
                setMsg('La informacion se guardo correctamente')
                setShowMsg(true)
                if (Ready4NextProcess && TerminaProceso >= 0) saveFinalStep()
                return
              })*/
              .catch((e: Error) => {
                setHeader('Error')
                setMsg('Ocurrio un error: ' + e + ' en el detail')
                setShowMsg(true)
                return
              })
          }
          setIDTrafico(response.data.id)
          if (Ready4NextProcess && TerminaProceso >= 0 && Depto === 'Corresponsalias') {
            if(Proceso === 1) {
              setNextStepModalHeader("Referencia completa"); 
              setNextStepModalBody("Esta referencia se encuentra completa ¿Desea enviarla a contabilidad?")
              setShowNexStepModal(true);
            }
          }else{
            setHeader('Informativo')
            setMsg('La informacion se guardo correctamente')
            setShowMsg(true)
          }
          return
        } else if (response.data.estatusCode === 409.1) {
          setHeader('Error')
          setMsg(
            'Ese pedimento ya esta registrado en el trafico: ' +
              response.data.folioGemco
          )
          setShowMsg(true)
          return
        } else if (response.data.estatusCode === 409.2) {
          setHeader('Error')
          setMsg(
            'Esa cuenta de gastos ya esta registrada en el trafico: ' +
              response.data.folioGemco
          )
          setShowMsg(true)
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  function currentDate(): string {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  function getCurrentYear(): string {
    var today = new Date()
    return today.getFullYear().toString()
  }

  const LoadEverything = () => {
    let clientes: IClientes[] = [];
    ClavesPedimentosService.getAllClavesPedimentos()
    .then(response => setClavesPedimentos(response.data))
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Ocurrio un error: ' + e)
      setShowMsg(true)
      return
    })
    CorresponsalesDataService.getAll()
      .then((response) => {
        dispatch(populateCatCorresponsales(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CTrafDataService.GetCorresponsalesTraficoEstatus()
      .then((response) => {
        setDataCatEstatus(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    
    TiposMercanciaService.GetAll()
    .then((response) => {
      setTiposMercancia(response.data);
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Ocurrio un error: ' + e)
      setShowMsg(true)
      return
    });

    CFacDataService.getAll(IDTrafico)
      .then((response) => {
        dispatch(InitCorresponsalesFacturas(IDTrafico))
        response.data.forEach((element) => {
          dispatch(addCorresponsalesFacturas(element))
        })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CFac3erDataService.getAll(IDTrafico)
      .then((response) => {
        dispatch(InitCorresponsalesFacturasTerceros(IDTrafico))
        response.data.forEach((element) => {
          dispatch(addCorresponsalesFacturasTerceros(element))
        })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CContDataService.getAll(IDTrafico)
      .then((response) => {
        dispatch(InitCorresponsalesContenedores(IDTrafico))
        response.data.forEach((element) => {
          dispatch(addCorresponsalesContenedores(element))
        })
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    ClientesDataService.getAllClientes(0)
      .then((response) => {
        clientes = response.data;
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CAntDataService.getAll(IDTrafico)
      .then((response) => {
        dispatch(populateCorresponsalesAnticipos(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CGuiasDataService.getAll(IDTrafico)
      .then((response) => {
        dispatch(populateCorresponsalesGuias(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    ProvDataService.getAll(0)
      .then((response) => {
        dispatch(populateCatProveedores(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CCatTiposDocumentos.getAll(2206, 1)
      .then((response) => {
        setCatTiposDocumentos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CCatTiposDocumentos.getAll(2206, 2)
      .then((response) => {
        setCatTiposDocumentosInfoCorresponsal(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CCueCompService.GetAllEstatus()
      .then((response) => {
        dispatch(
          populateCorresponsalesCuentasComplementariasEstatus(response.data)
        )
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CTrafDataService.GetAll(IDTrafico)
      .then((response) => {
        setFolioTrafico(response.data.folioGemco)
        setProceso(response.data.proceso)
        setIDCliente(response.data.idCliente)
        getCentroCostos(response.data.idCliente, clientes);
        setIDTabulador(response.data.idTabulador)
        setTipoOperacion(response.data.tipoOperacion)
        setOpEntrada(response.data.opEntrada)
        setOpSalida(response.data.opSalida)
        setIDCorresponsal(response.data.idCorresponsal)
        setBultos(response.data.bultos)
        setKilos(response.data.kilos)
        setEstatus(response.data.estatus ? response.data.estatus : 0)
        setTrafico(response.data.trafico)
        setPedimento(response.data.pedimento)
        setClavePedimento(response.data.clave ? response.data.clave : '')
        setFechaPago(setDate(response.data.fechaPago))
        setTipoCambio(response.data.tipoCambio)
        setValorAduanaMN(response.data.valorAduanaMN)
        setTotalPagado(response.data.totalPagado)
        setValorFacturaMN(response.data.valorFacturaMN)
        setCantidadFracciones(response.data.cantidadFracciones)
        setBuque(response.data.buque)
        setValorFacturasDls(response.data.valorFacturaDls)
        setDescripcionMercancia(response.data.descripcionMercancia)
        setObservaciones(
          response.data.observaciones ? response.data.observaciones : ''
        )
        setFechaDesaduanamiento(StrtoDate(response.data.fechaDesaduanamiento))
        setSemaforoFiscal(response.data.semaforoFiscal)
        setNoCuenta(response.data.noCuenta)
        setFechaCuenta(setDate(response.data.fechaCuenta))
        setIDTipoMercancia(response.data.idTipoMercancia);
        setCargoPara(response.data.cargoA)
        setCausaRectificacion(response.data.causaRectificacion)
        setTotalHonorariosAA(response.data.totalHonorariosAA)
        setTotalGastosTerceros(response.data.totalGastosTerceros)
        setFechaRegistro(setDate(response.data.fechaRegistro))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CContaDataService.Get(IDTrafico, 1, 2)
      .then((response) => {
        setRechazosTrafico(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    CContaDataService.Get(IDTrafico, 2, 1)
      .then((response) => {
        setRechazosContabilidad(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
      PedimentosConsolidadosService.GetAll(IDTrafico)
      .then((resp) => {
        dispatch(populatePedimentos(resp.data))
      })
      loadRectificacionHistorico()
       DoesThe2ZipFileExists()
  }

  const loadRectificacionHistorico = () => {
    CTrafDataService.GetRectificacionHistorico(IDTrafico)
    .then((response) => {
      dispatch(populateRectificaciones(response.data))
      if (response.data.length > 0) {  
        let ultimaRectificacion = response.data[response.data.length-1]
        setEncabezadoPedimento1("Pedimento Master Rectificado")
        setAduanaH(ultimaRectificacion.aduana.toString())
        setPatenteH(ultimaRectificacion.patente.toString())
        setPedimentoH(ultimaRectificacion.pedimento.toString())
        setClavePedimentoH(ultimaRectificacion.clave)
        setFechaPagoH(ultimaRectificacion.fechaPago)
      }
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Ocurrio un error: ' + e)
      setShowMsg(true)
      return
    })
  }

  const DoesThe2ZipFileExists = () => {
    if (IDTrafico > 0) {
      FileManService.getFileInfoFromProcess(IDTrafico, 2)
        .then((response) => {
          setTieneArchivoZIPGEMCO(
            response.data.nombreArchivo.length > 5 ? 1 : 0
          )
        })
        .catch((e: Error) => {
          return
        })
      FileManService.getFileInfoFromProcess(IDTrafico, 3)
        .then((response) => {
          setTieneArchivoZIPCorresponsal(
            response.data.nombreArchivo.length > 5 ? 1 : 0
          )
        })
        .catch((e: Error) => {
          return
        })
    }
  }

  useEffect(() => {
    if (mCFacturas.length >= 2 && IDTrafico > 0) {
      CPartidasService.GetAll(IDTrafico)
        .then((response) => {
          dispatch(populateCorresponsalesPartidas(response.data))
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
      CPedimentoService.Get(IDTrafico)
        .then((responsed) => {
          setFDespacho(setDate(responsed.data.fDespacho))
          setIDDetail(responsed.data.id)
          setIDAduana(responsed.data.aduana)
          setCostoDiario(responsed.data.costoDiario)
          setDescripcion(responsed.data.descripcion)
          setIDDestino(responsed.data.destino)
          setDiasPedPagado(responsed.data.diasCPPagado)
          setEmbalaje(responsed.data.embalaje)
          setIDEstatus(responsed.data.estatus)
          setFAlmacenajeInicioGastos(responsed.data.fAlmacenajeInicioGastos)
          setFHInstrucciones(StrtoDate(responsed.data.fhInstrucciones))
          setFHEntregaPlanta(StrtoDate(responsed.data.fhEntregaPlanta))
          setFechaETA(setDate(responsed.data.fechaETA))
          setFletes(responsed.data.fletes)
          setFRevalidacionGuia(responsed.data.fRevalidacionGuia)
          setHAWB(responsed.data.hawb)
          setIncoterm(responsed.data.incoterm)
          setLineaTransportistaInternacional(
            responsed.data.lineaTransportistaInternacional
          )
          setMAWB(responsed.data.mawb)
          setMontoUSD(responsed.data.montoUSA)
          setNoGuia(responsed.data.noGuia)
          setObservaciones(responsed.data.observaciones)
          setOrigen(responsed.data.origen)
          setOtros(responsed.data.otros)
          setTransportista(responsed.data.paqueteriaTransportista)
          setPesoNeto(responsed.data.pesoNeto)
          setPreferenciaArancelaria(responsed.data.preferenciaArancelaria)
          setSeguros(responsed.data.seguros)
          setIDTipoEmbarque(responsed.data.tipoEmbarque)
          setTotalPagar(responsed.data.totalPagar)
          setFNotificacion(setDate(responsed.data.fNotificacion))
          setLineaNaviera(responsed.data.lineaNaviera)
          setFraccionArancelaria(responsed.data.fraccionArancelaria)
          setCostoDiarioConexion(responsed.data.costoDiarioConexion)
          setTotalPagarConexion(responsed.data.totalPagarConexion)
        })
        .catch((e: Error) => {
          /*   setHeader('Error')
          setMsg('Ocurrio un error: ' + e + ' en el detail')
          setShowMsg(true) */
          return
        })
    }
  }, [mCFacturas, IDTrafico, dispatch])

  useEffect(() => {
    if (Proceso) {
      if (Depto === 'Contabilidad') {
        setTerminaProceso(0)
      } else if (Depto === 'Corresponsalias' && Proceso <= 1) {
        setChangePermission(true)
      } else if (Depto === 'Corresponsalias' && Proceso > 1) {
        setChangePermission(false)
      } else if (Perfil === 'Corresponsales' && Proceso <= 1){
        setChangePermission(true)
      }else setChangePermission(false)
    }
    if (IDTrafico === 0) {
      setChangePermission(true)
    }
  }, [Proceso, IDTrafico, Depto, []])

  /*useEffect(() => {
    console.log('cargado todo 1')
    LoadEverything()
  }, [])*/

  useEffect(() => {
    LoadEverything()
  }, [IDTrafico])

  useEffect(() => {
    if (
      IDCorresponsal !== 0 &&
      Aduana !== '' && Aduana !== '0' && Aduana !== ' ' && Aduana !== undefined && Aduana !== null &&
      Patente !== 0 &&
      Pedimento !== 0 &&
      FechaPago !== '' &&
      FechaDesaduanamiento !== null &&
      NoCuenta !== null &&
      TieneArchivoZIPGEMCO &&
      TieneArchivoZIPCorresponsal && 
      DescripcionMercancia !== '' && DescripcionMercancia !== undefined && DescripcionMercancia !== null && DescripcionMercancia !== " " &&
      CentroCostos !== ''
    ) {
      let tmp = Pedimento?.toString()
      if (tmp?.length === 7) {
        setStatusTrafico(2)
        setTerminaProceso(0)
        setStatusColor('readyColor')
        setReady4NextProcess(true)
      } else {
        setStatusColor('pendingColor')
        setReady4NextProcess(false)
      }
    } else {
      setStatusColor('pendingColor')
      setReady4NextProcess(false)
    }
  }, [
    IDCorresponsal,
    Aduana,
    Patente,
    Pedimento,
    FechaPago,
    FechaDesaduanamiento,
    NoCuenta,
    TieneArchivoZIPGEMCO,
    TieneArchivoZIPCorresponsal,
    DescripcionMercancia,
    CentroCostos
  ])

  useEffect(() => {
    if (mCorresponsales) {
      const result = mCorresponsales.filter(
        (item) => item.id === IDCorresponsal
      )
      if (result.length) {
        setAduana(result[0].aduana)
        setPatente(result[0].patente)
      }
    }
  }, [IDCorresponsal, mCorresponsales])

  const StrtoDate = (str: string): Date | null => {
    if (str) {
      const original: any = new Date(str)
      return original
    } else return null
  }

  /*useEffect(() => {
    if (FechaPago) {
      getTipoCambio(FechaPago)
    }
  }, [FechaPago])

  const getTipoCambio = (Fecha: string) => {
    DashboardService.getTipoCambio(Fecha)
      .then((response) => {
        setTipoCambio(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: no pude traer tipo de cambio para esa fecha')
        setShowMsg(true) 
        return
      })
  }*/

  useEffect(() => {
    if (Clientes) {
      let tmpArray: ItemList[] = []
      Clientes.forEach((element) => {
        if (element.sClave === IDCliente)
          tmpArray.push({ id: IDCliente, item: element.sRazonSocial })
      })
      setNombreCliente(tmpArray[0].item)
      CCatAduanasService.getAll(IDCliente)
        .then((response) => {
          setDSCatAduanas(response.data)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
      CCatDestinosService.getAll(IDCliente)
        .then((response) => {
          setDSCatDestinos(response.data)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
      CCatMediosEmbarqueService.getAll()
        .then((response) => {
          setDSCatMediosEmbarque(response.data)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
      CCatTiposEmbarqueService.getAll()
        .then((response) => {
          setDSCatTiposEmbarque(response.data)
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  }, [IDCliente])

  /*useEffect(() => {
    let cont =
      mCContenedores.length > 0
        ? mCContenedores.length - 1
        : mCContenedores.length
  }, [mCContenedores])*/

  useEffect(() => {
    let ant =
      mCAnticipos.length > 0 ? mCAnticipos.length - 1 : mCAnticipos.length
    setLblAnticipos('(' + ant + ') anticipos')
  }, [mCAnticipos])

  useEffect(() => {
    let fac3 = mCFTerceros.filter(function (row) {
      return row.factura !== '-Seleccione-' && row.idTrafico === IDTrafico
    }).length
    setLblFacturasTerceros('(' + fac3 + ') Facturas  pagos a terceros')
  }, [mCFTerceros])

  useEffect(() => {
    let fac = mCFacturas.length > 0 ? mCFacturas.length - 1 : mCFacturas.length
    setLblFacturas('(' + fac + ') Facturas ')
    let total = 0
    mCFacturas.forEach((element) => {
      total += element.idTrafico === IDTrafico ? element.valorFacturaDls : 0
    })
    setValorFacturasDls(total)
  }, [mCFacturas, IDTrafico, IDCliente])

  useEffect(() => {
    let ant = mCPedimentosConsolidados.filter(x => x.id > 0)
    let label = `Pedimentos Consolidados (${ant.length})`
    setLblPedimentos(label)
  }, [mCPedimentosConsolidados])

  function calculateDaysBetweenDates(date1: Date, date2: Date) {
    var oneDay = 24 * 60 * 60 * 1000
    var date1InMillis = date1.getTime()
    var date2InMillis = date2.getTime()
    var days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay)
    return days
  }

  useEffect(() => {
    if (FechaDesaduanamiento && FAlmacenajeInicioGastos && CostoDiario) {
      const dias = calculateDaysBetweenDates(
        new Date(FAlmacenajeInicioGastos),
        FechaDesaduanamiento
      )
      setTotalPagar((dias - 1) * CostoDiario)
    }
  }, [FechaDesaduanamiento, FAlmacenajeInicioGastos, CostoDiario])

  function showModalBox(i: number) {
    setLoadComponent(i)
    if (i === 1) setModalSize('lg')
    else setModalSize('sm')
    setShowModal(true)
  }

  function setDate(fecha: string | undefined): string {
    if (!fecha) return ''
    else {
      var dd = fecha?.substring(3, 5)
      var mm = fecha?.substring(0, 2)
      var yyyy = fecha?.substring(6, 10)
      return yyyy + '-' + mm + '-' + dd
    }
  }

  function getAduana(id: number): string {
    Aduanas.forEach((element) => {
      return element.id === id ? element.descripcion : ''
    })
    return ''
  }

  let handleColor = (time: Date) => {
    return time.getHours() > 12 ? 'text-success' : 'text-error'
  }

  const concatFacturasPedidos = (facturas: ICorresponsalFacturas[]) => {
    let pedidos = "";
    facturas.forEach((factura, index) => {
      if(factura.pedido !== ''){
        if(index <= facturas.length){
          pedidos += `${factura.pedido},`;
        }else{
          pedidos += factura.pedido;
        }
      }
    });
    return pedidos;
  }

  const getCentroCostos = (idCliente: number, clientes: IClientes[]) => {
    //if(Perfil === "Corresponsales"){
      if(idCliente > 0){
        CentrosCostosService.get(idCliente)
          .then(response => {
            setCentroCostos(response.data.centroCostos)
            setClaveClienteFacturacion(response.data.clienteFacturacion);
            
            if(response.data.clienteFacturacion !== 0 && response.data.clienteFacturacion !== idCliente){
              let cliente = clientes!.filter(x => x.sClave === response.data.clienteFacturacion)[0];
              let nombreCliente = cliente.sRazonSocial.split('|')[0].trim()
              setNombreClienteFacturacion(nombreCliente);
              setRfcClienteFacturacion(cliente.sRFC);
            }
            return
          })
          .catch((e: Error) => {
            setCentroCostos(''); setClaveClienteFacturacion(0); setNombreClienteFacturacion(''); setRfcClienteFacturacion('');
            setHeader('Error')
            setMsg('Centro de costos no asignado. Solicitar a servicios administrativos la asignacion del centro de costos del cliente.')
            setShowMsg(true)
            return
        });
      }
    //}
  }

  const GetClientesZip = () => {
    FileManagerServices.GetClientesZip(IDTrafico)
    .then(response => {
      downloadFile(response.data, `expediente_${FolioTrafico}.zip`);
    }).catch(error => {
      setHeader('Error')
      setMsg('No se han encontrado uno o más de los archivos obligatorios para cerrar la cuenta.')
      setShowMsg(true)
      return
    })
  }

  const downloadFile = (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }

  const showHistorialRectificaciones = () => {
    setLoadComponent(14);
    setShowModal(true);
  }
  return (
    <div style={{height:'100%', overflowY:'scroll'}}>
      <Card className="labelSize13px" style={{borderStyle:'none', height:'100%'}}>
        <Card.Body className={StatusColor}>
          <Card>
            <Card.Header>
              <Row>
                <Col xs={2}>DATOS GEMCO</Col>
                <Col xs={9}></Col>
                <Col xs={1} style={{ textAlign: 'right' }}>
                  <div
                    style={{ /*paddingLeft: '35px',*/ cursor: 'pointer' }}
                    onClick={() => {
                      props.onClose(true)
                    }}
                  >
                    <IconContext.Provider
                      value={{ color: 'red', size: '25px' }}
                    >
                      <BsFillXCircleFill />
                    </IconContext.Provider>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className={StatusColor}>
              <Card.Text>
                <Container fluid="md">
                  <Row style={{padding: "5px 0"}}>
                    <Col sm={12} md={12} lg={4} xl={2} xxl={2}>
                      <Row>
                        <Col sm={3} md={2} lg={4}>
                          <Form.Label
                            style={{ display: 'flex'}}
                          >
                            <IconContext.Provider
                              value={{ color: 'blue', size: '15px' }}
                            >
                              <FaRegClone />
                            </IconContext.Provider>
                            &nbsp;Trafico
                          </Form.Label>
                        </Col>
                        <Col sm={3} md={3} lg={8}>
                          <InputGroup size="sm">
                            <input
                              type="hidden"
                              value={IDTrafico}
                              id="IDTrafico"
                              disabled={true}
                            />
                            <FormControl
                              aria-label=""
                              id="FolioTrafico"
                              value={FolioTrafico}
                              disabled={true}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} md={12} lg={8}  xl={6} xxl={6}>
                      <Row>
                        <Col sm={3} md={2} lg={2}>
                          <Form.Label>Fecha registro</Form.Label>
                        </Col>
                        <Col sm={3} md={3} lg={3}>
                          <Form.Control
                            type="date"
                            id="FechaRegistro"
                            size="sm"
                            disabled={true}
                            value={FechaRegistro}
                          />
                        </Col>
                        <Col sm={2} md={1} lg={1}>
                          <Form.Label>Usuario</Form.Label>
                        </Col>
                        <Col sm={4} md={3} lg={3}>
                          <Form.Control
                            type="text"
                            id="UsuarioRegistro"
                            value={Usuario}
                            size="sm"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{padding: "5px 0"}}>
                    <Col sm={12} md={6} lg={4}>
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Label>
                            Cliente
                            <Form.Control
                              as="select"
                              id="CmbCliente"
                              disabled={(Depto !== 'Corresponsalias' && Depto !== 'Sistemas')}
                              onChange={(e) => {
                                setIDCliente(parseInt(e.target.value))
                                getCentroCostos(+e.target.value, Clientes!);
                              }}
                              value={IDCliente}
                              className="form-select form-select-sm"
                            >
                              <option value="0">-SELECCIONE-</option>
                              {Clientes
                                ? Clientes.map((c) => {
                                    return (
                                      <option key={c.sClave} value={c.sClave}>
                                        {c.sRazonSocial}
                                      </option>
                                    )
                                  })
                                : null}
                            </Form.Control>
                          </Form.Label>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} md={6} lg={4} style={{display: ClaveClienteFacturacion === IDCliente  || ClaveClienteFacturacion === 0 ? 'none' : ''}}>
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Label>
                            Facturar A
                            <Form.Control
                              as="select"
                              id="CmbCliente"
                              disabled={true}                              
                              value={ClaveClienteFacturacion}
                              className="form-select form-select-sm"
                            >
                              <option value="0">-SELECCIONE-</option>
                              {Clientes
                                ? Clientes.map((c) => {
                                    return (
                                      <option key={c.sClave} value={c.sClave}>
                                        {c.sRazonSocial}
                                      </option>
                                    )
                                  })
                                : null}
                            </Form.Control>
                          </Form.Label>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} md={12} lg={4}>
                      <Row>
                        <Col sm={6} md={6} lg={4}>
                          <Form.Label> Centro de Costos
                            <CurrencyFormat
                              value={CentroCostos}
                              disabled={true}
                              displayType={'input'}
                              format={'#-####'}
                              style={{
                                fontSize: '18px',
                                backgroundColor: '#F1EEF9',
                                border: '2px solid #5923F6',
                                color: '#5923F6',
                                width: '100%',
                                textAlign: 'right',
                                borderRadius: '10px',
                                paddingRight: '10px',
                                paddingLeft: '10px'
                              }}
                            />
                          </Form.Label>
                        </Col>
                        <Col sm={6} md={6} lg={8}>
                        </Col>
                      </Row>
                    </Col>
                  </Row>                  
                  <Row style={{padding: "5px 0"}}>
                    <Col sm={12} md={12} lg={6}>
                      <Row className='justify-content-between'>
                        <Col sm={3} md={3} lg={2}>
                          <Form.Label>T. Operacion</Form.Label>
                        </Col>
                        <Col sm={3} md={3} lg={3}>
                          <Form.Control
                            as="select"
                            onChange={(e) =>
                              setTipoOperacion(parseInt(e.target.value))
                            }
                            value={TipoOperacion}
                            disabled={(Depto !== 'Corresponsalias' && Depto !== 'Sistemas')}
                            className="form-select form-select-sm"
                          >
                            <option value="0">--Seleccione--</option>
                            <option value="1">Importacion</option>
                            <option value="2">Exportacion</option>
                          </Form.Control>
                        </Col>
                        <Col sm={3} md={3} lg={3}>
                          <Form.Control
                            as="select"
                            id="CmbOpEntrada"
                            onChange={(e) => setOpEntrada(parseInt(e.target.value))}
                            value={OpEntrada}
                            disabled={(Depto !== 'Corresponsalias' && Depto !== 'Sistemas')}
                            className="form-select form-select-sm"
                          >
                            <option value="0">- Operacion de Entrada -</option>
                            <option value="1">Aereo</option>
                            <option value="2">Ferocarril</option>
                            <option value="3">Maritimo</option>
                            <option value="4">Terrestre</option>
                            <option value="5">Regularizacion</option>
                            <option value="6">Cable</option>
                          </Form.Control>
                        </Col>
                        <Col sm={3} md={3} lg={3}>
                          <Form.Control
                            as="select"
                            id="CmbOpSalida"
                            onChange={(e) => setOpSalida(parseInt(e.target.value))}
                            value={OpSalida}
                            disabled={(Depto !== 'Corresponsalias' && Depto !== 'Sistemas')}
                            className="form-select form-select-sm"
                          >
                            <option value="0">- Operacion de Salida -</option>
                            <option value="1">Aereo</option>
                            <option value="2">Ferocarril</option>
                            <option value="3">Maritimo</option>
                            <option value="4">Terrestre</option>
                            <option value="5">Regularizacion</option>
                            <option value="6">Cable</option>
                          </Form.Control>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                      <Row>
                        <Col sm={4} md={4} lg={4}>
                          <Form.Label>
                            <IconContext.Provider
                              value={{ color: 'blue', size: '15px' }}
                            >
                              <FcBusinessman />
                            </IconContext.Provider>
                            &nbsp;Nombre Corresponsal
                          </Form.Label>
                        </Col>
                        <Col sm={8} md={8} lg={8}>
                          <Form.Control
                            as="select"
                            id="CmbIDCorresponsal"
                            onChange={(e) => {
                              setIDCorresponsal(parseInt(e.target.value))
                            }}
                            className="form-select form-select-sm"
                            value={IDCorresponsal}
                            disabled={(Depto !== 'Corresponsalias' && Depto !== 'Sistemas')}
                          >
                            <option value="0">-Seleccione-</option>
                            {mCorresponsales
                              ? mCorresponsales.map((item, index) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.nombre} | {item.patente} | {item.aduana}
                                    </option>
                                  )
                                })
                              : ''}
                          </Form.Control>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{padding: "5px 0"}}>
                    <Col sm={11} md={11} lg={11} 
                        style={{ visibility: Proceso < 2 ? 'visible' : 'hidden', textAlign: 'end'}}
                      >
                      </Col>
                      <Col sm={1} md={1} lg={1} 
                        style={{ visibility: Proceso < 2 ? 'visible' : 'hidden', textAlign: 'end'}}
                      >
                        {ChangePermission ? (
                            <Button
                            id="BtnSave1"
                            variant="primary"
                            size="sm"
                            style={{ paddingLeft: '20px', paddingRight: '20px' }}
                            onClick={() => saveForm()}
                            >
                              Guardar
                            </Button>
                        ) : (
                          ''
                        )}
                      </Col>
                  </Row>
                </Container>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <Tabs
            defaultActiveKey= {Perfil === 'Corresponsales'? "InformacionCorresponsal" : "InformacionGeneral"}
            id="uncontrolled-tab-example"
            className="mb-3"
            style={{visibility: IDTrafico !== 0 ? 'visible' : 'hidden'}}
          >
            <Tab
              eventKey="InformacionGeneral"
              title="Informacion general"
              id="TabInformacionGeneral"
              tabClassName={Perfil === 'Corresponsales' ? 'd-none' : ''}
            >
              <Card
                style={{ visibility: IDTrafico !== 0 ? 'visible' : 'hidden' }}
              >
                <Card.Body className={StatusColor}>
                  <Card.Text>
                    <Container fluid="md">
                      <Row style={{padding: "5px 0"}}>
                        <Col sm={12} md={12} lg={6}>
                          <Row style={{alignItems:'baseline'}}>
                            <Col xs={6}>
                              <SelectGuias
                                IDTrafico={IDTrafico}
                                Editable={Depto === 'Corresponsalias'}
                              />
                            </Col>
                            <Col xs={3}>
                              <Form.Label>
                                Bultos
                                <CurrencyFormat
                                  value={Bultos}
                                  displayType='input'
                                  readOnly= {Depto !== 'Corresponsalias' ? true : false}
                                  thousandSeparator={true}
                                  onValueChange={(values: any) => {
                                    const { value } = values
                                    setBultos(value)
                                  }}
                                  style={{
                                    fontSize: '15px',
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #000000',
                                    width: '100%',
                                    borderRadius: '3px',
                                    textAlign: 'end',
                                    padding: '0 5px'
                                  }}
                                />
                              </Form.Label>
                            </Col>
                            <Col xs={3}>
                              <Form.Label>
                                Kilos
                                <CurrencyFormat
                                  value={Kilos}
                                  displayType='input'
                                  readOnly= {Depto !== 'Corresponsalias' ? true : false}
                                  thousandSeparator={true}
                                  onValueChange={(values: any) => {
                                    const { value } = values
                                    setKilos(value)
                                  }}
                                  style={{
                                      fontSize: '15px',
                                      backgroundColor: '#FFFFFF',
                                      border: '1px solid #000000',
                                      width: '100%',
                                      borderRadius: '3px',
                                      textAlign: 'end',
                                      padding: '0 5px'
                                    }}
                                />
                              </Form.Label>
                            </Col>
                          </Row> 
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                          <Row>
                            <Col xs={6}>
                              <Form.Label style={{width:'100%'}}>
                                Tipo de Mercancia
                                <Form.Control
                                  as="select"
                                  className="form-select form-select-sm"
                                  value={IDTipoMercancia!}
                                  disabled={
                                    Depto !== 'Corresponsalias' ? true : false
                                  }
                                  onChange={(e) => {
                                    setIDTipoMercancia(parseInt(e.target.value))
                                  }}
                                >
                                  <option value={undefined}>-SELECCIONE-</option>
                                  {TiposMercancia
                                    ? TiposMercancia.map((t) => {
                                        return (
                                          <option key={t.id} value={t.id}>
                                            {t.descripcion}
                                          </option>
                                        )
                                      })
                                    : null}
                                </Form.Control>
                              </Form.Label>
                            </Col>
                            <Col xs={6}>
                              <Form.Label>
                                Estatus
                                <Form.Control
                                  as="select"
                                  className="form-select form-select-sm"
                                  value={Estatus}
                                  disabled={
                                    Depto !== 'Corresponsalias' ? true : false
                                  }
                                  onChange={(e) => {
                                    setEstatus(parseInt(e.target.value))
                                  }}
                                >
                                  <option value="0">-SELECCIONE-</option>
                                  {DataCatEstatus
                                    ? DataCatEstatus.map((c) => {
                                        return (
                                          <option key={c.id} value={c.id}>
                                            {c.estatus}
                                          </option>
                                        )
                                      })
                                    : null}
                                </Form.Control>
                              </Form.Label>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{padding: "5px 0"}}>                        
                        <Col sm={12} md={12} lg={6}>
                          <fieldset className='border p-2'>
                            <legend className='w-auto' style={{float:'none', fontSize:'1rem'}}>{EncabezadoPedimento1}</legend>
                            <Row className='justify-content-between' style={{alignItems:'center'}}>
                                <Col sm={5} md={5} lg={5}>
                                  <Button variant="success" 
                                    onClick={()=>{setLoadComponent(13);setShowModal(true);}}
                                    style={{width:'100%', padding:'6px'}}
                                  >
                                    {LblPedimentos}
                                  </Button>
                                </Col>
                                <Col sm={3} md={3} lg={3}>
                                <Form.Label>
                                  Trafico
                                  <Form.Control
                                    type="text"
                                    id="Trafico"
                                    size="sm"
                                    value={Trafico}
                                    disabled={
                                      Depto !== 'Corresponsalias' ? true : false
                                    }
                                    onChange={(e) => {
                                      setTrafico(e.target.value)
                                    }}
                                  />
                                </Form.Label>
                              </Col>
                              <Col sm={2} md={3} lg={2}>
                                <Form.Label>
                                  Aduana
                                  <Form.Control
                                    type="text"
                                    id="Aduana"
                                    size="sm"
                                    disabled={true}
                                    value={Aduana}
                                  />
                                </Form.Label>
                              </Col>
                              <Col sm={2} md={3} lg={2}>
                                <Form.Label>
                                  Patente
                                  <Form.Control
                                    type="text"
                                    id="Patente"
                                    size="sm"
                                    disabled={true}
                                    value={Patente}
                                  />
                                </Form.Label>
                              </Col>
                            </Row>
                            <Row className='justify-content-between' style={{alignItems:'center'}}>
                            <Col sm={2} md={2} lg={3}>
                                <Form.Label>
                                  <span>
                                    Pedimento
                                  </span>
                                  <CurrencyFormat
                                    value={Pedimento}
                                    displayType='input'
                                    readOnly= {Depto !== 'Corresponsalias' ? true : false}
                                    format={'### ####'}
                                    mask="_"
                                    style={{
                                      fontSize: '18px',
                                      backgroundColor: '#FEFDF5',
                                      border: '2px solid #837F5D',
                                      width: '100%',
                                      textAlign: 'right',
                                      borderRadius: '10px'
                                    }}
                                  />
                                </Form.Label>
                              </Col>
                              <Col sm={3} md={3} lg={2}>
                                <Form.Label>
                                  Clave
                                  <Form.Control
                                    as="select"
                                    id="CmbClavePedimento"
                                    onChange={(e) => {
                                      setClavePedimento(e.target.value)
                                    }}
                                    className="form-select form-select-sm"
                                    value={ClavePedimento}
                                    disabled={(Depto !== 'Corresponsalias')}
                                  >
                                    <option value="-Seleccione-">-Seleccione-</option>
                                    {ClavesPedimentos
                                      ? ClavesPedimentos.map((item, index) => {
                                          return (
                                            <option key={item.id} value={item.clave}>
                                              {item.clave}
                                            </option>
                                          )
                                        })
                                      : ''}
                                  </Form.Control> 
                                </Form.Label>
                              </Col>
                              <Col sm={3} md={3}>
                                <Form.Label>
                                  Fec. Pago
                                  <Form.Control
                                  type="date"
                                  id="FechaPago"
                                  size="sm"
                                  value={FechaPago}
                                  disabled={
                                    Depto !== 'Corresponsalias' ? true : false
                                  }
                                  onChange={(e) => setFechaPago(e.target.value)}
                                  />
                                </Form.Label>
                              </Col>
                              <Col xs={3}>
                                <Form.Label style={{width:'100%', visibility: AduanaH === '' ? 'hidden':'visible'}}>
                                  Cargo A
                                  <Form.Control
                                    as="select"
                                    className="form-select form-select-sm"
                                    value={CargoPara}
                                    disabled={
                                      Depto !== 'Corresponsalias' ? true : false
                                    }
                                    onChange={(e) => {
                                      setCargoPara(parseInt(e.target.value))
                                    }}
                                    
                                  >
                                    <option value="0">-SELECCIONE-</option>
                                    <option value="1">CLIENTE</option>
                                    <option value="2">CORRESPONSAL</option>                                  
                                  </Form.Control>
                                </Form.Label>
                              </Col>
                            </Row>
                            <Row className='justify-content-between' style={{alignItems:'center'}}>
                              <Col sm={3}>
                                <Form.Label style={{visibility: AduanaH === '' ? 'hidden':'visible'}}>Causa Rectificacion:</Form.Label>
                              </Col>
                              <Col sm={9}>
                                <Form.Control
                                  as="textarea"
                                  id="Observacion"
                                  size="sm"
                                  value={CausaRectificacion}
                                  disabled={Depto !== 'Corresponsalias'}
                                  onChange={(e) => setCausaRectificacion(e.target.value)}
                                  style={{visibility: AduanaH === '' ? 'hidden':'visible'}}
                                />
                              </Col>
                            </Row>
                          </fieldset>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                          <fieldset className='border p-2' style={{height:'100%'}}>
                            <legend className='w-auto' style={{float:'none', fontSize:'1rem'}}>Última Rectificación</legend>
                          <Row className='justify-content-between' style={{alignItems:'center', paddingTop:'15px'}}>
                            <Col sm={5} md={4} lg={4}>
                              <Button variant="success" style={{width:'100%', padding:'6px'}} onClick={() => {showHistorialRectificaciones()}}>{LblRectificaciones}</Button>
                            </Col>                              
                            <Col sm={1} md={1} lg={2}>
                                <Form.Label>Aduana</Form.Label>
                            </Col>
                            <Col sm={2} md={3} lg={2}>
                              <CurrencyFormat
                                value={AduanaH}
                                disabled={true}
                                displayType={'input'}
                                style={{
                                  fontSize: '18px',
                                  backgroundColor: '#F1EEF9',
                                  border: '2px solid #5923F6',
                                  color: '#5923F6',
                                  width: '100%',
                                  textAlign: 'right',
                                  borderRadius: '10px',
                                  paddingRight: '10px',
                                  paddingLeft: '10px'
                                }}
                              />
                            </Col>
                            <Col sm={1} md={1} lg={2}>
                              <Form.Label>Patente</Form.Label>
                            </Col>
                            <Col sm={2} md={3} lg={2}>
                                  <CurrencyFormat
                                    value={PatenteH}
                                    disabled={true}
                                    displayType={'input'}
                                    style={{
                                      fontSize: '18px',
                                      backgroundColor: '#F1EEF9',
                                      border: '2px solid #5923F6',
                                      color: '#5923F6',
                                      width: '100%',
                                      textAlign: 'right',
                                      borderRadius: '10px',
                                      paddingRight: '10px',
                                      paddingLeft: '10px'
                                    }}
                                  />
                            </Col>
                          </Row>
                          <Row className='justify-content-between' style={{alignItems:'center', paddingTop:'15px'}}>
                            <Col sm={2} md={2} lg={2}>
                                <Form.Label>
                                  Pedimento
                                </Form.Label>
                            </Col>
                            <Col sm={2} md={2} lg={3}>
                                  <CurrencyFormat
                                    value={PedimentoH}
                                    disabled={true}
                                    displayType={'input'}
                                    format={'### ####'}
                                    style={{
                                      fontSize: '18px',
                                      backgroundColor: '#F1EEF9',
                                      border: '2px solid #5923F6',
                                      color: '#5923F6',
                                      width: '100%',
                                      textAlign: 'right',
                                      borderRadius: '10px',
                                      paddingRight: '10px',
                                      paddingLeft: '10px'
                                    }}
                                  />
                            </Col>
                            <Col sm={1} md={1}>
                                <Form.Label>Clave</Form.Label>
                            </Col>
                            <Col sm={3} md={3}lg={2}>
                                  <Form.Control
                                    type="text"
                                    id="ClavePedimentoH"
                                    size="sm"
                                    value={ClavePedimentoH}
                                    disabled={true}
                                    style={{
                                      fontSize: '18px',
                                      backgroundColor: '#F1EEF9',
                                      border: '2px solid #5923F6',
                                      color: '#5923F6',
                                      width: '100%',
                                      textAlign: 'right',
                                      borderRadius: '10px'
                                    }}
                                  />
                            </Col>
                            <Col sm={1} md={1}>
                                <Form.Label>Fec. Pago</Form.Label>
                            </Col>
                            <Col sm={3} md={3}>
                                  <CurrencyFormat
                                    value={FechaPagoH}
                                    disabled={true}
                                    format={'##/##/####'}
                                    displayType={'input'}
                                    style={{
                                      fontSize: '18px',
                                      backgroundColor: '#F1EEF9',
                                      border: '2px solid #5923F6',
                                      color: '#5923F6',
                                      width: '100%',
                                      textAlign: 'right',
                                      borderRadius: '10px',
                                      paddingRight: '10px',
                                      paddingLeft: '10px'
                                    }}
                                  />
                            </Col>
                          </Row>
                          </fieldset>
                        </Col>
                      </Row>
                      <Row style={{padding: "5px 0"}}>
                        <Col sm={12} md={12} lg={6}>
                          <Row style={{alignItems: 'center'}}>
                            <Col sm={2} md={2} lg={2}>
                              <Form.Label className="labelSize13px">
                                Tipo cambio
                              </Form.Label>
                            </Col>
                            <Col sm={4} md={4} lg={4}>
                              <CurrencyFormat
                                value={TipoCambio}
                                prefix={'$'}
                                displayType='input'
                                readOnly= {Depto !== 'Corresponsalias' ? true : false}
                                thousandSeparator={true}
                                onValueChange={(values: any) => {
                                  const { value } = values
                                  setTipoCambio(value)
                                }}
                                style={{
                                    fontSize: '18px',
                                    backgroundColor: '#F5FFED',
                                    border: '2px solid #25D05B',
                                    width: '100%',
                                    textAlign: 'right',
                                    borderRadius: '10px',
                                    paddingRight: '10px',
                                    paddingLeft: '10px'
                                  }}
                              />
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                              <Form.Label className="labelSize13px">
                                Val Aduana MN
                              </Form.Label>
                            </Col>
                            <Col sm={4} md={4} lg={4}>
                              <CurrencyFormat
                                value={ValorAduanaMN}
                                prefix={'$'}
                                displayType='input'
                                readOnly= {Depto !== 'Corresponsalias' ? true : false}
                                thousandSeparator={true}
                                onValueChange={(values: any) => {
                                  const { value } = values
                                  setValorAduanaMN(value)
                                }}
                                style={{
                                  fontSize: '18px',
                                  backgroundColor: '#F5FFED',
                                  border: '2px solid #25D05B',
                                  width: '100%',
                                  textAlign: 'right',
                                  borderRadius: '10px',
                                  paddingRight: '10px',
                                  paddingLeft: '10px'
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                          <Row style={{alignItems: 'center'}}>
                            <Col sm={2} md={2} lg={2}>
                              <Form.Label className="labelSize13px">
                                Total pagado
                              </Form.Label>
                            </Col>
                            <Col sm={4} md={4} lg={4}>
                              <CurrencyFormat
                                value={TotalPagado}
                                prefix={'$'}
                                displayType='input'
                                readOnly= {Depto !== 'Corresponsalias' ? true : false}
                                thousandSeparator={true}
                                onValueChange={(values: any) => {
                                  const { value } = values
                                  setTotalPagado(value)
                                }}
                                style={{
                                  fontSize: '18px',
                                  backgroundColor: '#F5FFED',
                                  border: '2px solid #25D05B',
                                  width: '100%',
                                  textAlign: 'right',
                                  borderRadius: '10px',
                                  paddingRight: '10px',
                                  paddingLeft: '10px'
                                }}
                              />
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                              <Form.Label className="labelSize13px">
                                Cant fracciones
                              </Form.Label>
                            </Col>
                            <Col sm={4} md={4} lg={4}>
                                <InputGroup size="sm">
                                  <FormControl
                                    aria-label=""
                                    id="CantidadFracciones"
                                    value={CantidadFracciones}
                                    type="number"
                                    min={0}
                                    pattern="[0-9]*"
                                    disabled={
                                      Depto !== 'Corresponsalias' ? true : false
                                    }
                                    onChange={(e) =>
                                      setCantidadFracciones((v) =>
                                        e.target.validity.valid
                                          ? parseInt(e.target.value)
                                          : v
                                      )
                                    }
                                  />
                                </InputGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{padding: "5px 0"}}>
                        <Col sm={12} md={12} lg={6}>
                          <Row>
                            <Col sm={2}>
                              <Form.Label>Buque</Form.Label>
                            </Col>
                            <Col sm={4}>
                              <Form.Control
                                type="text"
                                id="Buque"
                                size="sm"
                                value={Buque}
                                disabled={
                                  Depto !== 'Corresponsalias' ? true : false
                                }
                                onChange={(e) => setBuque(e.target.value)}
                              />
                            </Col>
                            <Col sm={6}>
                              <SelectContainer
                                IDTrafico={IDTrafico}
                                Editable={Depto === 'Corresponsalias'}
                                setFechaDesaduanamiento={setFechaDesaduanamiento}
                                FechaDesaduanamiento={FechaDesaduanamiento!}
                                setSemaforoFiscal={setSemaforoFiscal}
                                SemaforoFiscal={SemafoFiscal!}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col sm={2}>
                              <Form.Label
                                id="LinkFacturas123"
                                onClick={() => {
                                  showModalBox(2)
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <IconContext.Provider
                                  value={{ color: 'green', size: '15px' }}
                                >
                                  <HiCurrencyDollar />
                                </IconContext.Provider>
                                &nbsp;{LblFacturas}
                              </Form.Label>
                            </Col>
                            <Col xs={4}>
                              <Form.Control
                                as="select"
                                onChange={(e) => {}}
                                className="form-select form-select-sm"
                                disabled={Depto !== 'Corresponsalias'}
                              >
                                {mCFacturas
                                  ? mCFacturas.map((item, index) => {
                                      return item.idTrafico === IDTrafico ? (
                                        <option key={item.id} value={item.id}>
                                          {item.factura}
                                        </option>
                                      ) : (
                                        <option value="0">-Seleccione-</option>
                                      )
                                    })
                                  : ''}
                              </Form.Control>
                            </Col>
                            <Col xs={2}>
                              <Form.Label className="labelSize13px">
                                Val Fact Dls
                              </Form.Label>
                            </Col>
                            <Col xs={4}>
                              <CurrencyFormat
                                prefix={'$'}
                                thousandSeparator={true}
                                value={ValorFacturasDls}
                                displayType={'input'}
                                onValueChange={(values: any) => {
                                  const { value } = values
                                  setValorFacturasDls(value)
                                }}
                                readOnly={true}
                                style={{
                                  fontSize: '18px',
                                  backgroundColor: '#F5FFED',
                                  border: '2px solid #25D05B',
                                  width: '100%',
                                  textAlign: 'right',
                                  borderRadius: '10px',
                                  paddingRight: '10px',
                                  paddingLeft: '10px'
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        
                        
                        
                      </Row>
                      <Row style={{padding: "5px 0"}}>
                        <Col sm={12} md={12} lg={6}>
                          <Row style={{alignItems: 'center'}}>
                            <Col sm={3}>
                              <Form.Label>Descripcion de la mercancia:</Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                as="textarea"
                                id="DescripcionMercancia"
                                size="sm"
                                value={DescripcionMercancia}
                                disabled={Depto !== 'Corresponsalias'}
                                onChange={(e) =>
                                  setDescripcionMercancia(e.target.value)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={12} md={12} lg={6}>
                          <Row style={{alignItems: 'center'}}>
                            <Col sm={3}>
                              <Form.Label>Observaciones:</Form.Label>
                            </Col>
                            <Col sm={9}>
                              <Form.Control
                                as="textarea"
                                id="Observacion"
                                size="sm"
                                value={Observaciones}
                                disabled={Depto !== 'Corresponsalias'}
                                onChange={(e) => setObservaciones(e.target.value)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{padding: "5px 0"}}>
                        <Col sm={12} md={12} lg={4}>
                          <Row className="align-items-center">
                            <Col sm={5}>
                              <Form.Label className="labelSize13px">
                                Fecha de desaduanamiento
                              </Form.Label>
                            </Col>
                            <Col sm={7}>
                              <DatePicker
                                open={false}
                                selected={FechaDesaduanamiento}
                                onChange={(date: any) =>
                                  setFechaDesaduanamiento(date)
                                }
                                disabled={Depto !== 'Corresponsalias'}
                                showTimeSelect
                                timeClassName={handleColor}
                                dateFormat="MM/dd/yyyy HH:mm:ss"
                                placeholderText="MM/DD/AAAA HH:mm:ss"
                                isClearable={Depto === 'Corresponsalias'}
                                customInput={
                                  <MaskedInput
                                    type="text"
                                    mask={[/[0-1]/, /[0-9]/, "/", /[0-3]/, /[0-9]/, "/", /[2]/, /[0]/, /[0-9]/, /[0-9]/," ",/[0-2]/, /[0-9]/,":",/[0-5]/, /[0-9]/,":",/[0-5]/, /[0-9]/]}
                                  />
                                }
                              />{' '}
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={12} md={12} lg={4}>
                          <Row className="align-items-center justify-content-between">
                            <Col sm={3}>
                              <Form.Label className="labelSize13px">
                                Semaforo fiscal
                              </Form.Label>
                            </Col>
                            <Col sm={8}>
                              <Form.Control
                                as="select"
                                className="form-select form-select-sm"
                                value={SemafoFiscal}
                                disabled={
                                  Depto !== 'Corresponsalias' ? true : false
                                }
                                onChange={(e) =>
                                  setSemaforoFiscal(parseInt(e.target.value))
                                }
                              >
                                <option value="0">Rojo</option>
                                <option value="1">Verde</option>
                              </Form.Control>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={12} lg={4}>
                          <Row className="align-items-center">
                            <Col sm={4}>
                              <Form.Label className="labelSize13px">
                                Fecha/hora entrega en planta
                              </Form.Label>
                            </Col>
                            <Col sm={8}>
                              <DatePicker
                                selected={FHEntregaPlanta}
                                disabled={Depto !== 'Corresponsalias'}
                                onChange={(date: any) => setFHEntregaPlanta(date)}
                                showTimeSelect
                                timeClassName={handleColor}
                                dateFormat="MM/dd/yyyy HH:mm:ss"
                                placeholderText="No definido"
                                isClearable
                              />
                            </Col>
                          </Row>
                        </Col>
                        
                      </Row>
                      <Row style={{padding: "5px 0"}}>
                        <Col sm={12} md={12} lg={6}>
                          <Row>
                            <Col sm={2}>
                              <Form.Label>ZIP Archivo Pedimento</Form.Label>
                            </Col>
                            <Col sm={10}>
                              <FileManager
                                IDUser={UserId}
                                width={500}
                                height={200}
                                IDProcess={2}
                                IdFile={IDTrafico}
                                FileName={''}
                                canDelete={Depto === 'Corresponsalias'}
                                FileType={['zip']}
                                Leyenda={
                                  'Aquí se podrá descargar el archivo oficial del tráfico cuando sea cargado'
                                }
                                onAppendFM={function (idFile: number): void {
                                  DoesThe2ZipFileExists()
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Tab>
            <Tab
              eventKey="InformacionAdicional"
              title="Informacion adicional"
              tabClassName={mCFacturas.length >= 2 && Perfil !== 'Corresponsales' ? '' : 'd-none'}
            >
              <Card>
                <Card.Body>
                  <Row>
                    <Col xs={1}>Aduana</Col>
                    <Col xs={2}>
                      <Form.Control
                        as="select"
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => {
                          setIDAduana(parseInt(e.target.value))
                        }}
                        value={Aduana}
                        className="form-select form-select-sm"
                      >
                        <option value="0">-SELECCIONE-</option>
                        {DSCatAduanas
                          ? DSCatAduanas.map((c) => {
                              return (
                                <option
                                  key={c.claveAduana}
                                  value={c.claveAduana}
                                >
                                  {c.aduana}
                                </option>
                              )
                            })
                          : null}
                      </Form.Control>
                    </Col>
                    <Col xs={1}>Tipo embarque</Col>
                    <Col xs={2}>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setIDTipoEmbarque(parseInt(e.target.value))
                        }}
                        value={IDTipoEmbarque}
                        disabled={Depto !== 'Corresponsalias'}
                        className="form-select form-select-sm"
                      >
                        <option value="0">-SELECCIONE-</option>
                        {DSCatTiposEmbarque
                          ? DSCatTiposEmbarque.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>
                                  {c.descripcion}
                                </option>
                              )
                            })
                          : null}
                      </Form.Control>
                    </Col>
                    <Col xs={1}>Destino</Col>
                    <Col xs={2}>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          setIDDestino(parseInt(e.target.value))
                        }}
                        value={IDDestino}
                        disabled={Depto !== 'Corresponsalias'}
                        className="form-select form-select-sm"
                      >
                        <option value="0">-SELECCIONE-</option>
                        {DSCatDestinos
                          ? DSCatDestinos.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>
                                  {c.destino}
                                </option>
                              )
                            })
                          : null}
                      </Form.Control>
                    </Col>
                    <Col xs={2}>Clave pedimento</Col>
                    <Col xs={1}>
                      <Form.Control
                        type="text"
                        id="Clave"
                        size="sm"
                        value={ClavePedimento}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setClavePedimento(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 5 }}>
                    <Col xs={2}>
                      <Form.Label
                        onClick={() => {
                          showModalBox(2)
                        }}
                        style={{ cursor: 'pointer', width:'100%'}}
                      >
                        <IconContext.Provider
                          value={{ color: 'green', size: '15px' }}
                        >
                          <HiCurrencyDollar />
                        </IconContext.Provider>
                        &nbsp;{LblFacturas}
                        <Form.Control
                          as="select"
                          onChange={(e) => {}}
                          className="form-select form-select-sm"
                          disabled={Depto !== 'Corresponsalias'}
                        >
                          {mCFacturas
                            ? mCFacturas.map((item, index) => {
                                return item.idTrafico === IDTrafico ? (
                                  <option key={item.id} value={item.id}>
                                    {item.factura}
                                  </option>
                                ) : (
                                  <option value="0">-Seleccione-</option>
                                )
                              })
                            : ''}
                        </Form.Control>
                      </Form.Label>
                    </Col>
                    <Col xs={2}>
                      <Form.Label style={{width:'100%'}}>
                        Descripcion
                        <Form.Control
                          type="text"
                          id="Descripcion"
                          size="sm"
                          value={Descripcion}
                          disabled={Depto !== 'Corresponsalias'}
                          onChange={(e) => setDescripcion(e.target.value)}
                        />
                      </Form.Label>
                    </Col>
                    <Col xs={3}>
                      <Form.Label style={{width:'100%'}}>
                        Linea transportista internacional
                        <Form.Control
                          type="text"
                          id="LineaTransportistaInternacional"
                          size="sm"
                          value={LineaTransportistaInternacional}
                          disabled={Depto !== 'Corresponsalias'}
                          onChange={(e) =>
                            setLineaTransportistaInternacional(e.target.value)
                          }
                        />
                      </Form.Label>
                    </Col>
                    <Col xs={3}>
                      <Form.Label style={{width:'100%'}}>
                        Linea Naviera
                        <Form.Control
                          type="text"
                          id="LineaNaviera"
                          size="sm"
                          value={LineaNaviera}
                          disabled={Depto !== 'Corresponsalias'}
                          onChange={(e) =>
                            setLineaNaviera(e.target.value)
                          }
                        />
                      </Form.Label>
                    </Col>
                    <Col xs={2}>
                      <Form.Label style={{width:'100%'}}>
                        Fecha Notificacion
                        <Form.Control
                          type="date"
                          id="FNotificacion"
                          size="sm"
                          value={FNotificacion}
                          disabled={Depto !== 'Corresponsalias'}
                          onChange={(e) => setFNotificacion(e.target.value)}
                        />
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 5 }}>
                    <Col xs={1}>MAWB</Col>
                    <Col xs={1}>
                      <Form.Control
                        type="text"
                        id="MAWB"
                        size="sm"
                        value={MAWB}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setMAWB(e.target.value)}
                      />
                    </Col>
                    <Col xs={1}>HAWB</Col>
                    <Col xs={1}>
                      <Form.Control
                        type="text"
                        id="HAWB"
                        size="sm"
                        value={HAWB}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setHAWB(e.target.value)}
                      />
                    </Col>
                    <Col>Peso bruto</Col>
                    <Col>
                      <CurrencyFormat
                        value={Kilos}
                        displayType={
                          Depto === 'Corresponsalias' ? 'input' : 'text'
                        }
                        thousandSeparator={true}
                        onValueChange={(values: any) => {
                          const { value } = values
                          setKilos(value)
                        }}
                        style={
                          Depto === 'Corresponsalias'
                            ? {
                                fontSize: '15px',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #000000',
                                width: '100px',
                                borderRadius: '3px'
                              }
                            : {
                                fontSize: '15px',
                                backgroundColor: '#FFFFFF',
                                paddingLeft: '20px',
                                paddingRight: '5px',
                                border: '1px solid #000000',
                                width: '100px',
                                borderRadius: '3px'
                              }
                        }
                      />
                    </Col>
                    <Col>Peso neto</Col>
                    <Col>
                      <CurrencyFormat
                        value={PesoNeto}
                        displayType={
                          Depto === 'Corresponsalias' ? 'input' : 'text'
                        }
                        thousandSeparator={true}
                        onValueChange={(values: any) => {
                          const { value } = values
                          setPesoNeto(value)
                        }}
                        style={
                          Depto === 'Corresponsalias'
                            ? {
                                fontSize: '15px',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #000000',
                                width: '70px',
                                borderRadius: '3px'
                              }
                            : {
                                fontSize: '15px',
                                backgroundColor: '#FFFFFF',
                                paddingLeft: '20px',
                                paddingRight: '5px',
                                border: '1px solid #000000',
                                width: '70px',
                                borderRadius: '3px'
                              }
                        }
                      />
                    </Col>
                    <Col xs={1}>Bultos</Col>
                    <Col xs={1}>
                      <CurrencyFormat
                        value={Bultos}
                        displayType={
                          Depto === 'Corresponsalias' ? 'input' : 'text'
                        }
                        thousandSeparator={true}
                        onValueChange={(values: any) => {
                          const { value } = values
                          setBultos(value)
                        }}
                        style={
                          Depto === 'Corresponsalias'
                            ? {
                                fontSize: '15px',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #000000',
                                width: '70px',
                                borderRadius: '3px'
                              }
                            : {
                                fontSize: '15px',
                                backgroundColor: '#FFFFFF',
                                paddingLeft: '20px',
                                paddingRight: '5px',
                                border: '1px solid #000000',
                                width: '70px',
                                borderRadius: '3px'
                              }
                        }
                      />
                    </Col>
                    <Col xs={1}>Incoterm</Col>
                    <Col xs={1}>
                      <Form.Control
                        type="text"
                        id="Incoterm"
                        size="sm"
                        value={Incoterm}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setIncoterm(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 5 }}>
                    <Col>Fecha ETA</Col>
                    <Col xs={2}>
                      <Form.Control
                        type="date"
                        id="FechaETA"
                        size="sm"
                        value={FechaETA}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setFechaETA(e.target.value)}
                      />
                    </Col>
                    <Col>Monto USD</Col>
                    <Col>
                      <CurrencyFormat
                        value={MontoUSD}
                        thousandSeparator={true}
                        displayType={
                          Depto === 'Corresponsalias' ? 'input' : 'text'
                        }
                        onValueChange={(values: any) => {
                          const { value } = values
                          setMontoUSD(value)
                        }}
                        style={
                          Depto === 'Corresponsalias'
                            ? {
                                fontSize: '15px',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #000000',
                                width: '90px',
                                borderRadius: '3px'
                              }
                            : {
                                fontSize: '15px',
                                backgroundColor: '#FFFFFF',
                                paddingLeft: '20px',
                                paddingRight: '5px',
                                border: '1px solid #000000',
                                width: '90px',
                                borderRadius: '3px'
                              }
                        }
                      />
                    </Col>
                    <Col xs={1}>Origen</Col>
                    <Col xs={6}>
                      <Form.Control
                        type="text"
                        id="Origen"
                        size="sm"
                        value={Origen}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setOrigen(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 5 }}>
                    <Col xs={3}>
                      <Form.Label style={{width:'100%'}}>
                        Fraccion Arancelaria
                        <Form.Control
                          type="text"
                          id="FraccionArancelaria"
                          size="sm"
                          value={FraccionArancelaria}
                          disabled={Depto !== 'Corresponsalias'}
                          onChange={(e) => setFraccionArancelaria(e.target.value)}
                        />
                      </Form.Label>
                    </Col>
                    <Col xs={2}>
                      <Form.Label>
                        Preferencia arancelaria
                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            setPreferenciaArancelaria(parseInt(e.target.value))
                          }}
                          value={PreferenciaArancelaria}
                          className="form-select form-select-sm"
                          disabled={Depto !== 'Corresponsalias'}
                        >
                          <option value="0">No</option>
                          <option value="1">Si</option>
                        </Form.Control>
                      </Form.Label>
                    </Col>
                    <Col xs={2}>
                      <Form.Label style={{width:'100%'}}>
                        Fecha/hora instrucciones
                        <DatePicker
                          selected={FHInstrucciones}
                          disabled={Depto !== 'Corresponsalias'}
                          onChange={(date: any) => setFHInstrucciones(date)}
                          showTimeSelect
                          timeClassName={handleColor}
                          dateFormat="MM/dd/yyyy HH:mm:ss"
                          placeholderText="No definido"
                          isClearable
                        />
                      </Form.Label>
                    </Col>
                    <Col xs={2}>
                      <Form.Label style={{width:'100%'}}>
                        Fecha/hora entrega en planta
                        <DatePicker
                          selected={FHEntregaPlanta}
                          disabled={Depto !== 'Corresponsalias'}
                          onChange={(date: any) => setFHEntregaPlanta(date)}
                          showTimeSelect
                          timeClassName={handleColor}
                          dateFormat="MM/dd/yyyy HH:mm:ss"
                          placeholderText="No definido"
                          isClearable
                        />{' '}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 5 }}>
                    <Col xs={1}>Observaciones</Col>
                    <Col xs={11}>
                      <Form.Control
                        type="text"
                        id="Observaciones"
                        size="sm"
                        value={Observaciones}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setObservaciones(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 5 }}>
                    <Col xs={2}>Nombre de paqueteteria o transportista</Col>
                    <Col xs={3}>
                      <Form.Control
                        type="text"
                        id="Transportista"
                        size="sm"
                        value={Transportista}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setTransportista(e.target.value)}
                      />
                    </Col>
                    <Col xs={2}>No guia de embarque a destino</Col>
                    <Col xs={2}>
                      <Form.Control
                        type="text"
                        id="NoGuia"
                        size="sm"
                        value={NoGuia}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setNoGuia(e.target.value)}
                      />
                    </Col>
                    <Col>Fecha despacho</Col>
                    <Col xs={2}>
                      <Form.Control
                        type="date"
                        id="FechaDespacho"
                        size="sm"
                        value={FDespacho}
                        disabled={Depto !== 'Corresponsalias'}
                        onChange={(e) => setFDespacho(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  {CatTiposDocumento ? (
                    <Row className="g-1">
                      {CatTiposDocumento.map((c) => {
                        return (
                          <Col key={c.id} xs={4}>
                            {c.proceso !== 16 ?
                              <Card
                                style={{ width: '100%', textAlign: 'center', height:'100%' }}
                                key={c.id}
                              >
                                <Card.Body key={c.id}>
                                  <MFileManager
                                    key={c.id}
                                    IDTrafico={IDTrafico}
                                    Proceso={c.proceso}
                                    showPreview={3}
                                    canEdit={Depto === 'Corresponsalias'}
                                    Leyenda={'Seleccione: ' + c.descripcion}
                                  />
                                </Card.Body>
                              </Card> : ''
                            }
                          </Col>
                        )
                      })}
                    </Row>
                  ) : null}
                </Card.Body>
              </Card>
            </Tab>
            <Tab
              id="InformacionCorresponsal"
              eventKey="InformacionCorresponsal"
              title="Informacion corresponsal"
              tabClassName={mCFacturas.length >= 2 ? '' : 'd-none'}
            >
              <Row>
                <Col>Trafico</Col>
                <Col>
                  <Form.Control
                    type="text"
                    id="CTrafico"
                    size="sm"
                    value={Trafico}
                    disabled={Depto !== 'Corresponsalias'}
                    onChange={(e) => {
                      setTrafico(e.target.value)
                    }}
                  />
                </Col>
                <Col>Fecha entrada</Col>
                <Col>
                  <Form.Control
                    type="date"
                    id="FechaEntrada"
                    size="sm"
                    value={FEntrada}
                    disabled={Depto !== 'Corresponsalias' && Perfil !== 'Corresponsales'}
                    onChange={(e) => setFEntrada(e.target.value)}
                  />
                </Col>
                <Col>Fec revalida guia</Col>
                <Col>
                  <Form.Control
                    type="date"
                    id="FechaRevalidacionGuia"
                    size="sm"
                    value={FRevalidacionGuia}
                    disabled={Depto !== 'Corresponsalias' && Perfil !== 'Corresponsales'}
                    onChange={(e) => setFRevalidacionGuia(e.target.value)}
                  />
                </Col>
                <Col>Fec. Pago</Col>
                <Col>
                  <Form.Control
                    type="date"
                    id="CFechaPago"
                    size="sm"
                    value={FechaPago}
                    disabled={Depto !== 'Corresponsalias' ? true : false}
                    onChange={(e) => setFechaPago(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: 10 }}>
                <Col>Dias C/PDTO pag</Col>
                <Col>
                  <Form.Control
                    type="text"
                    id="DiasPedPagado"
                    size="sm"
                    value={DiasPedPagado}
                    disabled={true}
                  />
                </Col>
                <Col>Fletes</Col>
                <Col>
                  <CurrencyFormat
                    value={Fletes}
                    disabled={Depto !== 'Corresponsalias' && Perfil !== 'Corresponsales'}
                    thousandSeparator={true}
                    onValueChange={(values: any) => {
                      const { value } = values
                      setFletes(value)
                    }}
                    style={
                      Depto === 'Corresponsalias'
                        ? {
                            fontSize: '15px',
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #000000',
                            width: '90px',
                            borderRadius: '3px'
                          }
                        : {
                            fontSize: '15px',
                            backgroundColor: '#FFFFFF',
                            paddingLeft: '20px',
                            paddingRight: '5px',
                            border: '1px solid #000000',
                            width: '90px',
                            borderRadius: '3px'
                          }
                    }
                  />
                </Col>
                <Col>Seguros</Col>
                <Col>
                  <CurrencyFormat
                    value={Seguros}
                    thousandSeparator={true}
                    disabled={Depto !== 'Corresponsalias' && Perfil !== 'Corresponsales'}
                    onValueChange={(values: any) => {
                      const { value } = values
                      setSeguros(value)
                    }}
                    style={
                      Depto === 'Corresponsalias'
                        ? {
                            fontSize: '15px',
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #000000',
                            width: '90px',
                            borderRadius: '3px'
                          }
                        : {
                            fontSize: '15px',
                            backgroundColor: '#FFFFFF',
                            paddingLeft: '20px',
                            paddingRight: '5px',
                            border: '1px solid #000000',
                            width: '90px',
                            borderRadius: '3px'
                          }
                    }
                  />
                </Col>
                <Col>Embalaje</Col>
                <Col>
                  <CurrencyFormat
                    value={Embalaje}
                    thousandSeparator={true}
                    disabled={Depto !== 'Corresponsalias' && Perfil !== 'Corresponsales'}
                    onValueChange={(values: any) => {
                      const { value } = values
                      setEmbalaje(value)
                    }}
                    style={
                      Depto === 'Corresponsalias'
                        ? {
                            fontSize: '15px',
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #000000',
                            width: '90px',
                            borderRadius: '3px'
                          }
                        : {
                            fontSize: '15px',
                            backgroundColor: '#FFFFFF',
                            paddingLeft: '20px',
                            paddingRight: '5px',
                            border: '1px solid #000000',
                            width: '90px',
                            borderRadius: '3px'
                          }
                    }
                  />
                </Col>
                <Col>Otros</Col>
                <Col>
                  <CurrencyFormat
                    value={Otros}
                    thousandSeparator={true}
                    disabled={Depto !== 'Corresponsalias' && Perfil !== 'Corresponsales'}
                    onValueChange={(values: any) => {
                      const { value } = values
                      setOtros(value)
                    }}
                    style={
                      Depto === 'Corresponsalias'
                        ? {
                            fontSize: '15px',
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #000000',
                            width: '90px',
                            borderRadius: '3px'
                          }
                        : {
                            fontSize: '15px',
                            backgroundColor: '#FFFFFF',
                            paddingLeft: '20px',
                            paddingRight: '5px',
                            border: '1px solid #000000',
                            width: '90px',
                            borderRadius: '3px'
                          }
                    }
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: 10 }}>
                <Col xs={12}>
                  <Card
                    style={{
                      backgroundColor: 'readyColor',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      paddingTop: '5px',
                      paddingBottom: '5px'
                    }}
                  >
                    <Row>
                      <Col xs={1}>
                        <Form.Label className="labelSize13px">
                          Tipo cambio
                        </Form.Label>
                      </Col>
                      <Col xs={2}>
                        <CurrencyFormat
                          value={TipoCambio}
                          prefix={'$'}
                          displayType='input'
                          readOnly= {Depto !== 'Corresponsalias' ? true : false}
                          thousandSeparator={true}
                          onValueChange={(values: any) => {
                            const { value } = values
                            setTipoCambio(value)
                          }}
                          style={{
                              fontSize: '18px',
                              backgroundColor: '#F5FFED',
                              border: '2px solid #25D05B',
                              width: '100%',
                              textAlign: 'right',
                              borderRadius: '10px',
                              paddingRight: '10px',
                              paddingLeft: '10px'
                            }}
                        />
                      </Col>
                      <Col xs={3} style={{ textAlign: 'right' }}>
                        Fecha de despacho (Salida de contenedores)
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="date"
                          id="FechaDespacho"
                          size="sm"
                          value={FDespacho}
                          disabled={Depto !== 'Corresponsalias' && Perfil !== 'Corresponsales'}
                          onChange={(e) => setFDespacho(e.target.value)}
                        />
                      </Col>
                      <Col xs={1} style={{ textAlign: 'right' }}>
                        Estatus
                      </Col>
                      <Col xs={3}>
                        <Form.Control
                          as="select"
                          className="form-select form-select-sm"
                          value={Estatus}
                          disabled={Depto !== 'Corresponsalias' && Perfil !== 'Corresponsales'}
                          onChange={(e) => {
                            setEstatus(parseInt(e.target.value))
                          }}
                        >
                          <option value="0">-SELECCIONE-</option>
                          {DataCatEstatus
                            ? DataCatEstatus.map((c) => {
                                return (
                                  <option key={c.id} value={c.id}>
                                    {c.estatus}
                                  </option>
                                )
                              })
                            : null}
                        </Form.Control>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row style={{ paddingTop: 10 }}>
                <Col xs={12}>
                  <Partidas
                    IDTrafico={IDTrafico}
                    canEdit={Depto === 'Corresponsalias' || Perfil === 'Corresponsales'}
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: 10 }}>
                <Col xs={12}>
                  <Card>
                    <Card.Body>
                      {CatTiposDocumento ? (
                          <Row xs={1} md={3} className="g-1">
                            {CatTiposDocumentoInfoCorresponsal
                              ? CatTiposDocumentoInfoCorresponsal.map((c) => {
                                  return (
                                    <Col key={c.id}>
                                      <Card
                                        style={{
                                          textAlign: 'center',
                                          height:'100%'
                                        }}
                                      >
                                        <Card.Body>
                                          <FileManager
                                            IDUser={UserId}
                                            width={'100%'}
                                            height={100}
                                            IDProcess={c.proceso}
                                            IdFile={IDTrafico}
                                            FileName={''}
                                            canDelete={
                                              Depto === 'Corresponsalias'
                                            }
                                            FileType={['pdf']}
                                            setBackgroundColor={c.id}
                                            Prefijo={c.descripcion}
                                            Leyenda={`Aquí se podrá descargar el archivo ${c.descripcion} cuando sea cargado`}
                                            onAppendFM={function (
                                              idFile: number
                                            ): void {}}
                                          />
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  )
                                })
                              : ''}
                          </Row>
                      ) : null}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <MFileManager
                    key={IDTrafico}
                    IDTrafico={IDTrafico}
                    Proceso={10}
                    showPreview={1}
                    canEdit={Depto === 'Corresponsalias' || Perfil === 'Corresponsales'}
                    Leyenda={'Selecciones las imagenes de previo...'}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey="ComprobantesFacturacion"
              title="Comprobantes Facturacion"
              //tabClassName={Proceso >= 3 && Depto === 'Facturacion'? '' : 'd-none'}
            >
              <Card>
                <Card.Body>
                  <Row>
                    <Col xs={6}>
                      <Form.Label>PDF Cuenta Mexicana</Form.Label>
                    </Col>
                    <Col xs={6}>
                      <Form.Label>XML Cuenta Mexicana</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <FileManager
                        IDUser={UserId}
                        width={'100%'}
                        height={100}
                        IDProcess={37}
                        IdFile={IDTrafico}
                        FileName={''}
                        canDelete={
                          Depto === 'Facturacion' && Proceso === 3
                        }
                        FileType={['pdf']}
                        Leyenda={`Seleccione el PDF para subirlo al servidor...`}
                        onAppendFM={function (idFile: number ): void {}}
                      />
                    </Col>
                    <Col xs={6}>
                      <FileManager
                        IDUser={UserId}
                        width={'100%'}
                        height={100}
                        IDProcess={38}
                        IdFile={IDTrafico}
                        FileName={''}
                        canDelete={
                          Depto === 'Facturacion' && Proceso === 3
                        }
                        FileType={['xml']}
                        Leyenda={`Seleccione el XML para subirlo al servidor...`}
                        onAppendFM={function (idFile: number): void {}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Form.Label>PDF de Notas de Cargos</Form.Label>
                    </Col>
                    <Col xs={6}>
                      <Form.Label>Archivos Adicionales</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                        <FileManager
                          IDUser={UserId}
                          width={'100%'}
                          height={100}
                          IDProcess={41}
                          IdFile={IDTrafico}
                          FileName={''}
                          canDelete={
                            Depto === 'Facturacion' && Proceso === 3
                          }
                          FileType={['pdf']}
                          Leyenda={`Seleccione el PDF de las notas de cargos para subirlo al servidor...`}
                          onAppendFM={function (idFile: number): void {
                            
                          }}
                        />
                    </Col>
                    <Col xs={6}>
                      <FileManager
                        IDUser={UserId}
                        width={'100%'}
                        height={100}
                        IDProcess={39}
                        IdFile={IDTrafico}
                        FileName={''}
                        canDelete={
                          Depto === 'Facturacion' && Proceso === 3
                        }
                        FileType={['zip']}
                        Leyenda={`Seleccione el zip con los archivos adicionales  para subirlo al servidor...`}
                        onAppendFM={function (idFile: number): void {
                          
                        }}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
      <Modal
        show={showModal}
        onHide={handleClose}
        size={LoadComponent === 1 || LoadComponent === 7 ? 'lg' : 'sm'}
        dialogClassName={
          LoadComponent === 1 || LoadComponent === 2 || LoadComponent === 9
            ? 'modal-90w'
            : LoadComponent === 13 || LoadComponent === 14 ? 'modal-70w' : 'modal-50w'
        }
      >
        <Modal.Body>
          {LoadComponent === 1 ? (
            <div style={{ height: '350px', overflow: 'scroll' }}>
              <CatCorresponsales canDelete={Depto === 'Corresponsalias'} />
            </div>
          ) : null}
          {LoadComponent === 4 ? (
            <div style={{ height: '350px', overflow: 'scroll' }}>
              <PagosTerceros
                IDTrafico={IDTrafico}
                canDelete={Depto === 'Corresponsalias'}
                IDCorresponsal={IDCorresponsal}
              />
            </div>
          ) : null}
          {LoadComponent === 7 ? (
            <div style={{ height: '350px', overflow: 'scroll' }}>
              <Contenedores IDTrafico={IDTrafico} canDelete={Proceso === 1} />
            </div>
          ) : null}
          {LoadComponent === 2 ? (
            <div style={{ height: '600px' }}>
              <ProveedorFactura
                IDTrafico={IDTrafico}
                IDCorresponsal={IDCorresponsal}
                /*    updateList={updateList} */
                canDelete={Depto === 'Corresponsalias'}
              />
            </div>
          ) : null}
          {LoadComponent === 9 ? (
            <div style={{ height: '350px'}}>
              <Anticipos
                IDTrafico={IDTrafico}
                FolioTrafico={FolioTrafico}
                closeCRUDItems={function (arg: boolean): void {
                  setShowModal(arg)
                }}
                canDelete={Depto === 'Corresponsalias'}
                canUseActions={Depto === 'Corresponsalias' || Depto === 'Contabilidad'}
              />
            </div>
          ) : null}
          {LoadComponent === 10 ? (
            <div style={{ height: '350px', overflow: 'scroll' }}>
              <Tabulador
                IDCliente={IDCliente}
                IDTrafico={IDTrafico}
                IDTabulador={IDTabulador}
                NombreCliente={NombreCliente}
                IDCorresponsal={null}
                closeTabulador={function (arg: boolean): void {
                  setShowModal(arg)
                }}
                cambiaPrecuenta={function (idTab: number): void {
                  setIDTabulador(idTab)
                }}
                Depto={Depto}
              />
            </div>
          ) : null}
          {LoadComponent === 11 ? (
            <div style={{ height: '350px', overflow: 'scroll' }}>
              <Rectificaciones
                IDTrafico={IDTrafico}
                changeIdTrafico={function (newId: number): void {
                  setIDTrafico(newId)
                }}
              />
            </div>
          ) : null}
          {LoadComponent === 12 ? (
            <div style={{ height: '350px', overflow: 'scroll' }}>
              <Partidas
                IDTrafico={IDTrafico}
                canEdit={Depto === 'Corresponsalias' || Perfil === 'Corresponsales'}
              />
            </div>
          ) : null}
          {LoadComponent === 13 ? (
            <div style={{ height: '600px', overflow: 'auto' }}>
              <PedimentosConsolidados IdTrafico={props.IDTrafico} Aduana={Aduana!} Patente={Patente!} ClavesPedimento={ClavesPedimentos} Depto={Depto}/>
            </div>
          ) : null}
          {LoadComponent === 14? (
            <div style={{ height: '550px', overflow: 'hidden' }}>
              <NuevaRectificacion 
                IdTrafico={props.IDTrafico}
                Aduana={Aduana!}
                Patente={Patente!}
                ClavesPedimento={ClavesPedimentos}
                Depto={Depto}
                UserId={UserId}
                Pedimentos={mCPedimentosConsolidados}
                onAppendRectificacion={function (data: DTORectificacionHistorico): void {
                  throw new Error("Function not implemented.")
                } }/>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}