import moment from "moment"
import React, { useEffect } from "react"
import { useState } from "react"
import { Button, Card, Col, Form, ProgressBar, Row } from "react-bootstrap"
import { IconContext } from "react-icons"
import { BsCheckCircleFill, BsFileEarmarkExcel, BsFillXCircleFill, BsSearch } from "react-icons/bs"
import DTORptCorresponsalesTraficos from "../../../DTO/Corresponsales/DTORptCorresponsalesTraficos"
import ICatCorresponsales from "../../../Interfaces/Catalogos/ICatCorresponsales"
import IClientes from "../../../Interfaces/Catalogos/IClientes"
import ICorresponsalTrafico from "../../../Interfaces/Corresponsales/ICorresponsalTrafico"
import reporteGC50Service from "../../../Services/Reportes/reporteGC50.Service"
import CorresponsalesDataService from '../../../Services/Catalogos/Corresponsales.Services'
import { AgGridReact } from "ag-grid-react"
import { DTOReporteGC50 } from "../../../DTO/Reportes/GC50/DTOReporteGC50"

export const ReporteGC50: React.FC = () => {
    const [UserId, setUserId] = useState(() => {
        const stickyValue = window.localStorage.getItem('UserId')
        return stickyValue !== null ? JSON.parse(stickyValue) : 0
      })
    const [Data, setData] = useState<DTOReporteGC50[]>([])
    const [filteredData, setFilteredData] = useState<DTOReporteGC50[]>([])
    const [Inicio, setInicio] = useState(currentDate(-365))
    const [Fin, setFin] = useState(currentDate(0))
    const [Corresponsal, setCorresponsal] = useState(() => {
        const stickyValue = window.localStorage.getItem('IdCorresponsal')
        return stickyValue !== null ? JSON.parse(stickyValue) : 0
    })
    const [Clientes, setClientes] = useState<Array<IClientes>>()
    const gridRef = React.useRef<any>(null)
    const [DataCorresponsales, setDataCorresponsales] = useState<
    ICatCorresponsales[]
    >([])
    const [columnDefs] = useState([
        { field: 'trafico', headerName: 'Trafico', minWidth: 70, sortable: true, resizable: true},
        { field: 'corresponsal', headerName: 'Corresponsal', minWidth: 70, sortable: true,  resizable: true},
        { field: 'aduana', headerName: 'Aduana', minWidth: 70, sortable: true, resizable: true},
        { field: 'patente', headerName: 'Patente', minWidth: 70, sortable: true, resizable: true},
        { field: 'pedimentoCorresponsal', headerName: 'Pedimento Corresponsal', minWidth: 70, sortable: true, resizable: true},
        { field: 'pedimentoCorresponsalR1', headerName: 'Pedimento Corresponsal R1', minWidth: 70, sortable: true, resizable: true},
        { field: 'fechaPago', headerName: 'Fecha Pago', minWidth: 70, sortable: true, resizable: true},
        { field: 'fechaAnticipo', headerName: 'Fecha Anticipo', minWidth: 70, sortable: true, resizable: true},
        { field: 'sumaAnticipoCorresponsalFinanciado', headerName: 'Suma Anticipo Corresponsal Financiado', minWidth: 70, maxWidth:350, sortable: true, resizable: true},
        { field: 'fechaAlta', headerName: 'Fecha Alta', minWidth: 70, sortable: true, resizable: true},
        { field: 'usuarioAlta', headerName: 'Usuario Alta', minWidth: 70, sortable: true, resizable: true},
        { field: 'cliente', headerName: 'Cliente', minWidth: 70, sortable: true, resizable: true},
        { field: 'nombreCliente', headerName: 'Nombre Cliente', minWidth: 70, sortable: true, resizable: true},
        { field: 'cuenta', headerName: 'Cuenta', minWidth: 70, sortable: true, resizable: true},
        { field: 'tipoOperacion', headerName: 'Tipo Operacion', minWidth: 70, sortable: true, resizable: true},
        { field: 'terminado', headerName: 'Terminado', minWidth: 70, sortable: true, resizable: true},
        { field: 'fechaTerminado', headerName: 'Fecha Terminado', minWidth: 70, sortable: true, resizable: true},
        { field: 'fechaRegistroContabilizar', headerName: 'Fecha Registro Contabilizar', minWidth: 70, sortable: true, resizable: true},
        { field: 'fechaContabilizado', headerName: 'Fecha Contabilizado', minWidth: 70, sortable: true, resizable: true},
        { field: 'fechaFacturaCorresponsal', headerName: 'Fecha Factura Corresponsal', minWidth: 70, sortable: true, resizable: true},
        { field: 'motivoCuentaRechazada', headerName: 'Motivo Cuenta Rechazada', minWidth: 70, sortable: true, resizable: true},
        { field: 'estatusOperacion', headerName: 'Estatus Operacion', minWidth: 70, sortable: true, resizable: true},
        { field: 'cargoShipper', headerName: 'Cargo Shipper', minWidth: 70, sortable: true, resizable: true}
    ])

    function currentDate(days: number): string {
        var today = new Date()
        today.setDate(today.getDate() + days)
        var dd = String(today.getDate()).padStart(2, '0')
        var mm = String(today.getMonth() + 1).padStart(2, '0')
        var yyyy = today.getFullYear()
        return yyyy + '-' + mm + '-' + dd
    }

    useEffect(() => {
        CorresponsalesDataService.getAll()
      .then((response) => {
        setDataCorresponsales(response.data)
      })
      .catch((e: Error) => {
        return
      })
        generaReporte()
      }, [])

    const generaReporte = () => {
        const data: DTORptCorresponsalesTraficos = {
        Inicio: moment(Inicio).format('YYYY-MM-DD'),
        Fin: moment(Fin).format('YYYY-MM-DD'),
        TipoOperacion: 0,
        NoCliente: 0,
        IdCorresponsal: Corresponsal,
        Pedimento: 0,
        Aduana: '0',
        Patente: 0,
        Proceso: 0,
        Modo: 0,
        Referencia: ''
        }
        reporteGC50Service
        .GetReporteGC50(data)
        .then((response) => {
            setData(response.data)
            setFilteredData(response.data)
        })
        .catch((e: Error) => {
            return
        })
    }

    const filtraReporte = (e: any) => {
        gridRef.current.api.setQuickFilter(e.target.value)
    }

    const downloadExcel = () => {
      const data: DTORptCorresponsalesTraficos = {
        Inicio: moment(Inicio).format('YYYY-MM-DD'),
        Fin: moment(Fin).format('YYYY-MM-DD'),
        TipoOperacion: 0,
        NoCliente: 0,
        IdCorresponsal: Corresponsal,
        Pedimento: 0,
        Aduana: '0',
        Patente: 0,
        Proceso: 0,
        Modo: 0,
        Referencia: ''
        }
        reporteGC50Service.DownloadExcel(data)
        .then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ReporteGC50.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    } 

    return (
        <div>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={3}>
                    <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                        Buscar
                        <Form.Control
                        type='text'
                        size='sm'
                        placeholder='Search...'
                        onChange={(e) => {
                            filtraReporte(e)
                        }}
                        />
                    </Form.Label>    
                </Col>
                    <Col xs={2}>
                        <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                            Inicio
                            <Form.Control
                            defaultValue={Inicio}
                            type='date'
                            name='Inicio'
                            placeholder='Inicio'
                            title='Inicio'
                            alt='Inicio'
                            data-date-format='YYYY-mm-dd'
                            onChange={(e) => setInicio(e.target.value)}
                            size='sm'
                            />
                        </Form.Label>    
                    </Col>
                    <Col xs={2}>
                        <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                            Fin
                            <Form.Control
                            defaultValue={Fin}
                            type='date'
                            name='Fin'
                            placeholder='Fin'
                            title='Fin'
                            alt='Fin'
                            onChange={(e) => setFin(e.target.value)}
                            size='sm'
                            />
                        </Form.Label>    
                    </Col>
                    <Col lg={4}>
                        <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                            Corresponsal
                            <Form.Control
                            as='select'
                            onChange={(e) => {
                                setCorresponsal(parseInt(e.target.value))
                            }}
                            className='form-select form-select-sm'
                            >
                            <option value='0'>-SELECCIONE-</option>
                            {DataCorresponsales
                                ? DataCorresponsales.map((c) => {
                                    return (
                                    <option value={c.id} key={c.id}>
                                        {c.nombre}
                                    </option>
                                    )
                                })
                                : null}
                            </Form.Control>
                        </Form.Label>
                    </Col>
              </Row>
              <Row style={{paddingTop:'10px'}}>
                <Col lg={12} style={{textAlign:'end'}}>
                  <Button
                    variant='primary'
                    size='sm'
                    onClick={() => {
                      generaReporte()
                    }}
                    style={{marginRight:'5px'}}
                  >
                    <BsSearch />
                    &nbsp; Buscar
                  </Button>
                  <Button
                    size='sm'
                    variant='success'
                    onClick={() => {
                      downloadExcel();
                    }}
                    style={{marginRight:'5px'}}
                  >
                    <BsFileEarmarkExcel />
                    &nbsp; Excel
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
            <AgGridReact
              rowData={filteredData}
              columnDefs={columnDefs}
              pagination={true}
              paginationAutoPageSize={true}
              ref={gridRef}
              rowSelection={'multiple'}
              rowMultiSelectWithClick={true}
            ></AgGridReact>
          </div>
        </div>
      )

}