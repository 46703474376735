import { useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import ReportesAlenService from "../../../Services/Reportes/ReportesAlen.Service"
import { MsgInformativo } from "../../Utils/Toast/msgInformativo"

export const ReporteFacturasConsolidados:React.FC = () => {
    const [Referencia, setReferencia] = useState('')
    const [show, setShowMsg] = useState(false)
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [msgColor, setMsgColor] = useState('primary')

    const GetExcel = () => {
        if(Referencia !== ''){
            ReportesAlenService.getFacturasConsolidados(Referencia)
            .then(resp => {
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${Referencia}.xlsx`);
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                setHeader('Error')
                setMsg('Ocurrio un error al generar el reporte. Por favor, intentelo de nuevo.')
                setShowMsg(true)
                return
            })
        }else{
            setHeader('Error')
            setMsg('Favor de capturar la referencia para generar el reporte.')
            setShowMsg(true)
            return
        }
    }
    return(
        <>
            <Row style={{height:'90vh'}} className="align-items-center justify-content-center">
                    <Col xs={3}>
                        <Card style={{width:'100%'}}>
                            <Card.Header><h4>Reporte de Facturas</h4></Card.Header>
                            <Card.Body>
                                <Row style={{padding:'0px 20px 0px 20px'}} className="align-items-center justify-content-center">
                                    <Form.Control
                                        type="text"
                                        placeholder="Referencia"
                                        onChange={e => setReferencia(e.target.value)}
                                    />
                                </Row>
                            </Card.Body>
                            <Card.Footer style={{textAlign:'end'}}>
                                <Button variant="primary"  title="Quitar archivo cargado" style={{marginRight:'10px'}} onClick={GetExcel}>
                                    Generar Excel
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>

                </Row>
            <MsgInformativo
                show={show}
                msg={msg}
                header={header}
                msgColor={msgColor}
                closeToast={() => {
                setShowMsg(false)
                }}
            />
        </>
    )
}