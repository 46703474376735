import { ICellRendererParams } from "ag-grid-community";
import { FC, useState, useEffect } from "react";

export const ClienteProgressBar: FC<ICellRendererParams> = (props: ICellRendererParams) => {
    const [Color, setColor] = useState('progress-bar bg-CORRESPONSAL_NORMAL')

  useEffect(() => {
    if (props.data.proceso === 1) setColor('progress-bar bg-RECHAZOS_PARA_CORRESPONSAL')
    if (props.data.proceso === 2 || props.data.proceso === 3) {
      setColor('progress-bar bg-FACTURACION_LISTOS_PARA_FACTURAR')
    }
    if (props.data.proceso === 4) setColor('progress-bar bg-CORRESPONSAL_NORMAL')
  }, [props])

  const getTooltipTitle = () => {
    let proceso = props.data.proceso;
    switch(proceso){
      case 1:
          return "Por Terminar"
        break;
      case 2:
          return "Despachado Sin Facturar"
        break;
      case 3:
          return "Despachado Sin Facturar"
        break;
      case 4:
          return "Despachado y Facturado"
        break;
    }
  }

  return (
    <div>
      <div style={{ paddingTop: '20%' }}>
        <div className='progress' style={{ maxWidth: '80%' }}>
          <div className={Color} style={{ width: '100%' }} data-toggle="tooltip" data-placement="bottom" title={getTooltipTitle()}></div>
        </div>
      </div>
    </div>
  )
}