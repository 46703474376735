import { ICellRendererParams } from 'ag-grid-community'
import { FC, useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'

interface IProps {
  value: number
}

export const ProcessStatus: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  const [Proceso1, setProceso1] = useState(props.value >= 2 ? 100 : 0)
  // const [Proceso2, setProceso2] = useState(props.value >= 3 ? 100 : 0)
  // const [Proceso3, setProceso3] = useState(props.value >= 4 ? 100 : 0)
  const [Color, setColor] = useState('progress-bar bg-CORRESPONSAL_NORMAL')

  useEffect(() => {
    if (props.data.proceso === 0) setColor('progress-bar bg-RECHAZOS_PARA_CORRESPONSAL')
    if (props.data.proceso === 1) {
      if (props.data.rechazado > 0) setColor('progress-bar bg-RECHAZOS_POR_CONTABILIDAD')
      else setColor('progress-bar bg-CORRESPONSAL_NORMAL')
    }
  }, [props])

  return (
    <div style={{ paddingTop: '20%' }}>
      <ProgressBar>
        <ProgressBar variant={props.value >= 0 ? 'primary' : 'light'} now={23} key={1} />
        <ProgressBar variant='white' now={2} key={2} />
        <ProgressBar variant={props.value >= 1 ? 'success' : 'light'} now={23} key={3} />
        <ProgressBar variant='white' now={2} key={4} />
        <ProgressBar variant={props.value >= 2 ? 'warning' : 'light'} now={24} key={5} />
        <ProgressBar variant='white' now={1} key={6} />
        <ProgressBar variant={props.value === 3 ? 'info' : 'light'} now={25} key={7} />
      </ProgressBar>
    </div>
  )
}
