import { RowClickedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import React, { FC, useEffect, useState } from 'react'
import { Button, Card, Form, Modal } from 'react-bootstrap'
import ICorresponsalTrafico from '../../Interfaces/Corresponsales/ICorresponsalTrafico'
import ClientesDataService from '../../Services/Catalogos/Clientes.Services'
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import IClientes from '../../Interfaces/Catalogos/IClientes'
import { BsPlusLg, BsFileEarmarkExcel, BsSearch, BsFillXCircleFill, BsCheckCircleFill } from 'react-icons/bs'
import * as XLSX from 'xlsx'
import TraficoCorresponsales from '../Corresponsales/TraficoCorresponsales'
import ICatCorresponsales from '../../Interfaces/Catalogos/ICatCorresponsales'
import CorresponsalesDataService from '../../Services/Catalogos/Corresponsales.Services'
import reportesServices from '../../Services/Reportes/reportes.services'
import moment from 'moment'
import DTORptCorresponsalesTraficos from '../../DTO/Corresponsales/DTORptCorresponsalesTraficos'
import { InitCorresponsalesFacturas } from '../../store/features/Corresponsales/CorresponsalesFacturasSlice'
import { InitCorresponsalesFacturasTerceros } from '../../store/features/Corresponsales/CorresponsalesFacturasTercerosSlice'
import { InitCorresponsalesContenedores } from '../../store/features/Corresponsales/CorresponsalesContenedoresSlice'
import { useDispatch } from 'react-redux'
import { IconContext } from 'react-icons'
import { ProgressBar } from './customCells/progressBar'
import { ProcessStatus } from './customCells/ProcessStatus'

interface IProps {}

export default function RptCorresponsalesTraficosHst (props: IProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const queryParams = new URLSearchParams(window.location.search)
  const proc = queryParams.get('proc')
  const status = queryParams.get('status')
  const Modo = queryParams.get('Modo')
  //const [Mode, setMode] = useState(parseInt(window.location.search.replace('?proc=', '0')))
  const [StatusAnticipos, setStatusAnticipos] = useState(status ? parseInt(status) : 0)
  const dispatch = useDispatch()
  const [IDTrafico, setIDTrafico] = useState(0)
  const [Data, setData] = useState<ICorresponsalTrafico[]>([])
  const [filteredData, setFilteredData] = useState<ICorresponsalTrafico[]>([])
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-365))
  const [Fin, setFin] = useState(currentDate(0))
  const [TipoOperacion, setTipoOperacion] = useState(0)
  const [Cliente, setCliente] = useState(0)
  const [Corresponsal, setCorresponsal] = useState(0)
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [filtro, setFiltro] = useState('')
  const gridRef = React.useRef<any>(null)
  const [DataCorresponsales, setDataCorresponsales] = useState<ICatCorresponsales[]>([])
  const [Pedimento, setPedimento] = useState(0)
  const [Patente, setPatente] = useState(0);
  const [Aduana, setAduana] = useState('');
  const [columnDefs] = useState([
    { field: 'id', headerName: 'id', width: 70, sortable: true, filter: true },
    {
      field: 'proceso',
      headerName: 'Status',
      width: 90,
      center: true,
      sortable: true,
      filter: true,
      cellRenderer: ProcessStatus,
    },
    {
      field: 'folio',
      headerName: 'Folio',
      width: 120,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.data.folio
      },
    },
    {
      field: 'fechaRegistro',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value.substring(0, 10)
      },
    },
    { field: 'sUsuario', headerName: 'Usuario', sortable: true, filter: true },
    {
      field: 'sCliente',
      headerName: 'Cliente',
      sortable: true,
      filter: true,
    },
    { field: 'sTipoOperacion', headerName: 'Tipo operacion', sortable: true, filter: true },
    { field: 'sOpEntrada', headerName: 'Op Entrada', sortable: true, filter: true },
    { field: 'sOpSalida', headerName: 'Op Salida', sortable: true, filter: true },
    { field: 'sCorresponsal', headerName: 'Corresponsal', sortable: true, filter: true },
    { field: 'patente', sortable: true, filter: true },
    { field: 'aduana', sortable: true, filter: true },
    { field: 'pedimento', sortable: true, filter: true },
    {
      field: 'fechaPago',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value) return params.value.substring(0, 10)
        else return ''
      },
    },
    {
      field: 'fechaDesaduanamiento',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value) return params.value.substring(0, 10)
        else return ''
      },
    },
    {
      field: 'zipgemco',
      headerName: 'Zip GEMCO',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value === 'No')
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
        else
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
      },
    },
    {
      field: 'zipCorresponsal',
      headerName: 'Zip Corresponsal',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value === 'No')
          return (
            <div style={{ paddingLeft: '35px' }}>
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
        else
          return (
            <div style={{ paddingLeft: '35px' }}>
              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
      },
    },
    { field: 'ultimaActualizacion', sortable: true, filter: true },
  ])
  const [msgColor, setMsgColor] = useState('primary')

  function ConvertHexToString(str: string) {
    return unescape(str.replace(/\\/g, '%'))
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  useEffect(() => {
    // if (proc) setMode(parseInt(proc))
    if (status) {
      setStatusAnticipos(parseInt(status))
    } else setStatusAnticipos(0)
    const data: DTORptCorresponsalesTraficos = {
      Inicio: '',
      Fin: '',
      TipoOperacion: 0,
      NoCliente: 0,
      IdCorresponsal: Corresponsal,
      Pedimento: Pedimento,
        Aduana: Aduana,
        Patente: Patente,
      Proceso: proc ? parseInt(proc) : 0,
      Modo: Modo ? parseInt(Modo) : 0,
      Referencia: ''
    }
    reportesServices
      .getRptCorresponsalesTraficos(data)
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [proc, status])

  useEffect(() => {
    ClientesDataService.getAllClientes(UserId)
      .then((response) => {
        setClientes(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })

    CorresponsalesDataService.getAll()
      .then((response) => {
        setDataCorresponsales(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [])

  function getParams(e: RowClickedEvent) {
    AbreTrafico(e.data.id)
  }

  function AbreTrafico(id: number) {
    dispatch(InitCorresponsalesFacturas(IDTrafico))
    dispatch(InitCorresponsalesFacturasTerceros(IDTrafico))
    dispatch(InitCorresponsalesContenedores(IDTrafico))
    setIDTrafico(id)
    setShowModal(true)
  }

  const generaReporte = () => {
    const data: DTORptCorresponsalesTraficos = {
      Inicio: moment(Inicio).format('YYYY-MM-DD'),
      Fin: moment(Fin).format('YYYY-MM-DD'),
      TipoOperacion: TipoOperacion,
      NoCliente: Cliente,
      IdCorresponsal: Corresponsal,
      Pedimento: Pedimento,
        Aduana: Aduana,
        Patente: Patente,
      Proceso: proc ? parseInt(proc) : 0,
      Modo: Modo ? parseInt(Modo) : 0,
      Referencia: ''
    }
    reportesServices
      .getRptCorresponsalesTraficos(data)
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)
        console.log(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    gridRef.current.api.setQuickFilter(e.target.value)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Traficos de corresponsales')
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    let Heading = [
      [
        { title: 'Aduana Seccion Despacho', style: { font: { sz: '18', bold: true } } },
        'Patente',
        'Referencia',
        'Pedimento',
        'Fecha Pago',
        'Fecha Entrada Presentacion',
        'Clave Docto',
        'Es rectificacion',
        'Tipo Cambio',
        'Valor Dls',
        'Valor Aduana',
        'Numero Factura',
        'Fecha Factura',
        'Proveedor Factura',
        'Incrementables Fact',
        'Fraccion',
        'Subdiv NICO',
        'Descripcion',
        'Pais Origen',
        'Pais Vendedor',
        'Tasa DTA',
        'Tasa IGI',
        'Numero Parte',
        'Cantidad Comercial',
        'TL Pais',
        'Tipo Tasa',
        'Unidad Comercial',
        'Valor Factura Item',
        'Valor Comercial Ped',
        'Valor Factura Item MN',
      ],
    ]
    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    XLSX.writeFile(wb, fileName + '.xlsx')
    var range = XLSX.utils.decode_range(ws['!ref?'])
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <div className='row'>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-2'>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Fin'
                placeholder='Fin'
                title='Fin'
                alt='Fin'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label>Tipo oper</Form.Label>
            </div>
            <div className='col-md-1'>
              <Form.Control
                as='select'
                onChange={(e) => setTipoOperacion(parseInt(e.target.value))}
                className='form-select form-select-sm'
              >
                <option value='0'>-SELECCIONE-</option>
                <option value='1'>Importacion</option>
                <option value='2'>Exportacion</option>
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Form.Label>Cliente</Form.Label>
            </div>
            <div className='col-md-4'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setCliente(parseInt(e.target.value))
                }}
                className='form-select form-select-sm'
              >
                <option value='0'>-SELECCIONE-</option>
                {Clientes
                  ? Clientes.map((c) => {
                      return (
                        <option value={c.sClave} key={c.sClave}>
                          {c.sRazonSocial}
                        </option>
                      )
                    })
                  : null}
              </Form.Control>
            </div>
            <div className='col-md-1 right-label'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </div>
          </div>
          <div className='row' style={{ paddingTop: 5 }}>
            <div className='col'>
              {Depto === 'Corresponsalias' ? (
                <Button
                  size='sm'
                  variant='primary'
                  onClick={() => {
                    AbreTrafico(0)
                  }}
                >
                  <BsPlusLg />
                  &nbsp; Nuevo
                </Button>
              ) : (
                ''
              )}
            </div>
            <div className='col-4'>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </div>
            <div className='col'>
              <Button
                size='sm'
                variant='success'
                onClick={() => {
                  downloadExcel()
                }}
              >
                <BsFileEarmarkExcel />
                &nbsp; Excel
              </Button>
            </div>
            <div className='col'>Corresponsal</div>
            <div className='col-4'>
              <Form.Control
                as='select'
                onChange={(e) => {
                  setCorresponsal(parseInt(e.target.value))
                }}
                className='form-select form-select-sm'
              >
                <option value='0'>-SELECCIONE-</option>
                {DataCorresponsales
                  ? DataCorresponsales.map((c) => {
                      return (
                        <option value={c.id} key={c.id}>
                          {c.nombre}
                        </option>
                      )
                    })
                  : null}
              </Form.Control>
            </div>
            <div className='col'></div>
          </div>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          ref={gridRef}
          rowSelection={'single'}
          rowMultiSelectWithClick={true}
          onRowClicked={(e) => getParams(e)}
        ></AgGridReact>
      </div>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
      <Modal
        show={ShowModal}
        /*   onHide={() => setShowModal(false)} */
        size='sm'
        dialogClassName='modal-90w modal-innerDiv'
        disableEscapeKeyDown={true}
      >
        <Modal.Body>
          <div>
            <TraficoCorresponsales
              IDTrafico={IDTrafico}
              onClose={function (val: boolean): void {
                setShowModal(false)
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
