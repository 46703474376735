import * as React from 'react'
import { NavDropdown } from 'react-bootstrap'
import ItemMenu from '../../Interfaces/Catalogos/IItemMenu'

interface ISubmenuProps {
  submenu: ItemMenu[]
  descripcion: string
  allItems: ItemMenu[]
}

const Submenu: React.FunctionComponent<ISubmenuProps> = (props) => {
  const esPadre = (ItemSubmenu: ItemMenu, allItems: ItemMenu[]) => {
    var arrHijos = allItems.filter(function (item) {
      return ItemSubmenu.id === item.padreId
    })
    if (arrHijos.length) return true
    else return false
  }

  return (
    <div>
      {
        <>
          <NavDropdown title={props.descripcion} id="basic-nav-dropdown">
            {props.submenu.map((item, index) => {
              return !esPadre(item, props.allItems) ? (
                <>
                  <NavDropdown.Item key={item.id} href={'/#/' + item.url}>
                    {item.descripcion}
                  </NavDropdown.Item>
                </>
              ) : (
                <>
                  <NavDropdown
                    title={item.descripcion}
                    id="secondlevel"
                    className=""
                    drop="end"
                  >
                    {props.allItems
                      .filter(function (row) {
                        return row.padreId === item.id
                      })
                      .map((row, index) => {
                        return (
                          <NavDropdown.Item key={row.id} href={'/#/' + row.url}>
                            {row.descripcion}
                          </NavDropdown.Item>
                        )
                      })}
                  </NavDropdown>
                </>
              )
            })}
          </NavDropdown>
        </>
      }
    </div>
  )
}

export default Submenu
