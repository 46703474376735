import ICorresponsalRectificacionHistorico from "../../../Interfaces/Corresponsales/ICorresponsalRectificacionHistorico";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const RectificacionesPedimentos: ICorresponsalRectificacionHistorico[] = []

const initialState = {RectificacionesPedimentos};

export const RectificacionesPedimentosSlice = createSlice({
    name: 'RectificacionesPedimentos',
    initialState: initialState,
    reducers: {
        populateRectificaciones : (state, action: PayloadAction<ICorresponsalRectificacionHistorico[]>) => {
            state.RectificacionesPedimentos = []
            state.RectificacionesPedimentos.push(... action.payload)
          },
        addRectificacion : (state, action: PayloadAction<ICorresponsalRectificacionHistorico>) => {
            var Existe = state.RectificacionesPedimentos.find(function(item) {
              return item.id === action.payload.id;
            });
           if (!Existe) state.RectificacionesPedimentos.push(action.payload)
          },
          updateRectificacion : (state, action: PayloadAction<ICorresponsalRectificacionHistorico>) => {
            const i = state.RectificacionesPedimentos.findIndex(_element => _element.id === action.payload.id);
            if (i > -1) state.RectificacionesPedimentos[i] = action.payload;
            else state.RectificacionesPedimentos.push(action.payload);
          },
          deleteRectificacion : (state, action: PayloadAction<number>) => {
            const newArr = state.RectificacionesPedimentos.filter(data => data.id != action.payload);
            state.RectificacionesPedimentos=newArr
          },
    }
})


export const { populateRectificaciones, 
    addRectificacion, 
    updateRectificacion, 
    deleteRectificacion,
} = RectificacionesPedimentosSlice.actions;
export default RectificacionesPedimentosSlice.reducer;