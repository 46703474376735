import React, { FC, useEffect, useState } from 'react'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import CFac3DataService from '../../../../Services/Corresponsalias/Corresponsales.FacturasTerceros.services'
import { useDispatch, useSelector } from 'react-redux'
import ICorresponsalTerceros from '../../../../Interfaces/Corresponsales/ICorresponsalFacturasTerceros'
import { updateCorresponsalesFacturasTerceros } from '../../../../store/features/Corresponsales/CorresponsalesFacturasTercerosSlice'
import { Form } from 'react-bootstrap'
import { RootState } from '../../../../store/store'

interface IProps {
  row: ICorresponsalTerceros
  disabled?: boolean
  mode: number // 1: Number, 2: String
}

export const ControlledInput: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const mProveedores = useSelector((state: RootState) => state.CatProveedores.CatalogoProveedores)
  const [id, setid] = useState(props.row.id)
  const [value, setValue] = useState(props.row.factura)
  const [IDProveedor, setIDProveedor] = useState(props.row.idProveedor)
  const [ClasificacionProveedor, setClasificacionProveedor] = useState(1)
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')

  const onChange = (event: any) => {
    setValue(event.target.value)
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (isNaN(event.target.value) && props.mode === 1) {
        alert('Valor no valido!')
        return
      }
      const data: ICorresponsalTerceros = {
        id: id,
        factura: value,
        idTrafico: props.row.idTrafico,
        idProveedor: IDProveedor,
      }
      CFac3DataService.Append(data)
        .then((response) => {
          if (response.status === 200) {
            dispatch(updateCorresponsalesFacturasTerceros(response.data.registro))
            setHeader('Informativo')
            setMsg('La informacion se guardo exitosamente')
            setShowMsg(true)
            return
          }
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  }

  useEffect(() => {
    console.log('El proveedor es:............................' + JSON.stringify(props.row))
  }, [])

  return (
    <>
      <input
        value={value}
        onChange={onChange}
        disabled={props.disabled}
        onKeyDown={(e) => handleKeyDown(e)}
        style={{ width: '120px' }}
      />
      &nbsp;&nbsp;
      <Form.Control
        as='select'
        onChange={(f) => {
          setIDProveedor(parseInt(f.target.value))
        }}
        value={IDProveedor}
        className='form-select form-select-sm'
      >
        {mProveedores
          ? mProveedores
              .filter(function (row) {
                return row.clasificacion === ClasificacionProveedor
              })
              .map((p) => {
                return (
                  <option value={p.id} key={p.id}>
                    {p.nombre}
                  </option>
                )
              })
          : null}
      </Form.Control>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </>
  )
}
