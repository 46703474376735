import ICorresponsalTrafico from "../../Interfaces/Corresponsales/ICorresponsalTrafico";

export default class CCorresponsalesTrafico implements ICorresponsalTrafico {
    id: number=0
    folioGemco: string  
    fechaRegistro?: string 
    idUsuario: number=0  
    idCliente: number=0  
    tipoOperacion: number=0
    opEntrada: number=0  
    opSalida: number=0  
    idCorresponsal: number=0  
    bultos?: number  
    kilos?: number  
    estatus?: number  
    trafico?: string 
    aduana?: string  
    patente?: number  
    pedimento?: number  
    clave?: string 
    fechaPago?: string 
    tipoCambio?: number  
    valorAduanaMN?: number  
    totalPagado?: number  
    valorFacturaMN?: number  
    cantidadFracciones?: number  
    buque?: string 
    valorFacturaDls?: number  
    descripcionMercancia?: string 
    observaciones?: string 
    fechaDesaduanamiento: string
    semaforoFiscal?: number  
    noCuenta?: string 
    fechaCuenta?: string 
    tipoMercancia?: number  
    ultimaActualizacion?: string
    zipgemco?: string; 
    zipCorresponsales?: string;
    proceso: number;
    rechazado: number;
    idTabulador: number;
    noRecti: number;
    estatusCode: number;
    activo?: number;
    idTipoMercancia: number | null;
    cargoA: number;
    causaRectificacion: string;
    totalHonorariosAA: number;
    totalGastosTerceros: number;
    tieneSolicitud: number;
    pedimentoObligatorio: number;
    constructor() {
    this.id=0  
    this.folioGemco=''
    this.fechaRegistro=''
    this.idUsuario=0  
    this.idCliente=0  
    this.tipoOperacion=0
    this.opEntrada=0  
    this.opSalida=0  
    this.idCorresponsal=0  
    this.bultos=0  
    this.kilos=0  
    this.estatus=0  
    this.trafico='' 
    this.aduana='0'  
    this.patente=0  
    this.pedimento=0  
    this.clave='' 
    this.fechaPago='' 
    this.tipoCambio=0  
    this.valorAduanaMN=0
    this.totalPagado=0  
    this.valorFacturaMN=0  
    this.cantidadFracciones=0  
    this.buque=''
    this.valorFacturaDls=0  
    this.descripcionMercancia='' 
    this.observaciones='' 
    this.fechaDesaduanamiento=''
    this.semaforoFiscal=0  
    this.noCuenta='' 
    this.fechaCuenta='' 
    this.tipoMercancia=0  
    this.ultimaActualizacion=''
    this.zipgemco='' 
    this.zipCorresponsales=''
    this.proceso=1
    this.rechazado=1
    this.idTabulador=0
    this.noRecti=0;
    this.estatusCode=0;
    this.activo=1 
    this.idTipoMercancia=null;
    this.cargoA = 0;
    this.causaRectificacion = ''
    this.totalHonorariosAA = 0
    this.totalGastosTerceros = 0
    this.tieneSolicitud = 0
    this.pedimentoObligatorio = 1
    }

    public getEmptyObject(): ICorresponsalTrafico {
     return {
         id: 0,  
         folioGemco:'',
         fechaRegistro: '',
         idUsuario :0,  
         idCliente :0,  
         tipoOperacion :0,
         opEntrada :0,  
         opSalida:0,
         idCorresponsal :0,  
         bultos :0,  
         kilos :0,  
         estatus :0,  
         trafico :'', 
         aduana :'0',  
         patente :0,  
         pedimento :0,  
         clave :'', 
         fechaPago :'', 
         tipoCambio :0,  
         valorAduanaMN :0,
         totalPagado :0,  
         valorFacturaMN :0,  
         cantidadFracciones :0,  
         buque :'',
         valorFacturaDls :0,  
         descripcionMercancia :'', 
         observaciones :'', 
         fechaDesaduanamiento :'',
         semaforoFiscal :0,  
         noCuenta :'', 
         fechaCuenta :'', 
         tipoMercancia :0,  
         ultimaActualizacion :'', 
         zipgemco:'', 
         zipCorresponsales:'',
         proceso:1,
         rechazado:1,
         idTabulador:0,
         noRecti:0,
         estatusCode:0,
         activo :1 ,
         idTipoMercancia: null,
         cargoA: 0,
         causaRectificacion: '',
         totalHonorariosAA: 0,
         totalGastosTerceros: 0,
         tieneSolicitud: 0,
         pedimentoObligatorio: 1
     }
    }

    public setObject(data: ICorresponsalTrafico): ICorresponsalTrafico {
        return {
            id: data.id,  
            folioGemco: data.folioGemco,
            fechaRegistro: data.fechaRegistro,
            idUsuario : data.idUsuario,  
            idCliente :data.idCliente,  
            tipoOperacion :data.tipoOperacion,
            opEntrada : data.opEntrada,  
            opSalida: data.opSalida,
            idCorresponsal : data.idCorresponsal,  
            bultos :data.bultos,  
            kilos : data.kilos,  
            estatus : data.estatus,  
            trafico : data.trafico, 
            aduana : data.aduana,  
            patente : data.patente,  
            pedimento : data.pedimento,  
            clave : data.clave, 
            fechaPago : data.fechaPago, 
            tipoCambio : data.tipoCambio,  
            valorAduanaMN : data.valorAduanaMN,
            totalPagado : data.totalPagado,  
            valorFacturaMN : data.valorFacturaMN,  
            cantidadFracciones : data.cantidadFracciones,  
            buque : data.buque,
            valorFacturaDls : data.valorFacturaDls,  
            descripcionMercancia : data.descripcionMercancia, 
            observaciones : data.observaciones, 
            fechaDesaduanamiento : data.fechaDesaduanamiento,
            semaforoFiscal : data.semaforoFiscal,  
            noCuenta : data.noCuenta, 
            fechaCuenta : data.fechaCuenta, 
            tipoMercancia : data.tipoMercancia,  
            ultimaActualizacion : data.ultimaActualizacion, 
            zipgemco: data.zipgemco,
            zipCorresponsales: data.zipCorresponsales,
            proceso: data.proceso,
            rechazado: data.rechazado,
            idTabulador: data.idTabulador,
            noRecti: data.noRecti,
            estatusCode: data.estatusCode,
            activo : data.activo,
            idTipoMercancia: data.idTipoMercancia,
            cargoA: data.cargoA,
            causaRectificacion: data.causaRectificacion, 
            totalHonorariosAA: data.totalHonorariosAA,
            totalGastosTerceros: data.totalGastosTerceros,
            tieneSolicitud: data.tieneSolicitud,
            pedimentoObligatorio: data.pedimentoObligatorio
        }
       }
}