import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import ITabulador from '../../../Interfaces/Catalogos/ITabulador'
import CTabDataService from '../../../Services/Corresponsalias/Corresponsales.Tabuladores.Services'
import CTabPreDataService from '../../../Services/Corresponsalias/Corresponsales.Precuenta.Services'
import CTabDetDataService from '../../../Services/Corresponsalias/Corresponsales.Tabuladores.Detalle.Sevices'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import DTOConceptos from '../../../DTO/Utils/DTOConceptos'
import DataTable from 'react-data-table-component'
import 'react-data-table-component-extensions/dist/index.css'
import ITabuladorConceptos from '../../../Interfaces/Catalogos/ITabuladorConceptos'
import { ControlledInput } from '../../Utils/ControlledInput/ControlledInput'

interface IProps {
  IDCliente: number
  IDTabulador: number
  IDTrafico: number
  IDCorresponsal: number | null
  NombreCliente: string
  closeTabulador: (arg: boolean) => void
  cambiaPrecuenta: (IDPrecuenta: number) => void
  Depto: string
}

interface IselectedRows {
  allSelected: boolean
  selectedCount: number
  selectedRows: any
}

export const Tabulador: FC<IProps> = (props) => {
  const [IDTabulador, setIDTabulador] = useState(props.IDTabulador)
  const [Concepto, setConcepto] = useState(0)
  const [DetalleConceptos, setDetalleConceptos] = useState<ITabuladorConceptos[]>([])
  const [CatConceptos, setCatConceptos] = useState<DTOConceptos[]>([])
  const [Tabuladores, setTabuladores] = useState<ITabulador[]>([])
  const [AllTabuladores, setAllTabuladores] = useState<ITabulador[]>([])
  const [Costo, setCosto] = useState('')
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')

  const columnsConcepts = [
    {
      name: 'Concepto',
      width: '60%',
      selector: (row: ITabuladorConceptos) => row.concepto,
      sortable: true,
    },
    {
      name: 'Costo Cte',
      width: '15%',
      cell: (row: ITabuladorConceptos) => {
        return <ControlledInput id={row.id} value={row.costo.toFixed(2)} disabled={true} postCost={(id, value) => {}} />
        /*return row.activo === 1 ? (
          <ControlledInput id={row.id} value={row.costo} disabled={true} postCost={(id, value) => {}} />
        ) : (
          <ControlledInput id={row.id} value={0} disabled={true} postCost={(id, value) => {}} />
        )*/
      },
    },
    {
      name: 'Costo Corresp',
      width: '15%',
      cell: (row: ITabuladorConceptos) => {
        return <ControlledInput id={row.id} value={row.costoCorresponsal.toFixed(2)} disabled={true} postCost={(id, value) => {}} />
        /*return row.activo === 1 ? (
          <ControlledInput id={row.id} value={row.costo} disabled={true} postCost={(id, value) => {}} />
        ) : (
          <ControlledInput id={row.id} value={0} disabled={true} postCost={(id, value) => {}} />
        )*/
      },
    },
    {
      name: 'Activo',
      width: '10%',
      cell: (row: ITabuladorConceptos) => {
        return (
          <Form.Group controlId='formBasicCheckbox' style={{ textAlign: 'center' }}>
            <Form.Check
              type='checkbox'
              label=''
              defaultChecked={row.activo === 1 ? true : false}
              onChange={() => {
                switchConcept(row.id, row.activo ? 0 : 1)
              }}
              disabled={props.Depto !== 'Corresponsalias'}
            />
          </Form.Group>
        )
      },
    },
  ]

  const columnsTabs = [
    {
      name: 'id',
      selector: (row: ITabulador) => row.id,
      sortable: true,
    },
    {
      name: 'Nombre',
      width: '70%',
      // selector: (row: ITabulador) => row.nombre,
      cell: (row: ITabulador) => (
        <div
          style={{ width: '450px', cursor: 'pointer' }}
          onClick={() => {
            setIDTabulador(row.id)
          }}
        >
          {row.nombre}
        </div>
      ),
      sortable: true,
    },
  ]

  const switchConcept = (id: number, status: number) => {
    CTabPreDataService.ChangeStatus(id)
      .then((response) => {
        if (response.status === 200) {
          let tmp = DetalleConceptos
          let idx = tmp.findIndex((obj) => obj.id == id)
          tmp[idx].activo = status
          setDetalleConceptos(tmp)
          setHeader('Confirmacion')
          setMsg(response.data.respuesta)
          setShowMsg(true)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  //  const postCost = (id: number, Cost: number) => {}

  const GetAllTabs = () => {
    CTabDataService.GetByCustomer(props.IDCliente, props.IDCorresponsal)
      .then((response) => {
        setTabuladores(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    GetAllTabs()
  }, [])

  useEffect(() => {
    CTabPreDataService.GetAll(0, props.IDTrafico)
      .then((response) => {
        console.log('conceptos cargados debido a el tabulador guardado ' + JSON.stringify(response.data))
        setDetalleConceptos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
    // console.log(Tabulador)
  }, [IDTabulador, props])

  const filtraTabulador = (e: any) => {
    if (e.target.value !== '') {
      setTabuladores(
        AllTabuladores.filter(function (el) {
          return el.nombre.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())
        })
      )
    } else {
      setTabuladores(AllTabuladores)
    }
  }

  const saveForm = () => {
    // alert(IDTabulador)
    CTabPreDataService.Append(IDTabulador, props.IDTrafico)
      .then((response) => {
        setDetalleConceptos(response.data)
        props.cambiaPrecuenta(IDTabulador)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const addConcept = () => {
    if (IDTabulador === 0) {
      setHeader('Error')
      setMsg('Por favor, primero seleccione el tabulador')
      setShowMsg(true)
      return
    }
    if (Concepto === 0) {
      setHeader('Error')
      setMsg('Por favor, primero seleccione el concepto')
      setShowMsg(true)
      return
    }
    //    postCost(0, 0)
  }

  return (
    <div>
      <Container fluid>
        <Alert variant='primary'>
          <Row>
            <Col xs={2} style={{ paddingTop: '5px' }}>
              <h5>Cliente: </h5>
            </Col>
            <Col xs={10} style={{ textAlign: 'center' }}>
              <h4>{props.NombreCliente}</h4>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h6>Tabulador asignado: </h6>
            </Col>
            <Col xs={7}>
              <Form.Control
                as='select'
                value={IDTabulador}
                onChange={(e) => setIDTabulador(parseInt(e.target.value))}
                className='form-select form-select-sm'
                style={{ fontSize: '15px' }}
                disabled={props.Depto !== 'Corresponsalias'}
              >
                <option value='0'>- No tiene tabulador asignado -</option>
                {Tabuladores
                  ? Tabuladores.map((c) => {
                      return <option value={c.id}>{c.nombre}</option>
                    })
                  : null}
              </Form.Control>
            </Col>
            <Col xs={1}>
              {
                (props.Depto === 'Corresponsalias')
                ? <Button
                    variant='primary'
                    size='sm'
                    style={{ paddingLeft: '10px', paddingRight: '10px' }}
                    onClick={() => saveForm()}
                  >
                    Guardar
                  </Button>
                : ''
              }
            </Col>
          </Row>
        </Alert>
        <Row>
          {/*   <Col xs={6}>
            <Card style={{}}>
              <Card.Body>
                <Card.Subtitle className='mb-2 text-muted'>
                  <Row>
                    <Col xs={2} style={{ paddingTop: '8px' }}>
                      Tabulador
                    </Col>
                    <Col xs={10}>
                      <Form.Control
                        type='text'
                        size='sm'
                        placeholder='Busqueda de tabulador...'
                        onChange={(e) => {
                          filtraTabulador(e)
                        }}
                      />
                    </Col>
                  </Row>
                </Card.Subtitle>
                <DataTable
                  noHeader
                  defaultSortFieldId={'id'}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  columns={columnsTabs}
                  data={Tabuladores}
                />
              </Card.Body>
            </Card>
          </Col> */}
          <Col xs={12}>
            <Card style={{}}>
              <Card.Body>
                <Card.Subtitle className='mb-2 text-muted'></Card.Subtitle>
                <DataTable
                  noHeader
                  defaultSortFieldId={'id'}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  columns={columnsConcepts}
                  data={DetalleConceptos}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
