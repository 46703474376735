import { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { BsFillXCircleFill, BsCheckCircleFill } from "react-icons/bs";
import IFileManager from "../../../Interfaces/Utils/IFileManager";
import MFileManagerService from "../../../Services/Utils/MFileManager.Service";
import FileManagerDataService from "../../../Services/Utils/FileManager.Services";
import { Form } from "react-bootstrap";

interface IProps{
    hasFile: number;
    idTrafico: number;
    proceso: number;
}

export const CellIcon: React.FC<IProps> = (props: IProps) => {
    const [Archvios, setArchivos] = useState<IFileManager[]>([]);

    useEffect(() => {
        MFileManagerService.Get(props.idTrafico, props.proceso).then(response => {
            setArchivos(response.data);
        })
    },[]);

    const getFileById = (idTrafico:number) => {
        FileManagerDataService.getFileContentById(idTrafico)
        .then((response:any) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        })
    }

    const getFileByProcess = (idTrafico:number, proceso: number) => {
        FileManagerDataService.getFileContent(idTrafico, proceso)
        .then((response:any) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        })
    }
    return (
        <div style={{ paddingLeft: '20px', textAlign:'center' }}>
        {
            props.hasFile === 0 ?
            <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
            </IconContext.Provider>
            : [14, 15 , 16, 17, 18].includes(props.proceso) ? 
                <Form.Control
                    as="select"
                    id={`Select_${props.idTrafico}_${props.proceso}`}
                    onChange={(e) => {getFileById(+e.target!.value) }}
                    value={0}
                    className="form-select form-select-sm"
                >
                    <option value="0">-SELECCIONE-</option>
                    {Archvios
                    ? Archvios.map((a) => {
                        return (
                            <option key={a.id} value={a.id}>
                            {a.nombreArchivo}
                            </option>
                        )
                        })
                    : null}
                </Form.Control> 
            :
            <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsCheckCircleFill onClick={() => getFileByProcess(props.idTrafico, props.proceso)} style={{cursor:'pointer'}}/>
            </IconContext.Provider>
        }
        </div>
    )
}