import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReportesAlenService from "../../../Services/Reportes/ReportesAlen.Service";
import { MsgInformativo } from "../../Utils/Toast/msgInformativo";
import ClientesServices from "../../../Services/Catalogos/Clientes.Services";
import IClientes from "../../../Interfaces/Catalogos/IClientes";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import ICatCorresponsales from "../../../Interfaces/Catalogos/ICatCorresponsales";
import CorresponsalesServices from "../../../Services/Catalogos/Corresponsales.Services";
import ReporteCuentasCerradasService from "../../../Services/Reportes/ReporteCuentasCerradas.Service";
export const CuentasCerradas: React.FC<{}> = () => {
    const [Inicio, setInicio] = useState(currentDate(-7))
    const [Fin, setFin] = useState(currentDate(0))
    const [show, setShowMsg] = useState(false)
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [msgColor, setMsgColor] = useState('primary')
    const [TipoOperacion, setTipoOperacion] = useState(0)
    const [IDCliente, setIDCliente] = useState(0)
    const [Clientes, setClientes] = useState<Array<IClientes>>()
    const [IDCorresponsal, setIDCorresponsal] = useState(0)
    const [Corresponsales, setCorresponsales] = useState<Array<ICatCorresponsales>>()

    function currentDate(days: number): string {
        var today = new Date()
        today.setDate(today.getDate() + days)
        var dd = String(today.getDate()).padStart(2, '0')
        var mm = String(today.getMonth() + 1).padStart(2, '0')
        var yyyy = today.getFullYear()
        return yyyy + '-' + mm + '-' + dd
    }

    useEffect(() => {
        ClientesServices.getAllClientes(0)
        .then((response) => {
            //clientes = response.data;
            setClientes(response.data)
        })
        .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
        })
        CorresponsalesServices.getAll()
        .then((response) => {
            setCorresponsales(response.data)
        })
        .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
        })
    }, [])

    const GetExcel = () => {
        if(IDCliente === 0 && IDCorresponsal === 0){
            setHeader('Error')
            setMsg('Debe seleccionar un cliente o un corresponsal')
            setShowMsg(true)
            return
        }
        ReporteCuentasCerradasService.DownloadExcel(Inicio, Fin, TipoOperacion, IDCliente, IDCorresponsal)
        .then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Cuentas Cerradas ${Inicio} - ${Fin}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(e => {
            setHeader('Error')
            setMsg('Ocurrio un error al generar el reporte. Por favor, intentelo de nuevo.')
            setShowMsg(true)
            return
        })
    }
    return(
        <>
            <Row style={{height:'90vh'}} className="align-items-center justify-content-center">
                    <Col xs={6}>
                        <Card style={{width:'100%'}}>
                            <Card.Header><h4>Reporte de Cuentas Cerradas</h4></Card.Header>
                            <Card.Body>
                            <Row>
                                <Col xs={3}>
                                    <Form.Label style={{width:'100%'}}>
                                        Inicio 
                                        <Form.Control
                                            defaultValue={Inicio}
                                            type='date'
                                            name='Inicio'
                                            placeholder='Inicio'
                                            title='Inicio'
                                            alt='Inicio'
                                            data-date-format='YYYY-mm-dd'
                                            onChange={(e) => setInicio(e.target.value)}
                                            size='sm'
                                        />
                                    </Form.Label>
                                </Col>
                                <Col xs={3}>
                                    <Form.Label style={{width:'100%'}}>
                                        Fin
                                        <Form.Control
                                            defaultValue={Fin}
                                            type='date'
                                            name='Fin'
                                            placeholder='Fin'
                                            title='Fin'
                                            alt='Fin'
                                            onChange={(e) => setFin(e.target.value)}
                                            size='sm'
                                        />
                                    </Form.Label>
                                </Col>
                                <Col xs={3}>
                                    <Form.Label style={{width:'100%'}}>
                                        Tipo de Operación
                                        <Form.Control
                                            as="select"
                                            onChange={(e) =>
                                                setTipoOperacion(parseInt(e.target.value))
                                            }
                                            value={TipoOperacion}
                                            className="form-select form-select-sm"
                                        >
                                            <option value="0">--Seleccione--</option>
                                            <option value="1">Importacion</option>
                                            <option value="2">Exportacion</option>
                                        </Form.Control>
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label>
                                        Cliente
                                        <Form.Control
                                            as="select"
                                            id="CmbCliente"
                                            onChange={(e) => {
                                                setIDCliente(parseInt(e.target.value))
                                            }}
                                            value={IDCliente}
                                            className="form-select form-select-sm"
                                            >
                                            <option value="0">-SELECCIONE-</option>
                                            {Clientes
                                                ? Clientes.map((c) => {
                                                    return (
                                                    <option key={c.sClave} value={c.sClave}>
                                                        {c.sRazonSocial}
                                                    </option>
                                                    )
                                                })
                                            : null}
                                        </Form.Control>
                                    </Form.Label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label>
                                        Corresponsal
                                        <Form.Control
                                            as="select"
                                            id="CmbIDCorresponsal"
                                            onChange={(e) => {
                                                setIDCorresponsal(parseInt(e.target.value))
                                            }}
                                            className="form-select form-select-sm"
                                            value={IDCorresponsal}
                                        >
                                            <option value="0">-SELECCIONE-</option>
                                            {Corresponsales
                                            ? Corresponsales.map((item, index) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                        {item.nombre} | {item.patente} | {item.aduana}
                                                        </option>
                                                    )
                                                })
                                            : ''}
                                        </Form.Control>
                                    </Form.Label>
                                </Col>
                            </Row>
                            </Card.Body>
                            <Card.Footer style={{textAlign:'end'}}>
                                <Button variant="primary"  title="Quitar archivo cargado" style={{marginRight:'10px'}} onClick={GetExcel}>
                                    Generar Excel
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>

                </Row>
            <MsgInformativo
                show={show}
                msg={msg}
                header={header}
                msgColor={msgColor}
                closeToast={() => {
                setShowMsg(false)
                }}
            />
        </>
    )
}