import DTOAEPeriodo from "../../DTO/DTOAEPeriodo";
import DTOgetArchivosPorReferencia from "../../DTO/DTOgetArchivosPorReferencia";
import DTOgetThesePedimentos from "../../DTO/DTOgetThesePedimentos";
import IArchivoElectronico from "../../Interfaces/IArchivoElectronico";
import IFotosBodega from "../../Interfaces/IFotosBodega";
import IRpArchivoElectronicoOficial from "../../Interfaces/Reportes/IRptArchivoElectronicoOficial";
import http from "../common/http-common";


class ArchivoElectronicoDataService {
   /* getFile(data: IArchivoElectronico) {
       return http.get<Blob>(`/ArchivoElectronico/getFile`, { params: data })
   }
   getFileList(data: IArchivoElectronico) {
       return http.get<string[]>(`/ArchivoElectronico/getFileList`, { params: data })
   }            
   getInfoPicturesFromWarehouse(data: IFotosBodega) {
      return http.get<Array<IFotosBodega>>(`/ArchivoElectronico/getLogFotosBodega?Referencia=${data.referencia}`);
   }            
   getPictureContent(Archivo : string) {
      return http.get<Array<IFotosBodega>>(`/ArchivoElectronico/getPictureContent?Nombre=${Archivo}`);
   }
   getTheseReferencesByPeriod(data : DTOAEPeriodo) {
      return http.post<ArrayBuffer>("/ArchivoElectronico/downloadReferencesByPeriod", data, {responseType: 'arraybuffer'})
      .then(function (response) {
         return response
       })
       .catch(function (error) {
         console.log(error)
       })
   }     
   getTheseFilesByReferences(data : DTOgetArchivosPorReferencia) {
      return http.post<ArrayBuffer>("/ArchivoElectronico/getFilesByReference", data, {responseType: 'arraybuffer'})
      .then(function (response) {
         return response
       })
       .catch(function (error) {
         console.log(error)
       })
   }     
   getThesePDFs(data : DTOgetThesePedimentos) {
      return http.post<ArrayBuffer>("/ArchivoElectronico/downloadPDFs", data, {responseType: 'arraybuffer'})
      .then(function (response) {
         return response
       })
       .catch(function (error) {
         console.log(error)
       })
   } 
   getPDF(data: IArchivoElectronico) {
      return http.get<ArrayBuffer>(`/ArchivoElectronico/getFile?Referencia=${data.Referencia}&PedimentoLargo=${data.PedimentoLargo}&NoCliente=${data.NoCliente}&IdUsuario=${data.IdUsuario}`, {responseType: 'arraybuffer'})
      .then(function (response) {
         return response
       })
       .catch(function (error) {
         console.log(error)
       })
   } */
   getZippedReferences(data : DTOAEPeriodo) {
      return http.post<Blob>("/ArchivoElectronico/DownloadZippedArchivosOficiales", data, {responseType: 'blob'})
      .then(function (response) {
         return response
       })
       .catch(function (error) {
         console.log(error)
       })
   }
   
  }
  export default new ArchivoElectronicoDataService();