import React from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { EmbarquesPorImportar } from './EmbarquesPorImportar';
import { EmbarquesDespachados } from './EmbarquesDespachados';
import { BuscarEmbarques } from './BuscarEmbarques';
import { BsTruck } from 'react-icons/bs';


export const ReportesEmbarques: React.FC = () => {    
    return (
        <div className='px-2' style={{width:'85%'}}>
            <Card>
                <Card.Body>
                    <Tabs
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab
                        eventKey="EmbarquesPorImportar"
                        title={<span><BsTruck className='me-2'/>{"Embarques Por Importar"}</span>}
                        id="TabEmbarquesPorImportar"
                        >
                            {<EmbarquesPorImportar/>}
                        </Tab>
                        <Tab
                        eventKey="EmbarquesDespachados"
                        title={<span><BsTruck className='me-2'/>{"Embarques Despachados"}</span>}
                        id="TabEmbarquesdespachados"
                        >
                            {<EmbarquesDespachados/>}
                        </Tab>
                        <Tab
                        eventKey="BuscarEmbarques"
                        title={<span><BsTruck className='me-2'/>{"Buscar Embarques"}</span>}
                        id="TabBuscarEmbarques"
                        >
                            <BuscarEmbarques/>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        </div>
    )
}