import React, { FC, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import { FaEraser, FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import ICorresponsalContenedores from '../../../Interfaces/Corresponsales/ICorresponsalContenedores'
import CConDataService from '../../../Services/Corresponsalias/Corresponsales.Contenedores.Services'
import { RootState } from '../../../store/store'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import CContDataService from '../../../Services/Corresponsalias/Corresponsales.Contenedores.Services'
import {
  addCorresponsalesContenedores,
  deleteCorresponsalesContenedores,
} from '../../../store/features/Corresponsales/CorresponsalesContenedoresSlice'
import { ControlledInput } from './ControlledInput/ControlledInput'

interface IProps {
  IDTrafico: number
  canDelete: boolean
}

export const Contenedores: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const mCContenedores = useSelector((state: RootState) => state.CCData.CorresponsalesContenedores)
  const [DTData, setDTData] = useState<ICorresponsalContenedores[]>([])
  const [Contenedor, setContenedor] = useState('')
  const [IDContenedor, setIDContenedor] = useState(0)
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [DialogTabs, setDialogTabs] = useState(false)

  const columnsConcepts = [
    {
      name: 'id',
      width: '10%',
      selector: (row: ICorresponsalContenedores) => row.id,
      sortable: true,
    },
    {
      name: 'Contenedor',
      width: '75%',
      cell: (row: ICorresponsalContenedores) => {
        return (
          <ControlledInput id={row.id} value={row.contenedor} disabled={false} mode={2} IDTrafico={props.IDTrafico} />
        )
      },
    },
    {
      name: 'Elimina',
      cell: (row: ICorresponsalContenedores) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIDContenedor(row.id)
            setContenedor(row.contenedor)
            setDialogTabs(true)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <FaTimesCircle />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  useEffect(() => {
    CConDataService.getAll(props.IDTrafico)
      .then((response) => {
        setDTData(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [])

  const saveForm = () => {
    const data: ICorresponsalContenedores = {
      id: IDContenedor,
      contenedor: Contenedor,
      idTrafico: props.IDTrafico,
      fSemaforo: '',
      semaforo: 0,
      idTipoContenedor:0
    }
    CContDataService.Append(data)
      .then((response) => {
        if (response.status == 200) {
          if (data.id === 0) dispatch(addCorresponsalesContenedores(response.data))
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const deleteItem = () => {
    CConDataService.Delete(IDContenedor)
      .then((response) => {
        if (response.status === 200) {
          setHeader('Informativo')
          setMsg(response.data.respuesta)
          setShowMsg(true)
          setDialogTabs(false)
          dispatch(deleteCorresponsalesContenedores(IDContenedor))
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanForm = () => {
    setContenedor('')
    setIDContenedor(0)
  }

  return (
    <div>
      <Card style={{}}>
        <Card.Body>
          <Card.Subtitle className='mb-2 text-muted'>
            <Row
              style={{ paddingBottom: '5', paddingTop: '15' }}
              className={props.canDelete ? 'visible' : 'invisible heightZero'}
            >
              <Col
                xs={1}
                style={{ textAlign: 'right', paddingTop: '5px', cursor: 'pointer' }}
                onClick={() => {
                  cleanForm()
                }}
              >
                <IconContext.Provider value={{ color: 'orange', size: '25px' }}>
                  <FaEraser />
                </IconContext.Provider>
              </Col>
              <Col>
                <Form.Control
                  type='hidden'
                  id='IdItem'
                  size='sm'
                  value={IDContenedor}
                  placeholder='Contenedor'
                  disabled={true}
                />
              </Col>
              <Col xs={1}></Col>
              <Col xs={6}>
                <Form.Control
                  type='text'
                  id='Contenedor'
                  size='sm'
                  value={Contenedor}
                  placeholder='Contenedor'
                  pattern='[a-zA-Z-0-9 ]*'
                  onChange={(e) => setContenedor((v) => (e.target.validity.valid ? e.target.value : v))}
                />
              </Col>
              <Col xs={1}></Col>
              <Col xs={1}>
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => {
                    saveForm()
                  }}
                >
                  Agregar
                </Button>
              </Col>
              <Col>&nbsp;</Col>
            </Row>
          </Card.Subtitle>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            pagination
            highlightOnHover
            columns={columnsConcepts}
            data={mCContenedores.filter(function (el) {
              return el.id > 0
            })}
          />
        </Card.Body>
      </Card>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        time={2000}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
      {/*   <Modal
        show={DialogTabs}
        onHide={() => {
          setDialogTabs(false)
        }}
        size='sm'
      >
        <Modal.Body>
          <Alert variant='primary'>
            <b>¿Esta seguro de eliminar?</b>
            <br />
            <h5> {Contenedor} </h5>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant='danger'
                onClick={deleteItem}
                size='sm'
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Si
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='secondary'
                onClick={() => {
                  setDialogTabs(false)
                }}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal> */}
    </div>
  )
}
