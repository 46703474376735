import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IRegister } from '../../../Interfaces/IRegister'
import usuariosServices from '../../../Services/Catalogos/Usuarios.Services'
import PerfilesServices from '../../../Services/Catalogos/PerfilesMenu.Services'
import { IPerfil } from '../../../Interfaces/Catalogos/IPerfiles'
import { IconContext } from 'react-icons'
import {
  BsCheckCircleFill,
  BsCheckLg,
  BsFillPersonCheckFill,
  BsFillPersonDashFill
} from 'react-icons/bs'

interface IProps {
  show: boolean
  CloseModal: (arg: boolean) => void
  GetUserInfo: (arg: IRegister) => void
}

export const UserList: React.FC<IProps> = (props) => {
  const [sDialog, setsDialog] = React.useState(true)
  const [Usuarios, setUsuarios] = React.useState<IRegister[]>()
  const [Data, setData] = useState<IRegister[]>([])
  const [DataPerfiles, setDataPerfiles] = useState<IPerfil[]>([])
  const [DataOriginal, setDataOriginal] = useState<IRegister[]>([])
  const [filtro, setFiltro] = useState('')
  const columnsConcepts = [
    {
      name: 'id',
      width: '70px',
      cell: (row: IRegister) => (
        <div
          style={{ width: '100%' }}
          onClick={() => {
            props.GetUserInfo(row)
          }}
        >
          {row.id}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Usuario',
      width: '200px',
      cell: (row: IRegister) => (
        <div
          style={{ width: '100%' }}
          onClick={() => {
            props.GetUserInfo(row)
          }}
        >
          {row.usuario}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Nombre',
      width: '250px',
      cell: (row: IRegister) => (
        <div
          style={{ width: '100%' }}
          onClick={() => {
            props.GetUserInfo(row)
          }}
        >
          {row.nombre}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Correo',
      width: '250px',
      cell: (row: IRegister) => (
        <div
          style={{ width: '100%' }}
          onClick={() => {
            props.GetUserInfo(row)
          }}
        >
          {row.correo}
        </div>
      ),
      sortable: true
    },
    /*  {
      name: 'Ultima visita',
      width: '125px',
      cell: (row: IRegister) => (
        <div
          style={{ width: '100%' }}
          onClick={() => {
            props.GetUserInfo(row)
          }}
        >
          {row.fechaUltimaVisita}
        </div>
      ),
      sortable: true
    }, */
    {
      name: 'Perfil',
      width: '250px',
      cell: (row: IRegister) => (
        <div
          style={{ width: '100%' }}
          onClick={() => {
            props.GetUserInfo(row)
          }}
        >
          {getProfile(row.idPerfil)}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Estatus',
      width: '90px',
      cell: (row: IRegister) => (
        <div
          style={{ width: '100%', textAlign:'center' }}
          onClick={() => {
            props.GetUserInfo(row)
          }}
        >
          {row.activo == 1 ? (
            <IconContext.Provider value={{ color: 'green', size: '20px' }}>
              <BsCheckCircleFill />
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ color: 'red', size: '20px' }}>
              <BsFillPersonDashFill />
            </IconContext.Provider>
          )}
        </div>
      ),
      sortable: true
    }
  ]

  const getProfile = (Id: number) => {
    const NoAsignado = ' - NO ASIGNADO -'
    if (Id === 0) return NoAsignado
    else if (DataPerfiles) {
      const Profile = DataPerfiles.filter((item) => item.id === Id)
      return Profile[0].perfil ? Profile[0].perfil : NoAsignado
    }
    return NoAsignado
  }

  useEffect(() => {
    usuariosServices
      .getAllUsuarios()
      .then((response) => {
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
      })
    PerfilesServices.getAllPerfiles()
      .then((response) => {
        setDataPerfiles(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }, [props.show])

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    if (e.target.value.length > 0) {
      setData(
        Data.filter(function (row) {
          return (
            row.id.toString().includes(e.target.value) ||
            row.usuario.toLowerCase().includes(e.target.value) ||
            row.nombre.toLowerCase().includes(e.target.value) ||
            row.correo.toLowerCase().includes(e.target.value)
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  return props.show ? (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={'modal-70w'}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Alert>
        <Row>
          <Col xs={2}>
            <h5>Lista de usuarios</h5>
          </Col>
          <Col xs={10}>
            <Form.Control
              type="text"
              size="sm"
              placeholder="Search..."
              onChange={(e) => {
                filtraReporte(e)
              }}
            />
          </Col>
        </Row>
      </Alert>
      <Modal.Body>
        <DataTable
          noHeader
          defaultSortFieldId={'id'}
          defaultSortAsc={true}
          striped={true}
          dense={true}
          paginationPerPage={10}
          pagination
          highlightOnHover
          columns={columnsConcepts}
          data={Data}
          pointerOnHover
        />
        {/*  <Table striped bordered hover>
          <thead>
            <tr>
              <th>id</th>
              <th>Usuario</th>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Ultima visita</th>
              <th>Activo</th>
            </tr>
          </thead>
          <tbody>
            {Usuarios
              ? Usuarios.map((rec, index) => (
                  <tr
                    onClick={() => {
                      props.GetUserInfo(rec)
                    }}
                  >
                    <td>{rec.id}</td>
                    <td>{rec.usuario}</td>
                    <td>{rec.nombre}</td>
                    <td>{rec.correo}</td>
                    <td>{rec.fechaUltimaVisita}</td>
                    <td>{rec.activo == 0 ? 'Inactivo' : 'Activo'}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.CloseModal(false)
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null
}
