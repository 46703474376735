import React, { useEffect, useState } from "react"
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import IClientes from "../../Interfaces/Catalogos/IClientes"
import IRpArchivoElectronicoOficial from "../../Interfaces/Reportes/IRptArchivoElectronicoOficial"
import { VerDocumentos } from "../Reportes/customCells/verDocumentos"
import { MsgInformativo } from "../Utils/Toast/msgInformativo"
import { AgGridReact } from "ag-grid-react"
import { BsSearch, BsFileEarmarkExcel, BsCloudDownload } from "react-icons/bs"
import { RowClickedEvent } from "ag-grid-community"
import IArchivoElectronico from "../../Interfaces/IArchivoElectronico"
import * as XLSX from 'xlsx'
import DTOAEPeriodo from "../../DTO/DTOAEPeriodo"
import ClientesServices from "../../Services/Catalogos/Clientes.Services"
import reportesServices from "../../Services/Reportes/reportes.services"
import loadingImg from '../../images/ajaxloader.gif'
import ArchivoElectronicoServices from "../../Services/Catalogos/ArchivoElectronico.Services"

export const ReporteArchivoElectronico:React.FC = () => {
    //const dispatch = useDispatch()
    const [UserId, setUserId] = useState(() => {
        const stickyValue = window.localStorage.getItem('UserId')
        return stickyValue !== null ? JSON.parse(stickyValue) : 0
    })
    const [Years, setYears] = useState(generateArrayOfYears())
    const [Months, setMonths] = useState([
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ])
    const [Anio, setAnio] = useState(currentDate(1))
    const [Mes, setMes] = useState(currentDate(2))
    const [Clientes, setClientes] = useState<Array<IClientes>>()
    const [Data, setData] = useState<Array<IRpArchivoElectronicoOficial>>([])
    const [filteredData, setFilteredData] = useState<Array<IRpArchivoElectronicoOficial>>([])
    const [TipoOperacion, setTipoOperacion] = useState(0)
    const [Cliente, setCliente] = useState(0)
    const [msgColor, setMsgColor] = React.useState('primary')
    const [show, setShowMsg] = useState(false)
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [filtro, setFiltro] = useState('')
    const [showDialog, setShowDialog] = useState(false)
    const gridRef = React.useRef<any>(null)
    const [columnDefs] = useState([
        {
        field: 'referencia',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        sortable: true,
        filter: true,
        },
        { field: 'ver documentos', sortable: true, cellRenderer: (params: any) => {return <VerDocumentos referencia={params.data.referencia}/>} },
        { field: 'aduana', sortable: true, filter: true },
        { field: 'patente', sortable: true, filter: true },
        { field: 'pedimento', sortable: true, filter: true },
        { field: 'fechaPago', sortable: true, filter: true },
    ])

    const handleClose = () => setShowDialog(false)

    function generateArrayOfYears() {
        var max = new Date().getFullYear()
        var min = 2020
        var years = []
        for (var i = max; i >= min; i--) {
        years.push(i)
        }
        return years
    }

    function currentDate(mode: number): number {
        var today = new Date()
        var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
        var yyyy = today.getFullYear()
        return mode === 1 ? yyyy : parseInt(mm)
    }

    const generaReporte = () => {
        if(Anio === 0){
          setHeader('Error')
          setMsg('Especifique el año de búsqueda.')
          setShowMsg(true)
          return;
        }
        if(Mes === 0){
          setHeader('Error')
          setMsg('Especifique el mes de búsqueda.')
          setShowMsg(true)
          return;
        }
        if(Cliente===0 && Clientes!.length > 1){
          setHeader('Error')
          setMsg('Seleccione una razón social')
          setShowMsg(true)
          return;
        }
        const data: DTOAEPeriodo = {
          Anio: Anio,
          Mes: Mes,
          TipoOperacion: TipoOperacion,
          NoCliente: Cliente,
        }
        console.log(JSON.stringify(data))
        reportesServices
          .getRptArchivoElectronicoOficial(data)
          .then((response) => {
            setData(response.data)
            setFilteredData(response.data)
            console.log(response.data)
            setHeader('Informativo')
            setMsg('Se encontro la siguiente informacion...')
            setShowMsg(true)
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
      }
    
      const downloadExcel = () => {
        exportExcel(Data, 'Reporte archivo electronico oficial')
      }
    
      function exportExcel(jsonData: any[], fileName: string): void {
        let Heading = [['Referencia', 'Aduana', 'Patente', 'Pedimento', 'Fecha pago']]
        const wb = XLSX.utils.book_new()
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
        XLSX.utils.sheet_add_aoa(ws, Heading)
        XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, fileName + '.xlsx')
        var range = XLSX.utils.decode_range(ws['!ref?'])
        for (var C = range.s.c; C <= range.e.c; ++C) {
          var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
          if (!ws[address]) continue
          ws[address].v = ws[address].v.toUpperCase()
        }
      }
    
      const filtraReporte = (e: any) => {
        setFiltro(e.target.value)
        gridRef.current.api.setQuickFilter(e.target.value)
      }
    
      useEffect(() => {
        ClientesServices.getAllClientes(parseInt(UserId))
          .then((response) => {
            setClientes(response.data)
            if(response.data.length === 1)
              setCliente(response.data[0].sClave)
          })
          .catch((e: Error) => {
            console.log(e)
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
      }, [])
    
     /*  function getParams(e: RowClickedEvent) {
        const data: IArchivoElectronico = {
          Referencia: e.data.referencia,
          PedimentoLargo:
            Anio.toString().substring(2, 2) +
            ' ' +
            e.data.aduana.toString().substring(0, 2) +
            ' ' +
            e.data.patente.toString() +
            ' ' +
            e.data.pedimento.toString(),
          NoCliente: Cliente,
          IdUsuario: 0,
          Archivo: '',
        }
        console.log('.......................................................' + data)
        //dispatch(setArchivoElectronico(data))
      } */
    
    const downloadReferences = () => {
        setShowDialog(true)
        if (Cliente === 0) {
            setMsg('Seleccione el cliente')
            setHeader('Para poder continuar, favor de: ')
            setShowMsg(true)
        }
        let selectedNodes = gridRef.current.api.getSelectedNodes()
        let selectedData = selectedNodes.map((node: any) => node.data)
        const Referencias = selectedData.map((item: IRpArchivoElectronicoOficial) => item.referencia)
        const data = {
            Anio: Anio,
            Mes: Mes,
            NoCliente: Cliente,
            TipoOperacion: TipoOperacion,
            Referencias: Referencias,
        }
        console.log(data)
        ArchivoElectronicoServices.getZippedReferences(data)
            .then((response: any) => {
            const url = window.URL.createObjectURL(response.data)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Archivo Electronico Oficial.zip')
            document.body.appendChild(link)
            link.click()
            setShowDialog(false)
        })
        .catch((e: Error) => {
            console.log(e)
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
        })
    }

    return (
        <div>
        <Card>
          <Card.Body>
            <div className='row'>
              <div className='col-md-1'></div>
              <div className='col-md-1'>
                <Form.Select
                  aria-label='Año'
                  onChange={(e) => setAnio(parseInt(e.target.value))}
                  size='sm'
                  defaultValue={Anio}
                >
                  {Years.map((x, i) => (
                    <option value={x}>{x}</option>
                  ))}
                </Form.Select>
              </div>
              <div className='col-md-1'>
                <Form.Select
                  aria-label='Mes'
                  onChange={(e) => setMes(parseInt(e.target.value))}
                  size='sm'
                  defaultValue={Mes}
                >
                  <option value='0'>Mes</option>
                  {Months.map((x, i) => (
                    <option value={i + 1}>{x}</option>
                  ))}
                </Form.Select>
              </div>
              <div className='col-md-1 right-label'>
                <Form.Label>Tipo oper</Form.Label>
              </div>
              <div className='col-md-1'>
                <Form.Control
                  as='select'
                  onChange={(e) => setTipoOperacion(parseInt(e.target.value))}
                  className='form-select form-select-sm'
                >
                  <option value='0'>- Seleccione -</option>
                  <option value='1'>Importacion</option>
                  <option value='2'>Exportacion</option>
                </Form.Control>
              </div>
              <div className='col-md-1 right-label'>
                <Form.Label>Cliente</Form.Label>
              </div>
              <div className='col-md-4 form-group'>
                <Form.Control
                  as='select'
                  onChange={(e) => {
                    setCliente(parseInt(e.target.value))
                  }}
                  className='form-select form-select-sm'
                  value={Cliente}
                >
                  <option value='0'>-SELECCIONE-</option>
                  {Clientes
                    ? Clientes.map((c) => {
                        return c.agrupado === 1 ? <option value={c.sClave}>{c.sRazonSocial}</option> : ''
                      })
                    : null}
                </Form.Control>
              </div>
  
              <div className='col-md-1 right-label'>
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => {
                    generaReporte()
                  }}
                >
                  <BsSearch />
                  Buscar
                </Button>
              </div>
            </div>
            <div className='row' style={{ paddingTop: 5 }}>
              <div className='col'>&nbsp;</div>
              <div className='col-4'>
                <Form.Control
                  type='text'
                  placeholder='Search...'
                  size='sm'
                  onChange={(e) => {
                    filtraReporte(e)
                  }}
                />
              </div>
              <div className='col'>
                <Button
                  size='sm'
                  variant='success'
                  onClick={() => {
                    downloadExcel()
                  }}
                >
                  <BsFileEarmarkExcel />
                  &nbsp;Excel
                </Button>
                &nbsp; &nbsp;
                <Button
                  variant='danger'
                  size='sm'
                  onClick={() => {
                    downloadReferences()
                  }}
                >
                  <BsCloudDownload />
                  &nbsp;Descarga
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
        <br />
        <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
          <AgGridReact
            rowData={filteredData}
            columnDefs={columnDefs}
            pagination={true}
            paginationAutoPageSize={true}
            ref={gridRef}
            rowSelection={'multiple'}
            rowMultiSelectWithClick={true}
            onRowClicked={(e) => {/* getParams(e) */}}
          ></AgGridReact>
        </div>
        <MsgInformativo show={show} msg={msg} header={header} msgColor={msgColor} closeToast={() => setShowMsg(false)} />
        <Modal show={showDialog} onHide={handleClose} backdrop='static' keyboard={false} size='sm' centered>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={loadingImg} style={{ width: '50%', height: '50%' }} alt='proccessing' />
            Espere, por favor...
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    )
}