import http from "../common/http-common";
import ICorresponsalTrafico from "../../Interfaces/Corresponsales/ICorresponsalTrafico";
import ICorresponsalesRectificaciones from "../../Interfaces/Corresponsales/ICorresponsalesRectificaciones";
import ICorresponsalesCatTraficoEstatus from "../../Interfaces/Corresponsales/ICorresponsalesCatTraficoEstatus";
import DTOTraficoCompleto from "../../DTO/Corresponsales/DTOTraficoCompleto";
import IRespuesta from "../../Interfaces/IRespuesta";
import ICorresponsalRectificacionHistorico from "../../Interfaces/Corresponsales/ICorresponsalRectificacionHistorico";
import DTORectificacionHistorico from "../../DTO/Corresponsales/DTORectificacionHistorico";
import IReporteOperacionesNewell from "../../Interfaces/ReportesClientes/IReporteOperacionesNewell";

class Corresponsales_Trafico_DataService {
  GetAll(id: number) {
    return http.get<ICorresponsalTrafico>(`/Corresponsalias/Traficos/Get?id=${id}`);
  }

  GetByPedimento(pedimento: number) {
    return http.get<IReporteOperacionesNewell>(`/Corresponsalias/Traficos/GetByPedimento?pedimento=${pedimento}`);
  }

  Append(data: ICorresponsalTrafico) {
    return http.post<ICorresponsalTrafico>("/Corresponsalias/Traficos/Append", data);
  }
  GetRectificaciones(id: number) {
    return http.get<ICorresponsalesRectificaciones[]>(`/Corresponsalias/Traficos/GetRectificaciones?id=${id}`);
  }
  AddRectificacion(id: number) {
    return http.put<ICorresponsalesRectificaciones>(`/Corresponsalias/Traficos/AddRectificacion/${id}`);
  }
  GetCorresponsalesTraficoEstatus() {
    return http.get<ICorresponsalesCatTraficoEstatus[]>(`/Corresponsalias/Traficos/GetCorTraficoEstatus`);
  }
  ValidateComplete(data: DTOTraficoCompleto) {
    return http.put<IRespuesta>(`/Corresponsalias/Traficos/ValidateComplete/${data.id}`,data);
  }
  AppendRectificacionHistorico(data: DTORectificacionHistorico) {
    return http.post<ICorresponsalRectificacionHistorico>(`Corresponsalias/Traficos/Rectificacion/Historico/Append`,data);
  }
  GetRectificacionHistorico(IdTrafico: number) {
    return http.get<ICorresponsalRectificacionHistorico[]>(`Corresponsalias/Traficos/Rectificacion/Historico/Get?IdTrafico=${IdTrafico}`);
  }

  ValidaTraficoDuplicado(aduana: string | undefined, patente: number | undefined, pedimento:number | undefined, IdTrafico: number | undefined){
    return http.get<String>(`Corresponsalias/Traficos/Duplicado?aduana=${aduana}&patente=${patente}&pedimento=${pedimento}&idTrafico=${IdTrafico}`)
  }
  AppendFacturasContenedoresAlen(idTrafico:number, fileId:number){
    return http.post(`/Corresponsalias/Traficos/Append/LayoutAlen?idTrafico=${idTrafico}&fileId=${fileId}`);
  }
}
export default new Corresponsales_Trafico_DataService();