import React, { FC, useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsCashCoin } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import DashboardFacturacionDataService from '../../Services/Dashboard/Dashboard.Facturacion.Service'
import DashboardContaDataService from '../../Services/Dashboard/Dashboard.Contabilidad.Service'
import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import { FaReply } from 'react-icons/fa'
import { FcSurvey } from 'react-icons/fc'
import DashboardCorresponsales from './DashboardCorresponsales'
import { MdTimer } from 'react-icons/md'

interface IProps {
  Depto: string;
  Perfil: string;
}

export const DashboardFacturacion: FC<IProps> = (props) => {
  const [labels, setlabels] = useState<string[]>([]);
  const [series, setseries] = useState<number[]>([]);
  const [RechazosContabilidad, setRechazosContabilidad] = useState(0);
  const [SolicitudesReapertura, setSolicitudesReapertura] = useState(0);
  const navigate = useNavigate();
  const [ChartOptions, setChartOptions] = useState<ApexOptions>({
    labels: [
      '(2) Rechazos para el corresponsal',
      ' (0) Rechazados x Contabilidad',
      '(10) Pendientes por terminar',
    ],
    colors: ['#EB984E', '#E52626', '#ABEB4E', '#2288D3'],
    legend: { position: 'bottom' },
    /*plotOptions: {
      pie: {
        donut: {
          size: '55%',
        },
      },
    },*/
  });

  useEffect(() => {
    DashboardContaDataService.getTotalRechazosContabilidad().then((response) => {
      setRechazosContabilidad(response.data.total)
    }).catch((e: Error) => {
      return;
    })
    DashboardFacturacionDataService.getSolicitudesReapertura().then((response) => {
      setSolicitudesReapertura(response.data.total)
    }).catch((e: Error) => {return;})
    DashboardFacturacionDataService.getFacturacion()
      .then((response) => {
        console.log(response.data);
        let labels: string[] = [];
        let series: number[] = [];
        response.data.forEach((element) => {
          if (element.descripcion.indexOf('Total') === -1) {
            labels.push('(' + element.total + ') : ' + element.descripcion);
            series.push(element.total);
          }
        });
        setlabels(labels);
        setseries(series);

        setChartOptions({
          labels: labels,
          colors: ['#2288D3', '#E52626', ],
          legend: { position: 'bottom' },
          plotOptions: {
            pie: {
              donut: {
                size: '0%',
                labels: {
                  show: false,
                  total: {
                    showAlways: false,
                    show: false,
                    fontSize: '15px',
                  },
                  value: {
                    show: false,
                    fontSize: '43px',
                    fontWeight: 'bold',
                  },
                },
              },
            },
          },
          chart: {
            events: {
              legendClick: function(chartContext, seriesIndex, config) {
                switch(seriesIndex){
                  case 0:
                    navigate('../RptCorresponsalesTraficos/proc=3/modo=1');
                    break;
                  case 1:
                    navigate('../RptCorresponsalesTraficos/proc=3/modo=2');
                    break;
                }
              }
            }
          }
        });
      })
      .catch((e: Error) => {
        return;
      });
  }, [])

  return (
    <>
      {
       props.Depto === 'Facturacion' ?  <DashboardCorresponsales Perfil={props.Perfil}/> : ""
      }
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header><b>Facturación:</b> Tráficos Pendientes</Card.Header>
          <Card.Body>
            {labels ? (
                <ReactApexChart
                  type='donut'
                  options={ChartOptions}
                  series={series}
                  width={250}
                  height={290}
                />
              ) : (
                ''
            )}
          </Card.Body>
          <Card.Footer style={{ paddingRight: '5px' }}>
            <Link
              to='../RptCorresponsalesTraficos/proc=3/modo=3'
              style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
            >
              Ver mas...
            </Link>
          </Card.Footer>
        </Card>
      </Col>
      {props.Depto === 'Facturacion' ?
        <Col>
          <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
            <Card.Header>Contabilidad </Card.Header>
            <Card.Body style={{ paddingBottom: '50px' }}>
              <Card.Title>&nbsp;Rechazos para corresponsalias<br/><br/></Card.Title>
              <div style={{ fontSize: '6em' }} className='text-center'>
                <IconContext.Provider value={{ color: '#ff0018' }}>
                  <FcSurvey style={{fontSize:'larger'}}/>
                  <FaReply style={{fontSize:'50px', margin:'0 0 0 -4rem', position:"absolute", bottom: '6.5rem'}}/>
                </IconContext.Provider>
                {RechazosContabilidad}
              </div>
            </Card.Body>
            <Card.Footer style={{ paddingRight: '5px' }}>
              <Link
                to='../RptCorresponsalesTraficos/proc=2/modo=4'
                style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
              >
                Ver mas...
              </Link>
            </Card.Footer>
          </Card>
        </Col> : ""
      }
      {props.Perfil === 'Sup. Facturacion' ?
        <Col>
          <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
            <Card.Header>Facturación </Card.Header>
            <Card.Body style={{ paddingBottom: '50px' }}>
              <Card.Title>Solicitudes de reapertura de cuenta<br/><br/></Card.Title>
              <div style={{ fontSize: '6em' }} className='text-center'>
                <IconContext.Provider value={{ color: '#ff671b' }}>
                  <FcSurvey style={{fontSize:'larger'}}/>
                  <MdTimer style={{fontSize:'70px', margin:'0 0 0 -4rem', position:"absolute", bottom: '6rem'}}/>
                </IconContext.Provider>
                {SolicitudesReapertura}
            </div>
            </Card.Body>
            <Card.Footer style={{ paddingRight: '5px' }}>
              <Link
                to='../RptCorresponsalesTraficos/proc=3/modo=4'
                style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
              >
                Ver mas...
              </Link>
            </Card.Footer>
          </Card>
        </Col> : ""
      }
    </>
  )
}
