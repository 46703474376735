import { configureStore } from '@reduxjs/toolkit'
import userStatusReducer from './features/userStatusSlice/userStatusSlice'
import ArchivoElectronicoSlice  from './features/Reportes/ArchivoElectronico'
import CatProveedoresSlice from './features/CatProveedores/CatProveedoresSlice'
import CatCorresponsalesSlice from './features/CatCorresponsales/CatCorresponsalesSlice'
import CorresponsalesFacturasSlice from './features/Corresponsales/CorresponsalesFacturasSlice'
import CorresponsalesFacturasTercerosSlice from './features/Corresponsales/CorresponsalesFacturasTercerosSlice'
import CorresponsalesContenedoresSlice from './features/Corresponsales/CorresponsalesContenedoresSlice'
import CorresponsalesAnticipos from './features/Corresponsales/CorresponsalesAnticiposSlice'
import CorresponsalesGuiasSlice from './features/Corresponsales/CorresponsalesGuiasSlice'
import CorresponsalesPartidasSlice from './features/Corresponsales/CorresponsalesPartidasSlice'
import CorresponsalesCuentasComplementariasEstatusSlice from './features/Corresponsales/CorresponsalesCuentasComplementariasEstatusSlice'
import PedimentosConsolidadosSlice from './features/Corresponsales/CorresponsalesPedimentosConsolidadosSlice'
import RectificacionesPedimentosSlice from './features/Corresponsales/CorresponsalesPedimentosRectificacionesSlice'
import CatTiposContenedoresSlice from './features/CatTiposContenedores/CatTiposContenedoresSlice'


export const store = configureStore({
  reducer: {
    userStatus: userStatusReducer,
    ArchivoElectronico: ArchivoElectronicoSlice,
    CatProveedores: CatProveedoresSlice,
    CatCorresponsales: CatCorresponsalesSlice,
    CFData: CorresponsalesFacturasSlice,
    CF3rosData: CorresponsalesFacturasTercerosSlice,
    CCData : CorresponsalesContenedoresSlice,
    CAnticipos: CorresponsalesAnticipos,
    CGuias: CorresponsalesGuiasSlice,
    CPartidas: CorresponsalesPartidasSlice,
    CCueCompEstatus : CorresponsalesCuentasComplementariasEstatusSlice,
    CPedimentosConsolidados: PedimentosConsolidadosSlice,
    CRectificaciones: RectificacionesPedimentosSlice,
    CTiposContenedores : CatTiposContenedoresSlice
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch