import { useState } from "react";
import { Button, Card, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap"
import CurrencyFormat from "react-currency-format";
import { IconContext } from "react-icons";
import { FaEraser } from "react-icons/fa";
import CTrafDataService from "../../../Services/Corresponsalias/Corresponsales.Trafico.Services"
import { useDispatch, useSelector } from "react-redux";
import { ICatClavesPedimentos } from "../../../Interfaces/Catalogos/ICatClavesPedimentos";
import { DTOPedimentosConsolidados } from "../../../DTO/Corresponsales/DTOPedimentosConsolidados";
import DTORectificacionHistorico from "../../../DTO/Corresponsales/DTORectificacionHistorico";
import { updatePedimento } from "../../../store/features/Corresponsales/CorresponsalesPedimentosConsolidadosSlice";
import { addRectificacion } from "../../../store/features/Corresponsales/CorresponsalesPedimentosRectificacionesSlice";
import ICorresponsalRectificacionHistorico from "../../../Interfaces/Corresponsales/ICorresponsalRectificacionHistorico";
import { RootState } from "../../../store/store";
import { HistorialRectificaciones } from "./HistorialRectificaciones";
import { MsgInformativo } from "../../Utils/Toast/msgInformativo";

interface IProps {
    IdTrafico: number
    Aduana: string
    Patente: number
    ClavesPedimento: ICatClavesPedimentos[]
    Pedimentos: DTOPedimentosConsolidados[]
    Depto: string
    UserId: number
    onAppendRectificacion: (data: DTORectificacionHistorico) => void
}

export const NuevaRectificacion:React.FC<IProps> = (props) => {
    const dispatch = useDispatch()
    const [header, setHeader] = useState('')
    const [show, setShowMsg] = useState(false)
    const [msg, setMsg] = useState('')
    const msgColor = 'primary'

    const [showHistorial, setShowHistorial] = useState(false)

    const [Id, setId] = useState(0)
    const [Aduana, setAduana] = useState(props.Aduana)
    const [Patente, setPatente] = useState(props.Patente)
    const [Pedimento, setPedimento] = useState(0)
    const [ClavePedimento, setClavePedimento] = useState('');
    const [FechaPago, setFechaPago] = useState('')

    const [IdPedimentoH, setIdPedimentoH] = useState(0)
    const [AduanaH, setAduanaH] = useState(props.Aduana)
    const [PatenteH, setPatenteH] = useState(props.Patente)
    const [PedimentoH, setPedimentoH] = useState(0)
    const [ClavePedimentoH, setClavePedimentoH] = useState('')
    const [FechaPagoH, setFechaPagoH] = useState('')

    const [IdPedimentoConsolidadoSelected, setIdPedimentoConsolidadoSelected] = useState("#")

    function setDate(fecha: string | undefined): string {
        if (!fecha) return ''
        else {
          var dd = fecha?.substring(8, 10)
          var mm = fecha?.substring(5, 7)
          var yyyy = fecha?.substring(0, 4)
          return yyyy + '-' + mm + '-' + dd
        }
    }

    const generaRectificacion = () => {
        if (Aduana && Patente && Pedimento && ClavePedimento.length>0 && FechaPago) {
          const data : DTORectificacionHistorico = {
            IdTrafico: props.IdTrafico,
            IdUsuario: props.UserId,
            IdPedimento: IdPedimentoH,
            Aduana: Aduana,//Aduana de la rectificacion
            Patente: Patente,//Patente de la rectificacion
            Pedimento: Pedimento,//Pedimento de la rectificacion
            Clave: ClavePedimento,//Clave del pedimento de la rectificacion
            FechaPago: FechaPago,//Fecha de pago del pedimento de la rectificacion
            AduanaH: AduanaH,//Aduana del pedimento original
            PatenteH: PatenteH,//Patente del pedimento original
            PedimentoH: PedimentoH,//Pedimento original
            ClaveH: ClavePedimentoH,//Clave del pedimento original
            FechaPagoH: FechaPagoH//Fecha de pago del pedimento original
          }
          CTrafDataService.AppendRectificacionHistorico(data)
          .then((response) => {
            setHeader('Informativo')
            setMsg('La rectificación se creó correctamente')
            setShowMsg(true)
            dispatch(//Se actualizan los datos del pedimento seleccionado(original) con los datos de la rectificacion recien capturada
                updatePedimento({
                    id: data.IdPedimento,
                    idTrafico: data.IdTrafico,
                    aduana: data.Aduana,
                    patente: data.Patente,
                    pedimento: data.Pedimento,
                    clave: data.Clave,
                    fechaPago: data.FechaPago
                })
            )
            dispatch(//Los datos del pedimento original se guardan para mantener un historial de rectificaciones
                addRectificacion({
                    id: response.data.id,
                    usuario: response.data.usuario,
                    idTrafico: response.data.idTrafico,
                    aduana: response.data.aduana,
                    patente: response.data.patente,
                    pedimento: response.data.pedimento,
                    clave: response.data.clave,
                    fechaPago: response.data.fechaPago,
                    fhCreacion: response.data.fhCreacion,
                    activo: response.data.activo,
                    idPedimentoConsolidado: response.data.idPedimentoConsolidado
                })
            )
            cleanForm()
            props.onAppendRectificacion(data)//Al cerrar se pasan los datos a la vista del trafico para actualizar los campos
            return
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrió un error al crear la rectificación. Favor de actualizar la página e intentarlo nuevamente.')
            setShowMsg(true)
            return
          })
        } else {
          setHeader('Error')
          setMsg('Parea poder generar este proceso es necesario que los siguientes campos tengan informacion: Patente, Aduana, Pedimento, Clave, Fecha pago')
          setShowMsg(true)
          return
        }
      }

    const PrepareRectificacion = (idPedimentoConsolidado: number) => {
        var dto = props.Pedimentos.filter(x => x.id === idPedimentoConsolidado)[0]
        if(!dto.aduana){
            setHeader('Error')
            setMsg('Asegurese de capturar la aduana del pedimento')
            setShowMsg(true)
            setIdPedimentoConsolidadoSelected('#')
            return
        }
        if(dto.patente === 0){
            setHeader('Error')
            setMsg('Asegurese de capturar la patente del pedimento')
            setShowMsg(true)
            setIdPedimentoConsolidadoSelected('#')
            return
        }
        if(!dto.pedimento){
            setHeader('Error')
            setMsg('Asegurese de capturar el numero de pedimento')
            setShowMsg(true)
            setIdPedimentoConsolidadoSelected('#')
            return
        }
        if(!dto.clave){
            setHeader('Error')
            setMsg('Asegurese de capturar la clave del pedimento')
            setShowMsg(true)
            setIdPedimentoConsolidadoSelected('#')
            return
        }
        if(!dto.fechaPago){
            setHeader('Error')
            setMsg('Asegurese de capturar la fecha de pago del pedimento')
            setShowMsg(true)
            setIdPedimentoConsolidadoSelected('#')
            return
        }
        setIdPedimentoH(dto.id)
        setAduanaH(dto.aduana)
        setPatenteH(dto.patente)
        setPedimentoH(dto.pedimento)
        setClavePedimentoH(dto.clave)
        setFechaPagoH(dto.fechaPago)
        setShowHistorial(true)
    }

    const CancelIsRectificacion = () => {
        setAduanaH('0')
        setPatenteH(0)
        setPedimentoH(0)
        setClavePedimentoH('')
        setFechaPagoH('')
        setIdPedimentoH(0)
    }

    const cleanForm = () => {
        setIdPedimentoConsolidadoSelected("#")
        setIdPedimentoH(0)
        setPedimentoH(0)
        setClavePedimentoH('')
        setFechaPagoH('')
        setId(0)
        setPedimento(0)
        setClavePedimento('')
        setFechaPago('')
    }


    return (
        <Card style={{height:'100%'}}>
            <Card.Body style={{overflowY:'scroll'}}>
                { props.Depto === 'Corresponsalias' ? <>
                <Row className="justify-content-end align-items-center">
                    <Col xs={1}><span>Original</span></Col>
                    <Col xs={1}>
                    </Col>
                    <Col xs={1}>
                        <FormLabel>
                            Aduana
                            <FormControl type='text' size='sm' disabled value={AduanaH}></FormControl>
                        </FormLabel>
                    </Col>
                    <Col xs={1}>
                        <FormLabel>
                            Patente
                            <FormControl type='text' size='sm' disabled value={PatenteH}></FormControl>
                        </FormLabel>
                    </Col>
                    <Col xs={2}>
                        <FormLabel>
                            Pedimento
                            <Form.Control
                              as="select"
                              id="CmbCliente"
                              onChange={(e) => {
                                setIdPedimentoConsolidadoSelected(e.target.value)
                                PrepareRectificacion(+e.target.value)
                              }}
                              value={IdPedimentoConsolidadoSelected}
                              className="form-select form-select-sm"
                            >
                              <option value="#">-SELECCIONE-</option>
                              {props.Pedimentos
                                ? props.Pedimentos.map((p) => {
                                    return (
                                      <option key={p.id} value={p.id}>
                                        {p.pedimento}
                                      </option>
                                    )
                                  })
                                : null}
                            </Form.Control>
                        </FormLabel>
                    </Col>
                    <Col xs={2}>
                        <Form.Label>
                            Clave
                            <FormControl type='text' size='sm' disabled value={ClavePedimentoH}></FormControl>
                        </Form.Label>
                    </Col>
                    <Col xs={2}>
                        <Form.Label>
                            Fec. Pago
                            <Form.Control
                                type="date"
                                id="FechaPago"
                                size="sm"
                                value={setDate(FechaPagoH)}
                                readOnly={true}
                                disabled={true}
                            />
                        </Form.Label>
                    </Col>
                    <Col xs={2}>
                        <Button onClick={() => generaRectificacion()}>Agregar</Button>
                    </Col>
                </Row>
                <Row className="justify-content-end align-items-center">
                    <Col xs={1}><span style={{wordWrap:'normal'}}>Rectificación</span></Col>
                    <Col
                        xs={1}
                        style={{
                            textAlign: 'right',
                            paddingTop: '5px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            cleanForm();
                        }}
                    >
                        <IconContext.Provider value={{ color: 'orange', size: '25px' }}>
                        <FaEraser />
                        </IconContext.Provider>
                    </Col>
                    <Col xs={1}>
                        <FormLabel>
                            Aduana
                            <FormControl type='text' size='sm' disabled value={Aduana} onChange={(e) => setAduana(e.target.value)}></FormControl>
                        </FormLabel>
                    </Col>
                    <Col xs={1}>
                        <FormLabel>
                            Patente
                            <FormControl type='text' size='sm' disabled value={Patente} onChange={(e) => setPatente(+e.target.value)}></FormControl>
                        </FormLabel>
                    </Col>
                    <Col xs={2}>
                        <FormLabel>
                            Pedimento
                            <CurrencyFormat
                            value={Pedimento}
                            displayType='input'
                            format={'### ####'}
                            mask="_"
                            onValueChange={(values: any) => {
                                const { value } = values
                                if(value.length === 7 ){
                                CTrafDataService.ValidaTraficoDuplicado(Aduana, Patente, value, props.IdTrafico).then( response => {
                                    if(response.data !== ""){
                                        setHeader('Informativo')
                                        setMsg(`Pedimento Duplicado.\n\rLa referencia ${response.data} ya cuenta con los mismos datos de aduana, patente y pedimento`)
                                        setShowMsg(true)
                                        return
                                    }
                                    setPedimento(value.length === 0 ? 0 : value)
                                })
                                }
                            }}
                            style={{
                                fontSize: '18px',
                                backgroundColor: '#FEFDF5',
                                border: '2px solid #837F5D',
                                width: '100%',
                                textAlign: 'right',
                                borderRadius: '10px'
                            }}
                            />
                        </FormLabel>
                    </Col>
                    <Col xs={2}>
                        <Form.Label>
                            Clave
                            <Form.Control
                                as="select"
                                id="CmbClavePedimento"
                                onChange={(e) => {
                                    setClavePedimento(e.target.value)
                                }}
                                className="form-select form-select-sm"
                                value={ClavePedimento}
                            >
                                <option value="-Seleccione-">-Seleccione-</option>
                                {props.ClavesPedimento
                                    ? props.ClavesPedimento.map((item, index) => {
                                        return (
                                        <option key={item.id} value={item.clave}>
                                            {item.clave}
                                        </option>
                                        )
                                    })
                                : ''}
                            </Form.Control> 
                        </Form.Label>
                    </Col>
                    <Col xs={2}>
                        <Form.Label>
                            Fec. Pago
                            <Form.Control
                                type="date"
                                id="FechaPago"
                                size="sm"
                                value={FechaPago}
                                onChange={(e) => setFechaPago(e.target.value)}
                            />
                        </Form.Label>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
                <hr/> </>: '' }
                    <HistorialRectificaciones IdPedimentoConsolidado={IdPedimentoConsolidadoSelected} ></HistorialRectificaciones>
                <MsgInformativo
                    show={show}
                    msg={msg}
                    header={header}
                    msgColor={msgColor}
                    closeToast={() => {
                        setShowMsg(false)
                    }}
                />
            </Card.Body>
        </Card>
    )
}