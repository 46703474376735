import http from "../common/http-common";
import ICorresponsalPedimento from "../../Interfaces/Corresponsales/ICorresponsalPedimento";

class CorresponsalesDataService {

  Append(data: ICorresponsalPedimento) {
    return http.post<ICorresponsalPedimento>("/Corresponsalias/Pedimento/Append", data);
  }
  Get(idTrafico: number) {
    return http.get<ICorresponsalPedimento>(`/Corresponsalias/Pedimento/Get?IdTrafico=${idTrafico}`);
  }
  Delete(id: number) {
    return http.delete<ICorresponsalPedimento[]>(`/Corresponsalias/Pedimento/Delete/${id}`);
  }

}
export default new CorresponsalesDataService();