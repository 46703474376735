import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import ICatCorresponsales from '../../../../Interfaces/Catalogos/ICatCorresponsales'
import CorDataService from '../../../../Services/Catalogos/Corresponsales.Services'
import { updateCatCorresponsales } from '../../../../store/features/CatCorresponsales/CatCorresponsalesSlice'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'

interface IProps {
  record: ICatCorresponsales
}

export const ControlledInput: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const [IDCorresponsal, setIDCorresponsal] = useState(props.record.id)
  const [Corresponsal, setCorresponsal] = useState(props.record.nombre)
  const [Patente, setPatente] = useState(props.record.patente)
  const [Aduana, setAduana] = useState(props.record.aduana)
  const [Correos, setCorreos] = useState(props.record.correos)
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')

  const onChangeCor = (event: any) => {
    setCorresponsal(event.target.value)
  }

  const onChangeAd = (event: any) => {
    setAduana(event.target.value)
  }
  const onChangePat = (event: any) => {
    setPatente(event.target.value)
  }
  const onChangeEmail = (event: any) => {
    setCorreos(event.target.value)
  }

  const handleKeyDown = (event: any, mode: number) => {
    if (event.key === 'Enter') {
      if (isNaN(event.target.value) && mode === 1) {
        alert('Valor no valido!')
        return
      }
      const data: ICatCorresponsales = {
        id: props.record.id,
        nombre: Corresponsal,
        patente: Patente,
        aduana: Aduana,
        correos: Correos,
      }
      CorDataService.Append(data)
        .then((response) => {
          if (response.status == 200) {
            dispatch(updateCatCorresponsales(response.data))
            setHeader('Informativo')
            setMsg('La informacion se guardo exitosamente')
            setShowMsg(true)
            return
          }
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  }

  return (
    <div>
      <input
        value={Corresponsal}
        onChange={onChangeCor}
        disabled={false}
        onKeyDown={(e) => handleKeyDown(e, 2)}
        style={{ width: '450px', textAlign: 'left' }}
      />
      &nbsp; &nbsp; &nbsp;
      <input
        value={Patente}
        onChange={onChangePat}
        disabled={false}
        onKeyDown={(e) => handleKeyDown(e, 1)}
        style={{ width: '50px', textAlign: 'right' }}
      />
      &nbsp; &nbsp; &nbsp;
      <input
        value={Aduana}
        onChange={onChangeAd}
        disabled={false}
        onKeyDown={(e) => handleKeyDown(e, 1)}
        style={{ width: '30px', textAlign: 'right' }}
      />
      &nbsp; &nbsp; &nbsp;
      <input
        value={Correos}
        onChange={onChangeEmail}
        disabled={false}
        onKeyDown={(e) => handleKeyDown(e, 2)}
        style={{ width: '450px', textAlign: 'left' }}
      />
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
