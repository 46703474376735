import React, { useRef } from "react";
import { BsFileEarmarkText } from "react-icons/bs";
import { Link } from "react-router-dom";

export const SideBarMenu: React.FC = () => {
    const menuItemRef = useRef<HTMLLIElement>(null);

    const menuItemHover = () => {
        menuItemRef.current!.style.backgroundColor = "#4f4f4f";
    }

    const menuItemLeave = () => {
        menuItemRef.current!.style.backgroundColor = "#2d343c";
    }
    return (
        <>
            <div className="col-auto col-md-3 col-xl-2 p-0" style={{backgroundColor:'#2d343c', borderRadius:'10px', width:'15%'}}>
                <div className="d-flex flex-column align-items-center align-items-sm-start px-0 pt-4 text-white">
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100" id="menu">
                        <li className="nav-item p-2 w-100" onMouseEnter={() => menuItemHover()} onMouseLeave={() => menuItemLeave()} ref={menuItemRef} style={{textAlign:'center'}}>
                            <Link to="/Clientes/ReportesEmbarques" className="nav-link align-middle px-0 text-white">
                                <BsFileEarmarkText/>
                                <span className="ms-1 d-none d-sm-inline">Reporte de Embarques</span>
                            </Link>
                        </li>                                
                    </ul>
                </div>
            </div>            
        </>  
    );
}