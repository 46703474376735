import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICorresponsalesGuias from '../../../Interfaces/Corresponsales/ICorresponsalesGuias';
const CorresponsalesGuias: ICorresponsalesGuias[] = [{id:0, guia:'-Seleccione-', idTrafico:0}]
const initialState = { CorresponsalesGuias }

export const CorresponsalesGuiasSlice = createSlice({
  name: 'CorresponsalesGuias',
  initialState: initialState,
  reducers: {
    populateCorresponsalesGuias : (state, action: PayloadAction<ICorresponsalesGuias[]>) => {
      state.CorresponsalesGuias=[{id:0, guia:'-Seleccione-', idTrafico:0}]
      state.CorresponsalesGuias.push(... action.payload)
    },
    updateCorresponsalesGuias : (state, action: PayloadAction<ICorresponsalesGuias>) => {
      const i = state.CorresponsalesGuias.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.CorresponsalesGuias[i] = action.payload;
      else state.CorresponsalesGuias.push(action.payload);
    },
    deleteCorresponsalesGuias : (state, action: PayloadAction<number>) => {
      const newArr = state.CorresponsalesGuias.filter(data => data.id != action.payload);
      state.CorresponsalesGuias=newArr
    },
    InitCorresponsalesGuias : (state, action: PayloadAction<number>) => {
      state.CorresponsalesGuias=[{id:0, guia:'-Seleccione-', idTrafico:0}]
    },
  },
})

export const { 
  populateCorresponsalesGuias, 
  updateCorresponsalesGuias, 
  deleteCorresponsalesGuias,
  InitCorresponsalesGuias } = CorresponsalesGuiasSlice.actions;
export default CorresponsalesGuiasSlice.reducer;
