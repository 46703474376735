import * as React from 'react'
import { Formik, FormikHelpers, FormikProps, Form, Field, useField, FieldProps, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { IregistroPermisoClientes } from '../../Interfaces/registroPermisoClientes'
import '../../css/login.css'

interface IProps {}

const SignupSchema = Yup.object().shape({
  ModuloCliente: Yup.string().required('Requerido'),
  IoE: Yup.string().required('Requerido'),
})

export default function RegisterPermisoClientes(props: IProps) {
  const initialValues: IregistroPermisoClientes = {
    id: 1,
    IdUsuario: 1,
    ModuloCliente: 0,
    IoE: 0,
    Aduana: 0,
    Patente: 0,
  }

  const validateForm = (values: IregistroPermisoClientes) => {
    const errors = { ModuloCliente: '', IoE: '', Aduana: '', Patente: '' }
    //   console.log(errors);
    if (!values.ModuloCliente) {
      errors.ModuloCliente = 'Campo requerido'
    } else if (values.ModuloCliente == 0) {
      errors.ModuloCliente = 'Debe seleccionar el modulo'
    }
    if (!values.IoE) {
      errors.IoE = 'Campo requerido'
    } else if (values.IoE == 0) {
      errors.IoE = 'Debe seleccionar si es Importacion e Exportacion'
    }
    return errors
  }

  return (
    <div id='login'>
      <div className='container'>
        <div id='login-row' className='row justify-content-center align-items-center'>
          <div id='login-column' className='col-md-4'>
            <div id='login-box' className='col-md-12'>
              <Formik
                initialValues={initialValues}
                validationSchema={SignupSchema}
                onSubmit={(values) => {
                  console.log(values)
                }}
                validate={validateForm}
              >
                {(formik) => {
                  const { errors, touched, isValid, dirty, handleSubmit } = formik
                  return (
                    <Form id='login-form' className='form' onSubmit={handleSubmit}>
                      <h3 className='text-center text-info'>Permisos</h3>
                      <div className='form-group'>
                        <label className='text-info' htmlFor='ID'>
                          ID
                        </label>
                        <Field id='ID' name='ID' className='form-control' placeholder='' disabled='disabled' />
                      </div>
                      <div className='form-group'>
                        <label className='text-info' htmlFor='IdUsuario'>
                          ID Usuario
                        </label>
                        <Field
                          id='IdUsuario'
                          name='IdUsuario'
                          className='form-control'
                          placeholder=''
                          disabled='disabled'
                        />
                      </div>
                      <div className='form-group'>
                        <label className='text-info' htmlFor='ModuloCliente'>
                          Modulo cliente
                        </label>
                        <Field id='ModuloCliente' name='ModuloCliente' className='form-control' placeholder='0' />
                        {errors.ModuloCliente && touched.ModuloCliente ? (
                          <div>
                            {' '}
                            <span style={{ color: '#FF3346' }}> * {errors.ModuloCliente} </span>{' '}
                          </div>
                        ) : null}
                      </div>
                      <div className='form-group'>
                        <label className='text-info' htmlFor='IoE'>
                          Importacion / Exportacion
                        </label>
                        <Field id='IoE' name='IoE' className='form-control' placeholder='IoE' />
                        {errors.IoE && touched.IoE ? (
                          <div>
                            {' '}
                            <span style={{ color: '#FF3346' }}> * {errors.IoE} </span>{' '}
                          </div>
                        ) : null}
                      </div>
                      <div className='form-group'>
                        <label className='text-info' htmlFor='Aduana'>
                          Aduana
                        </label>
                        <Field id='Aduana' name='Aduana' className='form-control' placeholder='Aduana' />
                      </div>
                      <div className='form-group'>
                        <label className='text-info' htmlFor='Patente'>
                          Patente
                        </label>
                        <Field id='Patente' name='Patente' className='form-control' placeholder='Patente' />
                      </div>
                      <br />
                      <button type='submit' className='btn btn-primary btn-lg btn-block'>
                        login
                      </button>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
