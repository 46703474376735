import { Alert, Col, FormControl, Row, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { useEffect, useState } from "react"
import FormCheckInput from "react-bootstrap/esm/FormCheckInput"
import CorresponsalesFacturasServices from "../../../Services/Corresponsalias/Corresponsales.Facturas.Services"
import ICorresponsalFacturas from "../../../Interfaces/Corresponsales/ICorresponsalFacturas"
import { updateCorresponsalesFacturas } from "../../../store/features/Corresponsales/CorresponsalesFacturasSlice"
import { MsgInformativo } from "../../Utils/Toast/msgInformativo"

interface IProps{
    IDTrafico: number
    IdContenedor: number
    Contenedor: string
}
export const FacturasContenedor: React.FC<IProps> = (props) => {
    const mCFacturas = useSelector(
        (state: RootState) => state.CFData.CorresponsalesFacturas
    )
    const dispatch = useDispatch()
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [show, setShowMsg] = useState(false)
    const [msgColor, setMsgColor] = useState('primary')

    const AppendContenedor = (e: any) => {
        var data = mCFacturas.filter(x => x.id === +e.target.value)[0]
        var factura: ICorresponsalFacturas = {
            id: data.id,
            factura: data.factura,
            idTrafico: data.idTrafico,
            valorFacturaDls: data.valorFacturaDls,
            proveedor: data.proveedor,
            pedido: data.pedido,
            code: data.code,
            folioGEMCO: data.folioGEMCO,
            corresponsal: data.corresponsal,
            activo: data.activo,
            fechaFactura: data.fechaFactura,
            uuid: data.uuid,
            cove: data.cove,
            entrega: data.entrega,
            transporte: data.transporte,
            idContenedor: e.currentTarget.checked ? props.IdContenedor : 0
        }
        CorresponsalesFacturasServices.Append(factura)
        .then((resp:any) => {
            dispatch(updateCorresponsalesFacturas(factura));
            setHeader('Informativo')
            setMsg('Factura asignada correctamente')
            setShowMsg(true)
            return
        })
        .catch(() => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
        })
    }
    return (
        <>
        <Alert variant='primary' style={{height:'100%', overflowY: 'hidden'}}>
            <Row>
                <Col xs={12}>
                    <b>Agregar facturas al contenedor {props.Contenedor}</b>
                </Col>
            </Row>
            <div className="table-wrapper" style={{width:'100%', maxHeight:'450px'}}>
                <Table
                    className="table table-earnings table-earnings__challenge"
                    striped
                    bordered
                    hover
                >
                    <thead>
                        <tr><th></th><th>Factura</th></tr>
                    </thead>
                    <tbody>
                        {mCFacturas
                            ? mCFacturas.map((item, index) => {
                                return item.idTrafico === props.IDTrafico ? (
                                <tr>
                                    <td>
                                        <FormCheckInput 
                                            onChange={(event:any) => {
                                                    AppendContenedor(event)
                                            }}
                                            value={item.id}
                                            disabled = {item.idContenedor !== 0 && item.idContenedor !== props.IdContenedor}
                                            defaultChecked = {item.idContenedor === props.IdContenedor}
                                        />
                                    </td>
                                    <td>
                                        {item.factura}
                                    </td>
                                    </tr>
                                ) : (
                                ''
                                )
                            })
                        : ''}
                    </tbody>
                </Table>
            </div>
        </Alert>
        <MsgInformativo
            show={show}
            msg={msg}
            header={header}
            msgColor={msgColor}
            closeToast={() => {
            setShowMsg(false)
            }}
        />
        </>
    )
}