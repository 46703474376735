import React, { FC, useEffect, useState } from 'react'
import MFileManagerDS from '../../../Services/Utils/MFileManager.Service'
import IFileManager from '../../../Interfaces/Utils/IFileManager'
import { Alert, Button, Card, Col, ListGroup, Modal, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsFillXCircleFill } from 'react-icons/bs'
import { MsgInformativo } from '../Toast/msgInformativo'
import { TargetURL } from '../../../Constants/TargetURL'

interface IProps {
  IDTrafico: number
  Proceso: number
  Leyenda?: string
  showPreview: number
  canEdit: boolean
}

export const MFileManager: FC<IProps> = (props) => {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [IDTrafico, setIDTrafico] = useState<number>(props.IDTrafico ? props.IDTrafico : 0)
  const [Proceso, setProceso] = useState<number>(props.Proceso ? props.Proceso : 0)
  const [ListaArchivos, setListaArchivos] = useState<IFileManager[]>()
  const [NombreArchivo, setNombreArchivo] = useState('')
  const [MsgDialogDelete, setMsgDialogDelete] = useState(false)
  const [IDArchivo, setIDArchivo] = useState(0)
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const URL = new TargetURL()
  const msgColor = 'primary'

  const selectedFiles = (selectorFiles: any) => {
    var formData = new FormData()
    for (let i = 0; i < selectorFiles.files.length; i++) {
      formData.append('FileList', selectorFiles.files[i])
    }
    MFileManagerDS.Append(formData, IDTrafico, Proceso, UserId)
      .then((response) => {
        setListaArchivos(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error: ' + e)
        return
      })
    return false
  }

  useEffect(() => {
    setIDTrafico(props.IDTrafico)
    setProceso(props.Proceso)
    MFileManagerDS.Get(props.IDTrafico, props.Proceso)
      .then((response) => {
        setListaArchivos(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error: ' + e)
        return
      })
  }, [])

  const confirmDelete = (row: IFileManager) => {
    setIDArchivo(row.id)
    setNombreArchivo(row.nombreArchivo)
    setMsgDialogDelete(true)
  }

  const deleteItem = () => {
    MFileManagerDS.DeleteFile(IDArchivo)
      .then((response) => {
        var arrArchivos = ListaArchivos!.filter(function (el) {
          return el.id !== IDArchivo
        })
        setListaArchivos(arrArchivos)
        setHeader('Informtivo')
        setMsg(response.data.respuesta)
        setMsgDialogDelete(false)
        setShowMsg(false)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error, no se elimino el archivo')
        setMsgDialogDelete(false)
        setShowMsg(false)
        return
      })
    return false
  }

  const getFileContent = (row: IFileManager) => {
    MFileManagerDS.getFileContentById(row.id, row.proceso)
      .then((response: any) => {
        if (response.status === 200) {
          if (row.nombreArchivo.toLowerCase().endsWith('.pdf')) {
            // console.log(response.data)
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else if (
            row.nombreArchivo.toLowerCase().endsWith('.png') ||
            row.nombreArchivo.toLowerCase().endsWith('.jpg')
          ) {
            const blob = new Blob([response.data], { type: 'image/png' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else if (row.nombreArchivo.toLowerCase().endsWith('.xlsx')) {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else if (
            row.nombreArchivo.toLowerCase().endsWith('.xls')) {
            const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', NombreArchivo ? NombreArchivo : 'Archivo.zip')
            document.body.appendChild(link)
            link.click()
          }
        } else {
          setHeader('Error')
          setMsg('A este concepto no se le ha anexado PDF')
          setShowMsg(true)
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A este concepto no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <br />
      <Card>
        <Card.Body>
          <Card.Title style={{ fontSize: '15px' }}>
            {props.Leyenda ? props.Leyenda : ''}&nbsp;&nbsp;&nbsp;
            {props.canEdit ? (
              <input type='file' multiple name='FileList' onChange={(e) => selectedFiles(e.target)} />
            ) : (
              ''
            )}
          </Card.Title>
          {ListaArchivos && props.showPreview === 1 ? (
            <div>
              <Row xs={1} md={3} className='g-1'>
                {ListaArchivos
                  ? ListaArchivos.map((rec) => {
                      return (
                        <Col key={rec.id}>
                          <Card style={{ width: '28rem', textAlign: 'center' }}>
                            <Card.Body>
                              <Row>
                                <Col xs={3}></Col>
                                <Col xs={5}>
                                  <img
                                    src={`${URL.get()}/Utils/MFileManager/GetFileContentById?id=${rec.id}&Proceso=${
                                      rec.proceso
                                    }`}
                                    width={150}
                                    height={200}
                                    alt={'Imagen'}
                                    onClick={() => {
                                      getFileContent(rec)
                                    }}
                                  />
                                </Col>
                                <Col
                                  xs={1}
                                  alt='De un click aqui para eliminar'
                                  style={{ visibility: props.canEdit ? 'visible' : 'hidden' }}
                                >
                                  <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                    <BsFillXCircleFill
                                      onClick={() => {
                                        confirmDelete(rec)
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </IconContext.Provider>
                                </Col>
                                <Col xs={3}></Col>
                              </Row>
                              <Row>
                                <Col xs={12}>&nbsp;</Col>
                              </Row>
                              <Row>
                                <Col xs={12}>{rec.nombreArchivo}</Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  : ''}
              </Row>
            </div>
          ) : ListaArchivos && props.showPreview === 2 ? (
            <div>
              <Row xs={1} md={3} className='g-1'>
                {ListaArchivos
                  ? ListaArchivos.map((rec) => {
                      return (
                        <Col key={rec.id}>
                          <Card style={{ width: '28rem', textAlign: 'center' }}>
                            <Card.Body>
                              <Alert variant='primary'>
                                <Row>
                                  <Col xs={11}>{rec.nombreArchivo}</Col>
                                  <Col
                                    xs={1}
                                    alt='De un click aqui para eliminar'
                                    style={{ visibility: props.canEdit ? 'visible' : 'hidden' }}
                                  >
                                    <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                      <BsFillXCircleFill
                                        onClick={() => {
                                          confirmDelete(rec)
                                        }}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </IconContext.Provider>
                                  </Col>
                                </Row>
                              </Alert>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  : ''}
              </Row>
            </div>
          ) : ListaArchivos && props.showPreview === 3 ? (
            <div>
              {ListaArchivos
                ? ListaArchivos.map((rec) => {
                    return (
                      <ListGroup style={{ width: '100%' }}>
                        <ListGroup.Item
                          key={rec.id}
                          style={{
                            paddingLeft: '5px',
                            backgroundColor: '#CFE2FF',
                            color: '#314EFA',
                          }}
                        >
                          <Row>
                            <Col>
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  getFileContent(rec)
                                }}
                              >
                                {rec.nombreArchivo}
                              </span>
                            </Col>
                            <Col xs={1} style={{ visibility: props.canEdit ? 'visible' : 'hidden' }}>
                              <span>
                                <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                  <BsFillXCircleFill
                                    onClick={() => {
                                      confirmDelete(rec)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </IconContext.Provider>
                              </span>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    )
                  })
                : ''}
            </div>
          ) : (
            ''
          )}
        </Card.Body>
      </Card>
      <Modal show={MsgDialogDelete} onHide={() => setMsgDialogDelete(false)} size='lg'>
        <Modal.Body>
          <h5>
            Favor de confirmar
            <Row>
              <Col xs={12}>&nbsp;</Col>
            </Row>
            <Alert variant='primary'>
              ¿Esta seguro de eliminar el archivo: <br />
              <br /> {NombreArchivo}?
            </Alert>
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={1}>
              <Button variant='secondary' onClick={() => setMsgDialogDelete(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
            <Col xs={5} style={{ paddingLeft: '550px', paddingRight: '0px' }}>
              &nbsp;
            </Col>
            <Col xs={1}>
              <Button variant='danger' onClick={() => deleteItem()} size='sm'>
                Eliminar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
