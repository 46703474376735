import http from "../common/http-common";
import ICorresponsalCatAduanas from "../../Interfaces/Corresponsales/ICorresponsalCatAduanas";

class CorresponsalesDataService {

  getAll(idCliente: number) {
    return http.get<ICorresponsalCatAduanas[]>(`/Corresponsalias/CatAduanas/GetAll?idCliente=${idCliente}`);
  }

}
export default new CorresponsalesDataService();