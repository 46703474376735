import http from "../common/http-common";
import ICorresponsalContenedores from "../../Interfaces/Corresponsales/ICorresponsalContenedores";
import IRespuesta from "../../Interfaces/IRespuesta";

class CorresponsalesDataService {
  getAll(idTrafico: number) {
    return http.get<ICorresponsalContenedores[]>(`/Corresponsalias/Contenedores/getAll?idTrafico=${idTrafico}`);
  }
  Append(data: ICorresponsalContenedores) {
    return http.post<ICorresponsalContenedores>("/Corresponsalias/Contenedores/Append", data);
  }
  Delete(id: number) {
    return http.delete<IRespuesta>(`/Corresponsalias/Contenedores/Delete/${id}`);
  }
}
export default new CorresponsalesDataService();