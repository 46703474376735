import http from "../common/http-common";
import IRespuesta from "../../Interfaces/IRespuesta";
import IFileManager from "../../Interfaces/Utils/IFileManager";

class FileManagerDataService {

  getFileContent(id: number, Proceso: number) {
    return http.get(`/FileManager/getFile?id=${id}&proceso=${Proceso}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  } 
  getFileInfoFromProcess(id: number, proc: number) {
    return http.get<IFileManager>(`/FileManager/GetFileInfoByProcess?id=${id}&Proceso=${proc}`);
  }
  getFileInfoById(id: number) {
    return http.get<IFileManager>(`/FileManager/GetFileInfoById?id=${id}`);
  }
  DeleteFile(id: number) {
    return http.delete<IRespuesta>(`/FileManager/DeleteById/${id}`);
  }

  ConvertFile(fileId: number, process:  number){
    return http.get<IFileManager>(`/FileManager/ConvertExcel2New?id=${fileId}&Proceso=${process}`);
  }

  GetClientesZip(id: number){
    return http.get(`/FileManager/GetClientesZip?id=${id}`, {responseType: 'blob'});
  }

  getFileContentById(id: number) {
    return http.get<ArrayBuffer>(`/FileManager/GetFileById?fileId=${id}`, {responseType: 'arraybuffer'})
    .then(function (response) {
       return response
     })
     .catch(function (error) {
       console.log(error)
     })
  }
  getFileListByreferencia(referencia: string) {
    return http.get<IFileManager[]>(`/FileManager/FileListByReferencia?Referencia=${referencia}`)
  }
}
export default new FileManagerDataService();