import http from "../common/http-common";
import ICorresponsalFacturasTerceros from "../../Interfaces/Corresponsales/ICorresponsalFacturasTerceros";
import IRespuesta from "../../Interfaces/IRespuesta";
import DTOCorresponsalFacturasTerceros from "../../DTO/Corresponsales/DTOCorresponsalFacturasTerceros";

class Corresponsales_FacturasTerceros_DataService {
  getAll(idTrafico: number) {
    return http.get<ICorresponsalFacturasTerceros[]>(`/Corresponsalias/FacturasTerceros/getAll?idTrafico=${idTrafico}`);
  }
  Append(data: ICorresponsalFacturasTerceros) {
    return http.post<DTOCorresponsalFacturasTerceros>("/Corresponsalias/FacturasTerceros/Append", data);
  }
  Delete(id: number) {
    return http.delete<IRespuesta>(`/Corresponsalias/FacturasTerceros/Delete/${id}`);
  }
}
export default new Corresponsales_FacturasTerceros_DataService();