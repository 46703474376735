import http from "../common/http-common";
import IDashboardTotal from "../../Interfaces/IDashboardTotal";

class Dashboard_Contabilidad_DataService {
  getTotalTraficosPendientesAutorizar(mode: number) {
    return http.get<IDashboardTotal>(`/Corresponsalias/Traficos/getTotal?mode=${mode}`);
  }
  getTotalAnticiposPendientes() {
    return http.get<IDashboardTotal>(`/Corresponsalias/Anticipos/getPendientesAutorizar`);
  }
  getTotalCuentasComplementariasPedientes() {
    return http.get<IDashboardTotal>(`/Corresponsalias/CuentasComplementarias/GetPendientes`);
  }
  getTotalRechazosContabilidad() {
    return http.get<IDashboardTotal>(`/Dashboard/Corresponsales/RechazosContabilidad`);
  }
  
  GetTraficosConCargos() {
    return http.get<IDashboardTotal>(`/Dashboard/Corresponsales/TraficosConCargos`);
  }
}
export default new Dashboard_Contabilidad_DataService();