import http from "../common/http-common";
import { ICatCentroCostos } from "../../Interfaces/Catalogos/ICatCentroCostos";
import DTOCatCentrosCostos from "../../DTO/DTOCatCentrosCostos";

class CentrosCostosService{
    getAllCentrosCostos(){
        return http.get<DTOCatCentrosCostos[]>('/Catalogos/CatCentrosCostos/GetAll');
    }

    append(centroCostos: ICatCentroCostos){
        console.log('Guardando ',centroCostos)
        return http.post<DTOCatCentrosCostos[]>('/Catalogos/CatCentrosCostos/Append', centroCostos);
    }

    get(idCliente: number){
        return http.get<ICatCentroCostos>(`/Catalogos/CatCentrosCostos?idCliente=${idCliente}`);
    }
}

export default new CentrosCostosService();