import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatProveedores from '../../../Interfaces/Catalogos/ICatProveedores';
const CatalogoProveedores: ICatProveedores[] = [{id:0, nombre:'- Seleccione proveedor -', clasificacion:0}]
const initialState = { CatalogoProveedores }

export const CatProveedoresSlice = createSlice({
  name: 'CatProveedores',
  initialState: initialState,
  reducers: {
    populateCatProveedores : (state, action: PayloadAction<ICatProveedores[]>) => {
      state.CatalogoProveedores=[{id:0, nombre:'- Seleccione proveedor -', clasificacion:0}]
      state.CatalogoProveedores.push(...action.payload)
    },
    addCatProveedores : (state, action: PayloadAction<ICatProveedores>) => {
      var Existe = state.CatalogoProveedores.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.CatalogoProveedores.push(action.payload)
    },
    updateCatProveedores : (state, action: PayloadAction<ICatProveedores>) => {
      const i = state.CatalogoProveedores.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.CatalogoProveedores[i] = action.payload;
      else state.CatalogoProveedores.push(action.payload);
    },
    deleteCatProveedores : (state, action: PayloadAction<number>) => {
      const newArr = state.CatalogoProveedores.filter(data => data.id != action.payload);
      state.CatalogoProveedores=newArr
    },
  },
})

export const { addCatProveedores, populateCatProveedores, updateCatProveedores, deleteCatProveedores } = CatProveedoresSlice.actions;
export default CatProveedoresSlice.reducer;
