import React, { FC, useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { ICatCentroCostos } from "../../../Interfaces/Catalogos/ICatCentroCostos";
import CentrosCostosService from "../../../Services/Catalogos/CentrosCostos.Service";
import DTOCatCentrosCostos from "../../../DTO/DTOCatCentrosCostos";
import { MsgInformativo } from "../../Utils/Toast/msgInformativo";
import { error } from "console";

export const CatCentroConstos: FC = () => {

    const [CentrosCostos, setCentrosCostos] = useState<DTOCatCentrosCostos[]>([]);
    const centros:ICatCentroCostos[] = [];
    const [header, setHeader] = useState('')
    const [show, setShowMsg] = useState(false)
    const [msg, setMsg] = useState('')
    const msgColor = 'primary'
    useEffect(()=>{
        CentrosCostosService.getAllCentrosCostos().then(response => setCentrosCostos(response.data))
    },[])

    const saveCentroCosto = (centroCostos: ICatCentroCostos, index: number) => {
        CentrosCostosService.append(centroCostos).then(response => {
            setCentrosCostos(response.data)
            setHeader('Informativo')
            setMsg('Centro de costos actualizado con exito')
            setShowMsg(true)
            return
        }).catch(error => {
            setHeader('Error')
            setMsg('Ocurrio un error al guardar el centro de costos. Intentelo de nuevo')
            setShowMsg(true)
            return
        });
    }
    return (
        <Row style={{paddingTop:'3rem'}}>
            <Col lg={1} xl={2}></Col>
            <Col xs={12} lg={10} xl={8}>
                <Card style={{width:'100%'}}>
                    <Card.Header><h4>Centro de Costos</h4></Card.Header>
                    <Card.Body style={{
                        height: '35rem'
                    }}>
                        <div style={{
                            height: '100%',
                            overflowY:'scroll'
                        }}>
                        <Table responsive>
                            <thead>
                                <th>Clave</th>
                                <th>Cliente</th>
                                <th>Centro Costos</th>
                                <th>Cliente Facturacion</th>
                                <th></th>
                            </thead>
                            <tbody>
                                {
                                    CentrosCostos.map((centroCosto, index) => 
                                        <tr>
                                            <td>{centroCosto.idCliente}</td>
                                            <td>{centroCosto.nombreCliente}</td>
                                            <td>{
                                                <CurrencyFormat
                                                    value={centroCosto.centroCostos}
                                                    disabled={false}
                                                    displayType={'input'}
                                                    format={'#-####'}
                                                    style={{
                                                    fontSize: '18px',
                                                    backgroundColor: '#F1EEF9',
                                                    border: '2px solid #5923F6',
                                                    color: '#5923F6',
                                                    width: '100%',
                                                    textAlign: 'right',
                                                    borderRadius: '10px',
                                                    paddingRight: '10px',
                                                    paddingLeft: '10px',
                                                    }}
                                                    onValueChange={(values: any) => {
                                                        const {formattedValue, value, floatValue} = values;
                                                        centroCosto.centroCostos = formattedValue;
                                                    }}
                                                />
                                            }</td>
                                            <td>{
                                                <CurrencyFormat
                                                    value={centroCosto.clienteFacturacion}
                                                    disabled={false}
                                                    displayType={'input'}
                                                    format={'####'}
                                                    style={{
                                                    fontSize: '18px',
                                                    backgroundColor: '#F1EEF9',
                                                    border: '2px solid #5923F6',
                                                    color: '#5923F6',
                                                    width: '100%',
                                                    textAlign: 'right',
                                                    borderRadius: '10px',
                                                    paddingRight: '10px',
                                                    paddingLeft: '10px',
                                                    }}
                                                    onValueChange={(values: any) => {
                                                        const {formattedValue, value, floatValue} = values;
                                                        centroCosto.clienteFacturacion = formattedValue;
                                                    }}
                                                />
                                            }</td>
                                            <td><Button variant="primary" onClick={() => saveCentroCosto(centroCosto, index)}>Guardar</Button></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={1} xl={2}></Col>
            <MsgInformativo
                show={show}
                msg={msg}
                header={header}
                msgColor={msgColor}
                closeToast={() => {
                setShowMsg(false)
                }}
            />
        </Row>
    )
} 