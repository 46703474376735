import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICorresponsalCuentaComplementariaEstatus from '../../../Interfaces/Corresponsales/ICorresponsalCuentaComplementariaEstatus';
const CorresponsalesCuentasComplementariasEstatus: ICorresponsalCuentaComplementariaEstatus[] = 
[{id:0, estatus:'-Seleccione-'}]
const initialState = { CorresponsalesCuentasComplementariasEstatus }

export const CorresponsalesCuentasComplementariasEstatusSlice = createSlice({
  name: 'CorresponsalesCuentasComplementariasEstatus',
  initialState: initialState,
  reducers: {
    populateCorresponsalesCuentasComplementariasEstatus : (state, action: PayloadAction<ICorresponsalCuentaComplementariaEstatus[]>) => {
      state.CorresponsalesCuentasComplementariasEstatus =  [] 
      state.CorresponsalesCuentasComplementariasEstatus.push(... action.payload)
    },
    addCorresponsalesCuentasComplementariasEstatus : (state, action: PayloadAction<ICorresponsalCuentaComplementariaEstatus>) => {
      var Existe = state.CorresponsalesCuentasComplementariasEstatus.find(function(item) {
        return item.id === action.payload.id;
      });
     if (!Existe) state.CorresponsalesCuentasComplementariasEstatus.push(action.payload)
    },
    updateCorresponsalesCuentasComplementariasEstatus : (state, action: PayloadAction<ICorresponsalCuentaComplementariaEstatus>) => {
      console.log('informacion pasada al store: '+JSON.stringify(action.payload))
      const i = state.CorresponsalesCuentasComplementariasEstatus.findIndex(_element => _element.id === action.payload.id);
      if (i > -1) state.CorresponsalesCuentasComplementariasEstatus[i] = action.payload;
      else state.CorresponsalesCuentasComplementariasEstatus.push(action.payload);
    console.log('valor de i:'+i)
      console.log(JSON.stringify(state.CorresponsalesCuentasComplementariasEstatus))
    },
    deleteCorresponsalesCuentasComplementariasEstatus : (state, action: PayloadAction<number>) => {
      const newArr = state.CorresponsalesCuentasComplementariasEstatus.filter(data => data.id != action.payload);
      state.CorresponsalesCuentasComplementariasEstatus=newArr
    },
    InitCorresponsalesCuentasComplementariasEstatus : (state, action: PayloadAction<number>) => {
      state.CorresponsalesCuentasComplementariasEstatus=[{id:0, estatus:'-Seleccione-'}]
    },
  },
})

export const { addCorresponsalesCuentasComplementariasEstatus, 
  populateCorresponsalesCuentasComplementariasEstatus, 
  updateCorresponsalesCuentasComplementariasEstatus, 
  deleteCorresponsalesCuentasComplementariasEstatus,
  InitCorresponsalesCuentasComplementariasEstatus } = CorresponsalesCuentasComplementariasEstatusSlice.actions;
export default CorresponsalesCuentasComplementariasEstatusSlice.reducer;
