import React, { FC, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import CuentasComplementariasDS from '../../../../Services/Corresponsalias/Corresponsales.CuentasComplementarias.Services'
import DTOCuentaComplementariaEstatus from '../../../../DTO/Corresponsales/DTOCuentaComplementariaEstatus'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'

interface IProps {
  IDEstatus: number
  IDCuenta: number
  Enabled: boolean
}

export const SelectEstatus: FC<IProps> = (props) => {
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [MsgTime, setMsgTime] = useState(3000)
  const mCCCEstatus = useSelector(
    (state: RootState) => state.CCueCompEstatus.CorresponsalesCuentasComplementariasEstatus
  )
  const [IDEstatus, setIDEstatus] = useState(props.IDEstatus)

  const changeStatus = (nuevoEstatus: number) => {
    setIDEstatus(nuevoEstatus)
    const data: DTOCuentaComplementariaEstatus = {
      id: props.IDCuenta,
      estatus: nuevoEstatus,
    }
    CuentasComplementariasDS.ChangeStatus(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Se ha cambiado el estatus de la cuenta')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Form.Control
        as='select'
        onChange={(e) => {
          changeStatus(parseInt(e.target.value))
        }}
        className='form-select form-select-sm'
        style={{ width: '350px' }}
        disabled={Depto !== 'Contabilidad' || props.Enabled}
        value={IDEstatus}
      >
        {mCCCEstatus
          ? mCCCEstatus.map((item, index) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.estatus}
                </option>
              )
            })
          : ''}
      </Form.Control>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        time={MsgTime}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
