import React, { FC, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import 'react-flexy-table/dist/index.css';
import FacDataService from '../../../Services/Corresponsalias/Corresponsales.Facturas.Services';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { FcManager } from 'react-icons/fc';
import ICorresponsalFacturas from '../../../Interfaces/Corresponsales/ICorresponsalFacturas';
import {
  addCorresponsalesFacturas,
  deleteCorresponsalesFacturas,
  populateCorresponsalesFacturas,
} from '../../../store/features/Corresponsales/CorresponsalesFacturasSlice';
import '../../../css/react-flexy-table.css';
import { MsgInformativo } from '../../Utils/Toast/msgInformativo';
import CurrencyFormat from 'react-currency-format';
import { CatProveedores } from '../CatProveedores/CatProveedores';
import { FaEraser, FaTimesCircle } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { ControlledInput } from './ControlledInput/ControlledInput';

interface IProps {
  IDTrafico: number;
  IDCorresponsal: number;
  canDelete: boolean;
}

export const ProveedorFactura: FC<IProps> = (props) => {
  const mCFacturas = useSelector(
    (state: RootState) => state.CFData.CorresponsalesFacturas
  );
  const mProveedores = useSelector(
    (state: RootState) => state.CatProveedores.CatalogoProveedores
  );
  const dispatch = useDispatch();
  const [ShowModal, setShowModal] = useState(false);
  const [IDFactura, setIDFactura] = useState(0);
  const [Factura, setFactura] = useState('');
  const [ValorFacturaDls, setValorFacturaDls] = useState<number>(0);
  const [Pedido, setPedido] = useState('');
  const [header, setHeader] = useState('');
  const [show, setShowMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [DialogTabs, setDialogTabs] = useState(false);
  const [IDProveedor, setIDProveedor] = useState(0);
  const MsgTime = 2000;
  const msgColor = 'primary';
  const ClasificacionProveedor = 2;
  const [Uuid, setUuid] = useState<string>('');
  const [FechaFactura, setFechaFactura] = useState<string>('');
  const [Cove, setCove] = useState<string>('');
  const [Entrega, setEntrega] = useState<string>('');
  const [Transporte, setTransporte] = useState<string>('');
  const TblHeader: React.FC = () => (
    <table>
      <tr
        style={{
          fontSize: '15px',
          fontWeight: 'bold',
          backgroundColor: '#FFFFFF',
        }}
      >
        <td width='140px' style={{ textAlign: 'center' }}>
          Factura
        </td>
        <td width='120px' style={{ textAlign: 'center' }}>
          Valor Dls
        </td>
        <td width='120px' style={{ textAlign: 'center' }}>
          Pedido
        </td>
        <td width='560px' style={{ textAlign: 'center' }}>
          Proveedor
        </td>
        <td width='120px' style={{ textAlign: 'center' }}>
          UUID
        </td>
        <td width='120px' style={{ textAlign: 'center' }}>
          Fecha Factura
        </td>
        <td width='120px' style={{ textAlign: 'center' }}>
          COVE
        </td>
        <td width='120px' style={{ textAlign: 'center' }}>
          Entrega
        </td>
        <td width='120px' style={{ textAlign: 'center' }}>
          Transporte
        </td>
      </tr>
    </table>
  );

  const columnsFacturas = [
    {
      name: 'Id',
      width: '65px',
      selector: (row: ICorresponsalFacturas) => row.id,
      sortable: true,
    },
    {
      name: 'Factura',
      width:'150px',
      selector: (row: ICorresponsalFacturas) => row.factura,
      sortable: true,
    },
    {
      name: 'Valor Dls',
      width:'100px',
      selector: (row: ICorresponsalFacturas) => '$ ' + row.valorFacturaDls,
      sortable: true,
    },
    {
      name: 'Pedido',
      width:'130px',
      selector: (row: ICorresponsalFacturas) => row.pedido,
      sortable: true,
    },
    {
      name: 'Proveedor',
      width:'150px',
      selector: (row: ICorresponsalFacturas) => mProveedores.filter(x => x.id === row.proveedor)[0].nombre,
      sortable: true,
      center:true,
      wrap: true
    },
    {
      name: 'UUID',
      width:'160px',
      selector: (row: ICorresponsalFacturas) => row.uuid ? row.uuid : '',
      sortable: true,
      center:true,
      wrap: true
    },
    {
      name: 'Fecha Factura',
      width: '125px',
      selector: (row: ICorresponsalFacturas) => row.fechaFactura? row.fechaFactura!.substring(0,11) : '',
      sortable: true,
    },
    {
      name: 'COVE',
      selector: (row: ICorresponsalFacturas) => row.cove ? row.cove : '',
      sortable: true,
      allowOverflow: true
    },
    {
      name: 'Entrega',
      selector: (row: ICorresponsalFacturas) => row.entrega ? row.entrega : '',
      sortable: true,
    },
    {
      name: 'Transporte',
      selector: (row: ICorresponsalFacturas) => row.transporte ? row.transporte : '',
      sortable: true,
    },
    /* {
      name: <TblHeader />,
      width: '75%',
      cell: (row: ICorresponsalFacturas) => {
        return <ControlledInput record={row} disabled={false} mode={2} />;
      },
    }, */
    {
      name: 'Elimina',
      width: '80px',
      cell: (row: ICorresponsalFacturas) => (
        <div
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            visibility: props.canDelete ? 'visible' : 'hidden',
          }}
          onClick={() => {
            setIDFactura(row.id);
            setFactura(row.factura);
            setDialogTabs(true);
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <FaTimesCircle />
          </IconContext.Provider>
        </div>
      ),
    },
  ];

  useEffect(() => {
    FacDataService.getAll(props.IDTrafico)
      .then((response) => {
        console.log(response.data);
        dispatch(populateCorresponsalesFacturas(response.data));
      })
      .catch((e: Error) => {
        setHeader('Error');
        setMsg('Ocurrio un error: ' + e);
        setShowMsg(true);
        return;
      });
  }, [props.IDTrafico, dispatch]);

  const cleanForm = () => {
    setFactura('');
    setIDFactura(0);
    setIDProveedor(0);
    setValorFacturaDls(0);
    setPedido('')
    setUuid('')
    setFechaFactura('')
    setCove('')
    setEntrega('')
    setTransporte('')
  };

  const saveForm = () => {
    /*if (Factura.length <= 3) {
      setHeader('Error');
      setMsg('Proporcione la factura para poder continuar');
      setShowMsg(true);
      setDialogTabs(false);
      return false;
    }*/
    if (ValorFacturaDls === 0) {
      setHeader('Error');
      setMsg('Proporcione el valor en dlls de la factura para poder continuar');
      setShowMsg(true);
      setDialogTabs(false);
      return false;
    }
    if (IDProveedor === 0) {
      setHeader('Error');
      setMsg('Seleccione un proveedor para poder continuar');
      setShowMsg(true);
      setDialogTabs(false);
      return false;
    }
    const data: ICorresponsalFacturas = {
      id: 0,
      factura: Factura,
      idTrafico: props.IDTrafico,
      valorFacturaDls: ValorFacturaDls,
      proveedor: IDProveedor,
      corresponsal: props.IDCorresponsal,
      pedido: Pedido,
      code: 0,
      folioGEMCO: '',
      uuid: Uuid,
      fechaFactura: FechaFactura,
      cove: Cove,
      entrega: Entrega,
      transporte: Transporte,
      idContenedor: 0,
    };
    FacDataService.Append(data)
      .then((response) => {
        if (response.status === 200) {
          setHeader('Informativo');
          const respuesta: ICorresponsalFacturas = response.data;
          console.log(respuesta);
          if (respuesta.code && typeof respuesta.code === 'number') {
            const result = respuesta.code;
            if (result === 409)
              setMsg(
                `La factura [${response.data.factura}] de ese proveedor ya se han registrado previamente en el año en curso, lo encuentra en el folio [${response.data.folioGEMCO}]`
              );
            else {
              setMsg('La factura se agrego exitosamente ');
              if (data.id === 0)
                dispatch(addCorresponsalesFacturas(response.data));
            }
          }
          setShowMsg(true);
          setDialogTabs(false);
        }
      })
      .catch((e: Error) => {
        setHeader('Error');
        setMsg('Ocurrio un error: ' + e);
        setShowMsg(true);
        return;
      });
  };

  const deleteItem = () => {
    FacDataService.Delete(IDFactura)
      .then((response) => {
        if (response.status === 200) {
          setHeader('Informativo');
          setMsg('La factura se ha eliminado');
          setShowMsg(true);
          setDialogTabs(false);
          dispatch(deleteCorresponsalesFacturas(IDFactura));
          return;
        }
      })
      .catch((e: Error) => {
        setHeader('Error');
        setMsg('Ocurrio un error: ' + e);
        setShowMsg(true);
        return;
      });
  };

  return (
    <div>
      <Card style={{}}>
        <Card.Body>
          <Card.Subtitle className='mb-2 text-muted'>
            <Row
              style={{ paddingBottom: '5', paddingTop: '15' }}
              className={props.canDelete ? 'visible' : 'invisible heightZero'}
            >
              <Col
                xs={1}
                style={{
                  textAlign: 'right',
                  paddingTop: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  cleanForm();
                }}
              >
                <IconContext.Provider value={{ color: 'orange', size: '25px' }}>
                  <FaEraser />
                </IconContext.Provider>
              </Col>
              <Col xs='1'>
                <Form.Control
                  type='text'
                  id='Factura'
                  size='sm'
                  value={Factura}
                  placeholder='Factura'
                  pattern='[a-zA-Z-0-9 ]*'
                  onChange={(e) =>
                    setFactura((v) =>
                      e.target.validity.valid ? e.target.value : v
                    )
                  }
                />
              </Col>
              <Col xs='1'>
                <CurrencyFormat
                  id='ValorFacturaDls'
                  value={ValorFacturaDls}
                  prefix={'$'}
                  displayType={'input'}
                  thousandSeparator={true}
                  onValueChange={(values: any) => {
                    const { value } = values;
                    setValorFacturaDls(value);
                  }}
                  style={{
                    fontSize: '18px',
                    backgroundColor: '#F5FFED',
                    border: '2px solid #25D05B',
                    width: '100%',
                    textAlign: 'right',
                    borderRadius: '10px',
                  }}
                />
              </Col>
              <Col xs={1}>
                <Form.Control
                  type='text'
                  id='Pedido'
                  size='sm'
                  value={Pedido}
                  placeholder='Pedido'
                  onChange={(e) => setPedido(e.target.value)}
                />
              </Col>
              <Col xs={5}>
                <Form.Control
                  id='IDProveedor'
                  as='select'
                  onChange={(e) => {
                    setIDProveedor(parseInt(e.target.value));
                  }}
                  className='form-select form-select-sm'
                  value={IDProveedor}
                >
                  <option>-Seleccione-</option>
                  {mProveedores
                    ? mProveedores
                        .filter(function (row) {
                          return row.clasificacion === ClasificacionProveedor;
                        })
                        .map((item, index) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.nombre}
                            </option>
                          );
                        })
                    : ''}
                </Form.Control>
              </Col>
              <Col xs={1} style={{ cursor: 'pointer' }}>
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
                    <FcManager />
                  </IconContext.Provider>
                </Button>
              </Col>
              <Col xs={1}></Col>
              <Col xs={1}>
                <Button
                  id='BtnSave'
                  variant='primary'
                  size='sm'
                  onClick={() => {
                    saveForm();
                  }}
                >
                  Agregar
                </Button>
              </Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row className={props.canDelete ? 'visible justify-content-end' : 'invisible heightZero'} >
              <Col xs={3}>
                <Form.Control
                  type='text'
                  id='UUID'
                  size='sm'
                  value={Uuid}
                  placeholder='UUID'
                  onChange={(e) => setUuid(e.target.value)}
                />
              </Col>
              <Col xs={2}>
                <Form.Control
                  type='date'
                  id='FechaFactura'
                  size='sm'
                  value={FechaFactura}
                  placeholder='Fecha de la Factura'
                  onChange={(e) => setFechaFactura(e.target.value)}
                />
              </Col>
              <Col xs={2}>
                <Form.Control
                  type='text'
                  id='Cove'
                  size='sm'
                  value={Cove}
                  placeholder='COVE'
                  onChange={(e) => setCove(e.target.value)}
                />
              </Col>
              <Col xs={2}>
                <Form.Control
                  type='text'
                  id='Entrega'
                  size='sm'
                  value={Entrega}
                  placeholder='Entrega'
                  onChange={(e) => setEntrega(e.target.value)}
                />
              </Col>
              <Col xs={2}>
                <Form.Control
                  type='text'
                  id='Transporte'
                  size='sm'
                  value={Transporte}
                  placeholder='Transporte'
                  onChange={(e) => setTransporte(e.target.value)}
                />
              </Col>
            </Row>
          </Card.Subtitle>
          <DataTable
            /* noHeader */
            fixedHeader={true}
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            fixedHeaderScrollHeight='400px'
            persistTableHead
            striped
            dense
            paginationPerPage={7}
            paginationRowsPerPageOptions={[7, 10, 14, 20, 21]}
            responsive
            pagination
            highlightOnHover
            columns={columnsFacturas}
            data={mCFacturas.filter(function (el) {
              return el.id > 0;
            })}
          />
        </Card.Body>
      </Card>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        time={MsgTime}
        closeToast={() => {
          setShowMsg(false);
        }}
      />
      <Modal
        show={DialogTabs}
        onHide={() => {
          setDialogTabs(false);
        }}
        size='sm'
      >
        <Modal.Body>
          <Alert variant='primary'>
            <b>¿Esta seguro de eliminar?</b>
            <br />
            <h5> {Factura} </h5>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant='danger'
                onClick={deleteItem}
                size='sm'
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Si
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='secondary'
                onClick={() => {
                  setDialogTabs(false);
                }}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false);
        }}
        size='sm'
        dialogClassName='modal-50w'
      >
        <Modal.Body>
          <CatProveedores canDelete={true} clasificacion={2} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
