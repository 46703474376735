import http from "../common/http-common";
import ICatProveedores from "../../Interfaces/Catalogos/ICatProveedores";


class ProveedoresDataService {

  getAll(Clasificacion: number) {
    return http.get<ICatProveedores[]>(`/Catalogos/Corresponsales/Proveedores/getAll?Clasificacion=${Clasificacion}`);
  }  
  Append(data: ICatProveedores) {
    return http.post<ICatProveedores>("/Catalogos/Corresponsales/Proveedores/Append", data);
  }
  Delete(id: number) {
    return http.delete<ICatProveedores>(`/Catalogos/Corresponsales/Proveedores/Delete/${id}`);
  }
  
}
export default new ProveedoresDataService();