import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import ICatCorresponsales from '../../../Interfaces/Catalogos/ICatCorresponsales'
import CorresponsalesDataService from '../../../Services/Catalogos/Corresponsales.Services'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { useDispatch, useSelector } from 'react-redux'
import {
  addCatCorresponsales,
  deleteCatCorresponsales,
} from '../../../store/features/CatCorresponsales/CatCorresponsalesSlice'
import { RootState } from '../../../store/store'
import DataTable from 'react-data-table-component'
import { ControlledInput } from './ControlledInput/ControlledInput'
import { FaEraser, FaTimesCircle } from 'react-icons/fa'
import CurrencyFormat from 'react-currency-format'

interface IProps {
  canDelete: boolean
}

export const CatCorresponsales: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [filteredData, setFilteredData] = useState<ICatCorresponsales[]>([])
  const mCorresponsales = useSelector((state: RootState) => state.CatCorresponsales.CatCorresponsales)
  // const gridRef = React.useRef<any>(null)
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [id, setId] = useState(0)
  const [msgDialog, setMsgDialog] = useState(false)
  //  const [IDCorresponsal, setIDCorresponsal] = useState(0)
  const [Nombre, setNombre] = useState('')
  const [Patente, setPatente] = useState(0)
  const [Aduana, setAduana] = useState('0')
  const [Correos, setCorreos] = useState('')

  useEffect(() => {
    CorresponsalesDataService.getAll()
      .then((response) => {
        setFilteredData(response.data)
        console.log(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [])

  const columnsCorresponsales = [
    {
      name: 'id',
      width: '5%',
      selector: (row: ICatCorresponsales) => row.id,
      sortable: true,
    },
    {
      name: 'Corresponsal ...................................................................................................................................... Patente ...... Aduana ...... Correos',
      width: '90%',
      cell: (row: ICatCorresponsales) => <ControlledInput record={row} />,
    },
    {
      name: 'Elimina',
      width: '5%',
      cell: (row: ICatCorresponsales) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setId(row.id)
            setNombre(row.nombre)
            setPatente(row.patente)
            setAduana(row.aduana)
            setCorreos(row.correos)
            setMsgDialog(true)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <FaTimesCircle />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  function deleteRow(row: ICatCorresponsales) {
    setId(row.id)
    setNombre(row.nombre)
    setPatente(row.patente)
    setAduana(row.aduana)
    setMsgDialog(true)
  }

  const loadRow = (data: ICatCorresponsales) => {
    setId(data.id)
    setNombre(data.nombre)
    setPatente(data.patente)
    setAduana(data.aduana)
    setCorreos(data.correos)
  }

  function Guardar() {
    if (Patente < 999) {
      setHeader('Error!')
      setMsgColor('danger')
      setMsg('La patente debe tener 4 digitos')
      setShowMsg(true)
      return false
    }
    if (Aduana === '' || Aduana === '0' || Aduana === ' ' || Aduana === undefined || Aduana === null || Aduana.length < 3) {
      setHeader('Error!')
      setMsgColor('danger')
      setMsg('La aduana debe tener 3 digitos')
      setShowMsg(true)
      return false
    }
    const data: ICatCorresponsales = {
      id: id,
      nombre: Nombre,
      aduana: Aduana,
      patente: Patente,
      correos: Correos,
    }
    CorresponsalesDataService.Append(data)
      .then((response) => {
        if (response.status == 200) {
          dispatch(addCatCorresponsales(response.data))
          setMsgColor('primary')
          setHeader('Confirmacion')
          setMsg('La informacion se guardo exitosamente')
          setShowMsg(true)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  function cleanForm() {
    setId(0)
    setNombre('')
    setPatente(0)
    setAduana('')
  }

  const deleteItem = () => {
    setMsgDialog(false)
    CorresponsalesDataService.Delete(id)
      .then((response) => {
        if (response.status == 200) {
          dispatch(deleteCatCorresponsales(id))
          cleanForm()
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })

    setNombre('')
    setId(0)
  }

  return (
    <div>
      <Card className='labelSize13px'>
        <Card.Body>
          <Row
            style={{
              visibility: props.canDelete ? 'visible' : 'hidden',
              height: props.canDelete ? '' : '0px',
            }}
          >
            <Col xs={1}>
              <Form.Label>Corresponsal</Form.Label>
            </Col>
            <Col xs={5}>
              {/* <Form.Control
                type='text'
                id='Nombre'
                size='sm'
                value={Nombre}
                pattern='[a-zA-Z ]*'
                onChange={(e) => setNombre((v) => (e.target.validity.valid ? e.target.value : v))}
              /> */}
              <input
                type='text'
                className='form-control genericSelect'
                name='Nombre'
                id='Nombre'
                style={{ height: '30px' }}
                value={Nombre}
                placeholder='Proporcione el nombre del corresponsal'
                onChange={(e) => {
                  setNombre(e.target.value)
                }}
                /*  onKeyDown={(e) => handleKeyDown(e)} */
              />
            </Col>
            <Col xs={1}>
              <Form.Label>Correos</Form.Label>
            </Col>
            <Col xs={5}>
              <Form.Control
                type='text'
                id='Correos'
                size='sm'
                value={Correos}
                onChange={(e) => setCorreos((v) => e.target.value)}
              />
            </Col>
          </Row>
          <Row
            style={{
              paddingTop: 5,
              visibility: props.canDelete ? 'visible' : 'hidden',
              height: props.canDelete ? '' : '0px',
            }}
          >
            <Col
              xs={1}
              style={{ textAlign: 'right', paddingTop: '5px', cursor: 'pointer' }}
              onClick={() => {
                cleanForm()
              }}
            >
              <IconContext.Provider value={{ color: 'orange', size: '25px' }}>
                <FaEraser />
              </IconContext.Provider>
            </Col>
            <Col xs={2}>&nbsp;</Col>
            <Col>
              <Form.Label>Patente</Form.Label>
            </Col>
            <Col>
              <CurrencyFormat
                value={Patente}
                displayType={Depto === 'Corresponsalias' ? 'input' : 'text'}
                onValueChange={(values: any) => {
                  const { formattedValue, value } = values
                  setPatente(value)
                }}
                style={
                  Depto === 'Corresponsalias'
                    ? {
                        fontSize: '18px',
                        backgroundColor: '#F5FFED',
                        border: '2px solid #25D05B',
                        width: '80px',
                        textAlign: 'right',
                        borderRadius: '10px',
                      }
                    : {
                        fontSize: '18px',
                        backgroundColor: '#F5FFED',
                        border: '2px solid #25D05B',
                        width: '80px',
                        textAlign: 'right',
                        borderRadius: '10px',
                        paddingRight: '5px',
                        paddingLeft: '5px',
                      }
                }
              />
            </Col>
            <Col>&nbsp;</Col>
            <Col>
              <Form.Label>Aduana</Form.Label>
            </Col>
            <Col>
              <Form.Control
                value={Aduana}
                onChange={(e) => {
                  setAduana(e.target.value)
                }}
                style={{
                  fontSize: '18px',
                  backgroundColor: '#F5FFED',
                  border: '2px solid #25D05B',
                  width: '60px',
                  textAlign: 'right',
                  borderRadius: '10px',
                  padding: '1px 2px'
                }}
              />
            </Col>
            <Col xs={2}></Col>
            <Col md='auto'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  Guardar()
                }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
          <Row style={{ paddingTop: 5 }}>
            <DataTable
              noHeader
              defaultSortFieldId={'id'}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              columns={columnsCorresponsales}
              data={mCorresponsales}
            />
            <Col></Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={msgDialog} onHide={() => setMsgDialog(false)} size='sm'>
        <Modal.Body>
          <Alert variant='primary'>
            <b>¿Esta seguro de eliminar?</b>
            <br />
            <h5>
              {Nombre} | Patente: {Patente} | Aduana {Aduana}
            </h5>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6}>
              <Button variant='secondary' onClick={() => setMsgDialog(false)} size='sm'>
                Cerrar
              </Button>
            </Col>
            <Col xs={6}>
              <Button variant='primary' onClick={deleteItem} size='sm'>
                Si
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
