import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import { FaCopy } from 'react-icons/fa'
import ICorresponsalesRectificaciones from '../../../Interfaces/Corresponsales/ICorresponsalesRectificaciones'
import RectiDataService from '../../../Services/Corresponsalias/Corresponsales.Trafico.Services'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'

interface IProps {
  IDTrafico: number
  changeIdTrafico: (newId: number) => void
}

export const Rectificaciones: FC<IProps> = (props) => {
  const [DTData, setDTData] = useState<ICorresponsalesRectificaciones[]>([])
  const [id, setId] = useState(0)
  const [FolioGEMCO, setFolioGEMCO] = useState('')
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [DialogClone, setDialogClone] = useState(false)
  const [MsgTime, setMsgTime] = useState(3000)
  const dataColumns = [
    {
      name: 'id',
      left: true,
      width: '10%',
      selector: (row: ICorresponsalesRectificaciones) => row.id,
      cell: (row: ICorresponsalesRectificaciones) => (
        <div
          onClick={() => {
            props.changeIdTrafico(row.id)
          }}
        >
          {row.id}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Folio',
      width: '20%',
      selector: (row: ICorresponsalesRectificaciones) => row.folioGemco,
      sortable: true,
    },
    {
      name: 'Creado',
      width: '14%',
      selector: (row: ICorresponsalesRectificaciones) => row.fechaRegistro.substring(0, 10),
      sortable: true,
    },
    {
      name: 'Prev',
      center: true,
      width: '20%',
      selector: (row: ICorresponsalesRectificaciones) => row.prevRecti,
      cell: (row: ICorresponsalesRectificaciones) => <div>{row.prevRecti}</div>,
    },
    {
      name: 'Next',
      center: true,
      width: '20%',
      selector: (row: ICorresponsalesRectificaciones) => row.nextRecti,
      cell: (row: ICorresponsalesRectificaciones) =>
        row.nextRecti === 0 ? (
          <div
            style={{ textAlign: 'center', cursor: 'pointer' }}
            onClick={() => {
              setId(row.id)
              setFolioGEMCO(row.folioGemco)
              setDialogClone(true)
            }}
          >
            <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
              <FaCopy />
            </IconContext.Provider>
          </div>
        ) : (
          <div>{row.nextRecti}</div>
        ),
    },
  ]

  useEffect(() => {
    RectiDataService.GetRectificaciones(props.IDTrafico)
      .then((response) => {
        setDTData(response.data)
        console.log(JSON.stringify(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }, [props.IDTrafico])

  const clonItem = () => {
    RectiDataService.AddRectificacion(id)
      .then((response) => {
        if (response.status == 200) {
          setHeader('Informativo')
          setMsg('La rectificacion se agrego exitosamente')
          setShowMsg(true)
          setDialogClone(false)
          //if (data.id === 0) dispatch(addCorresponsalesFacturas(response.data))
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <DataTable
        title='Historial de rectificaciones'
        defaultSortFieldId={'id'}
        defaultSortAsc={true}
        pagination
        highlightOnHover
        columns={dataColumns}
        data={DTData ? DTData : []}
      />
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        time={MsgTime}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
      <Modal
        show={DialogClone}
        onHide={() => {
          setDialogClone(false)
        }}
        size='sm'
      >
        <Modal.Body>
          <Alert variant='primary'>
            <b>¿Esta seguro de realizar una rectificacion sobre este trafico: [ {FolioGEMCO} ]?</b>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant='danger'
                onClick={clonItem}
                size='sm'
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Si
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='secondary'
                onClick={() => {
                  setDialogClone(false)
                }}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
