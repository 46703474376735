import http from "../common/http-common";
import ICorresponsalCatTiposEmbarque from "../../Interfaces/Corresponsales/ICorresponsalesCatTiposEmbarque";

class CorresponsalesDataService {

  getAll() {
    return http.get<ICorresponsalCatTiposEmbarque[]>(`/Corresponsalias/CatTiposEmbarque/GetAll`);
  }

}
export default new CorresponsalesDataService();