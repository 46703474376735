import { DTOTraficoSolicitud } from "../../DTO/Corresponsales/DTOTraficoSolicitud"
import { ICorresponsalTraficoSolicitud } from "../../Interfaces/Corresponsales/ICorresponsalTraficoSolicitud"
import http from "../common/http-common";
class TraficoSolicitudServices {
    AppendSolicitudReapertura(solicitud: ICorresponsalTraficoSolicitud){
        return http.post<ICorresponsalTraficoSolicitud>(`/Corresponsalias/Solicitudes/Reapertura/Append`, solicitud)
    }
    AppendSolicitudEliminarReferencia(solicitud: ICorresponsalTraficoSolicitud){
        return http.post<ICorresponsalTraficoSolicitud>(`/Corresponsalias/Solicitudes/EliminarCuenta/Append`, solicitud)
    }
    GetSolicitudReapertura(idTrafico: number){
        return http.get<DTOTraficoSolicitud>(`/Corresponsalias/Solicitudes/Reapertura/Get?idTrafico=${idTrafico}`)
    }
    GetSolicitudEliminarReferencia(idTrafico: number){
        return http.get<DTOTraficoSolicitud>(`/Corresponsalias/Solicitudes/EliminarCuenta/Get?idTrafico=${idTrafico}`)
    }
}

export default new TraficoSolicitudServices()