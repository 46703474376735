import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ICorresponsalFacturas from '../../../Interfaces/Corresponsales/ICorresponsalFacturas';
const CorresponsalesFacturas: ICorresponsalFacturas[] = [
  {
    id: 0,
    factura: '-Seleccione-',
    idTrafico: 0,
    valorFacturaDls: 0,
    proveedor: 0,
    pedido: '',
    code: 0,
    folioGEMCO: '',
    uuid: '',
          fechaFactura: '',
          cove: '',
          entrega: '',
          transporte: '',
          idContenedor: 0,
  },
];
const initialState = { CorresponsalesFacturas };

export const CorresponsalesFacturasSlice = createSlice({
  name: 'CorresponsalesFacturas',
  initialState: initialState,
  reducers: {
    populateCorresponsalesFacturas: (
      state,
      action: PayloadAction<ICorresponsalFacturas[]>
    ) => {
      state.CorresponsalesFacturas = [
        {
          id: 0,
          factura: '-Seleccione-',
          idTrafico: 0,
          valorFacturaDls: 0,
          proveedor: 0,
          pedido: '',
          code: 0,
          folioGEMCO: '',
          uuid: '',
          fechaFactura: '',
          cove: '',
          entrega: '',
          transporte: '',
          idContenedor: 0,
        },
      ];
      state.CorresponsalesFacturas.push(...action.payload);
    },
    addCorresponsalesFacturas: (
      state,
      action: PayloadAction<ICorresponsalFacturas>
    ) => {
      var Existe = state.CorresponsalesFacturas.find(function (item) {
        return item.id === action.payload.id;
      });
      if (!Existe) state.CorresponsalesFacturas.push(action.payload);
    },
    updateCorresponsalesFacturas: (
      state,
      action: PayloadAction<ICorresponsalFacturas>
    ) => {
      const i = state.CorresponsalesFacturas.findIndex(
        (_element) => _element.id === action.payload.id
      );
      if (i > -1) state.CorresponsalesFacturas[i] = action.payload;
      else state.CorresponsalesFacturas.push(action.payload);
    },
    deleteCorresponsalesFacturas: (state, action: PayloadAction<number>) => {
      const newArr = state.CorresponsalesFacturas.filter(
        (data) => data.id != action.payload
      );
      state.CorresponsalesFacturas = newArr;
    },
    InitCorresponsalesFacturas: (state, action: PayloadAction<number>) => {
      // state.CorresponsalesFacturas.splice(0,state.CorresponsalesFacturas.length-1)
      state.CorresponsalesFacturas = [
        {
          id: 0,
          factura: '-Seleccione-',
          idTrafico: 0,
          valorFacturaDls: 0,
          proveedor: 0,
          pedido: '',
          code: 0,
          folioGEMCO: '',
          uuid: '',
          fechaFactura: '',
          cove: '',
          entrega: '',
          transporte: '',
          idContenedor: 0,
        },
      ];
    },
    addTxtFacturas: (state, action: PayloadAction<ICorresponsalFacturas[]>) =>{
      const newArr = [...state.CorresponsalesFacturas, ...action.payload]
      state.CorresponsalesFacturas=newArr
    }
  },
});

export const {
  addCorresponsalesFacturas,
  populateCorresponsalesFacturas,
  updateCorresponsalesFacturas,
  deleteCorresponsalesFacturas,
  InitCorresponsalesFacturas, addTxtFacturas
} = CorresponsalesFacturasSlice.actions;
export default CorresponsalesFacturasSlice.reducer;
