import http from "../common/http-common";
import ICorresponsalFacturas from "../../Interfaces/Corresponsales/ICorresponsalFacturas";
import IRespuesta from "../../Interfaces/IRespuesta";

class Corresponsales_Facturas_DataService {
  getAll(idTrafico: number) {
    return http.get<ICorresponsalFacturas[]>(`/Corresponsalias/Facturas/getAll/?idTrafico=${idTrafico}`);
  }
  Append(data: ICorresponsalFacturas) {
    return http.post<ICorresponsalFacturas>("/Corresponsalias/Facturas/Append", data);
  }
  Delete(id: number) {
    return http.delete<IRespuesta>(`/Corresponsalias/Facturas/Delete/${id}`);
  }
  GetExcel(idTrafico: number){
    return http.get(`/Corresponsalias/Facturas/GetExcel?idTrafico=${idTrafico}`, {responseType:'blob'})
  }
  AppendContenedor(idFactura: number, idContenedor: number){
    return http.post(`/Corresponsalias/Facturas/AppendContenedor`, {IdFactura: idFactura, IdContenedor: idContenedor})
  }
}
export default new Corresponsales_Facturas_DataService();