import { Button, Card, Col, Form, FormControl, FormLabel, Modal, Row, Table } from "react-bootstrap"
import CurrencyFormat from "react-currency-format"
import { ICatClavesPedimentos } from "../../../Interfaces/Catalogos/ICatClavesPedimentos"
import { useEffect, useState } from "react"
import { AgGridReact } from "ag-grid-react"
import { DTOPedimentosConsolidados } from "../../../DTO/Corresponsales/DTOPedimentosConsolidados"
import PedimentosConsolidadosService from "../../../Services/Corresponsalias/Corresponsales.Trafico.Pedimento.Consolidados.Service"
import { IconContext } from "react-icons"
import { BiDownload } from "react-icons/bi"
import { BsPencilFill, BsFileEarmarkPdf, BsTrash } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { addPedimento, deletePedimento, updatePedimento } from "../../../store/features/Corresponsales/CorresponsalesPedimentosConsolidadosSlice"
import { MsgInformativo } from "../../Utils/Toast/msgInformativo"
import { FaEraser } from "react-icons/fa"
import DTORectificacionHistorico from "../../../DTO/Corresponsales/DTORectificacionHistorico"
import CTrafDataService from "../../../Services/Corresponsalias/Corresponsales.Trafico.Services"
import { addRectificacion } from "../../../store/features/Corresponsales/CorresponsalesPedimentosRectificacionesSlice"
import { HistorialRectificaciones } from "../Rectificaciones/HistorialRectificaciones"
import ICorresponsalRectificacionHistorico from "../../../Interfaces/Corresponsales/ICorresponsalRectificacionHistorico"
import '../../../css/generic01.css'

interface IProps {
    IdTrafico: number
    Aduana: string
    Patente: number
    ClavesPedimento: ICatClavesPedimentos[]
    Depto: string
}

export const PedimentosConsolidados:React.FC<IProps> = (props) => {
    const dispatch = useDispatch()
    const [header, setHeader] = useState('')
    const [show, setShowMsg] = useState(false)
    const [msg, setMsg] = useState('')
    const msgColor = 'primary'

    const [showModal, setShowModal] = useState(false)
    const [LoadComponent, setLoadComponent] = useState(0)

    const [Id, setId] = useState(0)
    const [Aduana, setAduana] = useState(props.Aduana)
    const [Patente, setPatente] = useState(props.Patente)
    const [Pedimento, setPedimento] = useState(0)
    const [ClavePedimento, setClavePedimento] = useState('');
    const [FechaPago, setFechaPago] = useState('')

    const [IdPedimentoH, setIdPedimentoH] = useState(0)
    const [AduanaH, setAduanaH] = useState(0)
    const [PatenteH, setPatenteH] = useState(0)
    const [PedimentoH, setPedimentoH] = useState(0)
    const [ClavePedimentoH, setClavePedimentoH] = useState('')
    const [FechaPagoH, setFechaPagoH] = useState('')
    const [isRectificacion, setIsRectificacion] = useState(false)
    const mCPedimentosConsolidados = useSelector((state:RootState) => state.CPedimentosConsolidados.PedimentosConsolidados)
    const mcRectificaciones = useSelector((state:RootState) => state.CRectificaciones.RectificacionesPedimentos)
    const [Rectificaciones, setRectificaciones] = useState<ICorresponsalRectificacionHistorico[]>([]);

    const cleanForm = () => {
        setId(0)
        setPedimento(0)
        setClavePedimento('')
        setFechaPago('')
    }

    const loadRow = (data: DTOPedimentosConsolidados) => {
        setId(data.id)
        setAduana(data.aduana)
        setPatente(data.patente)
        setPedimento(data.pedimento)
        setClavePedimento(data.clave)
        setFechaPago(setDate(data.fechaPago))
    }

    function setDate(fecha: string | undefined): string {
        if (!fecha) return ''
        else {
          var dd = fecha?.substring(8, 10)
          var mm = fecha?.substring(5, 7)
          var yyyy = fecha?.substring(0, 4)
          return yyyy + '-' + mm + '-' + dd
        }
    }

    const Append = () => {
        if(Aduana !== '' && Aduana !== '0' && Aduana !== ' ' && Aduana !== undefined && Aduana !== null &&
            Patente !== 0 &&
            Pedimento !== 0 &&
            FechaPago !== '' &&
            ClavePedimento !== '' ){
            PedimentosConsolidadosService.Append({
                id: Id,
                idTrafico: props.IdTrafico,
                aduana: Aduana,
                patente: Patente,
                pedimento: Pedimento,
                clave: ClavePedimento,
                fechaPago: FechaPago
            }).then(resp => {
                if(Id > 0){
                    dispatch(updatePedimento(resp.data))
                    setHeader('Informativo')
                    setMsg('Datos actualizados correctamente')
                    setShowMsg(true)
                }else{
                    dispatch(addPedimento(resp.data))
                    setHeader('Informativo')
                    setMsg('Pedimento guardado correctamente')
                    setShowMsg(true)
                }
                cleanForm()
                return
            }).catch((e) => {
                setHeader('Error')
                setMsg('Ocurrió un error al guardar el pedimento, favor de intentarlo de nuevo.')
                setShowMsg(true)
                return
            })
        }else{
            setHeader('Error')
            setMsg('Favor de llenar todos los datos del pedimento')
            setShowMsg(true)
            return
        }
    }

    const Delete = (id: number) => {
        PedimentosConsolidadosService.Delete(id)
        .then(resp => {
            dispatch(deletePedimento(id))
            setHeader('Informativo')
            setMsg('Pedimento eliminado correctamente')
            setShowMsg(true)
            return
        })
        .catch((e) => {
            setHeader('Error')
            setMsg('Ocurrió un error al borrar el pedimento, favor de intentarlo de nuevo.')
            setShowMsg(true)
            return
        })
    }
    return (
        <>
        <Card style={{height:'100%'}}>
            <Card.Body>
                {props.Depto === 'Corresponsalias' ? <>
                <Row className="justify-content-end align-items-center">
                    <Col xs={1}></Col>
                    <Col
                        xs={1}
                        style={{
                            textAlign: 'right',
                            paddingTop: '5px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            cleanForm();
                        }}
                    >
                        <IconContext.Provider value={{ color: 'orange', size: '25px' }}>
                        <FaEraser />
                        </IconContext.Provider>
                    </Col>
                    <Col xs={1}>
                        <FormLabel>
                            Aduana
                            <FormControl type='text' size='sm' disabled value={Aduana} onChange={(e) => setAduana(e.target.value)}></FormControl>
                        </FormLabel>
                    </Col>
                    <Col xs={1}>
                        <FormLabel>
                            Patente
                            <FormControl type='text' size='sm' disabled value={Patente} onChange={(e) => setPatente(+e.target.value)}></FormControl>
                        </FormLabel>
                    </Col>
                    <Col xs={2}>
                        <FormLabel>
                            Pedimento
                            <CurrencyFormat
                            value={Pedimento}
                            displayType='input'
                            format={'### ####'}
                            mask="_"
                            onValueChange={(values: any) => {
                                const { value } = values
                                if(value.length === 7 ){
                                CTrafDataService.ValidaTraficoDuplicado(Aduana, Patente, value, props.IdTrafico).then( response => {
                                    if(response.data !== ""){
                                        setHeader('Informativo')
                                        setMsg(`Pedimento Duplicado.\n\rLa referencia ${response.data} ya cuenta con los mismos datos de aduana, patente y pedimento`)
                                        setShowMsg(true)
                                        return
                                    }
                                    setPedimento(value.length === 0 ? 0 : value)
                                })
                                }
                            }}
                            style={{
                                fontSize: '18px',
                                backgroundColor: '#FEFDF5',
                                border: '2px solid #837F5D',
                                width: '100%',
                                textAlign: 'right',
                                borderRadius: '10px'
                            }}
                            />
                        </FormLabel>
                    </Col>
                    <Col xs={2}>
                        <Form.Label>
                            Clave
                            <Form.Control
                                as="select"
                                id="CmbClavePedimento"
                                onChange={(e) => {
                                    setClavePedimento(e.target.value)
                                }}
                                className="form-select form-select-sm"
                                value={ClavePedimento}
                            >
                                <option value="-Seleccione-">-Seleccione-</option>
                                {props.ClavesPedimento
                                    ? props.ClavesPedimento.map((item, index) => {
                                        return (
                                        <option key={item.id} value={item.clave}>
                                            {item.clave}
                                        </option>
                                        )
                                    })
                                : ''}
                            </Form.Control> 
                        </Form.Label>
                    </Col>
                    <Col xs={2}>
                        <Form.Label>
                            Fec. Pago
                            <Form.Control
                                type="date"
                                id="FechaPago"
                                size="sm"
                                value={FechaPago}
                                onChange={(e) => setFechaPago(e.target.value)}
                            />
                        </Form.Label>
                    </Col>
                    <Col xs={2}>
                        <Button onClick={() => Append()}>Agregar</Button>
                    </Col>
                </Row>
                <hr /></> : ("") }
                <div className="table-wrapper" style={{width:'100%', maxHeight:'100%'}}>
                    <Table
                        hover
                        size='sm'
                    >
                        <thead>
                            <tr><th>#</th>{props.Depto === 'Corresponsalias' ? <th>Acciones</th> : ''}<th>Aduana</th><th>Patente</th><th>Pedimento</th><th>Clave</th><th>Fecha de Pago</th></tr>
                        </thead>
                        <tbody>
                            {mCPedimentosConsolidados
                                ? mCPedimentosConsolidados.map((item, index) => {
                                    return item.id > 0 ? 
                                    <tr>
                                        <td>{index}</td>
                                        {props.Depto === 'Corresponsalias' ? 
                                            <td><div>
                                            <IconContext.Provider value={{ color: 'blue', size: '18px' }}>
                                            <BsPencilFill
                                                onClick={() => {
                                                    loadRow(item)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            </IconContext.Provider>
                                            &nbsp;&nbsp;&nbsp;
                                            <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                            <BsTrash
                                                onClick={() => {
                                                    Delete(item.id)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            </IconContext.Provider>
                                        </div></td> : ''
                                        }
                                        <td>{item.aduana}</td>
                                        <td>{item.patente}</td>
                                        <td><div style={{display:'flex',  alignItems:'start'}}>{item.pedimento} {item.id === 0 ? <span style={{fontSize:'8px'}}>{'⭐'}</span> : ''}</div></td>
                                        <td>{item.clave}</td>
                                        <td>{item.fechaPago ? item.fechaPago.substring(0,10) : ''}</td>
                                    </tr>
                                    : ''
                                })
                            : ''}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
        <MsgInformativo
            show={show}
            msg={msg}
            header={header}
            msgColor={msgColor}
            closeToast={() => {
            setShowMsg(false)
            }}
        />
      </>
    )
}