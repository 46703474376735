import http from "../common/http-common";
import ICorresponsalPartidas from "../../Interfaces/Corresponsales/ICorresponsalPedimentoPartidas";
import IRespuesta from "../../Interfaces/IRespuesta";

class CorresponsalesDataService {

  Append(data: ICorresponsalPartidas) {
    return http.post<ICorresponsalPartidas>("/Corresponsalias/PedimentoPartida/Append", data);
  }
  GetAll(idTrafico: number) {
    return http.get<ICorresponsalPartidas[]>(`/Corresponsalias/PedimentoPartida/GetAll?idTrafico=${idTrafico}`);
  }
  Delete(id: number) {
    return http.delete<IRespuesta[]>(`/Corresponsalias/PedimentoPartida/Delete/${id}`);
  }

}
export default new CorresponsalesDataService();