import React from "react";
import { SideBarMenu } from "../Utils/SideBar/SideBar";
import { Outlet} from "react-router-dom";


export const ClientesExternos: React.FC = () => {
    
    return (
        <div className="container-fluid m-1">
            <div className="row flex-nowrap">
                <SideBarMenu/>
                <Outlet/>
            </div>
        </div>
    )
}