import React, { FC, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { IconContext } from 'react-icons';
import { FaEraser, FaTimesCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import ICorresponsalTerceros from '../../../Interfaces/Corresponsales/ICorresponsalFacturasTerceros';
import { RootState } from '../../../store/store';
import { MsgInformativo } from '../../Utils/Toast/msgInformativo';
import CFac3DataService from '../../../Services/Corresponsalias/Corresponsales.FacturasTerceros.services';
import { ControlledInput } from './ControlledInput/ControlledInput';
import {
  addCorresponsalesFacturasTerceros,
  deleteCorresponsalesFacturasTerceros,
} from '../../../store/features/Corresponsales/CorresponsalesFacturasTercerosSlice';
import { CatProveedores } from '../CatProveedores/CatProveedores';
import { FcManager } from 'react-icons/fc';
import { resourceUsage } from 'process';

interface IProps {
  IDTrafico: number;
  IDCorresponsal: number;
  canDelete: boolean;
}

export const PagosTerceros: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const mCFacturas3 = useSelector(
    (state: RootState) => state.CF3rosData.CorresponsalesFacturasTerceros
  );
  const mProveedores = useSelector(
    (state: RootState) => state.CatProveedores.CatalogoProveedores
  );
  const [IDProveedor, setIDProveedor] = useState(-1);
  const [DTData, setDTData] = useState<ICorresponsalTerceros[]>([]);
  const [ShowModal, setShowModal] = useState(false);
  const [Factura, setFactura] = useState('');
  const [IDFactura, setIDFactura] = useState(0);
  const [header, setHeader] = useState('');
  const [msgColor, setMsgColor] = React.useState('primary');
  const [show, setShowMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [MsgTime, setMsgTime] = useState(4000);
  const [DialogTabs, setDialogTabs] = useState(false);
  const [msgDialog, setMsgDialog] = useState(false);
  const [ClasificacionProveedor, setClasificacionProveedor] = useState(1);

  const columnsFacturas3 = [
    {
      name: 'id',
      width: '10%',
      selector: (row: ICorresponsalTerceros) => row.id,
      sortable: true,
    },
    {
      name: 'Factura',
      width: '75%',
      cell: (row: ICorresponsalTerceros) => {
        return <ControlledInput row={row} disabled={false} mode={2} />;
      },
    },
    {
      name: 'Elimina',
      cell: (row: ICorresponsalTerceros) => (
        <div
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            visibility: props.canDelete ? 'visible' : 'hidden',
          }}
          onClick={() => {
            setIDFactura(row.id);
            setFactura(row.factura);
            setDialogTabs(true);
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <FaTimesCircle />
          </IconContext.Provider>
        </div>
      ),
    },
  ];

  useEffect(() => {
    CFac3DataService.getAll(props.IDTrafico)
      .then((response) => {
        setDTData(response.data);
      })
      .catch((e: Error) => {
        setHeader('Error');
        setMsg('Ocurrio un error: ' + e);
        setShowMsg(true);
        return;
      });
    mProveedores.length > 0
      ? setIDProveedor(mProveedores[0].id)
      : setIDProveedor(0);
  }, []);

  const saveForm = () => {
    if (IDProveedor === 0) {
      setHeader('Error');
      setMsg('Para poder continuar, por favor, seleccione el proveedor');
      setShowMsg(true);
      return false;
    }
    if (Factura.length === 0) {
      setHeader('Error');
      setMsg('Para poder continuar, por favor, proporcione la factura');
      setShowMsg(true);
      return false;
    }
    const data: ICorresponsalTerceros = {
      id: IDFactura,
      factura: Factura,
      idTrafico: props.IDTrafico,
      idProveedor: IDProveedor,
    };
    CFac3DataService.Append(data)
      .then((response) => {
        if (response.status === 200) {
          setHeader('Informativo');
          setMsg(response.data.respuesta);
          setShowMsg(true);
          setDialogTabs(false);
          if (data.id === 0)
            dispatch(addCorresponsalesFacturasTerceros(response.data.registro));
        }
      })
      .catch((error: any) => {
        if (error.response) {
          setHeader('Error: No se permite duplicidad');
          setMsgColor('warning');
          setMsgTime(10000);
          setMsg(
            error.response.data.respuesta +
              ' en la factura: ' +
              error.response.data.registro.factura +
              ' en el trafico: CG' +
              error.response.data.registro.idTrafico
          );
          setShowMsg(true);
          return;
        }
      });
  };

  const deleteItem = () => {
    CFac3DataService.Delete(IDFactura)
      .then((response) => {
        if (response.status === 200) {
          setHeader('Informativo');
          setMsg(response.data.respuesta);
          setShowMsg(true);
          setDialogTabs(false);
          dispatch(deleteCorresponsalesFacturasTerceros(IDFactura));
          return;
        }
      })
      .catch((e: Error) => {
        setHeader('Error');
        setMsg('Ocurrio un error: ' + e);
        setShowMsg(true);
        return;
      });
  };

  const cleanForm = () => {
    setFactura('');
    setIDFactura(0);
  };

  return (
    <div>
      <Card style={{}}>
        <Card.Body>
          <Card.Subtitle className='mb-2 text-muted'>
            <Row
              style={{ paddingBottom: '5', paddingTop: '15' }}
              className={props.canDelete ? 'visible' : 'invisible heightZero'}
            >
              <Col
                xs={1}
                style={{
                  textAlign: 'right',
                  paddingTop: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  cleanForm();
                }}
              >
                <IconContext.Provider value={{ color: 'orange', size: '25px' }}>
                  <FaEraser />
                </IconContext.Provider>
              </Col>
              <Col>
                <Form.Control
                  type='hidden'
                  id='IdItem'
                  size='sm'
                  value={IDFactura}
                  placeholder='Contenedor'
                  disabled={true}
                />
              </Col>
              <Col xs={2}>
                {/* <Form.Control
                  type='text'
                  id='FacturaTerceros'
                  size='sm'
                  value={Factura}
                  placeholder='Factura 3ros'
                  pattern='[a-zA-Z-0-9 ]*'
                  onChange={(e) => setFactura((v) => (e.target.validity.valid ? e.target.value : v))}
                /> */}
                <input
                  type='text'
                  className='form-control genericSelect'
                  name='Factura'
                  id='Factura'
                  style={{ height: '30px' }}
                  value={Factura}
                  placeholder='Factura'
                  onChange={(e) => {
                    setFactura(e.target.value);
                  }}
                />
              </Col>
              <Col xs={5}>
                <Form.Control
                  as='select'
                  id='CmbIDProveedor'
                  onChange={(e) => {
                    setIDProveedor(parseInt(e.target.value));
                  }}
                  className='form-select form-select-sm'
                  value={IDProveedor}
                >
                  {mProveedores
                    ? mProveedores
                        .filter(function (row) {
                          return (
                            row.clasificacion === ClasificacionProveedor ||
                            row.id === 0
                          );
                        })
                        .map((item, index) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.nombre}
                            </option>
                          );
                        })
                    : ''}
                </Form.Control>
              </Col>
              <Col xs={1} style={{ cursor: 'pointer' }}>
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
                    <FcManager />
                  </IconContext.Provider>
                </Button>
              </Col>
              <Col xs={1}></Col>
              <Col xs={1}>
                <Button
                  id='BtnSave'
                  variant='primary'
                  size='sm'
                  onClick={() => {
                    saveForm();
                  }}
                >
                  Agregar
                </Button>
              </Col>
              <Col>&nbsp;</Col>
            </Row>
          </Card.Subtitle>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            pagination
            highlightOnHover
            columns={columnsFacturas3}
            data={mCFacturas3.filter(function (el) {
              return el.id > 0 && el.idTrafico === props.IDTrafico;
            })}
          />
        </Card.Body>
      </Card>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        time={MsgTime}
        closeToast={() => {
          setShowMsg(false);
        }}
      />
      <Modal
        show={DialogTabs}
        onHide={() => {
          setDialogTabs(false);
        }}
        size='sm'
      >
        <Modal.Body>
          <Alert variant='primary'>
            <b>¿Esta seguro de eliminar?</b>
            <br />
            <h5> {Factura} </h5>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6} style={{ textAlign: 'left', paddingRight: '100px' }}>
              <Button
                variant='danger'
                onClick={deleteItem}
                size='sm'
                style={{ paddingRight: '20px', paddingLeft: '20px' }}
              >
                Si
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                variant='secondary'
                onClick={() => {
                  setDialogTabs(false);
                }}
                size='sm'
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false);
        }}
        size='sm'
        dialogClassName='modal-50w'
      >
        <Modal.Body>
          <CatProveedores canDelete={true} clasificacion={1} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
