import http from '../common/http-common'
import ICorresponsalesTraficoContabilidad from '../../Interfaces/Corresponsales/ICorresponsalTraficoContabilidad'
import ICorresponsalRazonRechazo from '../../Interfaces/Corresponsales/ICorresponsalRazonesRechazo'

class Contabilidad_Corresponsales_DataService {
  Get(idTrafico: number, nuevoProceso: number, procesoAnterior: number) {
    return http.get<ICorresponsalRazonRechazo[]>(
      `/Contabilidad/Corresponsales/ContabilidadTrafico/Get?idTrafico=${idTrafico}&nuevoProceso=${nuevoProceso}&procesoAnterior=${procesoAnterior}`
    )
  }
  Append(data: ICorresponsalesTraficoContabilidad) {
    return http.post<ICorresponsalesTraficoContabilidad>(
      '/Contabilidad/Corresponsales/ContabilidadTrafico/Validacion',
      data
    )
  }
  GetHistorial(idTrafico: number){
    return http.get<ICorresponsalRazonRechazo[]>(`/Contabilidad/Corresponsales/ContabilidadTrafico/Historial?idTrafico=${idTrafico}`);
  }
}
export default new Contabilidad_Corresponsales_DataService()
