import http from "../common/http-common";
import IRespuesta from "../../Interfaces/IRespuesta";
import ITabuladorConceptos from "../../Interfaces/Catalogos/ITabuladorConceptos";

class Corresponsales_Precuenta_DataService {
  GetAll(id: number, IdTrafico: number) {
    return http.get<ITabuladorConceptos[]>(`/Corresponsalias/Precuenta/getAll?id=${id}&idTrafico=${IdTrafico}`);
  }
  ChangeStatus(id: number) {
    return http.delete<IRespuesta>(`/Corresponsalias/Precuenta/ChangeStatus/${id}`);
  }
  Append(id: number, IdTrafico: number) {
    return http.put<ITabuladorConceptos[]>(`/Corresponsalias/Precuenta/Append/${id}/${IdTrafico}`);
  }
}
export default new Corresponsales_Precuenta_DataService();