import http from '../common/http-common';

class ReportesAlenService{

    getRelacionFacturas(referencia: string){
        return http.get(`ReportesAlen/RelacionFacturas?referencia=${referencia}`, {responseType: 'blob'});
    }
    getFacturasConsolidados(referencia: string){
        return http.get(`ReportesAlen/FacturasConsolidados?referencia=${referencia}`, {responseType: 'blob'});
    }
}

export default new ReportesAlenService();