import { DTOPedimentosConsolidados } from "../../DTO/Corresponsales/DTOPedimentosConsolidados";
import http from "../common/http-common";

class PedimentosConsolidadosService{
    Append(data: DTOPedimentosConsolidados) {
        return http.post<DTOPedimentosConsolidados>("/Corresponsalias/PedimentosConsolidados/Append", data);
      }
      GetAll(idTrafico: number) {
        return http.get<DTOPedimentosConsolidados[]>(`/Corresponsalias/PedimentosConsolidados/GetAll?IdTrafico=${idTrafico}`);
      }
      Delete(id: number) {
        return http.delete<DTOPedimentosConsolidados>(`/Corresponsalias/PedimentosConsolidados/Delete/${id}`);
      }
}

export default new PedimentosConsolidadosService();