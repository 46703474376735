import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICatTiposContenedores } from '../../../Interfaces/Catalogos/ICatTiposContenedores'

const CatTiposContenedores: ICatTiposContenedores[] = []
const initialState = {CatTiposContenedores}

export const CatTiposContenedoresSlice = createSlice({
    name: 'CatTiposContenedores',
    initialState: initialState,
    reducers: {
        populateCatTiposContenedores : (state, action: PayloadAction<ICatTiposContenedores[]>) => {
            state.CatTiposContenedores = []
            state.CatTiposContenedores.push(... action.payload)
          },
    }
})

export const {
    populateCatTiposContenedores, 
    } = CatTiposContenedoresSlice.actions;
  export default CatTiposContenedoresSlice.reducer;
