import http from "../common/http-common";
import { IRegister } from "../../Interfaces/IRegister";
import DTOUsuariosShort from "../../DTO/DTOUsuariosShort";
import DTOClonarUsuario from "../../DTO/DTOClonarUsuario";
import { IUsuario } from "../../Interfaces/IUsuario";


class usuariosService {
  constructor() {
  }
  validate() {
    return http.get<null>("/Auth/validate");
  }
  getAllUsuarios() {
    return http.get<IRegister[]>("/Usuarios/getAllUsuarios");
  }
  getAllUsuariosShort() {
    return http.get<DTOUsuariosShort[]>("/Usuarios/getAllUsuariosShort");
  }
  create(data: IUsuario) {
    return http.post<[IRegister[]]>("/Usuarios/createUsuario", data);
  }
  clonarUsuario(data: DTOClonarUsuario) {
    return http.post<[DTOClonarUsuario[]]>("/Usuarios/clonarUsuario", data);
  }
  disableUser(id: number) {
    return http.put<boolean>(`/Usuarios/disableUser?id=${id}`);
  }
}
export default new usuariosService();