import http from "../common/http-common";

class ReportesNewellService{

    getReporteMensual(IdTrailasFile: number, IdOpCdAcunaFile: number, IdOpMyMFile: number){
        return http.get(`reportesNewell/ReporteMensual?idTrailasFile=${IdTrailasFile}&idRptCdAcunaFile=${IdOpCdAcunaFile}&idRptLazaroCardenasFile=${IdOpMyMFile}`, {responseType: 'blob'});
    }
    getReporteSemanal(idRptSemanalFile: number){
        return http.get(`reportesNewell/ReporteSemanal?idRptSemanalFile=${idRptSemanalFile}`, {responseType: 'blob'});
    }
}

export default new ReportesNewellService();