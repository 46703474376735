import React, { FC, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { IconContext } from 'react-icons'

import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import {
  BsPlusLg,
  BsFileEarmarkExcel,
  BsSearch,
  BsFillXCircleFill,
  BsCheckCircleFill,
} from 'react-icons/bs'
import { RowClickedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'

import * as XLSX from 'xlsx'
import moment from 'moment'

import { ProgressBar } from '../../Reportes/customCells/progressBar'

import DTORptCorresponsalesTraficos from '../../../DTO/Corresponsales/DTORptCorresponsalesTraficos'
import ICatCorresponsales from '../../../Interfaces/Catalogos/ICatCorresponsales'
import IClientes from '../../../Interfaces/Catalogos/IClientes'
import ICorresponsalTrafico from '../../../Interfaces/Corresponsales/ICorresponsalTrafico'
import { InitCorresponsalesContenedores } from '../../../store/features/Corresponsales/CorresponsalesContenedoresSlice'
import { InitCorresponsalesFacturas } from '../../../store/features/Corresponsales/CorresponsalesFacturasSlice'
import { InitCorresponsalesFacturasTerceros } from '../../../store/features/Corresponsales/CorresponsalesFacturasTercerosSlice'
import TraficoCorresponsales from '../../Corresponsales/TraficoCorresponsales'
import ClientesServices from '../../../Services/Catalogos/Clientes.Services'
import reportesServices from '../../../Services/Reportes/reportes.services'
import DTOFiltrosTraficosClientes from '../../../DTO/Corresponsales/DTOFiltrosTraficosClientes'
import TraficoCliente from './TraficoCliente'
import { ClienteProgressBar } from '../../Reportes/customCells/ClienteProgressBar'
/* import '../../css/generic01.css' */

interface IProps {}

export default function RptClientesTraficos(props: IProps) {
  const { proc, modo } = useParams()
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  }) 
  const queryParams = new URLSearchParams(window.location.search)
  const status = queryParams.get('status')
  const [StatusAnticipos, setStatusAnticipos] = useState(
    status ? parseInt(status) : 0
  )
  const dispatch = useDispatch()
  const [IDTrafico, setIDTrafico] = useState(0)
  const [Data, setData] = useState<ICorresponsalTrafico[]>([])
  const [filteredData, setFilteredData] = useState<ICorresponsalTrafico[]>([])
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [Inicio, setInicio] = useState(currentDate(-365))
  const [Fin, setFin] = useState(currentDate(0))
  const [TipoOperacion, setTipoOperacion] = useState(0)
  const [Cliente, setCliente] = useState(0)
  const [Corresponsal, setCorresponsal] = useState(() => {
    const stickyValue = window.localStorage.getItem('IdCorresponsal')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  //const [Corresponsal, setCorresponsal] = useState(0)
  const [Pedimento, setPedimento] = useState(0)
  const [Patente, setPatente] = useState(0);
  const [Aduana, setAduana] = useState('0');
  const [Referencia, setReferencia] = useState('')
  const [Estado, setEstado] = useState(0)
  const [Clientes, setClientes] = useState<Array<IClientes>>()
  const [filtro, setFiltro] = useState('')
  const gridRef = React.useRef<any>(null)
  const [DataCorresponsales, setDataCorresponsales] = useState<
    ICatCorresponsales[]
  >([])
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [columnDefs] = useState([
    { field: 'id', headerName: 'id', width: 70, sortable: true, filter: true },
    {
      field: 'proceso',
      headerName: 'Status',
      width: 90,
      center: true,
      sortable: true,
      filter: true,
      cellRenderer: ClienteProgressBar,
    },
    {
      field: 'folioGemco',
      headerName: 'Trafico',
      width: 140,
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.data.folioGemco
      },
    },
    {
      field: 'fechaRegistro',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        return params.value?.substring(0, 10)
      },
    },
    { field: 'sUsuario', headerName: 'Usuario', sortable: true, filter: true },
    {
      field: 'sCliente',
      headerName: 'Cliente',
      sortable: true,
      filter: true,
    },
    {
      field: 'sTipoOperacion',
      headerName: 'Tipo operacion',
      sortable: true,
      filter: true,
    },
    {
      field: 'sEstatus',
      headerName: 'Estado operacion',
      sortable: true,
      filter: true,
    },
    {
      field: 'sCorresponsal',
      headerName: 'Corresponsal',
      sortable: true,
      filter: true,
      hide: Perfil === 'Corresponsales' || Perfil === 'Clientes',
    },
    { field: 'patente', sortable: true, filter: true },
    { field: 'aduana', sortable: true, filter: true },
    { field: 'pedimento', sortable: true, filter: true },
    {
      field: 'fechaPago',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value) return params.value.substring(0, 10)
        else return ''
      },
    },
    {
      field: 'fechaDesaduanamiento',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => {
        if (params.value) return params.value.substring(0, 10)
        else return ''
      },
    },
    {
      field: 'zipgemco',
      headerName: 'Zip GEMCO',
      sortable: true,
      filter: true,
      hide: Perfil === 'Corresponsales' || Perfil === 'Clientes',
      cellRenderer: (params: any) => {
        if (params.value === 'No')
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
        else
          return (
            <div style={{ paddingLeft: '20px' }}>
              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
      },
    },
    {
      field: 'zipCorresponsal',
      headerName: 'Zip Corresponsal',
      sortable: true,
      filter: true,
      hide: Perfil === 'Corresponsales' || Perfil === 'Clientes',
      cellRenderer: (params: any) => {
        if (params.value === 'No')
          return (
            <div style={{ paddingLeft: '35px' }}>
              <IconContext.Provider value={{ color: 'red', size: '25px' }}>
                <BsFillXCircleFill />
              </IconContext.Provider>
            </div>
          )
        else
          return (
            <div style={{ paddingLeft: '35px' }}>
              <IconContext.Provider value={{ color: 'green', size: '25px' }}>
                <BsCheckCircleFill />
              </IconContext.Provider>
            </div>
          )
      },
    },
    { field: 'ultimaActualizacion', sortable: true, filter: true },
  ])
  const [msgColor, setMsgColor] = useState('primary')

  function ConvertHexToString(str: string) {
    return unescape(str.replace(/\\/g, '%'))
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  useEffect(() => {
    ClientesServices.getAllClientes(UserId)
    .then((response) => {
      setClientes(response.data)
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Ocurrio un error: ' + e)
      setShowMsg(true)
      return
    })
    generaReporte()
  }, [])

  function getParams(e: RowClickedEvent) {
    AbreTrafico(e.data.id)
  }

  function AbreTrafico(id: number) {
    dispatch(InitCorresponsalesFacturas(IDTrafico))
    dispatch(InitCorresponsalesFacturasTerceros(IDTrafico))
    dispatch(InitCorresponsalesContenedores(IDTrafico))
    setIDTrafico(id)
    setShowModal(true)
  }

  const generaReporte = () => {
    const data: DTOFiltrosTraficosClientes = {
      Inicio: moment(Inicio).format('YYYY-MM-DD'),
      Fin: moment(Fin).format('YYYY-MM-DD'),
      TipoOperacion: TipoOperacion,
      NoCliente: Cliente,
      IdCorresponsal: Corresponsal,
      Pedimento: Pedimento,
      Aduana: Aduana,
      Patente: Patente,
      Referencia: Referencia,
      IdUsuario: UserId,
      Estado: Estado
    }
    ClientesServices
    .GetTraficos(data)
    .then((response) => {
      setData(response.data)
      setFilteredData(response.data)
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Ocurrio un error: ' + e)
      setShowMsg(true)
      return
    })
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    gridRef.current.api.setQuickFilter(e.target.value)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Traficos de corresponsales')
  }

  function exportExcel(jsonData: any[], fileName: string): void {
    const data: DTOFiltrosTraficosClientes = {
      Inicio: moment(Inicio).format('YYYY-MM-DD'),
      Fin: moment(Fin).format('YYYY-MM-DD'),
      TipoOperacion: TipoOperacion,
      NoCliente: Cliente,
      IdCorresponsal: Corresponsal,
      Pedimento: Pedimento,
      Aduana: Aduana,
      Patente: Patente,
      Referencia: Referencia,
      IdUsuario: UserId,
      Estado: Estado
    }
    ClientesServices.GetExcel(data)
    .then(resp => {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Reporte_Operaciones.xlsx`);
      document.body.appendChild(link);
      link.click();
    }).catch(e => {
        setHeader('Error')
        setMsg('Ocurrio un error al generar el reporte. Por favor, intentelo de nuevo.')
        setShowMsg(true)
        return
    })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={8}>
              <Row>
                <Col xs={5}>
                  <Form.Control
                    type='text'
                    size='sm'
                    placeholder='Referencia'
                    onChange={(e) => {
                      setReferencia(e.target.value)
                      //filtraReporte(e)
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Control
                    type='text'
                    size='sm'
                    placeholder='Pedimento'
                    onChange={(e) => setPedimento(+e.target.value)}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Control
                    type='text'
                    size='sm'
                    placeholder='Aduana'
                    onChange={(e) => setAduana(e.target.value === '' ? '0' : e.target.value)}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Control
                    type='text'
                    size='sm'
                    placeholder='Patente'
                    onChange={(e) => setPatente(+e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row>
                <Col xs={6}>
                  <Form.Control
                    defaultValue={Inicio}
                    type='date'
                    name='Inicio'
                    placeholder='Inicio'
                    title='Inicio'
                    alt='Inicio'
                    data-date-format='YYYY-mm-dd'
                    onChange={(e) => setInicio(e.target.value)}
                    size='sm'
                  />
                </Col>
                <Col xs={6}>
                  <Form.Control
                    defaultValue={Fin}
                    type='date'
                    name='Fin'
                    placeholder='Fin'
                    title='Fin'
                    alt='Fin'
                    onChange={(e) => setFin(e.target.value)}
                    size='sm'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{paddingTop:'10px'}}>
            <Col lg={2}>
              <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                Tipo operacion
                <Form.Control
                  as='select'
                  onChange={(e) => setTipoOperacion(parseInt(e.target.value))}
                  className='form-select form-select-sm'
                >
                  <option value='0'>-SELECCIONE-</option>
                  <option value='1'>Importacion</option>
                  <option value='2'>Exportacion</option>
                </Form.Control>
              </Form.Label>
            </Col>
            <Col lg={4}>
              <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                Cliente
                <Form.Control
                  as='select'
                  onChange={(e) => {
                    setCliente(parseInt(e.target.value))
                  }}
                  className='form-select form-select-sm'
                >
                  <option value='0'>-SELECCIONE-</option>
                  {Clientes
                    ? Clientes.map((c) => {
                        return (
                          <option value={c.sClave} key={c.sClave}>
                            {c.sRazonSocial}
                          </option>
                        )
                      })
                    : null}
                </Form.Control>
              </Form.Label>
            </Col>
            <Col lg={2}>
              <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                Estado
                <Form.Control
                  as='select'
                  onChange={(e) => {
                    setEstado(parseInt(e.target.value))
                  }}
                  className='form-select form-select-sm'
                >
                  <option value='0'>-SELECCIONE-</option>
                  <option value='1'>Por Terminar</option>
                  <option value='2'>Despachado Sin Facturar</option>
                  <option value='3'>Despachado y Facturado</option>
                </Form.Control>
              </Form.Label>
            </Col>
            {(Perfil !== "Corresponsales" && Perfil !== "Clientes")? <>
              <Col lg={4}>
                <Form.Label style={{width:'100%', fontSize:'smaller'}}>
                  Corresponsal
                  <Form.Control
                    as='select'
                    onChange={(e) => {
                      setCorresponsal(parseInt(e.target.value))
                    }}
                    className='form-select form-select-sm'
                  >
                    <option value='0'>-SELECCIONE-</option>
                    {DataCorresponsales
                      ? DataCorresponsales.map((c) => {
                          return (
                            <option value={c.id} key={c.id}>
                              {c.nombre}
                            </option>
                          )
                        })
                      : null}
                  </Form.Control>
                </Form.Label>
              </Col></> : ""
            }
          </Row>
          <Row style={{paddingTop:'10px'}}>
            <Col lg={12} style={{textAlign:'end'}}>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  generaReporte()
                }}
                style={{marginRight:'5px'}}
              >
                <BsSearch />
                &nbsp; Buscar
              </Button>
                <Button
                  size='sm'
                  variant='success'
                  onClick={() => {
                    downloadExcel()
                  }}
                  style={{marginRight:'5px'}}
                >
                  <BsFileEarmarkExcel />
                  &nbsp; Excel
                </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={filteredData}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          ref={gridRef}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={true}
          onRowClicked={(e) => getParams(e)}
        ></AgGridReact>
      </div>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
      <Modal
        show={ShowModal}
        size='sm'
        dialogClassName='modal-90w'
        disableEscapeKeyDown={true}
      >
        <Modal.Body
          style={{
            height: 'calc(90vh)',
            overflowY: 'auto',
          }}
        >
          <div style={{height:'100%'}}>
            <TraficoCliente
              IDTrafico={IDTrafico}
              onClose={function (val: boolean): void {
                setShowModal(false)
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
