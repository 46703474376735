import { ICellRendererParams } from 'ag-grid-community'
import { FC, useEffect, useState } from 'react'

interface IProps {
  value: number
}

export const ProgressBar: FC<ICellRendererParams> = (props: ICellRendererParams) => {
  const [Proceso1, setProceso1] = useState(props.value >= 2 ? 100 : 0)
  // const [Proceso2, setProceso2] = useState(props.value >= 3 ? 100 : 0)
  // const [Proceso3, setProceso3] = useState(props.value >= 4 ? 100 : 0)
  const [Color, setColor] = useState('progress-bar bg-CORRESPONSAL_NORMAL')

  useEffect(() => {
    if (props.data.proceso === 0) setColor('progress-bar bg-RECHAZOS_PARA_CORRESPONSAL')
    if (props.data.proceso === 1 || props.data.proceso === 2) {
      if (props.data.rechazado > 0) setColor('progress-bar bg-RECHAZOS_POR_CONTABILIDAD')
      else setColor('progress-bar bg-CORRESPONSAL_NORMAL')
    }
    if (props.data.proceso === 3) setColor('progress-bar bg-FACTURACION_LISTOS_PARA_FACTURAR')
    if (props.data.proceso === 4) setColor('progress-bar bg-CORRESPONSAL_NORMAL')
  }, [props])

  const getTooltipTitle = () => {
    let proceso = props.data.proceso;
    switch(proceso){
      case 1:
          return "Corresponsalias"
        break;
      case 2:
          return "Contabilidad"
        break;
      case 3:
          return "Facturacion"
        break;
      case 4:
          return "Facturado"
        break;
    }
  }

  return (
    <div>
      <div style={{ paddingTop: '20%' }}>
        <div className='progress' style={{ maxWidth: '80%' }}>
          <div className={Color} style={{ width: '100%' }} data-toggle="tooltip" data-placement="bottom" title={getTooltipTitle()}></div>
        </div>
      </div>
    </div>
  )
}
