import { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { FaRegFileExcel } from "react-icons/fa";
import { MdCloudUpload, MdDeleteForever, MdCloudDownload } from "react-icons/md";
import { MsgInformativo } from "../Utils/Toast/msgInformativo";
import reportesNewellServices from "../../Services/Reportes/reportesNewell.services";
import axios from "axios";
import { TargetURL } from "../../Constants/TargetURL";
import FileManager from "../Utils/FileManager/FileManager";
import FileManagerServices from "../../Services/Utils/FileManager.Services";

export const ReporteMensualNewell: React.FC<{}> = () => {
    const [Mes, setMes] = useState('Septiembre')
    const [show, setShowMsg] = useState(false)
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [msgColor, setMsgColor] = useState('primary')
    const [UserId, setUserId] = useState(() => {
        const stickyValue = window.localStorage.getItem('UserId')
        return stickyValue !== null ? JSON.parse(stickyValue) : 0
      });
    const [IdTrailasFile, setIdTrailasFile] = useState(0);
    const [IdOpCdAcunaFile, setIdOpCdAcunaFile] = useState(0);
    const [IdOpMyMFile, setIdOpMyMFile] = useState(0);

    const GenerarReporteMensualNewell = async () => {
        if (IdTrailasFile === 0 || IdOpCdAcunaFile === 0 || IdOpMyMFile === 0){
            setHeader('Error')
            setMsg('Asegurese de cargar todos los archivos para continuar.')
            setShowMsg(true)
            return;
        }
        reportesNewellServices.getReporteMensual(IdTrailasFile, IdOpCdAcunaFile, IdOpMyMFile)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ReporteMensual_${Mes}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            console.log(error);
        });
    }

    const convertFile = (fileId: number, process: number) => {
        FileManagerServices.ConvertFile(fileId, process).then((resp: any) => console.log(resp));
    }

    return (
        <>
            <Row style={{paddingTop:'7rem'}}></Row>
            <Row>
                <Col xs={4}></Col>
                <Col xs={4}>
                    <Card style={{width:'100%'}}>
                        <Card.Header><h4>Reporte Mensual Newell</h4></Card.Header>
                        <Card.Body>
                            <Row className="justify-content-center">
                                <Col xs={6}>
                                    <Form.Label style={{width:'100%'}}>
                                        Mes 
                                        <Form.Control
                                            as="select"
                                            id="CmbOpEntrada"
                                            onChange={(e) => setMes(e.target.value)}
                                            value={Mes}
                                            className="form-select form-select-sm"
                                        >
                                            <option value="Enero">Enero</option>
                                            <option value="Febrero">Febrero</option>
                                            <option value="Marzo">Marzo</option>
                                            <option value="Abril">Abril</option>
                                            <option value="Mayo">Mayo</option>
                                            <option value="Junio">Junio</option>
                                            <option value="Julio">Julio</option>
                                            <option value="Agosto">Agosto</option>
                                            <option value="Septiembre">Septiembre</option>
                                            <option value="Octubre">Octubre</option>
                                            <option value="Noviembre">Noviembre</option>
                                            <option value="Diciembre">Diciembre</option>
                                        </Form.Control>
                                    </Form.Label>
                                </Col>
                            </Row>
                            <br/>
                            <Row style={{justifyContent:'center', alignItems:'center', padding:'0px 20px 0px 20px'}}>
                                <form 
                                style={{
                                    display: 'flex', flexDirection:'column', justifyContent:'center',
                                    alignItems:'center', height: '280px', width:'500px'
                                }}                                
                                >
                                    <div style={{
                                        width:'100%', padding:'10px', margin:'5px 0', display:'flex', alignItems:'center', justifyContent: 'space-between',
                                        border:'2px dashed rgb(20,117,207)', borderRadius:'5px'
                                    }}>
                                        <FileManager
                                            width={'25rem'}
                                            height={200}
                                            IDProcess={32}
                                            IDUser={UserId}
                                            IdFile={0}
                                            FileName={''}
                                            canDelete={true}
                                            FileType={['xls']}
                                            Leyenda={
                                            'Seleccione el reporte de trailas'
                                            }
                                            onAppendFM={function (idFile: number): void { setIdTrailasFile(idFile); convertFile(idFile, 32);}}
                                        />
                                    </div>
                                    <div style={{
                                        width:'100%', padding:'10px', margin:'5px 0', display:'flex', alignItems:'center', justifyContent: 'space-between',
                                        border:'2px dashed rgb(20,117,207)', borderRadius:'5px'
                                    }}>
                                        <FileManager
                                            width={'25rem'}
                                            height={200}
                                            IDProcess={33}
                                            IDUser={UserId}
                                            IdFile={0}
                                            FileName={''}
                                            canDelete={true}
                                            FileType={['xls']}
                                            Leyenda={
                                            'Seleccione el excel de operaciones de Cd. Acuña'
                                            }
                                            onAppendFM={function (idFile: number): void { setIdOpCdAcunaFile(idFile);  convertFile(idFile, 33); }}
                                        />
                                    </div>
                                    <div style={{
                                        width:'100%', padding:'10px', margin:'5px 0', display:'flex', alignItems:'center', justifyContent: 'space-between',
                                        border:'2px dashed rgb(20,117,207)', borderRadius:'5px'
                                    }}>
                                        <FileManager
                                            width={'25rem'}
                                            height={200}
                                            IDProcess={34}
                                            IDUser={UserId}
                                            IdFile={0}
                                            FileName={''}
                                            canDelete={true}
                                            FileType={['xlsx']}
                                            Leyenda={
                                            'Seleccione el excel de operaciones de Lazaro Cardenas'
                                            }
                                            onAppendFM={function (idFile: number): void { setIdOpMyMFile(idFile);}}
                                        />
                                    </div>
                                </form>
                            </Row>
                        </Card.Body>
                        <Card.Footer style={{textAlign:'end'}}>
                            <Button className="primary" onClick={GenerarReporteMensualNewell} title="Descargar reporte">
                                Generar <MdCloudDownload style={{marginLeft:'5px'}}/>
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col xs={4}></Col>
            </Row>
            <MsgInformativo
                show={show}
                msg={msg}
                header={header}
                msgColor={msgColor}
                closeToast={() => {
                setShowMsg(false)
                }}
            />
        </>
    )
}