import React, { FC, useState } from 'react'
import { Button } from 'react-bootstrap'
import { DialogAE } from '../ArchivoElectronico/DialogAE'
import ArchivoElectronicoDataServices from '../../../Services/Catalogos/ArchivoElectronico.Services'
import IFileManager from '../../../Interfaces/Utils/IFileManager';
import FileManagerServices from '../../../Services/Utils/FileManager.Services';
interface IProps {
  referencia: string;
}

export const VerDocumentos: FC<IProps> = (props) => {
  const [showDialog, setShowDialog] = useState(false)
  const [Archivos, setArchivos] = useState<IFileManager[]>([]);
  const showDocumentos = () => {
    FileManagerServices.getFileListByreferencia(props.referencia)
    .then((response) => {
      const filteredFileList = response.data.filter(x=> [2,10,11,12,13,14,15,19,17,18,37,38,39,41].includes(x.proceso))
      setArchivos(filteredFileList)
    })
    .catch((e: Error) => {
      console.log(e)
      return
    })
    setShowDialog(true)
  }

  const closeDialog = () => {
    setShowDialog(false)
  }

  return (
    <div>
      <span>
        <Button onClick={() => showDocumentos()} variant='secondary'>
          ver Documentos
        </Button>
      </span>
      <DialogAE showDialog={showDialog} header={''} closeDialog={closeDialog} archivos={Archivos}/>
    </div>
  )
}
