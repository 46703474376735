import http from "../common/http-common";
import DashboardCorresponsales from "../../Interfaces/Dashboard/DashboardCorresponsales";

class Dashboard_Facturacion_DataService {
 getFacturacion(){
    return http.get<DashboardCorresponsales[]>(`/Dashboard/Corresponsales/GetFacturacionDashboard`);
 }
 getSolicitudesReapertura(){
   return http.get<DashboardCorresponsales>(`/Dashboard/Corresponsales/SolicitudesReapertura`);
 }
}
export default new Dashboard_Facturacion_DataService();