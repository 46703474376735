import { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import UsuariosDS from '../../Services/Catalogos/Usuarios.Services'
import { MsgInformativo } from '../Utils/Toast/msgInformativo'
import DTOUsuariosShort from '../../DTO/DTOUsuariosShort'
import { IconContext } from 'react-icons'
import {
  BsCheckCircleFill,
  BsFillPencilFill,
  BsFillXCircleFill,
} from 'react-icons/bs'
import DataTable from 'react-data-table-component'
import { IUsuario } from '../../Interfaces/IUsuario'

export interface IUserControlProps {}

export default function UserControl(props: IUserControlProps) {
  const [Usuario, setUsuario] = useState('')
  const [IDEstatus, setIDEstatus] = useState(false)
  const [IDUser, setIDUser] = useState(0)
  const [header, setHerader] = useState('Error!')
  const [msgColor, setMsgColor] = useState('Primary')
  const [showMsg, setShowMsg] = useState(false)
  const [ModalDisableUser, setModalDisableUser] = useState(false)
  const [UsersData, setUsersData] = useState<DTOUsuariosShort[]>([])
  const [msg, setMsg] = useState('')

  const colData = [
    {
      name: 'id',
      width: '100px',
      selector: (row: DTOUsuariosShort) => row.id,
      sortable: true,
    },
    {
      name: 'Usuario',
      selector: (row: DTOUsuariosShort) => row.usuario,
    },
    {
        name: '',
        width: '80px',
        cell: (row: DTOUsuariosShort) => (
          <div
            style={{ textAlign: 'center', cursor: 'pointer' }}
            onClick={() => {
                setIDUser(row.id)
                setIDEstatus(row.activo)
                setUsuario(row.usuario)
            }}
          >
            <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
              <BsFillPencilFill />
            </IconContext.Provider>
          </div>
        ),
      },
    {
      name: 'Activo',
      width: '80px',
      cell: (row: DTOUsuariosShort) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIDUser(row.id)
            setIDEstatus(row.activo)
            setUsuario(row.usuario)
            setModalDisableUser(true)
          }}
        >
          {row.activo ? (
            <IconContext.Provider value={{ color: 'green', size: '25px' }}>
              <BsCheckCircleFill />
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ color: 'red', size: '25px' }}>
              <BsFillXCircleFill />
            </IconContext.Provider>
          )}
        </div>
      ),
    },
   
  ]

  const loadUsers = () => {
    UsuariosDS.getAllUsuariosShort()
      .then((response) => {
        //console.log(response.data)
        setUsersData(response.data)
      })
      .catch((e: Error) => {
        setMsg('Ocurrio un error: ' + e.toString())
        setShowMsg(true)
      })
  }

  useEffect(() => {
    loadUsers()
  }, [])

  const disableUser = () => {
    UsuariosDS.disableUser(IDUser)
    .then((response) => {
        setModalDisableUser(false)
        loadUsers()
    })
    .catch((e: Error) => {
      setMsg('Ocurrio un error: ' + e.toString())
      setShowMsg(true)
    })
  }

  const createUser = () => {
    const data: IUsuario = {
        id: IDUser,
        usuario: Usuario,
        nombre: Usuario,
        correo: Usuario  
    }
    UsuariosDS.create(data)
    .then((response) => {
        setModalDisableUser(false)
        loadUsers()
    })
    .catch((e: Error) => {
      setMsg('Ocurrio un error: ' + e.toString())
      setShowMsg(true)
    })
  }

  function closeToast(arg: boolean): void {
    throw new Error('Function not implemented.')
  }

  return (
    <div>
      <Row>
        <Col xs={12}>&nbsp;</Col>
      </Row>
      <Row>
        <Col></Col>
        <Col xs={4}>
          <Card>
            <Card.Header style={{ fontWeight: 'bold', fontSize: '20px' }}>
              Control de usuarios
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={2}>Usuario</Col>
                  <Col xs={10}>
                    <Form.Control
                      id='Usuario'
                      className='form-control'
                      placeholder='Usuario'
                      value={Usuario}
                      onChange={(e) => {
                        setUsuario(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
              </Card.Text>
              <Row>
                <Col xs={10}></Col>
                <Col xs={2}>
                  <Button variant='primary' onClick={()=>{createUser()}}>Guarda</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}></Col>
        <Col xs={3}>
          <Card>
            <Card.Header style={{ fontWeight: 'bold', fontSize: '20px' }}>
              Lista de usuarios
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={12}>
                    <DataTable
                      noHeader
                      defaultSortFieldId={'id'}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      columns={colData}
                      data={UsersData.filter(function (row) {
                        return row
                      })}
                    />
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col></Col>
      </Row>
      <Modal
        show={ModalDisableUser}
        onHide={() => setModalDisableUser(false)}
        size='lg'
      >
        <Modal.Body>
          <Card className='labelSize13px'>
            <Card.Body>
              <Card>
                <Row>
                  <Col xs={12}>
                    <Alert variant='warning' style={{ textAlign: 'center' }}>
                      <h6>
                        Esta seguro de { IDEstatus ? 'deshabilitar' : 'habilitar'} este este usuario ({Usuario})?
                        <br />
                      </h6>
                    </Alert>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <Col></Col>
                  <Col xs={1}>
                    <Button
                      variant='secondary'
                      style={{ textAlign: 'right' }}
                      onClick={() => {
                        setModalDisableUser(false)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                  <Col xs={7}></Col>
                  <Col xs={3}>
                    <Button
                      variant={IDEstatus ? 'danger' : 'primary'}
                      onClick={() => {
                        disableUser()
                      }}
                    >
                      { IDEstatus ? 'Deshabilitar' : 'Habilitar'}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
      <MsgInformativo
              show={showMsg}
              msg={msg}
              header={header}
              msgColor={msgColor} closeToast={()=> {}}      />
    </div>
  )
}
