import React, { FC, useState } from 'react'
import { Alert, Button, Card, Col, Modal, Row } from 'react-bootstrap'
import ICorresponsalPartidas from '../../../Interfaces/Corresponsales/ICorresponsalPedimentoPartidas'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import CPartidasService from '../../../Services/Corresponsalias/Corresponsales.Trafico.Pedimento.Partidas.Services'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { RootState } from '../../../store/store'
import { DialogBox } from './Dialog/DialogBox'
import { deleteCorresponsalesPartidas } from '../../../store/features/Corresponsales/CorresponsalesPartidasSlice'

interface IProps {
  IDTrafico: number
  canEdit: boolean
}

export const Partidas: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  }) 
  const [ShowModal, setShowModal] = useState(false)
  const [MsgDialogDelete, setMsgDialogDelete] = useState(false)
  const [IDPartida, setIDPartida] = useState(0)
  const [Factura, setFactura] = useState('')
  const [Descripcion, setDescripcion] = useState('')
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  // const [Info, setInfo] = useState<ICorresponsalPartidas>()
  const mCPartidas = useSelector(
    (state: RootState) => state.CPartidas.CorresponsalesPartidas
  )
  const msgColor = 'primary'
  const dataColPartidas = [
    {
      name: 'Partida',
      width: '90px',
      selector: (row: ICorresponsalPartidas) => row.partida,
      sortable: true
    },
    {
      name: 'Factura',
      width: '100px',
      selector: (row: ICorresponsalPartidas) => row.factura,
      sortable: true
    },
    {
      name: 'Proveedor',
      center: true,
      width: '450px',
      selector: (row: ICorresponsalPartidas) => row.proveedor
    },
    {
      name: 'Descripcion de material',
      center: true,
      width: '450px',
      selector: (row: ICorresponsalPartidas) => row.descripcionMaterial
    },
    {
      name: 'Frac arancelaria',
      center: true,
      width: '120px',
      selector: (row: ICorresponsalPartidas) => row.fraccionArancelaria
    },
    {
      name: 'Val aduana',
      center: true,
      width: '100px',
      selector: (row: ICorresponsalPartidas) => row.valorAduana
    },
    {
      name: 'DTA',
      center: true,
      width: '70px',
      selector: (row: ICorresponsalPartidas) => row.dta
    },
    {
      name: 'IGI',
      center: true,
      width: '70px',
      selector: (row: ICorresponsalPartidas) => row.igi
    },
    {
      name: 'IEPS',
      center: true,
      width: '70px',
      selector: (row: ICorresponsalPartidas) => row.ieps
    },
    {
      name: '',
      width: '130px',
      cell: (row: ICorresponsalPartidas) => (
        <Row>
          <Col>
            <div
              style={{
                textAlign: 'center',
                cursor: 'pointer',
                visibility: props.canEdit ? 'visible' : 'hidden'
              }}
              onClick={() => {
                loadData(row)
              }}
            >
              <IconContext.Provider value={{ color: 'blue', size: '20px' }}>
                <BsFillPencilFill />
              </IconContext.Provider>
            </div>
          </Col>
          <Col>&nbsp;&nbsp;</Col>
          <Col>
            <div
              style={{
                textAlign: 'center',
                cursor: 'pointer',
                visibility: props.canEdit ? 'visible' : 'hidden'
              }}
              onClick={() => {
                confirmDelete(row)
              }}
            >
              <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                <BsFillTrashFill />
              </IconContext.Provider>
            </div>
          </Col>
        </Row>
      )
    }
  ]

  const loadData = (row: ICorresponsalPartidas) => {
    setIDPartida(row.id)
    setShowModal(true)
  }

  const confirmDelete = (row: ICorresponsalPartidas) => {
    setIDPartida(row.id)
    setFactura(row.factura)
    setDescripcion(row.descripcionMaterial)
    setMsgDialogDelete(true)
  }

  const deleteItem = () => {
    CPartidasService.Delete(IDPartida)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deleteCorresponsalesPartidas(IDPartida))
          setMsgDialogDelete(false)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Card
        style={{
          backgroundColor: '#F1F9FE',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '5px',
          paddingBottom: '5px'
        }}
      >
        <Card.Title>
          <Row>
            <Col xs={11}> Partidas</Col>
            <Col>
              <Button
                style={{
                  visibility: Depto === 'Corresponsalias' || Perfil === 'heineken' ? 'visible' : 'hidden'
                }}
                variant="primary"
                onClick={() => {
                  setIDPartida(0)
                  setShowModal(true)
                }}
              >
                Agregar
              </Button>
            </Col>
          </Row>
        </Card.Title>
        <DataTable
          defaultSortFieldId={'id'}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          columns={dataColPartidas}
          data={mCPartidas.filter((a) => {
            if (a.idTrafico === props.IDTrafico) {
              return a
            }
          })}
        />
      </Card>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(false)
        }}
        size={'sm'}
        dialogClassName={'modal-70w'}
      >
        <Modal.Body>
          <div style={{ height: '400px', overflow: 'scroll' }}>
            <DialogBox IDTrafico={props.IDTrafico} IDPartida={IDPartida} />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={MsgDialogDelete}
        onHide={() => setMsgDialogDelete(false)}
        size="lg"
      >
        <Modal.Body>
          <Alert variant="primary">
            <h5>
              Favor de confirmar
              <br />
              <br />
              ¿Esta seguro de eliminar la factura: {Factura} : {Descripcion}?
            </h5>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={1}>
              <Button
                variant="secondary"
                onClick={() => setMsgDialogDelete(false)}
                size="sm"
              >
                Cerrar
              </Button>
            </Col>
            <Col xs={5} style={{ paddingLeft: '550px', paddingRight: '0px' }}>
              &nbsp;
            </Col>
            <Col xs={1}>
              <Button variant="danger" onClick={() => deleteItem()} size="sm">
                Eliminar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
