import { AgGridReact } from "ag-grid-react";
import React, { useState } from "react";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import { DTOReportesEmbarquesDespachados } from "../../../DTO/Reportes/Embarques/DTOReportesEmabarquesDespachados";
import reportesEmbarquesService from "../../../Services/Reportes/reportesEmbarques.Service";
import { CellIcon } from "../../Utils/CellIcon/CellIcon";
import { DTOBuscarEmbarques } from "../../../DTO/Reportes/Embarques/DTOBuscarEmbarques";
import { FaDownload } from "react-icons/fa";

export const BuscarEmbarques: React.FC = () => {
    const gridRef = React.useRef<any>(null)
    const [Busqueda, setBusqueda] = useState<string>('');
    const [Embarques, setEmbarques] = useState<DTOBuscarEmbarques[]>([]);
    const [columnDefs] = useState([
        { field: 'id', headerName: 'id', width: 70, sortable: true, filter: true },
        { field: 'aduana', headerName: 'ADUANA', },
        { field: 'tipoEmbarque', headerName: 'TIPO DE EMBARQUE',  },
        { field: 'destino', headerName: 'DESTINO',  },
        { field: 'diasEnAduana', headerName: 'DIAS EN ADUANA',  },
        { field: 'nombre', headerName: 'NOMBRE',  },
        { field: 'noPedimento', headerName: 'NO DE PEDIMENTO',  },
        { field: 'tipo', headerName: 'TIPO',  },
        { field: 'ordenCompra', headerName: 'ORDEN DE COMPRA',  },
        { field: 'factura', headerName: 'FACTURA',  },
        { field: 'proveedor', headerName: 'PROVEEDOR',  },
        { field: 'descripcionMercancia', headerName: 'DESCRIPCION',  },
        { field: 'bultos', headerName: 'BULTOS',  },
        { field: 'cantidadContenedores', headerName: 'CANTIDAD DE CONTENEDORES',  },
        { field: 'pesoNeto', headerName: 'PESO NETO',  },
        { field: 'incoterm', headerName: 'INCOTERM',  },
        { field: 'fechaETA', headerName: 'FECHA ETA',  },
        { field: 'estatus', headerName: 'ESTATUS',  },
        { field: 'observaciones', headerName: 'OBSERVACIONES',  },
        { field: 'fechaInicioGastosAlmacenaje', headerName: 'FECHA DE INICIO DE GASTOS DE ALMACENAJE',  },
        { field: 'costoDiarioAlmacenaje', headerName: 'COSTO DIARIO ALMACENAJE',  cellRenderer: (params: any) => {return `$ ${params.data.costoDiarioAlmacenaje}`}},
        { field: 'costoDiarioConexion', headerName: 'COSTO DIARIO CONEXION',  cellRenderer: (params: any) => {return `$ ${params.data.costoDiarioConexion}`}},
        { field: 'totalPagar', headerName: 'TOTAL A PAGAR ALMACENAJE',  cellRenderer: (params: any) => {return `$ ${params.data.totalPagar}`}},
        { field: 'totalPagarConexion', headerName: 'TOTAL PAGAR CONEXION',  cellRenderer: (params: any) => {return `$ ${params.data.totalPagarConexion}`}},
        { field: 'fechaDespacho', headerName: 'FECHA DE DESPACHO',  },
        { field: 'nombrePaqueteria', headerName: 'NOMBRE DE PAQUETERIA O TRANSPORTISTA',  },
        { field: 'noGuiaEmbarqueDestino', headerName: 'NO. DE GUIA DE EMBARQUE A DESTINO',  },
        { field: 'fechaEntregaDestinoFinal', headerName: 'FECHA DE ENTREGA EN DESTINO FINAL',  },
        { field: 'facturaCorresponsal', headerName: 'FACTURA',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.facturaCorresponsal} idTrafico={params.data.id} proceso={16} ></CellIcon>
        }},
        { field: 'certificadoCalidad', headerName: 'CERTIFICADO DE CALIDAD',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.certificadoCalidad} idTrafico={params.data.id} proceso={15} ></CellIcon>
        }},
        { field: 'packingList', headerName: 'PACKING LIST',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.packingList} idTrafico={params.data.id} proceso={18} ></CellIcon>
        }},
        { field: 'hojaTecnica', headerName: 'HOJA TECNICA',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.hojaTecnica} idTrafico={params.data.id} proceso={17} ></CellIcon>
        }},
        { field: 'bl', headerName: 'BL',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.bl} idTrafico={params.data.id} proceso={14} ></CellIcon>
        }},
        { field: 'pedimentoInformativo', headerName: 'PEDIMENTO INFORMATIVO',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.pedimentoInformativo} idTrafico={params.data.id} proceso={12} ></CellIcon>
        }, width: 130},
        { field: 'pedimentoSimplificado', headerName: 'PEDIMENTO SIMPLIFICADO',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.pedimentoSimplificado} idTrafico={params.data.id} proceso={13} ></CellIcon>
        }, width: 130},
        { field: 'doda', headerName: 'DODA',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.doda} idTrafico={params.data.id} proceso={11} ></CellIcon>
        }, width: 100},
        { field: 'honorarios', headerName: 'HONORARIOS AA',  cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.honorarios} idTrafico={params.data.id} proceso={37} ></CellIcon>
        }, width: 130},
        { field: 'totalHonorarios', headerName: 'TOTAL HONORARIOS AA', cellRenderer: (params: any) => {return `$ ${params.data.totalHonorarios}`}},
        { field: 'gastosTerceros', headerName: 'GASTOS A TERCEROS', cellRenderer: (params: any) => {
            return <CellIcon hasFile={params.data.gastosTerceros} idTrafico={params.data.id} proceso={41} ></CellIcon>
        }, width: 130},
        { field: 'totalGastosTerceros', headerName: 'TOTAL GASTOS A TERCEROS', },
        { field: 'comprobantesGastosTerceros', headerName: 'COMPROBANTES GASTOS A TERCEROS', }
    ]);

    const filtraEmbarques = (e: any) => {
        gridRef.current.api.setQuickFilter(e.target.value)
    }

    const buscarEmbarques = () => {
        if(Busqueda !== '' && Busqueda !== null && Busqueda !== undefined){
            reportesEmbarquesService.BuscarEmbarques(Busqueda)
            .then((response) => {
                setEmbarques(response.data);
            });
        }else{
            //Mostrar mensaje de error
        }
    }

    const exportarEmbarques = () => {
        reportesEmbarquesService.GetExcelBuscados(Busqueda)
        .then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ReporteBusquedaDeEmbarques.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    }

    return (
        <>
        <Card>
            <Card.Body>
            <Row className="mb-2">
                    <Col xs={5}>
                        <Form.Control
                            value={Busqueda}
                            type='text'
                            size='sm'
                            placeholder='Search...'
                            onChange={(e) => {
                                setBusqueda(e.target.value)
                            }}
                        />
                    </Col>
                    <Col>
                        <Button className="me-2" variant="primary" onClick={buscarEmbarques}>Generar</Button>
                        { Embarques.length > 0 ? <Button onClick={exportarEmbarques}><FaDownload/>&nbsp;Excel</Button> : "" }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        <Card className='mt-2' style={{visibility: Embarques.length > 0 ? "visible" : "hidden"}}>
            <Card.Body>  
                <Row className="mb-2">
                    <Col xs={5}>
                        <Form.Control
                            type='text'
                            size='sm'
                            placeholder='Search...'
                            onChange={(e) => {
                            filtraEmbarques(e)
                            }}
                        />
                    </Col>
                </Row>
                <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
                    <AgGridReact
                    rowData={Embarques}
                    columnDefs={columnDefs}
                    defaultColDef={{
                        resizable: true,
                        initialWidth: 200,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                    }}
                    pagination={true}
                    paginationAutoPageSize={true}
                    ref={gridRef}
                    rowSelection={'single'}
                    rowMultiSelectWithClick={true}
                    ></AgGridReact>
                </div>
            </Card.Body>
        </Card>
        </>
    );
}