import { FC } from 'react'
import { NavDropdown } from 'react-bootstrap'
import IUsuariosPerfilesMenu from '../../Interfaces/IPerfilesMenu'

interface IProps {
  menu: IUsuariosPerfilesMenu[] | undefined
}

const MenuUsuario: FC<IProps> = (props) => {
  return (
    <div>
      {props.menu
        ? props.menu.map((mainMenu) => {
            return mainMenu.padreId === 0 ? (
              <NavDropdown title={mainMenu.descripcion} id='basic-nav-dropdown'>
                {props.menu
                  ? props.menu.map((item) => {
                      return item.padreId === mainMenu.itemMenu ? (
                        <NavDropdown.Item key={item.id} href={item.url}>
                          {item.descripcion}
                        </NavDropdown.Item>
                      ) : (
                        ''
                      )
                    })
                  : null}
              </NavDropdown>
            ) : (
              ''
            )
          })
        : null}
    </div>
  )
}

export default MenuUsuario
