import DTORptCorresponsalesTraficos from "../../DTO/Corresponsales/DTORptCorresponsalesTraficos";
import { DTOReporteGC50 } from "../../DTO/Reportes/GC50/DTOReporteGC50";
import http from "../common/http-common";
class ReporteGC50Service{
    GetReporteGC50(data: DTORptCorresponsalesTraficos){
        return http.get<DTOReporteGC50[]>(`reporteGC50?Inicio=${data.Inicio}&Fin=${data.Fin}&TipoOperacion=${data.TipoOperacion}&NoCliente=${data.NoCliente}&IdCorresponsal=${data.IdCorresponsal}&Pedimento=${data.Pedimento}&Aduana=${data.Aduana}&Patente=${data.Patente}&Proceso=${data.Proceso}&Modo=${data.Modo}`);
    }
    
    DownloadExcel(data: DTORptCorresponsalesTraficos){
        return http.get(`reporteGC50/ExcelGc50?Inicio=${data.Inicio}&Fin=${data.Fin}&TipoOperacion=${data.TipoOperacion}&NoCliente=${data.NoCliente}&IdCorresponsal=${data.IdCorresponsal}&Pedimento=${data.Pedimento}&Aduana=${data.Aduana}&Patente=${data.Patente}&Proceso=${data.Proceso}&Modo=${data.Modo}`, {responseType: 'blob'});
    }
}

export default new ReporteGC50Service();