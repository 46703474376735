import  React, {FC, useRef, useState} from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { MdCloudDownload, MdCloudUpload, MdDelete, MdDeleteForever } from "react-icons/md";
import * as XLSX from 'xlsx'
import CTrafDataService from '../../Services/Corresponsalias/Corresponsales.Trafico.Services'
import { MsgInformativo } from "../Utils/Toast/msgInformativo";
import { FaRegFileExcel } from "react-icons/fa";
import IReporteOperacionesNewell from "../../Interfaces/ReportesClientes/IReporteOperacionesNewell";
import axios from "axios";
import { TargetURL } from "../../Constants/TargetURL";
import reportesNewellServices from "../../Services/Reportes/reportesNewell.services";
import FileManagerServices from "../../Services/Utils/FileManager.Services";

export const ReporteSemanalNewell: React.FC<{}> = () => {

    const [Inicio, setInicio] = useState(currentDate(-7))
    const [Fin, setFin] = useState(currentDate(0))
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const [show, setShowMsg] = useState(false)
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [msgColor, setMsgColor] = useState('primary')
    const [File, setFile] = useState<File | null>();
    const URL = new TargetURL()
    const [UserId, setUserId] = useState(() => {
        const stickyValue = window.localStorage.getItem('UserId')
        return stickyValue !== null ? JSON.parse(stickyValue) : 0
      });
    const token = localStorage.getItem('token');
    const [FileId, setFileId] = useState(0);

    function currentDate(days: number): string {
        var today = new Date()
        today.setDate(today.getDate() + days)
        var dd = String(today.getDate()).padStart(2, '0')
        var mm = String(today.getMonth() + 1).padStart(2, '0')
        var yyyy = today.getFullYear()
        return yyyy + '-' + mm + '-' + dd
    }

    const uploadFile = (file: File) => {
        const formData = new FormData()
        formData.append('name', file.name)
        formData.append('file', file)
        axios.post(
            URL.get() + `/FileManager/AppendFileByProcess?IdUsuario=${UserId}&Proceso=${42}&Tags=${0}&crud=1`,
            formData, {
                headers:{
                'Authorization': 'Bearer ' +  token
                }
            }
        )
        .then((res) => {
            if (res.data.id > 0) {
                setFileId(res.data.id);
            } else {
                setHeader('Error')
                setMsg('No se guardo el archivo, favor de verificar que no este vacio')
                setShowMsg(true)
                return
            }
        })
        .catch((err) => alert('File Upload Error ' + err))
    }

    const downloadReporte = () => {
        reportesNewellServices.getReporteSemanal(FileId)
        .then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ReporteSemanal_${Inicio}_${Fin}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(e => {
            setHeader('Error')
            setMsg('Ocurrio un error al generar el reporte. Por favor, intentelo de nuevo.')
            setShowMsg(true)
            return
        })
    }

    const deleteFile = () => {
        FileManagerServices.DeleteFile(FileId)
        .then(resp => {
            if(resp.status === 200 && hiddenFileInputRef.current !== null ) {
                hiddenFileInputRef.current.value = "";
                setFile(null);
                setFileId(0)
            }
        })
    }

    return (
        <>
            <Row style={{paddingTop:'7rem'}}></Row>
            <Row>
                <Col xs={4}></Col>
                <Col xs={4}>
                    <Card style={{width:'100%'}}>
                        <Card.Header><h4>Reporte Semanal Newell</h4></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label style={{width:'100%'}}>
                                        Inicio 
                                        <Form.Control
                                            defaultValue={Inicio}
                                            type='date'
                                            name='Inicio'
                                            placeholder='Inicio'
                                            title='Inicio'
                                            alt='Inicio'
                                            data-date-format='YYYY-mm-dd'
                                            onChange={(e) => setInicio(e.target.value)}
                                            size='sm'
                                        />
                                    </Form.Label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label style={{width:'100%'}}>
                                        Fin
                                        <Form.Control
                                            defaultValue={Fin}
                                            type='date'
                                            name='Fin'
                                            placeholder='Fin'
                                            title='Fin'
                                            alt='Fin'
                                            onChange={(e) => setFin(e.target.value)}
                                            size='sm'
                                        />
                                    </Form.Label>
                                </Col>
                            </Row>
                            <br/>
                            <Row style={{justifyContent:'center', alignItems:'center', padding:'0px 20px 0px 20px'}}>
                                <form 
                                style={{
                                    display: 'flex', flexDirection:'column', justifyContent:'center',
                                    alignItems:'center', border: '2px dashed #1475cf', borderRadius:'5px',
                                    height: '300px', width:'500px', cursor: 'pointer',
                                }}
                                onClick={() => {hiddenFileInputRef.current?.click()}}
                                >
                                    <input type="file" ref={hiddenFileInputRef} style={{display:'none'}}
                                        onChange={(e:any) => {
                                            uploadFile(e.target.files[0]);
                                            setFile(e.target.files[0]);
                                        }}
                                    />

                                    {
                                        FileId === 0 ? <>
                                                <MdCloudUpload style={{fontSize:'60px', color:'#1475cf'}}/>
                                                <span style={{display:'block', paddingTop:'10px'}}>Seleccione un archivo...</span>
                                            </>
                                            : <>
                                                <FaRegFileExcel style={{fontSize:'60px', color:'#17d149'}}/>
                                                <span style={{display:'block', paddingTop:'10px'}}>{File!.name}</span>
                                            </>
                                    }
                                </form>
                            </Row>
                        </Card.Body>
                        <Card.Footer style={{textAlign:'end'}}>
                            <Button variant="danger" onClick={deleteFile} title="Quitar archivo cargado" style={{marginRight:'10px'}}>
                                Borrar <MdDeleteForever style={{marginLeft:'5px', fontSize:'20px'}}/>
                            </Button>
                            <Button className="primary" onClick={downloadReporte} title="Descargar reporte">
                                Generar <MdCloudDownload style={{marginLeft:'5px'}}/>
                            </Button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col xs={4}></Col>
            </Row>
            <MsgInformativo
                show={show}
                msg={msg}
                header={header}
                msgColor={msgColor}
                closeToast={() => {
                setShowMsg(false)
                }}
            />
        </>
    )
}