import { FC, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DashboardDataService from '../../Services/Dashboard/Dashboard.Corresponsales.Services';
import { useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { IconContext } from 'react-icons';
import { FcSurvey } from 'react-icons/fc';
import { MdTimer } from 'react-icons/md';

interface IProps {
  Perfil:string
}

export default function DashboardCorresponsales(props: IProps) {
  let navigate = useNavigate();
  const [TotalCorresponsales, setTotalCorresponsales] = useState(0);
  const [labels, setlabels] = useState<string[]>([]);
  const [series, setseries] = useState<number[]>([]);
  const [Solicitudes, setSolicitudes] = useState(0);
  //const [TipoCambio, setTipoCambio] = useState(0)
  const [CircleOptions, setCirleOpcions] = useState<ApexOptions>({
    labels: [
      '(2) Rechazos para el corresponsal',
      ' (0) Rechazados x Contabilidad',
      '(10) Pendientes por terminar',
    ],
    colors: ['#EB984E', '#E52626', '#ABEB4E', '#2288D3'],
    legend: { position: 'bottom' },
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
        },
      },
    },
  });

  useEffect(() => {
    DashboardDataService.getSolicitudesEliminarCuenta().then((response) => {
      setSolicitudes(response.data.total)
    }).catch((e: Error) => {return;})
    DashboardDataService.getCorresponsales()
      .then((response) => {
        console.log(response.data);
        let labels: string[] = [];
        let series: number[] = [];
        response.data.forEach((element) => {
          if (element.descripcion.indexOf('Total') === -1) {
            labels.push('(' + element.total + ') : ' + element.descripcion);
            series.push(element.total);
          } else {
            setTotalCorresponsales(element.total);
          }
        });
        setlabels(labels);
        setseries(series);

        setCirleOpcions({
          labels: labels,
          colors: ['#EB984E', '#E52626', '#ABEB4E'],
          legend: { position: 'bottom' },
          plotOptions: {
            pie: {
              donut: {
                size: '55%',
                labels: {
                  show: true,
                  total: {
                    showAlways: true,
                    show: true,
                    fontSize: '15px',
                  },
                  value: {
                    show: true,
                    fontSize: '43px',
                    fontWeight: 'bold',
                  },
                },
              },
            },
          },
        });
      })
      .catch((e: Error) => {
        return;
      });
    //getTipoCambio(currentDate())
  }, []);

  function ConvertStringToHex(str: string) {
    var arr = [];
    for (var i = 0; i < str.length; i++) {
      arr[i] = ('00' + str.charCodeAt(i).toString(16)).slice(-4);
    }
    return '\\u' + arr.join('\\u');
  }

  function currentDate(): string {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  return (
    <>
      <Col>
        <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
          <Card.Header style={{fontSize:'15.9px'}}>
            <b>Corresponsales:</b> Traficos pendientes
          </Card.Header>
          <Card.Body style={{ paddingBottom: '10px', paddingTop: '0px' }}>
            {labels ? (
              <ReactApexChart
                type='donut'
                options={CircleOptions}
                series={series}
                width={250}
                height={310}
              />
            ) : (
              ''
            )}
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col xs={6} style={{ paddingRight: '5px' }}>
                {/*                 <Link
                  to={`../RptCorresponsalesTraficosHst?proc=0&status=0`}
                  style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
                >
                  Buscar
                </Link> */}
                {/* <span style={{ fontWeight: 'bold' }}>Tipo cambio: ${TipoCambio}</span> */}
              </Col>
              {/*  <Col xs={3}></Col> */}
              <Col xs={6} style={{ paddingRight: '5px' }}>
                <Link
                  to={`../RptCorresponsalesTraficos/proc=1/modo=1`}
                  style={{
                    textDecoration: 'none',
                    float: 'right',
                    paddingRight: '10px',
                  }}
                >
                  Ver mas...
                </Link>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
      {props.Perfil === 'Sup. Corresponsalias' ?
        <Col>
          <Card style={{ width: '18rem' }} className='dashboardComponentFont'>
            <Card.Header>Facturación </Card.Header>
            <Card.Body style={{ paddingBottom: '50px' }}>
              <Card.Title>Solicitudes para eliminar cuenta<br/><br/></Card.Title>
              <div style={{ fontSize: '6em' }} className='text-center'>
                <IconContext.Provider value={{ color: '#ff671b' }}>
                  <FcSurvey style={{fontSize:'larger'}}/>
                  <MdTimer style={{fontSize:'70px', margin:'0 0 0 -4rem', position:"absolute", bottom: '6rem'}}/>
                </IconContext.Provider>
                {Solicitudes}
            </div>
            </Card.Body>
            <Card.Footer style={{ paddingRight: '5px' }}>
              <Link
                to='../RptCorresponsalesTraficos/proc=1/modo=2'
                style={{ textDecoration: 'none', float: 'right', paddingRight: '10px' }}
              >
                Ver mas...
              </Link>
            </Card.Footer>
          </Card>
        </Col> : ""
      }
    </>
  );
};
