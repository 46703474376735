import React, { FC, useState } from 'react'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import ProvDataService from '../../../../Services/Catalogos/Proveedores.Services'
import { useDispatch } from 'react-redux'
import ICatProveedores from '../../../../Interfaces/Catalogos/ICatProveedores'
import { updateCatProveedores } from '../../../../store/features/CatProveedores/CatProveedoresSlice'

interface IProps {
  id: number
  value: any
  disabled?: boolean
  mode: number // 1: Number, 2: String
  clasificacion: number
}

export const ControlledInput: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const [id, setid] = useState(props.id)
  const [value, setValue] = useState(props.value)
  const [header, setHeader] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')

  const onChange = (event: any) => {
    setValue(event.target.value)
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (isNaN(event.target.value) && props.mode === 1) {
        alert('Valor no valido!')
        return
      }
      const data: ICatProveedores = {
        id: id,
        nombre: value,
        clasificacion: props.clasificacion,
      }
      ProvDataService.Append(data)
        .then((response) => {
          if (response.status == 200) {
            dispatch(updateCatProveedores(response.data))
            setHeader('Informativo')
            setMsg('La informacion se guardo exitosamente')
            setShowMsg(true)
            return
          }
        })
        .catch((e: Error) => {
          setHeader('Error')
          setMsg('Ocurrio un error: ' + e)
          setShowMsg(true)
          return
        })
    }
  }
  return (
    <>
      <input
        value={value}
        onChange={onChange}
        disabled={false}
        onKeyDown={(e) => handleKeyDown(e)}
        style={{ width: '75%' }}
      />
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </>
  )
}
